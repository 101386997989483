import {
  ALL_TOKEN_ALLOCATIONS,
  ADD_TOKEN_ALLOCATIONS,
  UPDATE_TOKEN_ALLOCATIONS,
  REMOVE_TOKEN_ALLOCATIONS,
} from "../actions/TokenAllocationTypes";

const initialState = {
  list: [],
};

export function TokenAllocationConfigReducer(state = initialState, action) {
  if (action.type === ALL_TOKEN_ALLOCATIONS) {
    return {
      ...state,
      list: action.payload.list,
    };
  }

  if (action.type === ADD_TOKEN_ALLOCATIONS) {
    const list = [...state.list];
    list.push(action.payload.item);
    return {
      ...state,
      list,
    };
  }

  if (action.type === UPDATE_TOKEN_ALLOCATIONS) {
    const list = [...state.list];
    const itemIndex = list.findIndex(
      (item) => item.id === action.payload.item.id
    );
    list[itemIndex] = { ...list[itemIndex], ...action.payload.item };
    return {
      ...state,
      list,
    };
  }

  if (action.type === REMOVE_TOKEN_ALLOCATIONS) {
    const list = [...state.list];
    const itemIndex = list.findIndex(
      (item) => item.id === action.payload.deletedId
    );
    list.splice(itemIndex, 1);
    return {
      ...state,
      list,
    };
  }

  return state;
}
