/// Menu
import MetisMenu from "metismenujs";
import React, { Component, useEffect, useContext } from "react";
import { useSelector, useDispatch } from "react-redux";
/// Scroll
import PerfectScrollbar from "react-perfect-scrollbar";
/// Link
import { Link, useHistory } from "react-router-dom";
import {
  getFirstName,
  getPermissions,
  getIsApproved,
  getAvatar,
  getUsername,
  getUser,
  getRoleId,
} from "../../../store/selectors/AuthSelectors";

import { logout, lockAction } from "../../../store/actions/AuthActions";
import moment from "moment";
import { useWeb3React } from "@web3-react/core";
import { ThemeContext } from "../../../context/ThemeContext";

class MM extends Component {
  componentDidMount() {
    this.$el = this.el;
    this.mm = new MetisMenu(this.$el);
  }

  componentWillUnmount() {}

  render() {
    return (
      <div className="mm-wrapper">
        <ul className="metismenu" ref={(el) => (this.el = el)}>
          {this.props.children}
        </ul>
      </div>
    );
  }
}

function SideBar() {
  const firstName = useSelector(getFirstName);
  const permissions = useSelector(getPermissions);
  const isApproved = useSelector(getIsApproved);
  const avatar = useSelector(getAvatar);
  const user = useSelector(getUser);
  const username = useSelector(getUsername);
  const dispatch = useDispatch();
  const { deactivate } = useWeb3React();
  const history = useHistory();
  const { menuToggle } = useContext(ThemeContext);
  const roleId = useSelector(getRoleId);

  /// Open menu
  useEffect(() => {
    // sidebar open/close
    var btn = document.querySelector(".nav-control");
    var aaa = document.querySelector("#main-wrapper");
    function toggleFunc() {
      return aaa.classList.toggle("menu-toggle");
    }
    btn.addEventListener("click", toggleFunc);

    // //sidebar icon Heart blast
    // var handleheartBlast = document.querySelector(".heart");
    // function heartBlast() {
    //   return handleheartBlast.classList.toggle("heart-blast");
    // }
    // handleheartBlast.addEventListener("click", heartBlast);
  }, []);

  function handlechange() {
    history.push("/profile");
  }

  function onLogout() {
    deactivate();
    dispatch(logout(history));
  }

  function onLock() {
    dispatch(lockAction(history));
  }

  /// Path
  let path = window.location.pathname;
  path = path.split("/");
  path = path[path.length - 1];

  const routes = [
    {
      key: "dashboard-route",
      route: "dashboard",
      name: "Dashboard",
      iconClassName: "flaticon-144-layout",
      permissions: [],
      locked: false,
    },
    {
      key: "users-route",
      route: "#",
      name: "Users",
      iconClassName: "flaticon-381-user-9",
      permissions: ["read:user"],
      locked: !isApproved,
      child: [
        {
          key: "list",
          route: "user-list",
          name: "Users List",
          permissions: ["read:user"],
          locked: !isApproved,
        },
        {
          key: "roles",
          route: "role-list",
          name: "Roles List",
          permissions: ["read:role"],
          locked: !isApproved,
        },
      ],
    },
    {
      key: "events",
      route: "#",
      name: "Rewards & Coupons",
      iconClassName: "flaticon-381-network",
      permissions: ["read:contest"],
      locked: !isApproved,
      child: [
        {
          key: "contest",
          route: "contests-list",
          name: "Contests",
          permissions: ["read:contest"],
          locked: !isApproved,
        },
        {
          key: "coupons",
          route: "coupons-list",
          name: "Coupons",
          permissions: ["read:reward"],
          locked: !isApproved,
        },
        {
          key: "products",
          route: "products-list",
          name: "Products",
          permissions: ["read:reward"],
          locked: !isApproved,
        },
        {
          key: "token-reward",
          route: "token-rewards",
          name: "Token Reward",
          permissions: ["read:reward"],
          locked: !isApproved,
          hidden: roleId === 1 || user.isNGO === true ? false : true,
        },
      ],
    },
    {
      key: "my-wallet",
      route: "my-wallet",
      name: "My Wallet",
      iconClassName: "flaticon-381-id-card",
      permissions: ["read:wallet"],
      locked: !isApproved,
    },
    {
      key: "transactions",
      route: "transactions",
      name: "Transactions",
      iconClassName: "flaticon-381-unlocked-4",
      permissions: ["read:finance"],
      locked: !isApproved,
    },
    {
      key: "projects",
      route: "#",
      name: "Projects",
      iconClassName: "flaticon-381-locations",
      permissions: ["read:project"],
      locked: !isApproved,
      child: [
        {
          key: "my-projects",
          route: "my-projects",
          name: "All Projects",
          permissions: [],
          locked: !isApproved,
        },
        {
          key: "add-project",
          route: "add-project",
          name: "Add Project",
          permissions: [],
          locked: !isApproved,
        },
      ],
    },
    {
      key: "donations",
      route: "#",
      name: "Donations",
      iconClassName: "flaticon-381-plus",
      permissions: ["read:donation", "read:tree"],
      locked: !isApproved,
      child: [
        {
          key: "my-donations",
          route: "my-donations",
          name: "All Donations",
          permissions: [],
          locked: !isApproved,
        },
        {
          key: "add-donation",
          route: "add-donation",
          name: "Add Donations",
          permissions: ["add:tree"],
          locked: !isApproved,
        },
      ],
    },
    {
      key: "virtual-lookup",
      route: "virtual-lookup",
      name: "Virtual Lookup",
      iconClassName: "flaticon-381-earth-globe-1",
      permissions: [],
      locked: !isApproved,
    },
    {
      key: "coin-details",
      route: "coin-details",
      name: "Coin Details",
      iconClassName: "flaticon-381-menu-3",
      permissions: ["read:coin"],
      locked: !isApproved,
    },
    {
      key: "mobile-app",
      route: "mobile-app",
      name: "Mobile App",
      iconClassName: "flaticon-381-smartphone",
      permissions: ["read:app"],
      locked: !isApproved,
      child: [
        {
          key: "android",
          route: "android-devices",
          name: "Android",
          permissions: [],
          locked: !isApproved,
        },
        {
          key: "ios",
          route: "ios-devices",
          name: "iOS",
          permissions: [],
          locked: !isApproved,
        },
      ],
    },
    {
      key: "settings",
      route: "#",
      name: "Settings",
      iconClassName: "flaticon-381-settings-7",
      permissions: ["read:settings"],
      locked: false,
      child: [
        {
          key: "profile",
          route: isApproved ? "profile" : "account",
          name: "Profile",
          permissions: [],
          locked: false,
        },
        {
          key: "error-logs",
          route: "error-logs",
          name: "Error Logs",
          permissions: ["read:error"],
          locked: false,
        },
        {
          key: "lock-portal",
          route: "#",
          name: "Lock Portal",
          onClick: () => onLock(),
          permissions: [],
          locked: false,
        },
        {
          key: "log-out",
          route: "#",
          name: "Log Out",
          onClick: () => onLogout(),
          permissions: [],
          locked: false,
        },
      ],
    },
    {
      key: "wishlist",
      route: "wishlist",
      name: "Wishlist",
      iconClassName: "flaticon-381-earth-globe-1",
      permissions: ["read:project"],
      locked: !isApproved,
    },
    {
      key: "help-support",
      route: "help-support",
      name: "Help & Support",
      iconClassName: "flaticon-381-help-1",
      permissions: [],
      locked: !isApproved,
    },
    {
      key: "feedback",
      route: "feedback",
      name: "Feedback",
      iconClassName: "flaticon-381-resume",
      permissions: [],
      locked: !isApproved,
    },
    {
      key: "audit-logs",
      route: "audit-logs",
      name: "Audit Logs",
      iconClassName: "flaticon-381-id-card-4",
      permissions: ["read:role"],
      locked: !isApproved,
    },
    {
      key: "token-allocations",
      route: "#",
      name: "Token Allocations",
      iconClassName: "flaticon-381-settings-7",
      permissions: ["read:allocaionConfig"],
      isAvailable: roleId === 1 ? true : false,
      locked: false,
      child: [
        {
          key: "all-token-allocations",
          route: "token-allocations",
          name: "All Token Allocations",
          permissions: [],
          locked: false,
        },
        {
          key: "add-token-allocation",
          route: "add-token-allocation",
          name: "Add Token Allocation",
          permissions: [],
          locked: false,
        },
      ],
    },
  ];

  return (
    <div className="deznav">
      <PerfectScrollbar className="deznav-scroll">
        <div className="main-profile">
          <div className="image-bx">
            <img
              src={avatar}
              alt=""
              style={{ backgroundColor: "white", borderRadius: 5, padding: 5 }}
            />
            <Link to={"#"}>
              <i
                className="fa fa-cog"
                aria-hidden="true"
                onClick={handlechange}
              ></i>
            </Link>
          </div>
          <h5 className="mb-0 fs-20 text-black ">
            <span className="font-w400">Hello,</span> {firstName}
          </h5>
          <p className="mb-0 fs-14 font-w400">{username}</p>
        </div>
        <MM className="metismenu" id="menu">
          {routes.map((item, index) => {
            return (typeof item.isAvailable === "boolean" &&
              item.isAvailable) ||
              item.permissions.length == 0 ||
              permissions.some((perm) =>
                item.permissions.some((_perm) => _perm === perm)
              ) ? (
              <li
                key={item.key}
                className={`${
                  (item.child &&
                    item.child.some((child) => child.route == path)) ||
                  item.route == path
                    ? "mm-active"
                    : ""
                }`}
              >
                <Link
                  className={`${
                    item.child && !item.locked ? "has-arrow" : ""
                  } ai-icon`}
                  to={item.locked ? "#" : "/" + item.route}
                >
                  <i className={item.iconClassName}></i>
                  <span
                    className="nav-text"
                    style={{ marginRight: item.locked ? 4 : 0 }}
                  >
                    {item.name}
                  </span>
                  {item.locked && !menuToggle ? (
                    <i
                      className={"flaticon-381-lock-2"}
                      style={{
                        color: "#E74C3C",
                        position: "absolute",
                        right: 5,
                        top: "auto",
                        bottom: "auto",
                      }}
                    ></i>
                  ) : null}
                </Link>
                {item.child && !item.locked && !item.hidden ? (
                  <ul>
                    {item.child.map((child, index) =>
                      (child.permissions.length == 0 ||
                        permissions.some((perm) =>
                          child.permissions.some((_perm) => _perm === perm)
                        )) &&
                      !child.hidden ? (
                        <li key={child.key}>
                          <Link
                            {...{ onClick: child.onClick }}
                            className={`${
                              path === child.route ? "mm-active" : ""
                            }`}
                            to={"/" + child.route}
                          >
                            {child.name}
                          </Link>
                        </li>
                      ) : null
                    )}
                  </ul>
                ) : null}
              </li>
            ) : null;
          })}
        </MM>
        <div className="copyright">
          <p>
            <strong>Terra Nova Technologies</strong> © {moment().format("YYYY")}{" "}
            All Rights Reserved
          </p>
          {/* <p className="fs-12">
            Made with <span className="heart"></span> by Queper Private LTD
          </p> */}
        </div>
      </PerfectScrollbar>
    </div>
  );
}

export default SideBar;
