import React, { useEffect } from "react";
import Switch from "react-switch";
import { Row } from "react-bootstrap";
import DropZone from "../../../partials/DropZone";
import EventForm from "./EventForm";
import ProductForm from "./ProductForm";
import RewardForm from "./RewardForm";
import { useSelector, useDispatch } from "react-redux";
import { getCoupons } from "../../../../../store/selectors/CouponSelector";
import { couponList } from "../../../../../store/actions/CouponActions";
import {
  getUserId,
  getPilotCity,
} from "../../../../../store/selectors/AuthSelectors";

const TARGET_TYPES_SU = ["Tree Plantation", "Purchase Product", "Water Pond"];
const TARGET_TYPES_MU = ["Event"];
const REACH = ["City Wide", "Private", "Global"];
const DURATIONS = [
  { title: "24 Hours", value: 1 },
  { title: "3 Days", value: 3 },
  { title: "7 Days", value: 7 },
  { title: "15 Days", value: 15 },
  { title: "30 Days", value: 30 },
  { title: "45 Days", value: 45 },
  { title: "60 Days", value: 60 },
  { title: "90 Days", value: 90 },
];
const BORDs = ["Single", "Multiple", "Lottery"];

function CreateContestForm(props) {
  const {
    handleChange,
    errors,
    formData,
    onAddBenefit,
    onRemoveBenefit,
    handleChangeBenefit,
    handleChangeProduct,
    handleChangeEvent,
    handleChangeOption,
    onAddOption,
    onRemoveOption,
  } = props;
  const pilotCity = useSelector(getPilotCity);
  const userId = useSelector(getUserId);
  const coupons = useSelector(getCoupons);
  const dispatch = useDispatch();

  useEffect(() => {
    if (coupons.length == 0) {
      dispatch(couponList(userId));
    }
  }, []);

  return (
    <section>
      <div className="row">
        <div className="col-lg-4 mb-2 text-center">
          <div className="form-group mt-3">
            <DropZone
              src={formData.banner ? formData.banner.uri : null}
              title="Contest Banner"
              caption="Drag & Drop a banner image here"
              onlyImage={true}
              onChange={(file) => handleChange("banner", file)}
              onRemove={() => handleChange("banner", null)}
              error={errors.banner}
              width="70%"
              height="500px"
            />
          </div>
        </div>
        <div className="col-lg-8 mb-2">
          <div className="form-group">
            <label className="text-label">Title*</label>
            <input
              value={formData.title}
              type="text"
              name="name"
              className={
                errors.title ? "form-control border-danger" : "form-control"
              }
              placeholder="Title"
              required
              onChange={(e) => handleChange("title", e.target.value)}
            />
            <small className="text-danger">{errors.title}</small>
          </div>
          <div className="form-group">
            <label className="text-label">Description*</label>
            <textarea
              value={formData.description}
              type="text"
              name="description"
              style={{ minHeight: "150px", maxHeight: "150px" }}
              className={
                errors.description
                  ? "form-control border-danger"
                  : "form-control"
              }
              placeholder="Description"
              required
              onChange={(e) => handleChange("description", e.target.value)}
            ></textarea>
            <small className="text-danger">{errors.description}</small>
          </div>
          <div className="form-group">
            <label className="text-label">Contest Reach*</label>
            <select
              value={formData.reach}
              defaultValue={""}
              className={
                errors.reach
                  ? "form-control custom-select border-danger"
                  : "form-control custom-select"
              }
              onChange={(e) => handleChange("reach", e.target.value)}
            >
              <option value={""}>Select</option>
              {REACH.map((reach, index) => (
                <option key={index} value={reach}>
                  {reach}
                </option>
              ))}
            </select>
            {errors.reach ? (
              <small className="text-danger">{errors.reach}</small>
            ) : formData.reach == "City Wide" ? (
              <small>
                Contest will only available for users registered in{" "}
                <strong>{pilotCity.name}</strong>
              </small>
            ) : formData.reach == "Private" ? (
              <small>
                Contest will only available for users registered by you
              </small>
            ) : formData.reach == "Global" ? (
              <small>
                Contest will available for participants in all pilot Cities
              </small>
            ) : null}
          </div>
          <div className="form-group">
            <label className="text-label">Contest Duration*</label>
            <select
              value={formData.duration}
              defaultValue={""}
              className={
                errors.duration
                  ? "form-control custom-select border-danger"
                  : "form-control custom-select"
              }
              onChange={(e) => handleChange("duration", e.target.value)}
            >
              <option value={""}>Select</option>
              {DURATIONS.map((duration, index) => (
                <option key={index} value={duration.value}>
                  {duration.title}
                </option>
              ))}
            </select>
            {errors.duration ? (
              <small className="text-danger">{errors.duration}</small>
            ) : formData.duration ? (
              <small>
                Upon approval the contest will run for {formData.duration}{" "}
                Day(s)
              </small>
            ) : null}
          </div>
          <div className="form-group">
            <label className="text-label">Reward Distribution*</label>
            <select
              value={formData.bord}
              defaultValue={""}
              className={
                errors.bord
                  ? "form-control custom-select border-danger"
                  : "form-control custom-select"
              }
              onChange={(e) => {
                handleChange("bord", e.target.value);
                handleChange("type", "");
                handleChange("target", "");
                handleChange("product", {
                  title: "",
                  description: "",
                  price: "",
                });
                handleChange("cEvent", { title: "", options: [] });
              }}
            >
              <option value={""}>Select</option>
              {BORDs.map((bord, index) => (
                <option key={index} value={bord}>
                  {bord}
                </option>
              ))}
            </select>
            {errors.bord ? (
              <small className="text-danger">{errors.bord}</small>
            ) : formData.bord == "Single" ? (
              <small>
                The reward will be given to the first user who archive the
                target
              </small>
            ) : formData.bord == "Multiple" ? (
              <small>
                The reward as a whole will be given to every single user who
                archive the target
              </small>
            ) : formData.bord == "Lottery" ? (
              <small>A random participant will be given the reward</small>
            ) : null}
          </div>
          {formData.bord && formData.bord !== "Lottery" ? (
            <div className="form-group">
              <label className="text-label">Contest Type*</label>
              <select
                value={formData.type}
                defaultValue={""}
                className={
                  errors.type
                    ? "form-control custom-select border-danger"
                    : "form-control custom-select"
                }
                onChange={(e) => {
                  handleChange("type", e.target.value);
                  handleChange("target", "");
                }}
              >
                <option value={""}>Select</option>
                {formData.bord == "Single"
                  ? TARGET_TYPES_SU.map((target, index) => (
                    <option key={index} value={target}>
                      {target}
                    </option>
                  ))
                  : formData.bord == "Multiple"
                    ? TARGET_TYPES_MU.map((target, index) => (
                      <option key={index} value={target}>
                        {target}
                      </option>
                    ))
                    : null}
              </select>
              <small className="text-danger">{errors.type}</small>
            </div>
          ) : null}
          {formData.type && formData.type !== "Event" ? (
            <div className="form-group">
              <label className="text-label">Target*</label>
              <input
                value={formData.target}
                type="number"
                name="target"
                className={
                  errors.target ? "form-control border-danger" : "form-control"
                }
                placeholder="Target"
                required
                onChange={(e) => handleChange("target", e.target.value)}
              />
              <small className="text-danger">{errors.target}</small>
            </div>
          ) : null}
          {formData.type == "Purchase Product" ? (
            <ProductForm
              productId={formData.productId}
              handleChangeProduct={handleChangeProduct}
              handleChange={handleChange}
              errors={errors}
            />
          ) : null}
          {formData.type == "Event" ? (
            <EventForm
              event={formData.cEvent}
              {...{
                handleChangeEvent,
                handleChangeOption,
                onAddOption,
                onRemoveOption,
                errors,
              }}
            />
          ) : null}
          <RewardForm
            {...{
              handleChange,
              errors,
              formData,
              coupons,
              onAddBenefit,
              onRemoveBenefit,
              handleChangeBenefit,
            }}
          />
        </div>
      </div>
    </section>
  );
}

export default CreateContestForm;
