import {
  ALL_TOKEN_REWARDS,
  ADD_TOKEN_REWARDS,
  UPDATE_TOKEN_REWARDS,
  REMOVE_TOKEN_REWARDS,
  ALL_TOKEN_REQUEST_REWARDS,
  TRANSFER_ALL_TOKEN_REWARDS,
  TRANSFER_TOKEN_REWARDS,
} from "../actions/TokenRewardTypes";

const initialState = {
  list: [],
  requests: [],
};

export function TokenRewardReducer(state = initialState, action) {
  if (action.type === ALL_TOKEN_REWARDS) {
    return {
      ...state,
      list: action.payload.list,
    };
  }

  if (action.type === ALL_TOKEN_REQUEST_REWARDS) {
    return {
      ...state,
      requests: action.payload.list,
    };
  }

  if (action.type === ADD_TOKEN_REWARDS) {
    const list = [...state.list];
    list.push(action.payload.item);
    return {
      ...state,
      list,
    };
  }

  if (action.type === UPDATE_TOKEN_REWARDS) {
    const list = [...state.list];
    const itemIndex = list.findIndex(
      (item) => item.id === action.payload.item.id
    );
    list[itemIndex] = { ...list[itemIndex], ...action.payload.item };
    return {
      ...state,
      list,
    };
  }

  if (action.type === TRANSFER_TOKEN_REWARDS) {
    const requests = [...state.requests];
    const itemIndex = requests.findIndex((item) => item.id === action.payload);
    requests.splice(itemIndex, 1);
    return {
      ...state,
      requests,
    };
  }

  if (action.type === TRANSFER_ALL_TOKEN_REWARDS) {
    return {
      ...state,
      requests: [],
    };
  }

  if (action.type === REMOVE_TOKEN_REWARDS) {
    const list = [...state.list];
    const itemIndex = list.findIndex(
      (item) => item.id === action.payload.deletedId
    );
    list.splice(itemIndex, 1);
    return {
      ...state,
      list,
    };
  }

  return state;
}
