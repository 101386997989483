import {
    listHelp as listHelpService,
    createHelp as createHelpService,
    updateHelp as updateHelpService,
    removeHelp as removeHelpService,
} from "../../services/HelpSupportServic";


import {
    LIST_HELP_ACTION,
    CREATE_HELP_ACTION,
    UPDATE_HELP_ACTION,
    DELETE_HELP_ACTION,
} from "./HelpSupportTypes";

export function helpList(userId) {
    return (dispatch) =>
        listHelpService(userId)
            .then((res) => {
                if (res.data.status === 200) {
                    dispatch(
                        listHelpConfirm({
                            list: res.data.tickets,
                        })
                    );
                }
            })
            .catch((err) => {
                console.log(err);
            });
}

export function listHelpConfirm(payload) {
    return {
        type: LIST_HELP_ACTION,
        payload,
    };
}



export function createHelp(formdata) {
    return (dispatch) =>
        createHelpService(formdata)
            .then((res) => {
                if (res.data.status === 200) {
                    dispatch(
                        createHelpConfirm(res.data.newTicket)
                    );
                }
            })
            .catch((err) => {
                console.log(err);
            });
}

export function createHelpConfirm(payload) {
    return {
        type: CREATE_HELP_ACTION,
        payload,
    };
}

export function updateHelp(data, ticketId) {
    return (dispatch) =>
        updateHelpService(data, ticketId)
            .then((res) => {
                if (res.data.status === 200) {
                    dispatch(
                        updateHelpConfirm(
                            res.data.updatedTicket,
                            ticketId
                        )
                    );
                }
            })
            .catch((err) => {
                console.log(err);
            });
}

export function updateHelpConfirm(data, id) {
    return {
        type: UPDATE_HELP_ACTION,
        payload: {
            data,
            id,
        },
    };
}

export function removeHelp(ticketId) {
    return (dispatch) =>
        removeHelpService(ticketId)
            .then((res) => {
                if (res.data.status === 200) {
                    dispatch(removeHelpConfirm(ticketId));
                }
            })
            .catch((err) => {
                console.log(err);
            });
}

export function removeHelpConfirm(payload) {
    return {
        type: DELETE_HELP_ACTION,
        payload,
    };
}
