import React, { Fragment, useContext, useState } from "react";
/// React router dom
import { Link } from "react-router-dom";
import { ThemeContext } from "../../../context/ThemeContext";
import WhiteLogo from "../../../images/logo-full-white.png";
import Logo from "../../../images/logo-full.png";
import LogoSMWhite from "../../../images/logo-sm-white.png";
import LogoSM from "../../../images/logo.png";

const NavHader = () => {
  const [toggle, setToggle] = useState(false);
  const { navigationHader, openMenuToggle, background } =
    useContext(ThemeContext);
  return (
    <div className="nav-header">
      <Link to="/" className="brand-logo">
        {background.value === "dark" ? (
          <img src={toggle ? LogoSMWhite : WhiteLogo} />
        ) : (
          <img src={toggle ? LogoSM : Logo} />
        )}
      </Link>

      <div
        className="nav-control"
        onClick={() => {
          setToggle(!toggle);
          openMenuToggle();
        }}
      >
        <div className={`hamburger ${toggle ? "is-active" : ""}`}>
          <span className="line"></span>
          <span className="line"></span>
          <span className="line"></span>
        </div>
      </div>
    </div>
  );
};

export default NavHader;
