import React from "react";
import "./TreeInfo.css";
import { AiOutlineArrowDown } from "react-icons/ai"
import { Button } from "react-bootstrap";

const TreeWidget = (props) => {
  const { tree, handleOnTreeClick } = props;
  return (
    <div className="main_container mt-4" onClick={() => handleOnTreeClick(tree)}>
      <div className="tree_container" style={{ backgroundColor: tree.bg }} />
      <img src={tree.url} />
      <p>{tree.name}</p>
      <Button style={{ backgroundColor: tree.color, color: "black" }}>{<AiOutlineArrowDown />}</Button>
    </div>
  )

};

export default TreeWidget;
