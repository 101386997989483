import api from "./AxiosInstance";

export function listCoupons(userId) {
  return api.get("coupons/by/u/" + userId);
}
export function createCoupon(data) {
  return api.post("coupons", data);
}

export function updateCoupon(couponId, data) {
  return api.patch("coupons/" + couponId, data);
}

export function removeCoupon(couponId) {
  return api.delete("coupons/" + couponId);
}
