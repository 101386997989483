import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import "bootstrap-daterangepicker/daterangepicker.css";
import { useSelector, useDispatch, connect } from "react-redux";
import {
  getMapKeyAction,
  loadingToggleAction,
} from "../../../store/actions/AuthActions";
import {
  getMapKey,
  getPilotCity,
  getUserId,
} from "../../../store/selectors/AuthSelectors";
import Map from "./partials/map/Map";

/// Scroll
import PerfectScrollbar from "react-perfect-scrollbar";
import STree from "./../../../images/svg/donation.svg";
import Loading from "../partials/Loading";
import Slider from "../PluginsMenu/Noui Slider/Slider";
import Switch from "react-switch";
import { Row } from "react-bootstrap";

import { treePlantationCount } from "../../../store/actions/DonationActions";
import { getTreePlantationCount } from "../../../store/selectors/DonationSelector";
import { getTreesForVL } from "../../../services/DonationService";

const VirtualLookup = (props) => {
  const { showLoading } = props;
  const pilotCity = useSelector(getPilotCity);
  const [words, setWords] = useState("");
  const [useCoords, setUseCoords] = useState(false);
  const [zoomLevel, setZoomLevel] = useState(12);
  const MAPKEY = useSelector(getMapKey);
  const dispatch = useDispatch();
  const plantationCount = useSelector(getTreePlantationCount);
  const userId = useSelector(getUserId);
  const [location, setLocation] = useState({
    lat: Number(pilotCity.lat),
    lng: Number(pilotCity.lng),
  });
  const [coords, setCoords] = useState(location);
  const [trees, setTrees] = useState([]);

  useEffect(() => {
    if (MAPKEY == null || MAPKEY == "") {
      dispatch(loadingToggleAction(true));
      dispatch(getMapKeyAction());
    }
    dispatch(treePlantationCount(userId));
  }, []);

  function handleChangeCoords(key, value) {
    setCoords((pre) => ({ ...pre, [key]: value }));
    setWords("");
  }

  function onZoomLevelChange(value) {
    setZoomLevel(value);
  }

  function virtualLookup() {
    getTreesForVL({ words, ...coords })
      .then((res) => {
        if (res.data.status == 200) {
          setTrees(res.data.trees);
          if (res.data.trees.length) {
            setLocation({
              lat: Number(res.data.trees[0].lat),
              lng: Number(res.data.trees[0].lng),
            });
          }
        }
      })
      .catch((err) => {
        // console.log(err);
      });
  }

  return (
    <div>
      <div className=" form-head d-flex flex-wrap mb-4 align-items-center">
        <h2 className="text-black mr-auto font-w600 mb-3">Virtual Lookup</h2>
      </div>
      <div className="tab-content">
        <div className="tab-pane active show fade ">
          <div className="row">
            <div className="col-xl-3 col-xxl-4 mt-4">
              <div className="card">
                {/* <div className="card-header pb-0 border-0">
                  <h4 className="mb-0 text-black fs-20">About</h4>
                  <DropdownButton />
                </div> */}
                <PerfectScrollbar className="card-body dz-scroll height600">
                  <div className="d-flex align-items-center mb-3 about-coin">
                    <div>
                      <img src={STree} alt="" width="80px" height="80px" />
                    </div>
                    <div className="ml-3">
                      <p className="font-w600 text-black sub-title">
                        Your Plantation
                      </p>
                      <h2 className="font-w600 text-black mb-0 title">
                        {plantationCount}
                      </h2>
                    </div>
                  </div>
                  <p className="fs-14">
                    Virtual Lookup is a Tool to find the location of a tree by
                    using a unique tree code or the coordinates
                  </p>
                  <label className={"mb-3"}>
                    <strong>Zoom Level</strong>
                  </label>
                  <Slider
                    min={0}
                    max={22}
                    start={12}
                    onChange={onZoomLevelChange}
                  />
                  <Row
                    style={{
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                    className="mt-4 px-3"
                  >
                    <div className="">
                      <strong>Use Coords</strong>
                    </div>
                    <Switch
                      onChange={() => setUseCoords(!useCoords)}
                      checked={useCoords}
                      onColor="#98C17A"
                      onHandleColor="#558B2F"
                      handleDiameter={20}
                      uncheckedIcon={false}
                      checkedIcon={false}
                      boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                      activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                      height={10}
                      width={35}
                      className=""
                    />
                  </Row>
                  <div className="form-group mt-4">
                    <label className={"mb-1"}>
                      <strong>Search Your Tree</strong>
                    </label>
                    <div className="input-group mb-3">
                      {useCoords ? null : (
                        <input
                          value={words}
                          onChange={(e) => {
                            setCoords({});
                            setWords(e.target.value);
                          }}
                          className="form-control"
                          placeholder={"Enter The unique tree code"}
                        />
                      )}
                      {useCoords ? (
                        <input
                          value={coords.lat}
                          onChange={(e) =>
                            handleChangeCoords("lat", Number(e.target.value))
                          }
                          max={90}
                          min={-90}
                          type="number"
                          className="form-control"
                          placeholder={"Latitude"}
                        />
                      ) : null}
                      {useCoords ? (
                        <input
                          value={coords.lng}
                          onChange={(e) =>
                            handleChangeCoords("lng", Number(e.target.value))
                          }
                          max={180}
                          min={-180}
                          type="number"
                          className="form-control"
                          placeholder="Longitude"
                        />
                      ) : null}
                      <div className="input-group-append">
                        <button
                          className="btn btn-primary"
                          type="button"
                          onClick={() => {
                            virtualLookup();
                          }}
                        >
                          <i
                            className="flaticon-381-search-2"
                          // style={{ fontSize: "24px" }}
                          ></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </PerfectScrollbar>
              </div>
            </div>
            <div className="col-xl-9 col-xxl-8 mt-4">
              <div className="card">
                <div className="card-body pb-0 pl-0 pr-0 pt-0" style={{ borderRadius: "5px", overflow: "hidden" }}>
                  {showLoading ? <Loading /> : null}
                  {MAPKEY && !showLoading ? (
                    <Map
                      defaultLocation={{
                        lat: Number(pilotCity.lat),
                        lng: Number(pilotCity.lng),
                      }}
                      location={location}
                      zoomLevel={zoomLevel}
                      mapKey={MAPKEY}
                      trees={trees}
                    />
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    errorMessage: state.auth.errorMessage,
    successMessage: state.auth.successMessage,
    showLoading: state.auth.showLoading,
  };
}

export default connect(mapStateToProps)(VirtualLookup);
