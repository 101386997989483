import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";

const Dialog = (props) => {
  const { open, title, text, yesBtn, noBtn, input, onClose } = props;
  const [isVisible, setIsVisible] = useState(false);
  const [isReady, setIsReady] = useState(false);
  const [inputProps, setInputProps] = useState({ value: "" });
  const [inputError, setInputError] = useState("");

  useEffect(() => {
    if (!isReady) {
      setIsReady(true);
    } else {
      setIsVisible(open);
    }
  }, [open]);

  function handleClose(status) {
    setTimeout(() => onClose(status), 300);
    setIsVisible(false);
  }
  return (
    <Modal
      className="fade"
      centered
      show={isVisible}
      onHide={() => handleClose(false)}
    >
      <Modal.Header>
        <h4 className="text-black">{title}</h4>
        <Button variant="" className="close" onClick={() => handleClose(false)}>
          <span>&times;</span>
        </Button>
      </Modal.Header>
      <Modal.Body>
        <p>{text}</p>
        {input ? (
          <input
            style={{ width: "100%" }}
            className={
              inputError ? "form-control border-danger" : "form-control"
            }
            value={inputProps.value || ""}
            placeholder={input.placeholder || "Placeholder"}
            type={input.type || "text"}
            autoFocus={input.autoFocus || false}
            onChange={(e) => {
              setInputProps((pre) => ({ ...pre, value: e.target.value }));
              if (inputError) setInputError("");
            }}
          />
        ) : null}
        {input && inputError ? (
          <small className="text-danger">{inputError}</small>
        ) : null}
      </Modal.Body>
      <Modal.Footer>
        {noBtn ? (
          <Button
            onClick={() => {
              handleClose(false);
              if (noBtn.onTap) noBtn.onTap();
            }}
            variant="danger light"
          >
            {noBtn.title || "Cancel"}
          </Button>
        ) : null}
        {yesBtn ? (
          <Button
            variant="primary light"
            onClick={() => {
              if (input && input.required && !inputProps.value) {
                setInputError("This field is required");
                return;
              }
              handleClose(true);
              if (yesBtn.onTap) {
                if (inputProps.value) {
                  yesBtn.onTap(inputProps.value);
                } else {
                  yesBtn.onTap(null);
                }
              }
            }}
          >
            {yesBtn.title || "Confirm"}
          </Button>
        ) : null}
      </Modal.Footer>
    </Modal>
  );
};

export default Dialog;

export function defaultDialogButtons(btnProps) {
  return {
    yesBtn: {
      title: "Yes",
    },
    noBtn: {
      title: "Cancel",
    },
    ...btnProps,
  };
}

const buttons = defaultDialogButtons();

export const defaultDialogProps = {
  open: false,
  title: "",
  text: "",
  ...buttons,
  onClose: () => {},
};
