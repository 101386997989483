export function getMyCompetitions(state) {
  if (state.auth.auth.userId) return state.competitions.list || [];
  return [];
}

export function getRequestedCompetitions(state) {
  if (state.auth.auth.userId) return state.competitions.requests || [];
  return [];
}

export function getCompetitionPreview(state) {
  if (state.auth.auth.userId) return state.competitions.preview || {};
  return {};
}
