import React from "react";
import { Link } from "react-router-dom";
//import { TabContent, TabPane, } from 'reactstrap';
//import classnames from 'classnames';
import { Nav, Tab } from "react-bootstrap";
import { useSelector } from "react-redux";
import { getDashboard } from "../../../../store/selectors/DashboardSelector";
import moment from "moment";
import { Button } from "react-bootstrap";

const IndexTab = () => {
  const data = useSelector(getDashboard);

  return (
    <div className="card text-center">
      <div className="card-header">
        <h4 className="fs-20 text-black">Latest Trading NFTs</h4>
      </div>
      <div className="row" style={{ alignItems: "center" }}>
        {data && data.topNfts ? data.topNfts.map((nft, index) => (
          <div className="col-md-3" key={index}>
            <img
              src={nft.media[0].gateway}
              width="130px"
              height="130px"
              style={{ borderRadius: "7px", cursor: "pointer" }}
              onClick={() => {
                window.open(
                  nft.detailsBaseUrl +
                  "/" +
                  nft.contract.address +
                  "/" +
                  nft.tokenId,
                  "_blank"
                );
              }}
            />
            <h6 className="text-black">{nft.title}</h6>
            {/* <span>{moment(nft.timeLastUpdated).format("DD MMM YYYY hh:mm a")}</span> */}
            {/* <Button
                      className="primary light"
                      onClick={() => {
                        window.open(
                          data.detailsBaseUrl +
                          "/" +
                          nft.contract.address +
                          "/" +
                          nft.tokenId,
                          "_blank"
                        );
                      }}
                    >
                      More Details
                    </Button> */}
          </div>
        ))
          : null}
        {/* <div className="tab-pane active show fade">
        <Tab.Content>
          <Tab.Pane eventKey="Monthly">
          </Tab.Pane>
        </Tab.Content>
      </div> */}
        {/* <div className="card-footer border-0 p-0 caret mt-1">
          <Link to={"./coin-details"} className="btn-link">
            <i className="fa fa-caret-down" aria-hidden="true"></i>
          </Link>
        </div> */}
      </div>
    </div>
  );
};
export default IndexTab;
