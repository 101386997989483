import React, { useEffect, useState } from "react";
import {
  getOneTicketById,
  getTicketMessages,

} from "../../../../../services/HelpSupportServic";
import { Button } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import {
  getAvatar,
  getFullName,
  getRoleName,
  getUserId,
} from "../../../../../store/selectors/AuthSelectors";
import Avatar from "react-avatar";
import moment from "moment";
import { updateHelp } from "../../../../../store/actions/HelpSupportActions";

function InfoHelpSupport(props) {
  const { ticketPreview } = props;
  const [messages, setMessages] = useState([]);
  const [message, setMessage] = useState("");
  const [ticketStatus, setTicketStatus] = useState(ticketPreview.status);
  const userId = useSelector(getUserId);
  const roleName = useSelector(getRoleName);
  const avatar = useSelector(getAvatar);
  const dispatch = useDispatch()

  useEffect(() => {
    getData();
  }, []);

  function getData() {
    getOneTicketById(ticketPreview.id)
      .then((res) => {
        if (res.data.status === 200) {
          setMessages(res.data.ticket.messages);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }
  function handleMessage(event) {
    setMessage(event.target.value);
  }
  function addQuestion() {
    getTicketMessages({
      message,
      ticketId: String(ticketPreview.id),
      userId: String(userId),
    })
      .then((res) => {
        if (res.data.status === 200) {
          setMessages((pre) => [...pre, res.data.ticketMessage]);
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
    setMessage("");
  }

  return (
    <section>
      <div className="row">
        <div className="col-lg-4">
          {ticketPreview ? (
            <div>
              {
                roleName == "SuperAdmin" ? (
                  <div className="form-group">
                    <label className="text-label">Status*</label>
                    <select
                      name="status"
                      value={ticketStatus}
                      defaultValue={""}
                      className="form-control custom-select"
                      required
                      onChange={(e) => {
                        dispatch(updateHelp({ status: e.target.value }, ticketPreview.id));
                        setTicketStatus(e.target.value)
                      }}
                    >
                      <option
                        value={"Pending"}
                        selected={ticketStatus == "Pending"}
                      >
                        Pending
                      </option>
                      <option
                        value={"InReview"}
                        selected={ticketStatus == "InReview"}
                      >
                        InReview
                      </option>
                      <option
                        value={"InProgress"}
                        selected={ticketStatus == "InProgress"}
                      >
                        InProgress
                      </option>
                      <option
                        value={"On Hold"}
                        selected={ticketStatus == "On Hold"}
                      >
                        On Hold
                      </option>
                      <option
                        value={"Awaiting Response"}
                        selected={ticketStatus == "Awaiting Response"}
                      >
                        Awaiting Response
                      </option>
                      <option
                        value={"Resolved"}
                        selected={ticketStatus == "Resolved"}
                      >
                        Resolved
                      </option>
                    </select>
                  </div>
                ) : null
              }
              <h5 className="text-label">Topic</h5>
              <p>{ticketPreview.topic}</p>
              <h5 className="text-label">Query</h5>
              <p>{ticketPreview.message}</p>
              {ticketPreview.image ? (
                <div className="">
                  <h5 className="text-label">Screenshot</h5>
                  <div className="mt-4">
                    <img
                      style={{
                        width: "300px",
                        height: "200px",
                      }}
                      src={
                        ticketPreview.image ? ticketPreview.image.link : null
                      }
                    />
                  </div>
                </div>
              ) : null}
            </div>
          ) : null}
        </div>
        <div
          className="col-lg-8"
          style={{ display: "flex", flexDirection: "column" }}
        >
          <h4 className="text-label mb-4">Messages</h4>
          <div
            style={{
              overflowY: "scroll",
              overflowX: "hidden",
              height: "500px",
              marginBottom: "10px",
            }}
          >
            {messages.map((ticketMessage, i) => {
              return (
                <div
                  key={i}
                  className="border-left border-info"
                  style={{ borderLeftWidth: "4px !important" }}
                >
                  <div className="row ml-2">
                    <span>
                      <Avatar
                        src={
                          ticketMessage.user.roleId == 1
                            ? avatar
                            : ticketMessage.user.avatar
                        }
                        round={true}
                        size="24"
                      />
                    </span>
                    <p
                      className={`ml-2 ${ticketMessage.user.roleId == 1
                        ? "text-info"
                        : "text-primary"
                        }`}
                    >
                      <strong>
                        {ticketMessage.user.roleId == 1
                          ? "TerraNova Support"
                          : ticketMessage.user.first_name +
                          " " +
                          ticketMessage.user.last_name}
                      </strong>
                    </p>
                    <p className="ml-2">
                      {moment(ticketMessage.createdAt).format(
                        "DD MMMM YYYY hh:mm a"
                      )}
                    </p>
                  </div>
                  <div className="ml-4" style={{ marginTop: -10 }}>
                    <p className="container">{ticketMessage.message}</p>
                  </div>
                </div>
              );
            })}
          </div>
          <div style={{ marginTop: "auto" }}>
            <div className="d-flex justify-content-center">
              <input
                type="text"
                className="form-control"
                placeholder="Type your message"
                onChange={handleMessage}
                value={message}
              />
              <Button
                className="ml-3 primary light"
                disabled={message === ""}
                onClick={addQuestion}
              >
                Send
              </Button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default InfoHelpSupport;
