import { listCountries as listCountriesService } from "../../services/CountryService";
import { COUNTRIES_LIST_ACTION } from "./CountryType";

export function listCountries() {
    return (dispatch) => {
        listCountriesService()
            .then((res) => {
                if (res.data.status == 200) {
                    dispatch(countriesConfirm({ list: res.data.countries }));
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };
}


export function countriesConfirm(payload) {
    return {
        type: COUNTRIES_LIST_ACTION,
        payload,
    };
}