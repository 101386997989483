import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getWalletAccount } from "../../../../store/selectors/AuthSelectors";
import {
  getMyNFTsService,
  getNFTsLoadingState,
} from "../../../../store/selectors/WalletSelector";
import Loading from "../../partials/Loading";
import { listMyNFTsAction } from "../../../../store/actions/WalletActions";
import moment from "moment";
import { Button } from "react-bootstrap";

const WalletTab = ({ tabTitle, tabCaption }) => {
  const walletAccount = useSelector(getWalletAccount);
  const isLoading = useSelector(getNFTsLoadingState);
  const myNFTs = useSelector(getMyNFTsService);
  const dispatch = useDispatch();

  useEffect(() => {
    if (isLoading) {
      dispatch(listMyNFTsAction(walletAccount.address));
    }
  }, []);

  return (
    <div className="card">
      <div className="card-header pb-2 d-block d-sm-flex flex-wrap border-0">
        <div className="mb-3">
          <h4 className="fs-20 text-black">
            {tabTitle}
            {myNFTs ? " - " + myNFTs.totalCount + " Owned" : ""}
          </h4>
          <p className="mb-0 fs-12">{tabCaption}</p>
        </div>
      </div>
      <div className="card-body p-0">
        <div className="table-responsive">
          <table className="table shadow-hover card-table border-no tbl-btn short-one">
            <tbody>
              {myNFTs
                ? myNFTs.ownedNfts.map((nft, index) => (
                  <tr key={index}>
                    <td>
                      <img
                        src={nft.media[0].gateway}
                        width="50px"
                        height="50px"
                        style={{ borderRadius: "7px" }}
                      />
                    </td>
                    <td>
                      <span className="font-w600 text-black">
                        {nft.title}
                      </span>
                    </td>
                    <td>
                      <span className="text-black">
                        {moment(nft.timeLastUpdated).format(
                          "DD MMM YYYY hh:mm a"
                        )}
                      </span>
                    </td>
                    <td>
                      <Button
                        className="primary light"
                        onClick={() => {
                          window.open(
                            myNFTs.detailsBaseUrl +
                            "/" +
                            nft.contract.address +
                            "/" +
                            nft.tokenId,
                            "_blank"
                          );
                        }}
                      >
                        More Details
                      </Button>
                    </td>
                  </tr>
                ))
                : null}
            </tbody>
          </table>
        </div>
        {/* <div className="tab-pane active show fade">
          <Tab.Content>
            <Tab.Pane eventKey="Monthly">
            </Tab.Pane>
          </Tab.Content>
        </div> */}
      </div>
      {/* <div className="card-footer border-0 p-0 caret mt-1">
            <Link to={"./coin-details"} className="btn-link">
              <i className="fa fa-caret-down" aria-hidden="true"></i>
            </Link>
          </div> */}
      {isLoading ? <Loading /> : null}
    </div>
  );
};
export default WalletTab;
