import React, { Fragment, useEffect, useState } from "react";
import { Button, ButtonGroup } from "react-bootstrap";
import DropZone from "../../../partials/DropZone";
import "../../../../../css/screen-transition.css";
import { useDispatch, useSelector } from "react-redux";
import {
  // getRoleName,
  // getUser,
  getUserId,
} from "../../../../../store/selectors/AuthSelectors";
// import { collectionAllList } from "../../../../../store/actions/DonationActions";
// import { getCollectionsforIndividual } from "../../../../../store/selectors/DonationSelector";
import { BsArrowUpRight, BsTrash } from "react-icons/bs";
// import ExpiryCounter from "../../partials/contestPreview/ExpiryCounter";
// import { useHistory } from "react-router-dom";
import { getCountries } from "../../../../../store/selectors/CountrySelector";
import { listCountries } from "../../../../../store/actions/CountryAction";
import MultiFileDropZone from "../../../partials/MultiFileDropZone";
import Loader from "../../../../loader.json";
import Lottie from "lottie-react";
import { Form } from "react-bootstrap";
import { getGroupProjects } from "../../../../../services/DonationService";
import {
  DURATIONS,
  REGIONS,
  TYPES,
  DURATION_TYPES,
  DURATION_MONTHS_VALUES,
  DURATION_YEARS_VALUES,
} from "../../../../../api/data";
import "flag-icons/css/flag-icons.min.css";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Editor from "react-simple-wysiwyg";
import "./collectionForm.css";

const CollectionForm = (props) => {
  const {
    collectionId,
    errors,
    onSubmit,
    handleChange,
    handleChangeType,
    data,
    showLoader,
    removeDocument,
    isEditing,
    previewDocs,
    handleHelperData,
    helperData,
    removePreviewDoc,
    dialog,
  } = props;

  const [groupProjects, setGroupProjects] = useState([]);
  const [cities, setCities] = useState([]);

  const dispatch = useDispatch();
  const userId = useSelector(getUserId);
  // const user = useSelector(getUser);
  // const roleName = useSelector(getRoleName);
  const countries = useSelector(getCountries);
  // const collections = useSelector(getCollectionsforIndividual);
  // const history = useHistory();

  const linkedProject = groupProjects.find(
    (project) => project.groupProjectId === data.groupProjectId
  );

  const PHONE_CODES =
    cities && cities.length
      ? cities
          .map((c) => c.phone_code)
          .filter(function (item, pos, self) {
            return self.indexOf(item) == pos;
          })
          .sort(function (a, b) {
            var x = a.toLowerCase();
            var y = b.toLowerCase();
            return x < y ? -1 : x > y ? 1 : 0;
          })
      : [];

  useEffect(() => {
    dispatch(listCountries());

    getData();
  }, []);

  // useEffect(() => {
  //   dispatch(collectionAllList(userId));
  // }, []);

  function getData() {
    getGroupProjects(
      userId,
      isEditing && collectionId ? collectionId : undefined
    )
      .then((res) => {
        if (res.data.status === 200) {
          setGroupProjects(res.data.projects);
          setCities(res.data.cities || []);
        }
      })
      .catch((err) => {});
  }

  return (
    <div>
      <Fragment>
        <div className="row d-flex justify-content-center">
          <div className="col-lg-6 col-xs-12">
            <div className="form-group">
              <div className="mt-4 text-center">
                <DropZone
                  errors={errors}
                  required
                  name="image"
                  title="Collection Image"
                  src={data.image ? data.image.uri : null}
                  onChange={(file) => handleChange("image", file)}
                  onRemove={() => handleChange("image", null)}
                  caption="Click to explore the image gallery"
                  height="170px"
                />
                {errors.image ? (
                  <p
                    style={{ marginTop: -20, fontSize: "11px" }}
                    className="text-danger"
                  >
                    {errors.image}
                  </p>
                ) : null}
                {isEditing && previewDocs && previewDocs.image ? (
                  <Button
                    className="primary"
                    onClick={() => {
                      window.open(previewDocs.image.link, "_blank");
                    }}
                  >
                    <BsArrowUpRight className="mr-1" />
                    Preview
                  </Button>
                ) : null}
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-xs-12">
            <div className="form-group">
              <div className="mt-4 text-center">
                <DropZone
                  errors={errors}
                  required
                  name="banner"
                  title="Banner Image"
                  src={data.banner ? data.banner.uri : null}
                  onChange={(file) => handleChange("banner", file)}
                  onRemove={() => handleChange("banner", null)}
                  caption="Click to explore the image gallery"
                  height="170px"
                />
                {errors.banner ? (
                  <p
                    style={{ marginTop: -20, fontSize: "11px" }}
                    className="text-danger"
                  >
                    {errors.banner}
                  </p>
                ) : null}
                {isEditing && previewDocs && previewDocs.banner ? (
                  <Button
                    className="primary"
                    onClick={() => {
                      window.open(previewDocs.banner.link, "_blank");
                    }}
                  >
                    <BsArrowUpRight className="mr-1" />
                    Preview
                  </Button>
                ) : null}
              </div>
            </div>
          </div>
        </div>
        <div className="row" style={{ display: "flex" }}>
          <div className="col-lg-6 col-xs-12">
            <div className="form-group my-3">
              <Form.Check
                checked={!data.isGroupProject}
                type="radio"
                id={"no group"}
                label="Individual Project"
                onChange={() => handleChange("isGroupProject", false)}
              />
              <Form.Check
                checked={data.isGroupProject}
                type="radio"
                id={"group"}
                label="Group Project"
                onChange={() => handleChange("isGroupProject", true)}
              />
            </div>
            {data.isGroupProject === false &&
            groupProjects &&
            groupProjects.length ? (
              <div className="form-group">
                <label className="text-label">
                  Link with group project (optional)
                </label>
                <select
                  required
                  className={
                    errors.countryId
                      ? "form-control custom-select border-danger"
                      : "form-control custom-select"
                  }
                  onChange={(e) =>
                    handleChange("groupProjectId", e.target.value)
                  }
                  value={
                    isEditing && linkedProject
                      ? linkedProject.id
                      : data.groupProjectId
                      ? data.groupProjectId
                      : ""
                  }
                >
                  <option value={""}>Select</option>
                  {groupProjects
                    ? groupProjects.map((project, i) => (
                        <option key={i} value={project.id}>
                          {Number(i + 1) + ". "}{" "}
                          {"(" + project.region + ": " + project.type + ") "}
                          {project.title}
                        </option>
                      ))
                    : null}
                </select>
                {errors.groupProjectId ? (
                  <p style={{ fontSize: "11px" }} className="text-danger">
                    {errors.groupProjectId}
                  </p>
                ) : null}
              </div>
            ) : !data.isGroupProject ? (
              <p style={{ fontSize: "11px" }}>
                (Note: You don't have group projects to link yet.)
              </p>
            ) : null}
            <div className="form-group">
              <label className="text-label">
                Title<strong className="text-danger">*</strong>
              </label>
              <input
                type="text"
                name="title"
                placeholder="Write the project title"
                value={data.title}
                required
                onChange={(e) => handleChange("title", e.target.value)}
                maxLength={50}
                max={50}
                className={
                  errors.title ? "form-control border-danger" : "form-control"
                }
              />
              <small style={{ position: "absolute", right: 10 }}>
                {data.title.length} | 50
              </small>
              {errors.title ? (
                <p style={{ fontSize: "11px" }} className="text-danger">
                  {errors.title}
                </p>
              ) : null}
              <label className="text-label mt-3">
                Short Description<strong className="text-danger">*</strong>
              </label>
              <textarea
                type="text"
                name="s_description"
                placeholder="Description"
                value={data.s_description}
                required
                onChange={(e) => handleChange("s_description", e.target.value)}
                className={
                  errors.s_description
                    ? "form-control border-danger"
                    : "form-control"
                }
                maxLength={500}
                style={{ height: "110px" }}
              ></textarea>
              <small style={{ position: "absolute", right: 10 }}>
                {data.s_description.length} | 500
              </small>
              {errors.s_description ? (
                <p style={{ fontSize: "11px" }} className="text-danger">
                  {errors.s_description}
                </p>
              ) : null}
              <label className="text-label mt-3">
                Long Description<strong className="text-danger">*</strong>
              </label>
              {/* <textarea
                type="text"
                name="l_description"
                placeholder="Description"
                value={data.l_description}
                onChange={(e) => handleChange("l_description", e.target.value)}
                className={
                  errors.l_description
                    ? "form-control border-danger"
                    : "form-control"
                }
                maxLength={3000}
                style={{ height: "170px" }}
              ></textarea> */}
              <Editor
                containerProps={{
                  style: { resize: "vertical", minHeight: "250px" },
                }}
                value={data.l_description}
                onChange={(e) => handleChange("l_description", e.target.value)}
              />
              {/* <small style={{ position: "absolute", right: 10 }}>
                {data.l_description.length} | 3000
              </small> */}
              {errors.l_description ? (
                <p style={{ fontSize: "11px" }} className="text-danger">
                  {errors.l_description}
                </p>
              ) : null}
            </div>
            <div className="form-group mt-3">
              <label className="text-label">
                Region<strong className="text-danger">*</strong>
              </label>
              <div className="buttons">
                {REGIONS.map((region, index) => {
                  return (
                    <button
                      className={
                        errors.region
                          ? "border-danger"
                          : data.region === region.title
                          ? "selected"
                          : ""
                      }
                      required
                      key={index}
                      onClick={() => {
                        handleChange("region", region.title);
                      }}
                    >
                      <div>
                        <span>{region.title}</span>
                      </div>
                    </button>
                  );
                })}
                {errors.region ? (
                  <p style={{ fontSize: "11px" }} className="text-danger">
                    {errors.region}
                  </p>
                ) : null}
              </div>
            </div>
            <div className="form-group">
              <label className="text-label">Project Location Address</label>
              <div className="input-group">
                <div className="input-group-prepend">
                  <input
                    type="text"
                    name="lat"
                    value={data.lat || ""}
                    onChange={(e) => {
                      const sanitizedValue = e.target.value.replace(
                        /[^\d.-]/g,
                        ""
                      );
                      handleChange("lat", sanitizedValue);
                    }}
                    className={
                      errors.lat || errors.lng
                        ? "form-control border-danger"
                        : "form-control"
                    }
                    placeholder={"Latitude"}
                  />
                </div>
                <input
                  type="text"
                  name="lng"
                  value={data.lng || ""}
                  required
                  onChange={(e) => {
                    const sanitizedValue = e.target.value.replace(
                      /[^\d.-]/g,
                      ""
                    );
                    handleChange("lng", sanitizedValue);
                  }}
                  className={
                    errors.lat || errors.lng
                      ? "form-control border-danger"
                      : "form-control"
                  }
                  placeholder={"Longitude"}
                />
                <div className="col-lg-12">
                  {errors.lat ? (
                    <p style={{ fontSize: "11px" }} className="text-danger">
                      {errors.lat}
                    </p>
                  ) : null}
                  {errors.lng ? (
                    <p style={{ fontSize: "11px" }} className="text-danger">
                      {errors.lng}
                    </p>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-xs-12">
            <div className="form-group">
              <label className="text-label">
                Duration<strong className="text-danger">*</strong>
              </label>
              <div className="buttons">
                {DURATIONS.map((duration, index) => {
                  return (
                    <button
                      className={
                        errors.type
                          ? "border-danger"
                          : data.duration === duration.title
                          ? "selected"
                          : ""
                      }
                      required
                      key={String(index)}
                      onClick={() => {
                        handleChange("duration", duration.title);

                        if (duration.value)
                          handleChange("duration_value", duration.value);
                        else handleChange("duration_value", "");

                        if (duration.type)
                          handleChange("duration_type", duration.type);
                        else handleChange("duration_type", "");
                      }}
                    >
                      <div>
                        <span>{duration.title}</span>
                      </div>
                    </button>
                  );
                })}
                {errors.duration ? (
                  <p style={{ fontSize: "11px" }} className="text-danger">
                    {errors.duration}
                  </p>
                ) : null}
              </div>
              {data.duration === "Other" ? (
                <div
                  className="input-group col-lg-10 mb-3"
                  style={{ marginLeft: -5 }}
                >
                  <div className="input-group-prepend">
                    <select
                      required
                      value={data.duration_type || ""}
                      onChange={(e) =>
                        handleChange("duration_type", e.target.value)
                      }
                      className={
                        errors.duration_type
                          ? "form-control custom-select border-danger"
                          : "form-control custom-select"
                      }
                    >
                      <option value="">Select Type</option>
                      {DURATION_TYPES.map((type, i) => {
                        return (
                          <option key={i} value={type}>
                            {type}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <select
                    required
                    // disabled={!DURATION_TYPES.includes(data.duration_type)}
                    value={data.duration_value || ""}
                    onChange={(e) =>
                      handleChange("duration_value", e.target.value)
                    }
                    className={
                      errors.duration_value
                        ? "form-control custom-select border-danger"
                        : "form-control custom-select"
                    }
                    style={{ marginLeft: -3 }}
                  >
                    <option value="">Select Duration</option>
                    {(data.duration_type === "Months"
                      ? DURATION_MONTHS_VALUES
                      : data.duration_type === "Year(s)"
                      ? DURATION_YEARS_VALUES
                      : []
                    ).map((value, i) => {
                      return (
                        <option key={i} value={value}>
                          {value}
                        </option>
                      );
                    })}
                  </select>
                  <div className="col-lg-12">
                    {data.duration === "Other" && errors.duration_type ? (
                      <p style={{ fontSize: "11px" }} className="text-danger">
                        {errors.duration_type}
                      </p>
                    ) : null}
                    {data.duration === "Other" && errors.duration_value ? (
                      <p style={{ fontSize: "11px" }} className="text-danger">
                        {errors.duration_value}
                      </p>
                    ) : null}
                  </div>
                </div>
              ) : null}
              <label className="text-label mt-2">
                Type<strong className="text-danger">*</strong>
              </label>
              <div className="buttons">
                {TYPES.map((type, index) => {
                  return (
                    <button
                      className={
                        errors.type
                          ? "border-danger"
                          : data.type.split(",").includes(type.title)
                          ? "selected"
                          : ""
                      }
                      required
                      key={index}
                      onClick={() => {
                        handleChangeType("type", type.title);
                        if (type.title === "Other")
                          handleChange("type_value", "");
                      }}
                    >
                      <div>
                        <span>{type.title}</span>
                      </div>
                    </button>
                  );
                })}
                {errors.type ? (
                  <p style={{ fontSize: "11px" }} className="text-danger">
                    {errors.type}
                  </p>
                ) : null}
              </div>
              {data.type.split(",").includes("Other") ? (
                <div className="col-lg-10 col-sm-12" style={{ marginLeft: -5 }}>
                  <input
                    type="text"
                    name="type_value"
                    placeholder="Comma separated types (e.g. Lake Cleanup,water treatment)"
                    value={data.type_value || ""}
                    onChange={(e) => handleChange("type_value", e.target.value)}
                    className={
                      errors.type_value
                        ? "form-control border-danger"
                        : "form-control"
                    }
                  />
                  <div className="col-lg-12">
                    {errors.type_value ? (
                      <p style={{ fontSize: "11px" }} className="text-danger">
                        {errors.type_value}
                      </p>
                    ) : null}
                  </div>
                </div>
              ) : null}
              <label className="text-label mt-3">Budget (local currency)</label>
              <div className="input-group">
                <div className="input-group-prepend">
                  <select
                    required
                    value={data.currency_code || ""}
                    onChange={(e) =>
                      handleChange("currency_code", e.target.value)
                    }
                    className={
                      errors.currency_code
                        ? "form-control custom-select border-danger"
                        : "form-control custom-select"
                    }
                  >
                    <option value="">Select Currency</option>
                    {cities &&
                      cities.length &&
                      cities
                        .map((c) => c.currency_code)
                        .filter(function (item, pos, self) {
                          return self.indexOf(item) == pos;
                        })
                        .sort(function (a, b) {
                          var x = a.toLowerCase();
                          var y = b.toLowerCase();
                          return x < y ? -1 : x > y ? 1 : 0;
                        })
                        .map((cc, i) => {
                          // extracted country codes
                          return (
                            <option key={i} value={cc}>
                              {cc}
                            </option>
                          );
                        })}
                  </select>
                </div>
                <input
                  type="number"
                  name="budget"
                  value={data.budget}
                  onChange={(e) => handleChange("budget", e.target.value)}
                  className={
                    errors.budget
                      ? "form-control border-danger"
                      : "form-control"
                  }
                />
              </div>
              {errors.budget ? (
                <p style={{ fontSize: "11px" }} className="text-danger">
                  {errors.budget}
                </p>
              ) : null}
              {errors.currency_code ? (
                <p style={{ fontSize: "11px" }} className="text-danger">
                  {errors.currency_code}
                </p>
              ) : null}
              <label className="text-label mt-3">
                Budget (global currency)
              </label>
              <OverlayTrigger
                delay={{ hide: 450, show: 300 }}
                overlay={(props) => (
                  <Tooltip {...props}>
                    Calculate the budget in global currency of your choice for
                    the ‘Budget (local currency)’ figure entered above and add
                    the nearest round figure with corresponding global currency
                    code e.g. USD 35000
                  </Tooltip>
                )}
                placement="bottom"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-question-circle ml-1"
                  viewBox="0 0 16 16"
                >
                  <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                  <path d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286m1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94" />
                </svg>
              </OverlayTrigger>
              <div className="input-group">
                <div className="input-group-prepend">
                  <select
                    required
                    value={data.global_currency_code || ""}
                    onChange={(e) =>
                      handleChange("global_currency_code", e.target.value)
                    }
                    className={
                      errors.global_currency_code
                        ? "form-control custom-select border-danger"
                        : "form-control custom-select"
                    }
                  >
                    <option value="">Select Currency</option>
                    {cities &&
                      cities.length &&
                      cities
                        .map((c) => c.currency_code)
                        .filter(function (item, pos, self) {
                          return self.indexOf(item) == pos;
                        })
                        .sort(function (a, b) {
                          var x = a.toLowerCase();
                          var y = b.toLowerCase();
                          return x < y ? -1 : x > y ? 1 : 0;
                        })
                        .map((cc, i) => {
                          // extracted country codes
                          return (
                            <option key={i} value={cc}>
                              {cc}
                            </option>
                          );
                        })}
                  </select>
                </div>
                <input
                  type="number"
                  name="global_budget"
                  value={data.global_budget}
                  onChange={(e) =>
                    handleChange("global_budget", e.target.value)
                  }
                  className={
                    errors.global_budget
                      ? "form-control border-danger"
                      : "form-control"
                  }
                />
              </div>
              {errors.global_budget ? (
                <p style={{ fontSize: "11px" }} className="text-danger">
                  {errors.global_budget}
                </p>
              ) : null}
              {errors.global_currency_code ? (
                <p style={{ fontSize: "11px" }} className="text-danger">
                  {errors.global_currency_code}
                </p>
              ) : null}
              <label className="text-label mt-3">
                Minimum donation accepted (local currency)
              </label>
              <OverlayTrigger
                delay={{ hide: 450, show: 300 }}
                overlay={(props) => (
                  <Tooltip {...props}>
                    Minimum amount that can be donated by a user to this
                    project. The minimum amount can be 1 USD or equivalent.
                  </Tooltip>
                )}
                placement="bottom"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-question-circle ml-1"
                  viewBox="0 0 16 16"
                >
                  <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                  <path d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286m1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94" />
                </svg>
              </OverlayTrigger>
              <div className="input-group">
                <div className="input-group-prepend">
                  <select
                    required
                    value={data.min_don_currency || ""}
                    onChange={(e) =>
                      handleChange("min_don_currency", e.target.value)
                    }
                    className={
                      errors.min_don_currency
                        ? "form-control custom-select border-danger"
                        : "form-control custom-select"
                    }
                  >
                    <option value="">Select Currency</option>
                    {cities &&
                      cities.length &&
                      cities
                        .map((c) => c.currency_code)
                        .filter(function (item, pos, self) {
                          return self.indexOf(item) == pos;
                        })
                        .sort(function (a, b) {
                          var x = a.toLowerCase();
                          var y = b.toLowerCase();
                          return x < y ? -1 : x > y ? 1 : 0;
                        })
                        .map((cc, i) => {
                          // extracted country codes
                          return (
                            <option key={i} value={cc}>
                              {cc}
                            </option>
                          );
                        })}
                  </select>
                </div>
                <input
                  type="number"
                  name="min_don_amount"
                  value={data.min_don_amount}
                  onChange={(e) =>
                    handleChange("min_don_amount", e.target.value)
                  }
                  className={
                    errors.min_don_amount
                      ? "form-control border-danger"
                      : "form-control"
                  }
                />
              </div>
              {errors.min_don_amount ? (
                <p style={{ fontSize: "11px" }} className="text-danger">
                  {errors.min_don_amount}
                </p>
              ) : null}
              {errors.min_don_currency ? (
                <p style={{ fontSize: "11px" }} className="text-danger">
                  {errors.min_don_currency}
                </p>
              ) : null}
              <div className="form-group mt-3">
                <label className="text-label mt-3">
                  Goal<strong className="text-danger">*</strong>
                </label>
                <input
                  type="text"
                  name="goal"
                  value={data.goal}
                  required
                  onChange={(e) => handleChange("goal", e.target.value)}
                  className={
                    errors.goal ? "form-control border-danger" : "form-control"
                  }
                />
                {errors.goal ? (
                  <p style={{ fontSize: "11px" }} className="text-danger">
                    {errors.goal}
                  </p>
                ) : null}
              </div>
              <div className="form-group mt-3">
                <label className="text-label mt-3">Video URL</label>
                <input
                  type="text"
                  name="link"
                  value={data.link || ""}
                  required
                  onChange={(e) => handleChange("link", e.target.value)}
                  className={
                    errors.link ? "form-control border-danger" : "form-control"
                  }
                  placeholder="https://www.youtube.com/watch?v=abcdefghijk"
                />
                {errors.link ? (
                  <p style={{ fontSize: "11px" }} className="text-danger">
                    {errors.link}
                  </p>
                ) : null}
              </div>

              <div className="form-group mt-3">
                <label className="text-label mt-3">
                  Status<strong className="text-danger">*</strong>
                </label>
                <select
                  required
                  className={
                    errors.current_stage
                      ? "form-control custom-select border-danger"
                      : "form-control custom-select"
                  }
                  onChange={(e) => handleChange("current_stage", e.target.value)}
                  value={data.current_stage || ""}
                >
                  <option value={""}>Select</option>
                  <option value={"Initiated"}>Initiated</option>
                  <option value={"Pre-work Survey"}>Pre-work Survey</option>
                  <option value={"Budgeted"}>Budgeted</option>
                  <option value={"Open for Donations"}>
                    Open for Donations
                  </option>
                  <option value={"Ground work in progress"}>
                    Ground work in progress
                  </option>
                  <option value={"Near to Completion"}>
                    Near to completion
                  </option>
                  <option value={"Post-work Survey"}>Post-work Survey</option>
                  <option value={"Completed"}>Completed</option>
                  <option value={"Closed"}>Closed</option>
                </select>
                {errors.current_stage ? (
                  <p style={{ fontSize: "11px" }} className="text-danger">
                    {errors.current_stage}
                  </p>
                ) : null}
              </div>


            </div>
          </div>
        </div>

        <div className="col-lg-10">
          <h3 className="mt-3">Contact Info</h3>
          <div className="row">
            <div className="col-lg-6 col-xs-12 mt-2">
              <div className="form-group">
                <label className="text-label">
                  Owner Name<strong className="text-danger">*</strong>
                </label>
                <input
                  type="text"
                  name="oName"
                  value={data.oName}
                  required
                  onChange={(e) => handleChange("oName", e.target.value)}
                  className={
                    errors.oName ? "form-control border-danger" : "form-control"
                  }
                />
                {errors.oName ? (
                  <p style={{ fontSize: "11px" }} className="text-danger">
                    {errors.oName}
                  </p>
                ) : null}
              </div>
            </div>
            <div className="col-lg-6 col-xs-12 mt-2">
              <div className="form-group">
                <label className="text-label">
                  Owner Address<strong className="text-danger">*</strong>
                </label>
                <input
                  type="text"
                  name="oAddress"
                  value={data.oAddress}
                  required
                  onChange={(e) => handleChange("oAddress", e.target.value)}
                  className={
                    errors.oAddress
                      ? "form-control border-danger"
                      : "form-control"
                  }
                />
                {errors.oAddress ? (
                  <p style={{ fontSize: "11px" }} className="text-danger">
                    {errors.oAddress}
                  </p>
                ) : null}
              </div>
            </div>
            <div className="col-lg-6 col-xs-12 mt-2">
              <div className="form-group">
                <label className="text-label">
                  Owner Email<strong className="text-danger">*</strong>
                </label>
                <input
                  type="text"
                  name="oEmail"
                  value={data.oEmail || ""}
                  required
                  onChange={(e) => handleChange("oEmail", e.target.value)}
                  className={
                    errors.oEmail
                      ? "form-control border-danger"
                      : "form-control"
                  }
                />
                {errors.oEmail ? (
                  <p style={{ fontSize: "11px" }} className="text-danger">
                    {errors.oEmail}
                  </p>
                ) : null}
              </div>
            </div>
            <div className="col-lg-6 col-xs-12 mt-2">
              <div className="form-group">
                <label className="text-label">
                  Owner Phone No<strong className="text-danger">*</strong>
                </label>
                <div className="input-group">
                  <div className="input-group-prepend">
                    {helperData.oPhone_city ? (
                      <span className="input-group-text">
                        <span
                          className={
                            "fi fi-" + helperData.oPhone_city.country_code
                          }
                        ></span>
                      </span>
                    ) : null}
                    <select
                      required
                      value={data.oPhone_code || ""}
                      onChange={(e) => {
                        handleChange("oPhone_code", e.target.value);
                        const cityKey = cities
                          ? cities.findIndex(
                              (city) => city.phone_code === e.target.value
                            )
                          : -1;
                        if (cityKey !== -1) {
                          handleHelperData("oPhone_city", cities[cityKey]);
                        } else {
                          handleHelperData("oPhone_city", null);
                        }
                      }}
                      className={
                        errors.oPhone_code
                          ? "form-control custom-select border-danger"
                          : "form-control custom-select"
                      }
                    >
                      <option value="">Select Phone Code</option>
                      {PHONE_CODES.map((pc, i) => {
                        // extracted phone codes
                        return (
                          <option key={i} value={pc}>
                            {pc}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <input
                    type="text"
                    name="oPhone"
                    value={data.oPhone}
                    required
                    onChange={(e) => {
                      const sanitizedValue = e.target.value.replace(
                        /[^0-9]/g,
                        ""
                      );
                      if (
                        helperData.oPhone_city &&
                        sanitizedValue.length <=
                          helperData.oPhone_city.max_phone_digits
                      ) {
                        handleChange("oPhone", sanitizedValue);
                      }
                    }}
                    className={
                      errors.oPhone || errors.oPhone_code
                        ? "form-control border-danger"
                        : "form-control"
                    }
                    placeholder={
                      helperData.oPhone_city
                        ? helperData.oPhone_city.phone_pattern
                        : ""
                    }
                    maxLength={
                      helperData.oPhone_city
                        ? helperData.oPhone_city.max_phone_digits
                        : 0
                    }
                    disabled={helperData.oPhone_city == null}
                  />
                  <div className="col-lg-12">
                    {errors.oPhone_code ? (
                      <p style={{ fontSize: "11px" }} className="text-danger">
                        {errors.oPhone_code}
                      </p>
                    ) : null}
                    {errors.oPhone ? (
                      <p style={{ fontSize: "11px" }} className="text-danger">
                        {errors.oPhone}
                      </p>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-xs-12 mt-2">
              <div className="form-group">
                <label className="text-label">WhatsApp Number</label>
                <div className="input-group">
                  <div className="input-group-prepend">
                    {helperData.whatsapp_number_city ? (
                      <span className="input-group-text">
                        <span
                          className={
                            "fi fi-" +
                            helperData.whatsapp_number_city.country_code
                          }
                        ></span>
                      </span>
                    ) : null}
                    <select
                      required
                      value={data.wa_num_code || ""}
                      onChange={(e) => {
                        handleChange("wa_num_code", e.target.value);
                        const cityKey = cities
                          ? cities.findIndex(
                              (city) => city.phone_code === e.target.value
                            )
                          : -1;
                        if (cityKey !== -1) {
                          handleHelperData(
                            "whatsapp_number_city",
                            cities[cityKey]
                          );
                        } else {
                          handleHelperData("whatsapp_number_city", null);
                        }
                      }}
                      className={
                        errors.wa_num_code
                          ? "form-control custom-select border-danger"
                          : "form-control custom-select"
                      }
                    >
                      <option value="">Select Phone Code</option>
                      {PHONE_CODES.map((pc, i) => {
                        // extracted phone codes
                        return (
                          <option key={i} value={pc}>
                            {pc}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <input
                    type="number"
                    name="whatsapp_number"
                    value={data.whatsapp_number}
                    required
                    onChange={(e) => {
                      const sanitizedValue = e.target.value.replace(
                        /[^0-9]/g,
                        ""
                      );
                      if (
                        helperData.whatsapp_number_city &&
                        sanitizedValue.length <=
                          helperData.whatsapp_number_city.max_phone_digits
                      ) {
                        handleChange("whatsapp_number", sanitizedValue);
                      }
                    }}
                    className={
                      errors.whatsapp_number || errors.wa_num_code
                        ? "form-control border-danger"
                        : "form-control"
                    }
                    placeholder={
                      helperData.whatsapp_number_city
                        ? helperData.whatsapp_number_city.phone_pattern
                        : ""
                    }
                    maxLength={
                      helperData.whatsapp_number_city
                        ? helperData.whatsapp_number_city.max_phone_digits
                        : 0
                    }
                    disabled={helperData.whatsapp_number_city == null}
                  />
                  <div className="col-lg-12">
                    {errors.wa_num_code ? (
                      <p style={{ fontSize: "11px" }} className="text-danger">
                        {errors.wa_num_code}
                      </p>
                    ) : null}
                    {errors.whatsapp_number ? (
                      <p style={{ fontSize: "11px" }} className="text-danger">
                        {errors.whatsapp_number}
                      </p>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-xs-12 mt-2">
              <div className="form-group">
                <label className="text-label">Other Contact</label>
                <div className="input-group">
                  <div className="input-group-prepend">
                    {helperData.otherCon_city ? (
                      <span className="input-group-text">
                        <span
                          className={
                            "fi fi-" + helperData.otherCon_city.country_code
                          }
                        ></span>
                      </span>
                    ) : null}
                    <select
                      required
                      value={data.otherCon_code || ""}
                      onChange={(e) => {
                        handleChange("otherCon_code", e.target.value);
                        const cityKey = cities
                          ? cities.findIndex(
                              (city) => city.phone_code === e.target.value
                            )
                          : -1;
                        if (cityKey !== -1) {
                          handleHelperData("otherCon_city", cities[cityKey]);
                        } else {
                          handleHelperData("otherCon_city", null);
                        }
                      }}
                      className={
                        errors.otherCon_code
                          ? "form-control custom-select border-danger"
                          : "form-control custom-select"
                      }
                    >
                      <option value="">Select Phone Code</option>
                      {PHONE_CODES.map((pc, i) => {
                        // extracted phone codes
                        return (
                          <option key={i} value={pc}>
                            {pc}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <input
                    type="text"
                    name="otherCon"
                    value={data.otherCon}
                    required
                    onChange={(e) => {
                      const sanitizedValue = e.target.value.replace(
                        /[^0-9]/g,
                        ""
                      );
                      if (
                        helperData.otherCon_city &&
                        sanitizedValue.length <=
                          helperData.otherCon_city.max_phone_digits
                      ) {
                        handleChange("otherCon", sanitizedValue);
                      }
                    }}
                    className={
                      errors.otherCon || errors.otherCon_code
                        ? "form-control border-danger"
                        : "form-control"
                    }
                    placeholder={
                      helperData.otherCon_city
                        ? helperData.otherCon_city.phone_pattern
                        : ""
                    }
                    maxLength={
                      helperData.otherCon_city
                        ? helperData.otherCon_city.max_phone_digits
                        : 0
                    }
                    disabled={helperData.otherCon_city == null}
                  />
                  <div className="col-lg-12">
                    {errors.otherCon ? (
                      <p style={{ fontSize: "11px" }} className="text-danger">
                        {errors.otherCon}
                      </p>
                    ) : null}
                    {errors.otherCon_code ? (
                      <p style={{ fontSize: "11px" }} className="text-danger">
                        {errors.otherCon_code}
                      </p>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-xs-12 mt-2">
              <div className="form-group">
                <label className="text-label">
                  Country<strong className="text-danger">*</strong>
                </label>
                <select
                  required
                  className={
                    errors.countryId
                      ? "form-control custom-select border-danger"
                      : "form-control custom-select"
                  }
                  onChange={(e) => handleChange("countryId", e.target.value)}
                  value={data.countryId || ""}
                >
                  <option value={""}>Select</option>
                  {countries
                    ? countries.map((country, i) => (
                        <option key={i} value={country.id}>
                          {country.countryName}
                        </option>
                      ))
                    : null}
                </select>
                {errors.countryId ? (
                  <p style={{ fontSize: "11px" }} className="text-danger">
                    {errors.countryId}
                  </p>
                ) : null}
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-12 col-xs-12">
          <div className="form-group">
            <div className="mt-4 text-center">
              <MultiFileDropZone
                error={errors.documents}
                multiple="multiple"
                name="document"
                title="Documents (Optional)"
                srcs={data.documents ? data.documents : []}
                onChange={(files) => handleChange("documents", files)}
                onRemove={(i) => removeDocument(i)}
                caption="Maximum 5 documents to be uploaded"
                height="170px"
                fileLimit={5}
                accept={[
                  "image/jpeg",
                  "image/png",
                  "application/pdf",
                  "application/msword",
                  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                  "text/plain",
                ]}
              />
              {isEditing &&
              previewDocs &&
              previewDocs.docs &&
              previewDocs.docs.length
                ? previewDocs.docs.map((doc, index) => (
                    <ButtonGroup className="mb-1">
                      <Button
                        key={String(index)}
                        className={index > 0 ? "ml-1" : ""}
                        onClick={() => {
                          window.open(doc.link, "_blank");
                        }}
                      >
                        <BsArrowUpRight className="mr-1" />
                        {doc.name}
                      </Button>
                      <Button
                        key={String(index) + "-rm-btn"}
                        className={"btn-danger"}
                        onClick={() => {
                          dialog(
                            "Remove Document?",
                            "Are you sure you want to remove the selected document? please note, this action is irreversible",
                            {
                              yesBtn: {
                                title: "Confirm",
                                onTap: () => {
                                  removePreviewDoc(doc.id);
                                },
                              },
                              noBtn: { title: "Cancel" },
                            }
                          );
                        }}
                      >
                        <BsTrash />
                      </Button>
                    </ButtonGroup>
                  ))
                : null}
            </div>
          </div>
        </div>
        <div className="col-lg-12 mt-2 text-center">
          <p>
            By submitting this form you agreed to our{" "}
            <a
              target="_blank"
              href="https://terra-nova.io/terms-and-conditions/"
            >
              Terms & Conditions
            </a>
          </p>
          {showLoader == true ? (
            <div className="float-right">
              <Lottie
                animationData={Loader}
                loop={true}
                style={{
                  width: 50,
                  height: 50,
                }}
              />
            </div>
          ) : (
            <Button className="float-right" onClick={onSubmit}>
              {isEditing ? "Update" : "Submit"}
            </Button>
          )}
        </div>
      </Fragment>
    </div>
  );
};

export default CollectionForm;
