import React, { Fragment, useEffect, useState } from "react";
import {
  listUsers,
  updateUserStatus,
  removeUser,
  createSubUser,
} from "../../../store/actions/UserActions";
import {
  getIndividuals,
  getBusinesses,
  getBoth,
} from "../../../store/selectors/UserSelector";
import {
  getUserId,
  getRoleId,
  getUser,
} from "../../../store/selectors/AuthSelectors";
import { useSelector, useDispatch, connect } from "react-redux";
import FilteringTable from "../table/FilteringTable/FilteringTable";
import { ColumnFilter } from "../table/FilteringTable/ColumnFilter";
import { Badge, Col, Dropdown } from "react-bootstrap";
import Avatar from "react-avatar";
import Dialog from "../partials/Dialog";
import Modal from "../partials/Modal";
import { createUserSchema } from "../../../services/schema";
import CreateUserForm from "./partials/users/CreateUserForm";
import PreviewUser from "./partials/users/PreviewUser";
import Loader from "../../loader.json";
import Lottie from "lottie-react";

const ThreeDots = (
  <svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <rect x="0" y="0" width="24" height="24"></rect>
      <circle fill="#000000" cx="5" cy="12" r="2"></circle>
      <circle fill="#000000" cx="12" cy="12" r="2"></circle>
      <circle fill="#000000" cx="19" cy="12" r="2"></circle>
    </g>
  </svg>
);

const UserList = (props) => {
  const dispatch = useDispatch();
  const all = useSelector(getBoth);
  const individuals = useSelector(getIndividuals);
  const businesses = useSelector(getBusinesses);
  // const user = useSelector(getUser);
  const userId = useSelector(getUserId);
  const roleId = useSelector(getRoleId);
  const [activeTab, setActiveTab] = useState("all");
  const [formErrors, setFormErrors] = useState(createUserSchema.getDefault());
  const [formData, setFormData] = useState(createUserSchema.getDefault());
  const [showLoader, setShowLoader] = useState(null);

  const defaultDialog = {
    open: false,
    title: "",
    text: "",
    onClose: () => {},
  };
  const defaultForm = {
    open: false,
    title: "",
    data: {},
    onClose: () => {},
  };
  const defaultPreview = {
    open: false,
    title: "",
    data: {},
    onClose: () => {},
  };

  const [dialogProps, setDialogProps] = useState(defaultDialog);
  const [formProps, setFormProps] = useState(defaultForm);
  const [previewProps, setPreviewProps] = useState(defaultPreview);

  const columns = [
    {
      Header: "#",
      Footer: "#",
      accessor: "id",
      Filter: ColumnFilter,
    },
    {
      Header: "User",
      Footer: "User",
      Filter: ColumnFilter,
      Cell: (cell) => {
        let first_name = cell.row.original.first_name;
        let last_name = cell.row.original.last_name;
        let username = cell.row.original.username;
        let avatar = cell.row.original.avatar;
        let u_name = cell.row.original ? cell.row.original.username : "N/A";
        let name = "";
        if (first_name && last_name) {
          if (first_name) {
            name += first_name;
          }
          if (last_name) {
            name += " " + last_name;
          }
        } else if (first_name == null && last_name == null && username) {
          name = username;
        } else {
          name = "N/A";
        }
        if (u_name == null || u_name == "") {
          u_name = "N/A";
        }
        return (
          <div className="row" style={{ alignItems: "center" }}>
            <Avatar
              src={avatar}
              name={name == "N/A" ? "Terra Nova" : name}
              round={true}
              size="55"
            />
            <Col>
              <strong>{name}</strong>
              <div>{"@" + u_name}</div>
            </Col>
          </div>
        );
      },
    },
    {
      Header: "Contact",
      Footer: "Contact",
      Filter: ColumnFilter,
      Cell: (cell) => {
        let phone = cell.row.original.phone || "N/A";
        let email = cell.row.original.email || "N/A";
        return (
          <Col>
            <strong>{phone}</strong>
            <div>{email}</div>
          </Col>
        );
      },
    },
    {
      Header: "Pilot City",
      Footer: "Pilot City",
      accessor: "city",
      Cell: (cell) => {
        let city = cell.row.original.city
          ? cell.row.original.city.name +
            ", " +
            cell.row.original.city.country_code.toUpperCase()
          : "N/A";
        return city;
      },
      Filter: ColumnFilter,
    },
    {
      Header: "Status",
      Footer: "Status",
      accessor: "isApproved",
      Cell: (cell) => {
        let status = <Badge variant="light">{cell.row.original.status}</Badge>;
        if (cell.row.original.status === "Not Submitted") {
          status = <Badge variant="light">Not Submitted</Badge>;
        } else if (cell.row.original.status === "Pending Review") {
          status = <Badge variant="info light">Pending Review</Badge>;
        } else if (cell.row.original.status === "Rejected") {
          status = <Badge variant="danger light">Rejected</Badge>;
        } else if (cell.row.original.status == "Approved") {
          status = <Badge variant="success light">Approved</Badge>;
        }
        return status;
      },
      Filter: ColumnFilter,
    },
    {
      Header: "Actions",
      Footer: "Actions",
      Cell: (cell) => {
        if (roleId == 2) return null;
        let variant = "";
        const status = cell.row.original.status;
        const item = cell.row.original;
        if (status === "Approved") {
          variant = "success";
        } else if (status === "Pending Review") {
          variant = "info";
        } else if (status === "Rejected") {
          variant = "danger";
        }
        return showLoader == item.id ? (
          <Lottie
            animationData={Loader}
            loop={true}
            style={{
              width: 50,
              height: 50,
            }}
          />
        ) : (
          <Dropdown>
            <Dropdown.Toggle variant={variant} className="light sharp i-false">
              {ThreeDots}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item
                onSelect={() =>
                  setPreviewProps((pre) => ({
                    ...pre,
                    open: true,
                    user: cell.row.original,
                    title:
                      cell.row.original.first_name +
                      " " +
                      cell.row.original.last_name,
                    yesBtn: null,
                    noBtn: {
                      title: "Dismiss",
                      onTap: () => {
                        setPreviewProps(defaultPreview);
                      },
                    },
                    onClose: () => {
                      setPreviewProps(defaultPreview);
                    },
                  }))
                }
              >
                Preview
              </Dropdown.Item>
              <Dropdown.Header>Change Status</Dropdown.Header>
              {status === "Approved" ||
              status === "Rejected" ||
              status === "Not Submitted" ? (
                <Dropdown.Item
                  onSelect={() =>
                    changeUserStatus("Pending Review", cell.row.original.id)
                  }
                >
                  Pending Review
                </Dropdown.Item>
              ) : null}
              {status === "Pending Review" ||
              status === "Rejected" ||
              status === "Not Submitted" ? (
                <Dropdown.Item
                  onSelect={() =>
                    changeUserStatus("Approved", cell.row.original.id)
                  }
                >
                  Approve
                </Dropdown.Item>
              ) : null}
              {status === "Pending Review" ||
              status === "Approved" ||
              status === "Not Submitted" ? (
                <Dropdown.Item
                  onSelect={() =>
                    changeUserStatus("Rejected", cell.row.original.id)
                  }
                >
                  Reject
                </Dropdown.Item>
              ) : null}
              <Dropdown.Divider />
              <Dropdown.Header>Other Actions</Dropdown.Header>
              <Dropdown.Item
                onSelect={() => {
                  setDialogProps({
                    open: true,
                    title: "Confirmation",
                    text: "This action is irreversible. Are you sure to proceed?",
                    yesBtn: {
                      title: "Yes",
                      onTap: () => {
                        deleteUser(cell.row.original.id);
                        setDialogProps(defaultDialog);
                      },
                    },
                    noBtn: {
                      title: "No",
                      onTap: () => setDialogProps(defaultDialog),
                    },
                    onClose: () => setDialogProps(defaultDialog),
                  });
                }}
                variant="danger"
                className="danger light sharp i-false"
              >
                Delete
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        );
      },
    },
  ];

  let tabData = [];
  let headerBtns = [
    {
      title: "+ Add User",
      onClick: () => {
        setFormProps({
          open: true,
          title: "Add User",
          yesBtn: {
            title: "Submit",
            onTap: (data) => submit(data),
          },
          noBtn: {
            title: "Cancel",
            onTap: () => {
              setFormErrors(createUserSchema.getDefault());
              setFormProps(defaultForm);
              setFormData(createUserSchema.getDefault());
            },
          },
          onClose: () => {
            setFormErrors(createUserSchema.getDefault());
            setFormProps(defaultForm);
            setFormData(createUserSchema.getDefault());
          },
        });
      },
    },
  ];

  if (roleId !== 2) {
    tabData = [
      {
        name: "All",
        onClick: () => setActiveTab("all"),
        badge: all.length,
      },
      {
        name: "Individuals",
        onClick: () => setActiveTab("individuals"),
        badge: individuals.length,
      },
      {
        name: "Organization",
        onClick: () => setActiveTab("businesses"),
        badge: businesses.length,
      },
    ];
    headerBtns = [];
  }

  useEffect(() => {
    dispatch(listUsers(userId, roleId));
  }, []);

  function handleChange(e) {
    setFormData((pre) => ({
      ...pre,
      address: e.target.value,
    }));
    setFormErrors((pre) => ({
      ...pre,
      address: "",
    }));
  }

  function changeUserStatus(status, userId) {
    setShowLoader(userId);
    dispatch(updateUserStatus(status, userId, { setShowLoader }));
  }

  function deleteUser(userId) {
    dispatch(removeUser(userId));
  }

  function submit(data) {
    createUserSchema
      .validate(data, { abortEarly: false })
      .then((isValid) => {
        try {
          if (data.address.startsWith("0x")) {
            dispatch(createSubUser(userId, data));
          }
        } finally {
          if (!data.address.startsWith("0x")) {
            setFormErrors((pre) => ({
              ...pre,
              address: "Please enter a valid wallet address",
            }));
          } else {
            setFormErrors(createUserSchema.getDefault());
            setFormProps(defaultForm);
            setFormData(createUserSchema.getDefault());
          }
        }
      })
      .catch((err) => {
        err.inner.forEach((e) => {
          setFormErrors((pre) => ({ ...pre, [e.path]: e.message }));
        });
      });
  }

  return (
    <Fragment>
      <div className="col-xl-12 col-xxl-12">
        <FilteringTable
          data={
            activeTab === "all"
              ? all
              : activeTab === "individuals"
              ? individuals
              : activeTab === "businesses"
              ? businesses
              : []
          }
          columns={columns}
          searchText={" by ID"}
          title="Users"
          tabData={tabData}
          headerBtns={headerBtns}
        />
      </div>
      <Dialog {...dialogProps} />
      <Modal {...formProps} data={formData} submitWithData={true}>
        <CreateUserForm
          {...formProps}
          {...formData}
          errors={formErrors}
          handleChange={handleChange}
        />
      </Modal>
      <Modal {...previewProps}>
        <PreviewUser {...previewProps} />
      </Modal>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    successMessage: state.auth.successMessage,
    showLoading: state.auth.showLoading,
  };
};

export default connect(mapStateToProps)(UserList);
