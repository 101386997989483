import api from "./AxiosInstance";

export function listWalletDetails(userId) {
  return api.get("accounts/walletDetails/" + userId);
}

export function listTransactions(address) {
  return api.get("transactions/by/a/" + address);
}

export function getGasEST(data) {
  return api.post("accounts/gasEST", data);
}
export function getUserByAddress(address) {
  return api.get("find/by/a/" + address);
}
export function getTransfer(data) {
  return api.post("accounts/transfer", data);
}
export function getApprove(data) {
  return api.post("accounts/approve", data);
}
export function mintNFT(data) {
  return api.post("accounts/mintNFT", data);
}
export function getMyNFTs(address) {
  return api.post("accounts/getMyNFTs/" + address);
}
