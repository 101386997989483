import React, { Fragment } from "react";
import { Button } from "react-bootstrap";
import Loader from "../../../../loader.json";
import Lottie from "lottie-react";

function Form(props) {
  const {
    errors,
    onSubmit,
    handleChange,
    data,
    showLoader,
    isEditing,
    cities,
  } = props;

  return (
    <div className="col-md-6">
      <Fragment>
        <div className="row">
          <div className="col-sm-12">
            <label className="text-label">
              Amount (Local Currency)<strong className="text-danger">*</strong>
            </label>
            <div className="input-group">
              <div className="input-group-prepend">
                <select
                  required
                  value={data.currency || ""}
                  onChange={(e) => handleChange("currency", e.target.value)}
                  className={
                    errors.currency
                      ? "form-control custom-select border-danger"
                      : "form-control custom-select"
                  }
                >
                  <option value="">Select Currency</option>
                  {cities &&
                    cities.length &&
                    cities
                      .map((c) => c.currency_code)
                      .filter(function (item, pos, self) {
                        return self.indexOf(item) == pos;
                      })
                      .sort(function (a, b) {
                        var x = a.toLowerCase();
                        var y = b.toLowerCase();
                        return x < y ? -1 : x > y ? 1 : 0;
                      })
                      .map((cc, i) => {
                        // extracted country codes
                        return (
                          <option key={i} value={cc}>
                            {cc}
                          </option>
                        );
                      })}
                </select>
              </div>
              <input
                type="number"
                name="amount"
                placeholder="Amount"
                value={data.amount || ""}
                required
                onChange={(e) => handleChange("amount", e.target.value)}
                maxLength={50}
                max={50}
                className={
                  errors.title ? "form-control border-danger" : "form-control"
                }
              />
            </div>
            {errors.currency || errors.amount ? null : (
              <small>This amount will be auto converted to USD</small>
            )}
            {errors.currency ? (
              <small className="text-danger">{errors.currency}</small>
            ) : null}
            {errors.amount ? (
              <small className="text-danger">{errors.amount}</small>
            ) : null}
          </div>
          <div className="col-sm-12">
            <label className="text-label mt-3">
              No. of LogiE Token<strong className="text-danger">*</strong>
            </label>
            <input
              type="number"
              name="no_of_logie_token"
              placeholder="Number of LogiE Token"
              value={data.no_of_logie_token || ""}
              required
              onChange={(e) =>
                handleChange("no_of_logie_token", e.target.value)
              }
              className={
                errors.no_of_logie_token
                  ? "form-control border-danger"
                  : "form-control"
              }
            ></input>
            {errors.no_of_logie_token ? (
              <small className="text-danger">{errors.no_of_logie_token}</small>
            ) : null}
          </div>
          <div className="col-sm-6">
            <label className="text-label mt-3">
              Start Date<strong className="text-danger">*</strong>
            </label>
            <input
              type="date"
              name="start_date"
              placeholder="Start Date"
              value={data.start_date || ""}
              onChange={(e) => handleChange("start_date", e.target.value)}
              className={
                errors.start_date
                  ? "form-control border-danger"
                  : "form-control"
              }
            />
            {errors.start_date ? (
              <small className="text-danger">{errors.start_date}</small>
            ) : null}
          </div>
          <div className="col-sm-6">
            <label className="text-label mt-3">End Date</label>
            <input
              type="date"
              name="end_date"
              placeholder="end_date"
              value={data.end_date || ""}
              onChange={(e) => handleChange("end_date", e.target.value)}
              className={
                errors.end_date ? "form-control border-danger" : "form-control"
              }
            />
            {errors.end_date ? (
              <small className="text-danger">{errors.end_date}</small>
            ) : null}
          </div>
        </div>
        <div className="col-lg-12 mt-2 text-center">
          <p>
            By submitting this form you agreed to our{" "}
            <a
              target="_blank"
              href="https://terra-nova.io/terms-and-conditions/"
            >
              Terms & Conditions
            </a>
          </p>
          {showLoader == true ? (
            <div className="float-right">
              <Lottie
                animationData={Loader}
                loop={true}
                style={{
                  width: 50,
                  height: 50,
                }}
              />
            </div>
          ) : (
            <Button className="float-right" onClick={onSubmit}>
              {isEditing ? "Update" : "Submit"}
            </Button>
          )}
        </div>
      </Fragment>
    </div>
  );
}

export default Form;
