import React, { Fragment, useState } from "react";
import { reduxForm } from "redux-form";
import { connect, useSelector } from "react-redux";
import { Button } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import "../../../../../css/screen-transition.css";
import { AiOutlinePlus } from "react-icons/ai";
import {
  getRoleName,
  getUser,
} from "../../../../../store/selectors/AuthSelectors";

const AddDonation = (props) => {
  const history = useHistory();
  const user = useSelector(getUser);
  const roleName = useSelector(getRoleName);
  const [fadeAnim, setFadeAnim] = useState({
    leftContainer: "fade-in-left",
    rightContainer: "fade-in-right",
  });

  function handleAnim(key, value) {
    setFadeAnim((pre) => ({ ...pre, [key]: value }));
  }

  function handleAnimControl() {
    handleAnim("leftContainer", "fade-out-left");
    handleAnim("rightContainer", "fade-out-right");
  }

  return (
    <Fragment>
      <div className="col-md-12 text-center">
        <p className="fs-16">
          Donating to tree and water conservation initiatives is an excellent
          way to support the health of the planet and the well-being of
          communities around the world.{" "}
        </p>
        <hr />
      </div>
      <div className="row justify-content-center">
        <div className={"col-xl-6 col-sm-6 m-t35 " + fadeAnim.leftContainer}>
          <div className="card card-coin" style={{ borderRadius: "20px" }}>
            <div className="card-body text-center">
              <img
                src="https://images.pexels.com/photos/1122409/pexels-photo-1122409.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2"
                className="mb-3 currency-icon"
                width="80"
                height="80"
              />
              <h4 className="fs-20">Add Tree Donation</h4>
              <hr />
              <div className="">
                <p>
                  Donating to tree planting and conservation initiatives is a
                  great way to help mitigate the effects of climate change and
                  support the health.
                </p>
                <div className="mt-1 d-flex justify-content-center">
                  <Button
                    className="primary"
                    onClick={() => {
                      handleAnimControl();
                      history.push("/add-tree-donation");
                    }}
                  >
                    <AiOutlinePlus /> Add
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={"col-xl-6 col-sm-6 m-t35 " + fadeAnim.rightContainer}>
          <div className="card card-coin" style={{ borderRadius: "20px" }}>
            <div className="card-body text-center">
              <img
                src="https://images.pexels.com/photos/4035587/pexels-photo-4035587.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2"
                className="mb-3 currency-icon"
                width="80"
                height="80"
              />
              <h4 className="fs-20">Add Pond Donation</h4>
              <hr />
              <div className="">
                <p>
                  Water is a precious resource that is essential for life, yet
                  many people around the world do not have access to clean
                  drinking water.
                </p>
                <div className="mt-1 d-flex justify-content-center">
                  <Button
                    className="primary"
                    onClick={() => {
                      handleAnimControl();
                      history.push("/add-pond-donation");
                    }}
                  >
                    <AiOutlinePlus /> Add
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* {user.isNGO == true || roleName == "SuperAdmin" ? (
          <div className={"col-xl-4 col-sm-6 m-t35 " + fadeAnim.rightContainer}>
            <div className="card card-coin" style={{ borderRadius: "20px" }}>
              <div className="card-body text-center">
                <img
                  src="https://images.pexels.com/photos/2568889/pexels-photo-2568889.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2"
                  className="mb-3 currency-icon"
                  width="80"
                  height="80"
                />
                <h4 className="fs-20">Create Project</h4>
                <hr />
                <div className="">
                  <p>
                    With a create Project, it becomes easier to perform
                    operations like adding new products, information about
                    specific donations.
                  </p>
                  <div className="mt-1 d-flex justify-content-center">
                    <Button
                      className="primary"
                      onClick={() => {
                        handleAnimControl();
                        history.push("/add-project");
                      }}
                    >
                      <AiOutlinePlus /> Add
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : null} */}
      </div>
    </Fragment>
  );
};

const formWizard = reduxForm({
  form: "AddDonation",
})(AddDonation);

const submitFormWizard = connect((state) => {
  return { state };
})(formWizard);

const mapStateToProps = (state) => {
  return {
    errorMessage: state.auth.errorMessage,
    successMessage: state.auth.successMessage,
    showLoading: state.auth.showLoading,
  };
};

export default connect(mapStateToProps)(submitFormWizard);
