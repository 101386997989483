export const PREVIEW_TREE_DONATION = "[tree donation action] preview";
export const LIST_TREE_DONATION = "[tree donation action] list";
export const ADD_TREE_DONATION = "[tree donation action] add";
export const UPDATE_TREE_DONATION = "[tree donation action] update";
export const REMOVE_TREE_DONATION = "[tree donation action] remove";

// Pond

export const LIST_POND_DONATION = "[pond donation action] list";
export const ADD_POND_DONATION = "[pond donation action] add";
export const UPDATE_POND_DONATION = "[pond donation action] update";
export const REMOVE_POND_DONATION = "[pond donation action] remove";

/// Collection

export const LIST_COLLECTION_DONATION = "[collection donation action] list";
export const ADD_COLLECTION_DONATION = "[collection donation action] add";
export const UPDATE_COLLECTION_DONATION = "[collection donation action] update";
export const REMOVE_COLLECTION_DONATION = "[collection donation action] remove";
export const REMOVE_COLLECTION = "[collection action] remove";
export const COLLECTION_ALL_LIST_ACTION =
  "[collection action] list all collection";

export const COLLECTION_BY_REGION_ACTION =
  "[collection action] list region collection";

//

export const LIST_TREE = "[tree action] list";
export const ADD_TREE = "[tree action] add";
export const UPDATE_TREE = "[tree action] update";
export const REMOVE_TREE = "[tree action] remove";

export const LIST_POND = "[pond action] list";
export const LIST_COLLECTION = "[pond collection action] list";
export const UPDATE_COLLECTION_STATUS = "[collection action] update status";

export const MY_PLANTATION_COUNT = "[tree donation action] my plantation count";
// export const VL_TREES = "[tree donation action] VL trees";
