import api from "./AxiosInstance";

export function listRoles() {
  return api.get("roles/list");
}

export function createRole(data) {
  return api.post("roles", data);
}

export function updateRole(data, roleId) {
  return api.patch("roles/" + roleId, data);
}

export function removeRole(roleId) {
  return api.delete("roles/" + roleId);
}
