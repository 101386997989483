import React, { useState, useEffect } from "react";

function ExpiryCounter(props) {
  const { expiryDate, overMessage, text } = props;
  const [timeRemaining, setTimeRemaining] = useState({});
  const [isOver, setIsOver] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      const expiredate = new Date(expiryDate).getTime();
      const currentDate = Date.now();
      const time = expiredate - currentDate;
      if (time < 0) {
        setIsOver(true);
      } else {
        const days = Math.floor(time / (1000 * 60 * 60 * 24));
        const hours = Math.floor(
          (time % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        );
        const minutes = Math.floor((time % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((time % (1000 * 60)) / 1000);
        setTimeRemaining({ days, hours, minutes, seconds });
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [expiryDate]);

  return (
    <div>
      {isOver ? (
        <p>
          {overMessage ? (
            <b>{overMessage}</b>
          ) : (
            <b>The date is passed away</b>
          )}
        </p>
      ) : (
        <p>
          {timeRemaining.days && <span>{timeRemaining.days}d </span>}
          {timeRemaining.hours && <span>{timeRemaining.hours}h </span>}
          {timeRemaining.minutes && <span>{timeRemaining.minutes}m </span>}
          {timeRemaining.seconds && <span>{timeRemaining.seconds}s</span>}
        </p>
      )}
    </div>
  );
}

export default ExpiryCounter;
