import React from "react";

function CreateUserForm(props) {
  const { handleChange, address, errors } = props;

  return (
    <section>
      <div className="row">
        <div className="col-lg-6 mb-2">
          <div className="form-group">
            <label className="text-label">Wallet Address*</label>
            <input
              value={address}
              type="text"
              name="address"
              className={
                errors.address ? "form-control border-danger" : "form-control"
              }
              placeholder="Wallet Address"
              required
              onChange={handleChange}
            />
            {errors.address ? (
              <small className="text-danger">{errors.address}</small>
            ) : (
              <small>
                Enter wallet address of the user to add them as sub user under
                your account
              </small>
            )}
          </div>
        </div>
      </div>
    </section>
  );
}

export default CreateUserForm;
