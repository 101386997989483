import * as yup from "yup";
const EMPTY_FIELD_MESSAGE = "This field is required";
export const TREE_NAME_LENGTH = 20;
export const TREE_SCIENTIFIC_NAME_LENGTH = 30;
export const BENEFITS_PER_REWARD_LIMIT = 4;
export const OPTIONS_PER_EVENT_LIMIT = 4;

export function arrayOfObject_validator(
  data = [],
  requiredKeys = [],
  conditions = { definedAs: {}, required: [], matchCase: false }
) {
  let errors = [];
  for (let i = 0; i < data.length; i++) {
    const item = data[i];
    let _err = { key: i };
    for (const key in item) {
      if (Object.hasOwnProperty.call(item, key)) {
        if (requiredKeys.includes(key)) {
          if (conditions.required.length && conditions.required.includes(key)) {
            for (const definedKey in conditions.definedAs) {
              if (conditions.matchCase) {
                if (conditions.definedAs[definedKey] !== item[definedKey]) {
                  continue;
                }
              } else if (
                item[definedKey] &&
                conditions.definedAs[definedKey].toLowerCase() !==
                  item[definedKey].toLowerCase()
              ) {
                continue;
              }
              if (item[key] == null || item[key] == "") {
                _err = { ..._err, [key]: EMPTY_FIELD_MESSAGE };
              }
            }
          } else {
            if (item[key] == null || item[key] == "") {
              _err = { ..._err, [key]: EMPTY_FIELD_MESSAGE };
            }
          }
        }
      }
    }

    if (Object.keys(_err).length > 1) {
      errors.push(_err);
    }
  }
  return errors;
}

export const treeDonationValidator = yup.object().shape({
  image: yup.mixed().nullable().required(EMPTY_FIELD_MESSAGE).default(null),
  words: yup.string().nullable().required(EMPTY_FIELD_MESSAGE).default(""),
});
export const pondDonationValidator = yup.object().shape({
  image: yup.mixed().nullable().required(EMPTY_FIELD_MESSAGE).default(null),
  words: yup.string().nullable().required(EMPTY_FIELD_MESSAGE).default(""),
  pond: yup.object().shape({
    name: yup.string().nullable().required(EMPTY_FIELD_MESSAGE).default(""),
    shape: yup.string().nullable().required(EMPTY_FIELD_MESSAGE).default(""),
    depth: yup.string().nullable().required(EMPTY_FIELD_MESSAGE).default(""),
    capacity: yup.string().nullable().required(EMPTY_FIELD_MESSAGE).default(""),
    ph: yup.string().nullable().required(EMPTY_FIELD_MESSAGE).default(""),
    oxygen: yup.string().nullable().required(EMPTY_FIELD_MESSAGE).default(""),
    nutrient: yup.string().nullable().required(EMPTY_FIELD_MESSAGE).default(""),
    temperature: yup
      .string()
      .nullable()
      .required(EMPTY_FIELD_MESSAGE)
      .default(""),
    circulation: yup
      .string()
      .nullable()
      .required(EMPTY_FIELD_MESSAGE)
      .default(""),
    access: yup.string().nullable().required(EMPTY_FIELD_MESSAGE).default(""),
    landscape: yup
      .string()
      .nullable()
      .required(EMPTY_FIELD_MESSAGE)
      .default(""),
  }),
});

export const editCollectionValidator = yup.object().shape({
  title: yup.string().required(EMPTY_FIELD_MESSAGE).default(""),
  s_description: yup.string().required(EMPTY_FIELD_MESSAGE).default(""),
  l_description: yup.string().default(""),
  type: yup.string().required(EMPTY_FIELD_MESSAGE).default(""),
  type_value: yup
    .string()
    .default("")
    .when("type", (type, schema) => {
      if (typeof type === "string" && type.split(",").includes("Other"))
        return schema.required(EMPTY_FIELD_MESSAGE);
      return schema;
    })
    .nullable(),
  region: yup.string().required(EMPTY_FIELD_MESSAGE).default(""),
  countryId: yup.string().required(EMPTY_FIELD_MESSAGE).default(""),
  goal: yup.string().required(EMPTY_FIELD_MESSAGE).default(""),
  duration: yup.string().required(EMPTY_FIELD_MESSAGE).default(""),
  duration_type: yup.string().required("Duration type is required").default(""),
  duration_value: yup.string().required("Duration is required").default(""),
  oName: yup.string().required(EMPTY_FIELD_MESSAGE).default(""),
  oAddress: yup.string().required(EMPTY_FIELD_MESSAGE).default(""),
  oEmail: yup.string().required(EMPTY_FIELD_MESSAGE).default(""),
  oPhone: yup.string().required("Owner Phone Number is required").default(""),
  oPhone_code: yup.string().required("Phone code is required").default(""),
  otherCon: yup
    .string()
    .default("")
    .when("otherCon_code", (otherCon_code, schema) => {
      if (typeof otherCon_code === "string" && otherCon_code.length)
        return schema.required(EMPTY_FIELD_MESSAGE);
      return schema;
    }),
  otherCon_code: yup.string().default(""),
  whatsapp_number: yup.string().default(""),
  budget: yup.string().required("Budget amount is required").default(""),
  currency_code: yup.string().required("Currency is required").default(""),
  min_don_currency: yup
    .string()
    .required(
      "Please selected currency for the for minimum donation acceptance"
    )
    .default(""),
  min_don_amount: yup
    .string()
    .required("Please write minimum donation amount")
    .default(""),
  global_budget: yup.string().default(""),
  global_currency_code: yup.string().default(""),
  documents: yup.mixed().nullable().default([]),
});

export const collectionDonationValidator = yup.object().shape({
  image: yup.mixed().nullable().required(EMPTY_FIELD_MESSAGE).default(null),
  banner: yup.mixed().nullable().required(EMPTY_FIELD_MESSAGE).default(null),
  title: yup.string().required(EMPTY_FIELD_MESSAGE).default(""),
  s_description: yup.string().required(EMPTY_FIELD_MESSAGE).default(""),
  l_description: yup.string().default(""),
  type: yup.string().required(EMPTY_FIELD_MESSAGE).default(""),
  type_value: yup
    .string()
    .default("")
    .when("type", (type, schema) => {
      if (typeof type === "string" && type.split(",").includes("Other"))
        return schema.required(EMPTY_FIELD_MESSAGE);
      return schema;
    }),
  region: yup.string().required(EMPTY_FIELD_MESSAGE).default(""),
  countryId: yup.string().required(EMPTY_FIELD_MESSAGE).default(""),
  goal: yup.string().required(EMPTY_FIELD_MESSAGE).default(""),
  duration: yup.string().required(EMPTY_FIELD_MESSAGE).default(""),
  duration_type: yup.string().required("Duration type is required").default(""),
  duration_value: yup
    .string()
    .required("Duration value is required")
    .default(""),
  oName: yup.string().required(EMPTY_FIELD_MESSAGE).default(""),
  current_stage: yup.string().required(EMPTY_FIELD_MESSAGE).default(""),
  oAddress: yup.string().required(EMPTY_FIELD_MESSAGE).default(""),
  oEmail: yup.string().required(EMPTY_FIELD_MESSAGE).default(""),
  oPhone: yup.string().required("Owner Phone Number is required").default(""),
  oPhone_code: yup.string().required("Phone code is required").default(""),
  otherCon: yup
    .string()
    .default("")
    .when("otherCon_code", (otherCon_code, schema) => {
      if (typeof otherCon_code === "string" && otherCon_code.length)
        return schema.required(EMPTY_FIELD_MESSAGE);
      return schema;
    }),
  otherCon_code: yup.string().default(""),
  whatsapp_number: yup.string().default(""),
  budget: yup.string().required("Budget amount is required").default(""),
  currency_code: yup.string().required("Currency is required").default(""),
  min_don_currency: yup
    .string()
    .required(
      "Please selected currency for the for minimum donation acceptance"
    )
    .default(""),
  min_don_amount: yup
    .string()
    .required("Please write minimum donation amount")
    .default(""),
  global_budget: yup.string().default(""),
  global_currency_code: yup.string().default(""),
  documents: yup.mixed().nullable().default([]),
});

export const uploadNFTValidator = yup.object().shape({
  to: yup.string().nullable().required(EMPTY_FIELD_MESSAGE).default(""),
  donationId: yup
    .string()
    .nullable()
    .required(EMPTY_FIELD_MESSAGE)
    .default(null),
  image: yup.mixed().nullable().required(EMPTY_FIELD_MESSAGE).default(null),
  title: yup.string().nullable().default(""),
  description: yup.string().nullable().default(""),
});

export function competitionFormValidator(data = {}, required = []) {
  let errors = {};
  for (const key in data) {
    if (Object.hasOwnProperty.call(data, key)) {
      if (required.includes(key)) {
        if (data[key] == "" || data[key] == null) {
          errors = {
            ...errors,
            [key]: EMPTY_FIELD_MESSAGE,
          };
        }
      }
      if (key == "benefits") {
        if (data[key].length) {
          for (let i = 0; i < data[key].length; i++) {
            if (data[key][i].type == "" || data[key][i].type == null) {
              errors = {
                ...errors,
                benefit: errors.benefit
                  ? [
                      ...errors.benefit,
                      { benefitKey: i, type: EMPTY_FIELD_MESSAGE },
                    ]
                  : [{ benefitKey: i, type: EMPTY_FIELD_MESSAGE }],
              };
            }
            if (
              data[key][i].type == "LogiE Tokens" ||
              data[key][i].type == "Ethereum"
            ) {
              if (data[key][i].amount == "" || data[key][i].amount == null) {
                errors = {
                  ...errors,
                  benefit: errors.benefit
                    ? [
                        ...errors.benefit,
                        { benefitKey: i, amount: EMPTY_FIELD_MESSAGE },
                      ]
                    : [{ benefitKey: i, amount: EMPTY_FIELD_MESSAGE }],
                };
              }
            } else if (data[key][i].type == "Coupon") {
              if (
                data[key][i].couponId == "" ||
                data[key][i].couponId == null
              ) {
                errors = {
                  ...errors,
                  benefit: errors.benefit
                    ? [
                        ...errors.benefit,
                        { benefitKey: i, couponId: EMPTY_FIELD_MESSAGE },
                      ]
                    : [{ benefitKey: i, couponId: EMPTY_FIELD_MESSAGE }],
                };
              }
            } else if (data[key][i].type == "Other") {
              if (data[key][i].title == "" || data[key][i].title == null) {
                errors = {
                  ...errors,
                  benefit: errors.benefit
                    ? [
                        ...errors.benefit,
                        { benefitKey: i, title: EMPTY_FIELD_MESSAGE },
                      ]
                    : [{ benefitKey: i, title: EMPTY_FIELD_MESSAGE }],
                };
              }
              if (
                data[key][i].description == "" ||
                data[key][i].description == null
              ) {
                errors = {
                  ...errors,
                  benefit: errors.benefit
                    ? [
                        ...errors.benefit,
                        { benefitKey: i, description: EMPTY_FIELD_MESSAGE },
                      ]
                    : [{ benefitKey: i, description: EMPTY_FIELD_MESSAGE }],
                };
              }
            }
          }
        } else {
          errors = {
            ...errors,
            benefits: "At least 1 benefit is required",
          };
        }
      }
      if (
        (data.bord == "Single" || data.bord == "Multiple") &&
        (data.type == "" || data.type == null)
      ) {
        errors = {
          ...errors,
          type: EMPTY_FIELD_MESSAGE,
        };
      }
      if (
        data.bord == "Single" &&
        (data.type == "Purchase Product" || data.type == "Tree Plantation")
      ) {
        if (data.target == "" || data.target == null) {
          errors = {
            ...errors,
            target: EMPTY_FIELD_MESSAGE,
          };
        }
        if (data.type == "Purchase Product" && key == "productId") {
          if (data.productId == "" || data.productId == null) {
            errors = {
              ...errors,
              productId: EMPTY_FIELD_MESSAGE,
            };
          }

          // for (const productKey in data[key]) {
          //   if (Object.hasOwnProperty.call(data[key], productKey)) {
          //     if (
          //       data[key][productKey] == "" ||
          //       data[key][productKey] == null
          //     ) {
          //       errors = {
          //         ...errors,
          //         product: {
          //           ...errors.product,
          //           [productKey]: EMPTY_FIELD_MESSAGE,
          //         },
          //       };
          //     }
          //   }
          // }
        }
      }
      if (data.bord == "Multiple" && data.type == "Event" && key == "cEvent") {
        if (data.cEvent.title == "" || data.cEvent.title == null) {
          errors = {
            ...errors,
            cEvent: {
              ...errors.cEvent,
              title: EMPTY_FIELD_MESSAGE,
            },
          };
        }
        if (data.cEvent.options.length < 2) {
          errors = {
            ...errors,
            option: "At least 2 options are required",
          };
        }
        if (data.cEvent.options && data.cEvent.options.length) {
          for (let i = 0; i < data.cEvent.options.length; i++) {
            const option = data.cEvent.options[i];
            if (option == "" || option == null) {
              errors = {
                ...errors,
                options: errors.options
                  ? [
                      ...errors.options,
                      { optionKey: i, title: EMPTY_FIELD_MESSAGE },
                    ]
                  : [{ optionKey: i, title: EMPTY_FIELD_MESSAGE }],
              };
            }
          }
        }
      }
    }
  }
  return errors;
}

export const tokenAllocationValidator = yup.object().shape({
  currency: yup
    .string()
    .nullable()
    .required("Currency is required")
    .default(""),
  amount: yup.string().nullable().required("Amount is required").default(""),
  no_of_logie_token: yup
    .string()
    .nullable()
    .required(EMPTY_FIELD_MESSAGE)
    .default(null),
  start_date: yup
    .mixed()
    .nullable()
    .required(EMPTY_FIELD_MESSAGE)
    .default(null),
  end_date: yup.string().nullable().default(""),
});

export const tokenRewardValidator = yup.object().shape({
  first_name: yup
    .string()
    .nullable()
    .required("This field is required")
    .default(""),
  last_name: yup
    .string()
    .nullable()
    .required("This field is required")
    .default(""),
  public_address: yup
    .string()
    .nullable()
    .required("This field is required")
    .default(""),
  amount: yup
    .string()
    .nullable()
    .required("This field is required")
    .default(""),
  email: yup
    .string()
    .nullable()
    .email("Please write a valid email")
    .required("This field is required")
    .default(""),
  phone: yup.string().nullable().required("This field is required").default(""),
});

export function submitAccountStep1(data = Object()) {
  const requiredKeys = [
    "roleId",
    "first_name",
    "last_name",
    "username",
    "email",
  ];

  let err = {};

  for (const key in requiredKeys) {
    const _key = requiredKeys[key];
    if (!data[_key]) {
      err = { ...err, [_key]: "This field is required" };
    }
  }

  if (Number(data.roleId) == 2) {
    if (!data.license_id) {
      err = { ...err, license_id: "This field is required" };
    }
    if (!data.selfie) {
      err = { ...err, selfie: "This field is required" };
    }
    if (!data.proof_of_address) {
      err = { ...err, proof_of_address: "This field is required" };
    }
    if (data.isNGO && !data.countryId) {
      err = { ...err, countryId: "This field is required" };
    }
  }

  if (Object.keys(err).length) {
    return err;
  }
  return true;
}

export function submitAccountStep2(data = Object()) {
  const requiredKeysIndividual = ["license_id", "selfie", "proof_of_address"];
  const requiredKeysOrganization = [
    "registration",
    "certificate",
    "company_name",
  ];

  let err = {};

  if (Number(data.roleId) === 3) {
    for (const key in requiredKeysIndividual) {
      const _key = requiredKeysIndividual[key];
      if (!data[_key]) {
        err = { ...err, [_key]: "This field is required" };
      }
    }
  } else if (Number(data.roleId) === 2) {
    for (const key in requiredKeysOrganization) {
      const _key = requiredKeysOrganization[key];
      if (!data[_key]) {
        err = { ...err, [_key]: "This field is required" };
      }
    }
  }

  if (Object.keys(err).length) {
    return err;
  }
  return true;
}
