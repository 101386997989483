import React, { Fragment, useEffect, useState } from "react";
import { errorList } from "../../../store/actions/ErrorActions";
import { useSelector, useDispatch, connect } from "react-redux";
import FilteringTable from "../table/FilteringTable/FilteringTable";
import { ColumnFilter } from "../table/FilteringTable/ColumnFilter";
import { getErrors } from "../../../store/selectors/ErrorSelector";
import { getUserId } from "../../../store/selectors/AuthSelectors";
import moment from "moment";


const ErrorList = (props) => {
  const dispatch = useDispatch();
  const data = useSelector(getErrors);
  const userId = useSelector(getUserId);

  const columns = [
    {
      Header: "#",
      Footer: "#",
      accessor: "id",
      Filter: ColumnFilter,
    },
    {
      Header: "Level",
      Footer: "Level",
      accessor: "level",
      Filter: ColumnFilter,
    },
    {
      Header: "Message",
      Footer: "Message",
      accessor: "message",
      Filter: ColumnFilter,
    },
    {
      Header: "Context",
      Footer: "Context",
      accessor: "context",
      Filter: ColumnFilter,
      Cell: (cell) => {
        if (cell.row.original.context) return cell.row.original.context + " LET";
        else return "N/A";
      },
    },
    {
      Header: "Trace",
      Footer: "Trace",
      accessor: "trace",
      Filter: ColumnFilter,
      Cell: (cell) => {
        if (cell.row.original.trace) return cell.row.original.trace + " LET";
        else return "N/A";
      },
    },
    {
      Header: "Timestamp",
      Footer: "Timestamp",
      accessor: "timestamp",
      Filter: ColumnFilter,
      Cell: (cell) => {
        if (cell.row.original.timestamp) {
          return moment(cell.row.original.timestamp).format("DD MM YYYY hh:mm a");
        }
      },
    },
  ];

  useEffect(() => {
    dispatch(errorList(userId));
  }, []);

  return (
    <Fragment>
      <div className="col-xl-12 col-xxl-12">
        <FilteringTable
          data={data}
          columns={columns}
          title="Errors"
        />
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    successMessage: state.auth.successMessage,
    showLoading: state.auth.showLoading,
  };
};

export default connect(mapStateToProps)(ErrorList);
