export function nullToEmptyValues(data = new Object()) {
  let _data = {};

  for (const property in data) {
    if (
      data[property] == null ||
      (typeof data[property] == "string" &&
        data[property].toLowerCase() == "null")
    ) {
      _data = { ..._data, [property]: "" };
    } else {
      _data = { ..._data, [property]: data[property] };
    }
  }

  return _data;
}

const Allowed = {
  Uppers: "QWERTYUIOPASDFGHJKLZXCVBNM",
  Lowers: "qwertyuiopasdfghjklzxcvbnm",
  Numbers: "1234567890",
  Symbols: "!@#$%^&*",
};

const getRandomCharFromString = (str) =>
  str.charAt(Math.floor(Math.random() * str.length));

export const generatePassword = (length = 8) => {
  // password will be @Param-length, default to 8, and have at least one upper, one lower, one number and one symbol
  let pwd = "";
  pwd += getRandomCharFromString(Allowed.Uppers); //pwd will have at least one upper
  pwd += getRandomCharFromString(Allowed.Lowers); //pwd will have at least one lower
  pwd += getRandomCharFromString(Allowed.Numbers); //pwd will have at least one number
  pwd += getRandomCharFromString(Allowed.Symbols); //pwd will have at least one symbolo
  for (let i = pwd.length; i < length; i++)
    pwd += getRandomCharFromString(Object.values(Allowed).join("")); //fill the rest of the pwd with random characters
  return pwd;
};

export function nFormatter(num, digits) {
  const lookup = [
    { value: 1, symbol: "" },
    { value: 1e3, symbol: "k" },
    { value: 1e6, symbol: "M" },
    { value: 1e9, symbol: "B" },
    { value: 1e12, symbol: "T" },
    { value: 1e15, symbol: "Q" },
    { value: 1e18, symbol: "Qu" },
    { value: 1e21, symbol: "S" },
    { value: 1e24, symbol: "Sep" },
    { value: 1e27, symbol: " Octillion" },
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  var item = lookup
    .slice()
    .reverse()
    .find(function (item) {
      return num >= item.value;
    });
  return item
    ? (num / item.value).toFixed(digits).replace(rx, "$1") + item.symbol
    : "0";
}

export function downloadTextToFile(text, fileTitle) {
  const element = document.createElement("a");
  const file = new Blob([text], {
    type: "text/plain",
  });
  element.href = URL.createObjectURL(file);
  element.download = fileTitle ? fileTitle + ".txt" : "myFile.txt";
  document.body.appendChild(element); // Required for this to work in FireFox
  element.click();
}

export function dataToBlob(dataURI) {
  // convert base64/URLEncoded data component to raw binary data held in a string
  var byteString;
  if (dataURI.split(",")[0].indexOf("base64") >= 0)
    byteString = atob(dataURI.split(",")[1]);
  else byteString = unescape(dataURI.split(",")[1]);

  // separate out the mime component
  var mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];

  // write the bytes of the string to a typed array
  var ia = new Uint8Array(byteString.length);
  for (var i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }

  return new Blob([ia], { type: mimeString });
}
