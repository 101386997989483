import React, { Fragment, useState, useEffect, useRef } from "react";
import PageTitle from "../../../../layouts/PageTitle";
import { reduxForm } from "redux-form";
import {
  getUserId,
  getPilotCity,
} from "../../../../../store/selectors/AuthSelectors";
import { listPonds as listPondsAction } from "../../../../../store/actions/DonationActions";
import { loadingToggleAction } from "../../../../../store/actions/AuthActions";
import { getPondsList } from "../../../../../store/selectors/DonationSelector";
import { useSelector, useDispatch, connect } from "react-redux";
import { useHistory } from "react-router-dom";
import Dialog, {
  defaultDialogButtons,
  defaultDialogProps,
} from "../../../partials/Dialog";
import { AiOutlineArrowLeft } from "react-icons/ai";
import FormData from "form-data";
import { createPondDonation } from "../../../../../services/DonationService";
import Loading from "../../../partials/Loading";
import { pondDonationValidator } from "../../../../../api/validator";
import { addPondDonation } from "../../../../../store/actions/DonationActions";
import "../../../../../css/screen-transition.css";
import PondForm from "./PondForm";
import { Button } from "react-bootstrap";
import { BsFillCaretDownFill } from 'react-icons/bs';
import { toast } from "react-toastify";

const PondDonationForm = (props) => {
  const { showLoading } = props;
  const dispatch = useDispatch();
  const userId = useSelector(getUserId);
  const pilotCity = useSelector(getPilotCity);
  const [category, setCategory] = useState(null);
  const ponds = useSelector(getPondsList);
  const [errors, setErrors] = useState({});
  const pondList = useRef();
  const [dialogProps, setDialogProps] = useState(defaultDialogProps);
  const history = useHistory();
  const [data, setData] = useState({
    userId,
    cityId: pilotCity.id,
    pondId: "",
    image: null,
    words: "",
    lat: "",
    lng: "",
    pond: {},
  });

  const [showLoader, setShowLoader] = useState(false);
  const [fadeAnim, setFadeAnim] = useState({ leftContainer: 'fade-in-left', rightContainer: 'fade-in-right' });

  function handleAnim(key, value) {
    setFadeAnim(pre => ({ ...pre, [key]: value }));
  }

  function handleAnimControl() {
    if (showForm) {
      handleAnim('leftContainer', 'fade-out-right');
    } else {
      handleAnim('leftContainer', 'fade-in-right');
    }
    handleAnim('rightContainer', 'fade-out-right');
  }



  const [showForm, setShowForm] = useState(false);

  function handleChangePond(key, value) {
    setData((pre) => ({ ...pre, pond: { ...pre.pond, [key]: value } }));
    const pondKey = 'pond.' + key;
    setErrors((pre => ({ ...pre, [pondKey]: '' })))
  }

  function onSubmit() {
    setShowLoader(true);
    try {
      dispatch(loadingToggleAction(true));
      pondDonationValidator
        .validate(data, {
          abortEarly: false,
        })
        .then(async (valid) => {
          const formdata = new FormData();
          for (const key in data) {
            if (key !== "image" && key !== "pond") {
              formdata.append(key, String(data[key]));
            } else if (key == "pond") {
              formdata.append(key, JSON.stringify(data[key]));
            } else {
              formdata.append(key, data.image.file);
            }
          }

          const res = await createPondDonation(formdata);
          if (res.data.status == 200) {
            setShowLoader(false)
            toast.success("Your pond successfully donated!")
            dispatch(addPondDonation(res.data.newDonation));
            history.push("/my-donations");
          }
        })
        .catch((err) => {
          toast.warning("Oops! something went wrong")
          setShowLoader(false)
          err.inner.forEach((e) => {
            setErrors((pre) => ({ ...pre, [e.path]: e.message }));
          });
        });
    } catch (error) {
      dispatch(loadingToggleAction(false));
    } finally {
      dispatch(loadingToggleAction(false));
    }
  }

  useEffect(() => {
    dispatch(listPondsAction('pond'));
  }, []);

  useEffect(() => {
    if (ponds && ponds.length && category == null) {
      setTimeout(() => {
        setCategory(ponds[0]);
        pondList?.current?.show();
      }, 300);
    }
  }, [ponds]);


  function handleChange(key, value) {
    setData((pre) => ({ ...pre, [key]: value }));
    setErrors((pre => ({ ...pre, [key]: '' })))
  }

  function dialog(
    title = "",
    text = "",
    buttons = defaultDialogButtons(),
    onClose = () => setDialogProps(defaultDialogProps)
  ) {
    setDialogProps({ open: true, title, text, ...buttons, onClose });
  }

  return (
    <Fragment>
      {showLoading ? <Loading /> : null}
      <PageTitle
        pageTitle={"Add Pond Donation"}
        pageContent={" "}
        activeMenu="Add Donation"
        motherMenu="Dashboard"
      />
      <Button style={{ marginBottom: "20px" }} onClick={() => { handleAnimControl(); setTimeout(() => history.goBack(), 300); }}>
        <AiOutlineArrowLeft className="mr-1" />
        Back
      </Button>
      {
        showForm == false ? (
          <div className="row">
            {
              ponds && ponds.length ? ponds.map((category, index) => {
                return (
                  <div className={"col-xl-4 col-sm-6 m-t35 " + fadeAnim.rightContainer} key={index}>
                    <div className="card card-coin" style={{ borderRadius: "20px" }}>
                      <div className="card-body text-center">
                        <h4 className="fs-20">{category.title}</h4>
                        <hr />
                        <div className="">
                          <p>{category.caption}</p>
                          <div className="mt-1 d-flex justify-content-center">
                            <Button className="primary" onClick={() => {
                              handleAnimControl();
                              setTimeout(() => {
                                setShowForm(true);
                                handleChangePond('categoryId', category.id)
                              }, 300)
                            }}><BsFillCaretDownFill /></Button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )
              }) : null
            }
          </div>
        ) : (
          <div className={"row " + fadeAnim.leftContainer}>
            <div>
              <PondForm
                data={data}
                errors={errors}
                onSubmit={onSubmit}
                showLoader={showLoader}
                handleChange={handleChange}
                defaultDialogButtons={defaultDialogButtons}
                handleChangePond={handleChangePond}
                dialog={dialog}
              />
            </div>
          </div>
        )
      }
      <Dialog {...dialogProps} />
    </Fragment>
  );
};

const formWizard = reduxForm({
  form: "donationForm",
})(PondDonationForm);

const submitFormWizard = connect((state) => {
  return { state };
})(formWizard);

const mapStateToProps = (state) => {
  return {
    errorMessage: state.auth.errorMessage,
    successMessage: state.auth.successMessage,
    showLoading: state.auth.showLoading,
  };
};

export default connect(mapStateToProps)(submitFormWizard);
