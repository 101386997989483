import React from "react";

const SIZE = 45;
const COLOR = "#ffffff";


export function rotateWithPivot(
  degrees,
  pivot = { x: 0, y: 0 },
  additionalTransforms = []
) {
  // Convert degrees to radians
  const radians = (degrees * Math.PI) / 180;
  // Calculate sine and cosine of the angle
  const sin = Math.sin(radians);
  const cos = Math.cos(radians);
  // Calculate the translation needed to move the pivot point to the origin
  const translateToOrigin = [
    { translateX: -pivot.x },
    { translateY: -pivot.y },
  ];
  // Calculate the rotation transform
  const rotate = [{ rotate: radians.toString() }];
  // Calculate the translation needed to move the pivot point back to its original position
  const translateBack = [{ translateX: pivot.x }, { translateY: pivot.y }];
  // Combine the transforms in the correct order
  const transform = [
    ...translateBack,
    ...rotate,
    ...translateToOrigin,
    ...additionalTransforms,
  ];
  return transform;
};

function line({ containerProps, lineProps, dotProps }) {
  return (
    <div style={{ ...containerProps }}>
      <div style={{ borderColor: COLOR, borderStyle: "dashed", ...lineProps }} />
      <div style={{ backgroundColor: COLOR, width: "10px", height: "10px", borderRadius: "100%", ...dotProps }} />
    </div>
  );
}


function PondItem(props) {
  const { position, icon, onClick } = props;

  function getPosition(position) {
    let positionProps = {};
    switch (position) {
      case "top":
        positionProps = { top: 5 };
        break;
      case "bottom":
        positionProps = { bottom: 5 };
        break;
      case "topLeftUp"://10
        positionProps = { top: "11%", left: "35%" };
        break;
      case "topLeftDown": //9
        positionProps = { top: "35%", left: "27%" };
        break;
      case "topRightUp": //2
        positionProps = { top: "12%", right: "34%" }
        break;
      case "topRightDown":
        positionProps = { top: "36%", right: "27%" };
        break;
      case "bottomLeftUp":
        positionProps = { bottom: "7%", left: "35%" };
        break;
      case "bottomLeftDown":
        positionProps = { bottom: "29%", left: "28%" };
        break;
      case "bottomRightUp":
        positionProps = { bottom: "5%", right: "36%" };
        break;
      case "bottomRightDown":
        positionProps = { bottom: "26%", right: "29%" };
        break;

      default:
        break;
    }
    return positionProps;
  }

  return (
    <div onClick={onClick} style={{ position: "absolute", alignItems: "center", justifyContent: "center", cursor: "pointer", ...getPosition(position) }}>

      {position === "topRightUp"
        ? line({
          containerProps: {
            position: "absolute",
            transform: `rotate(${-54}deg)`
          },
          lineProps: {
            width: `${SIZE * 1.1}px`,
            borderTopWidth: 0,
            borderRightWidth: 0,
            borderLeftWidth: 0,
            marginTop: "40px",
            marginLeft: "-45px"
          },
          dotProps: { marginLeft: "-55px", marginTop: "-6px" },
        })
        : null}
      {position === "topRightDown"
        ? line({
          containerProps: {
            position: "absolute",
            transform: `rotate(${-7}deg)`
          },
          lineProps: {
            height: `${SIZE * 0.9}px`,
            borderTopWidth: 0,
            borderLeftWidth: 0,
            borderRightWidth: 0,
            marginLeft: "-39px",
            marginTop: "-10px"
          },
          dotProps: { marginLeft: "-50px", marginTop: "-5px" },
        })
        : null}
      {position === "bottomRightUp"
        ? line({
          containerProps: {
            position: "absolute",
            transform: `rotate(${335}deg)`
          },
          lineProps: {
            height: `${SIZE * 0.9}px`,
            borderBottomWidth: 0,
            borderTopWidth: 0,
            borderRightWidth: 0,
            marginTop: "-40px",
            marginLeft: "14px"
          },
          dotProps: { marginTop: "-53px", marginLeft: "10px" },
        })
        : null}
      {position === "bottomRightDown"
        ? line({
          containerProps: {
            position: "absolute",
            transform: `rotate(${310}deg)`
          },
          lineProps: {
            height: `${SIZE * 0.9}px`,
            borderBottomWidth: 0,
            borderTopWidth: 0,
            borderRightWidth: 0,
            marginTop: "-30px",
            marginLeft: "-8px"
          },
          dotProps: { marginTop: "-50px", marginLeft: "-12px" },
        })
        : null}
      {position === "bottom"
        ? line({
          containerProps: {
            position: "absolute"
          },
          lineProps: {
            height: `${SIZE * 0.9}px`,
            borderBottomWidth: 0,
            borderTopWidth: 0,
            borderRightWidth: 0,
            marginTop: "-39px",
            marginLeft: "20px"
          },
          dotProps: { marginTop: "-49px", marginLeft: "16px" },
        })
        : null}
      <div
        style={{
          width: SIZE + `px`,
          height: SIZE + `px`,
          borderRadius: "100%",
          borderWidth: "1px",
          borderColor: COLOR,
          backgroundColor: COLOR,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {icon ? (icon) : null}
      </div>
      {position === "top"
        ? line({
          containerProps: { marginLeft: "20px" },
          lineProps: {
            height: `${SIZE * 0.9}px`,
            borderBottomWidth: 0,
            borderRightWidth: 0,
            borderTopWidth: 0,
          },
          dotProps: { marginLeft: "-4px" }
        })
        : null}
      {
        position === "bottomLeftUp"
          ? line({
            containerProps: {
              position: 'absolute',
              transform: `rotate(${294}deg)`
            },
            lineProps: {
              width: `${SIZE * 0.9}px`,
              borderTopWidth: 0,
              borderLeftWidth: 0,
              borderLeftWidth: 0,
              marginTop: "-50px",
              marginLeft: "94px"
            },
            dotProps: { marginLeft: "136px", marginTop: "-6px" },
          })
          : null
      }
      {
        position === "bottomLeftDown"
          ? line({
            containerProps: {
              position: "absolute",
              transform: `rotate(${-25}deg)`
            },
            lineProps: {
              width: `${SIZE * 0.9}px`,
              borderTopWidth: 0,
              borderRightWidth: 0,
              borderLeftWidth: 0,
              marginLeft: "50px",
              marginTop: "-28px"
            },
            dotProps: { marginLeft: "91px", marginTop: "-6px" },
          })
          : null
      }
      {
        position === "topLeftUp"
          ? line({
            containerProps: {
              justifyContent: "center",
              transform: `rotate(${-27}deg)`
            },
            lineProps: {
              height: `${SIZE * 0.9}px`,
              borderBottomWidth: 0,
              borderTopWidth: 0,
              borderRightWidth: 0,
              marginLeft: "43px"
            },
            dotProps: { marginLeft: "39px", marginTop: "2px" },
          })
          : null
      }
      {
        position === "topLeftDown"
          ? line({
            containerProps: {
              justifyContent: "center",
              transform: `rotate(${20}deg)`
            },
            lineProps: {
              height: `${SIZE * 0.9}px`,
              borderBottomWidth: 0,
              borderRightWidth: 0,
              borderLeftWidth: 0,
              marginTop: "-16px"
            },
            dotProps: { marginLeft: "80px", marginTop: "-44px" },
          })
          : null
      }
    </div>
  );
}

export default PondItem;
