import React, { Fragment } from "react";
import "./InfoWidget.css";
import { GiTreehouse } from "react-icons/gi";
import { GiFruitTree } from "react-icons/gi";
import { GiPineTree } from "react-icons/gi";
import { GiTreeDoor } from "react-icons/gi";
import { GiBurningTree } from "react-icons/gi";

const TreeCategory = (props) => {
  const { categories, handleChangeCategory } = props;

  const catIcons = {
    "Large Trees": <GiBurningTree size={40} />,
    "Intermediate Trees": <GiTreeDoor size={40} />,
    "Medium Trees": <GiPineTree size={40} />,
    "Small Trees": <GiFruitTree size={40} />,
    Other: <GiTreehouse size={40} />,
  };

  return (
    <Fragment>
      <div className="card category">
        <div className="card-body">
          {categories.map((category, index) => {
            {
              return (
                <div
                  key={index}
                  onClick={() => handleChangeCategory(category)}
                  style={{ borderWidth: "4px", borderColor: "red" }}
                >
                  <div className="icon d-flex justify-content-center">
                    {catIcons[category.title]}
                  </div>
                  <p className="d-flex justify-content-center">
                    {category.title}
                  </p>
                  <hr />
                </div>
              );
            }
          })}
        </div>
      </div>
    </Fragment>
  );
};

export default TreeCategory;
