import React, { Fragment, useState } from "react";
import { Button } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import success from "./success.json";
import Lottie from "lottie-react";
import { useEffect } from "react";
import { setTimeout } from "timers";

const PaymentMessage = (props) => {
  const {} = props;
  const history = useHistory();
  const [showAnimation, setShowAnimation] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setShowAnimation(true);
    }, 1000);
  }, []);

  return (
    <Fragment>
      <div className="row" style={{ justifyContent: "center" }}>
        <div className="col-xl-5 col-sm-6 m-t35">
          <div className="card card-coin" style={{ borderRadius: "20px" }}>
            <div className="card-body text-center">
              <div
                className="col-lg-12"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {showAnimation ? (
                  <Lottie
                    style={{ width: "100px", height: "100px" }}
                    animationData={success}
                    loop={false}
                  />
                ) : null}
              </div>
              <h4 className="fs-20 mt-3">Success</h4>
              <hr />
              <div className="">
                <p>Congratulations, your payment has been successfully done</p>
                <div className="mt-1 d-flex justify-content-center">
                  <Button
                    style={{ marginBottom: "20px" }}
                    onClick={() => {
                      history.push("/my-donations");
                    }}
                  >
                    Continue
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default PaymentMessage;
