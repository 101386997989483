import { isAuthenticated } from "./AuthSelectors";

export function getDashboard(state) {
  if (isAuthenticated(state)) return state.dashboard.data || {};
  return {};
}

export function getDashboardMarket(state) {
  if (isAuthenticated(state)) return state.dashboard.marketCap || [];
  return [];
}

export function getTopMarketCoins(state) {
  if (isAuthenticated(state))
    return (
      {
        names: state.dashboard.names || [],
        prices: state.dashboard.prices || [],
      } || null
    );
  return null;
}

export function getMarketDataLoadingStatus(state) {
  if (isAuthenticated(state)) return state.dashboard.isMarketDataLoading;
  return false;
}
