import React, { useContext } from "react";

/// React router dom
import { Switch, Route } from "react-router-dom";

/// Css
import "./index.css";
import "./chart.css";
import "./step.css";

/// Layout
import Nav from "./layouts/nav";
import Footer from "./layouts/Footer";
import ScrollToTop from "./pages/ScrollToTop";

/// Dashboard
import Home from "./components/Dashboard/Home";
import Wallet from "./components/Dashboard/Wallet";
import Transactions from "./components/Dashboard/Transactions";
import CoinDetails from "./components/Dashboard/CoinDetails";
import VirtualLookup from "./components/Dashboard/VirtualLookup";

//
import UserList from "./components/Dashboard/UserList";
import RoleList from "./components/Dashboard/RoleList";
import CitiesForm from "./components/Dashboard/partials/cities/CitiesForm";
import TreeDonationList from "./components/Dashboard/TreeDonationList";
import TreeDonationPreview from "./components/Dashboard/DonationPreview";
import ContestList from "./components/Dashboard/ContestList";
import CouponList from "./components/Dashboard/CouponList";
import ContestPreview from "./components/Dashboard/ContestPreview";

/// App
import AppProfile from "./components/AppsMenu/AppProfile/AppProfile";

// Forms
import SubmitAccount from "./components/Dashboard/forms/account/SubmitAccount";
import DonationForm from "./components/Dashboard/forms/donations/DonationForm";

/// Pages
import Setting from "./layouts/Setting";
import { ThemeContext } from "../context/ThemeContext";
import ProductLits from "./components/Dashboard/ProductList";
import HelpSupport from "./components/Dashboard/HelpSupport";
import AndroidList from "./components/Dashboard/AndroidList";
import IOSList from "./components/Dashboard/IOSList";
import ErrorList from "./components/Dashboard/ErrorList";
// import WaterPondList from "./components/Dashboard/WaterPondList";
import PondDonationForm from "./components/Dashboard/forms/donations/PondDonationForm";
import AddDonation from "./components/Dashboard/forms/donations/AddDonation";
import CollectionDonationForm from "./components/Dashboard/forms/donations/CollectionDonationForm";
import EditCollection from "./components/Dashboard/forms/donations/EditCollection";
import CollectionList from "./components/Dashboard/CollectionList";
import CollectionDonationPre from "./components/Dashboard/CollectionPreview";
import CollectionPayment from "./components/Dashboard/CollectionPayment";
import ProductPreview from "./components/Dashboard/ProductPreview";
import PaymentMessage from "./components/Dashboard/forms/donations/PaymentMessage";
import ContestParticipate from "./components/Dashboard/forms/donations/ContestParticipate";
import Feedback from "./components/Dashboard/Feedback";
import FeedbackPreview from "./components/Dashboard/FeedbackPreview";
import Editlogs from "./components/Dashboard/Editlogs";
import WishlistList from "./components/Dashboard/WishlistList";

// token allocation config
import TokenAllocationList from "./components/Dashboard/TokenAllocationList";
import TokenAllocationAddForm from "./components/Dashboard/forms/tokenAllocation/AddForm";
import TokenAllocationEditForm from "./components/Dashboard/forms/tokenAllocation/EditForm";

// token allocation config
import TokenRewardList from "./components/Dashboard/TokenRewardList";
import TokenRewardAddForm from "./components/Dashboard/forms/tokenReward/AddForm";

const Index = () => {
  const { menuToggle } = useContext(ThemeContext);
  const routes = [
    /// Dashboard
    { url: "", component: Home },
    { url: "dashboard", component: Home },
    { url: "my-wallet", component: Wallet },
    { url: "transactions", component: Transactions },
    { url: "coin-details", component: CoinDetails },
    { url: "profile", component: AppProfile },
    { url: "user-list", component: UserList },
    { url: "role-list", component: RoleList },
    // Start
    { url: "add-cities", component: CitiesForm },
    //
    { url: "cities-list", component: RoleList },
    // End
    { url: "my-donations", component: TreeDonationList },
    { url: "my-projects", component: CollectionList },
    { url: "tree-donation/:donationId", component: TreeDonationPreview },
    { url: "project/:collectionId", component: CollectionDonationPre },
    { url: "product-preview/:productId", component: ProductPreview },
    { url: "contest-participate/:contestId", component: ContestParticipate },
    { url: "feedback/:feedbackId", component: FeedbackPreview },
    { url: "coupons-list", component: CouponList },
    { url: "contests-list", component: ContestList },
    { url: "products-list", component: ProductLits },
    { url: "contests-preview", component: ContestPreview },
    { url: "virtual-lookup", component: VirtualLookup },
    { url: "help-support", component: HelpSupport },
    { url: "feedback", component: Feedback },
    { url: "android-devices", component: AndroidList },
    { url: "ios-devices", component: IOSList },
    { url: "error-logs", component: ErrorList },
    { url: "payment-message", component: PaymentMessage },
    { url: "audit-logs", component: Editlogs },
    { url: "wishlist", component: WishlistList },
    // Forms
    { url: "account", component: SubmitAccount },
    { url: "add-tree-donation", component: DonationForm },
    { url: "add-pond-donation", component: PondDonationForm },
    { url: "add-donation", component: AddDonation },
    { url: "add-project", component: CollectionDonationForm },
    { url: "edit-project/:collectionId", component: EditCollection },
    { url: "project-payment/:collectionId", component: CollectionPayment },
    { url: "token-allocations", component: TokenAllocationList },
    { url: "add-token-allocation", component: TokenAllocationAddForm },
    {
      url: "update-token-allocation/:tokenAllocationId",
      component: TokenAllocationEditForm,
    },
    { url: "token-rewards", component: TokenRewardList },
    { url: "add-token-reward", component: TokenRewardAddForm },
  ];
  let path = window.location.pathname;
  path = path.split("/");
  path = path[path.length - 1];
  let pagePath = path.split("-").includes("page");

  return (
    <>
      <div
        id={`${!pagePath ? "main-wrapper" : ""}`}
        className={`${!pagePath ? "show" : "mh100vh"}  ${
          menuToggle ? "menu-toggle" : ""
        }`}
      >
        {!pagePath && <Nav />}

        <div className={`${!pagePath ? "content-body" : ""}`}>
          <div
            className={`${!pagePath ? "container-fluid" : ""}`}
            style={{ minHeight: window.screen.height - 60 }}
          >
            <Switch>
              {routes.map((data, i) => (
                <Route
                  key={i}
                  exact
                  path={`/${data.url}`}
                  component={data.component}
                />
              ))}
            </Switch>
          </div>
        </div>
        {!pagePath && <Footer />}
      </div>
      <Setting />
      <ScrollToTop />
    </>
  );
};

export default Index;
