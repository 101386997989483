import React, { useState, useEffect } from "react";
import { Button, Badge } from "react-bootstrap";
import "./forms/donations/TreePreview.css";
import "../../../css/screen-transition.css";
import { TbTrees } from "react-icons/tb";
import { GiTreeGrowth } from "react-icons/gi";
import { SiGumtree } from "react-icons/si";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { useHistory, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { getRoleId } from "../../../store/selectors/AuthSelectors";
import { mintNFT } from "../../../services/WalletService";
import Loading from "../partials/Loading";
import { getDonationById } from "../../../services/DonationService";
import TNLogo from "../../../images/logo.png";
import { HiExternalLink } from "react-icons/hi";
import Dialog, {
  defaultDialogButtons,
  defaultDialogProps,
} from "../partials/Dialog";
import Modal from "../partials/Modal";
import { uploadNFTValidator } from "../../../api/validator";
import UploadNFTForm from "./forms/donations/UploadNFTForm";
import FormData from "form-data";
import Lottie from "react-lottie-player";
import DarkPond from "./darkPond.json";
import LightPond from "./lightPond.json";
import NeutralPond from "./neutralPond.json";
import PondItem from "./PondItem";
import { BiRectangle } from "react-icons/bi";
import { BsFillCircleFill } from "react-icons/bs";
import { CgShapeZigzag } from "react-icons/cg";
import { BiShapePolygon } from "react-icons/bi";
import { BiBrushAlt } from "react-icons/bi";
import { MdBrightnessMedium } from "react-icons/md";
import { SiDeepin } from "react-icons/si";
import { MdReduceCapacity } from "react-icons/md";
import { TbCircuitCapacitorPolarized } from "react-icons/tb";
import { TbCircuitCapacitor } from "react-icons/tb";
import { GiAcid } from "react-icons/gi";
import { BsEmojiNeutral } from "react-icons/bs";
import { GiAcidBlob } from "react-icons/gi";
import { GiHypodermicTest } from "react-icons/gi";
import { MdOutlineFireHydrantAlt } from "react-icons/md";
import { GiAerosol } from "react-icons/gi";
import { GiAcidTube } from "react-icons/gi";
import { GiVileFluid } from "react-icons/gi";
import { GiHydraShot } from "react-icons/gi";
import { FaTemperatureLow } from "react-icons/fa";
import { TbTemperature } from "react-icons/tb";
import { FaTemperatureHigh } from "react-icons/fa";
import { MdOutlineNature } from "react-icons/md";
import { MdEmojiNature } from "react-icons/md";
import { GiCircularSawblade } from "react-icons/gi";
import { MdBlurCircular } from "react-icons/md";
import { MdPublic } from "react-icons/md";
import { RiGitRepositoryPrivateFill } from "react-icons/ri";
import { MdOutlinePublishedWithChanges } from "react-icons/md";
import { BsGearWideConnected } from "react-icons/bs";
import { TbBrandCodecov } from "react-icons/tb";
import { BiLandscape } from "react-icons/bi";
import { MdLandscape } from "react-icons/md";
import { MdOutlineLandslide } from "react-icons/md";
import { SiSpond } from "react-icons/si";
import { GiPlantWatering } from "react-icons/gi";
import { getPermissions } from "../../../store/selectors/AuthSelectors";

const TreeDonationPreview = (props) => {
  let { donationId } = useParams();
  const history = useHistory();
  const roleId = useSelector(getRoleId);
  const permissions = useSelector(getPermissions);
  const [donation, setDonation] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isFormLoading, setIsFormLoading] = useState(false);
  const [clickedId, setClickedId] = useState(null);
  const [dialogProps, setDialogProps] = useState(defaultDialogProps);
  const [formErrors, setFormErrors] = useState(uploadNFTValidator.getDefault());
  const [formData, setFormData] = useState(uploadNFTValidator.getDefault());
  const [item, setItem] = useState(null);
  const [fadeAnim, setFadeAnim] = useState({
    key: "fade-in-up",
    container: "fade-in-up",
  });

  function handleAnim(key, value) {
    setFadeAnim((pre) => ({ ...pre, [key]: value }));
  }

  const defaultForm = {
    open: false,
    title: "",
    data: {},
    onClose: () => {},
  };

  const LANDSCAPE = [
    {
      title: "Natural Ponds",
      caption:
        "Natural ponds are designed to blend in with their surroundings and may be left in their natural state. These ponds may have natural banks and vegetation, and may be home to a variety of wildlife. They are often used to create a natural, tranquil atmosphere in a backyard or public park.",
      icon: (iconProps, iconSize) => (
        <BiLandscape size={20} color="black" {...iconProps} {...iconSize} />
      ),
    },
    {
      title: "Decorative Ponds",
      caption:
        "Decorative ponds are designed to enhance the aesthetic appeal of an area and may be found in residential or commercial landscaping. These ponds may be landscaped with rocks, plants, and water features such as fountains or waterfalls.",
      icon: (iconProps, iconSize) => (
        <MdLandscape size={20} color="black" {...iconProps} {...iconSize} />
      ),
    },
    {
      title: "Formal Ponds",
      caption:
        "Formal ponds are typically found in formal gardens and are designed to be symmetrical and geometrically shaped. These ponds may have straight edges and be surrounded by formal landscaping such as hedges or topiaries.",
      icon: (iconProps, iconSize) => (
        <MdOutlineLandslide
          size={20}
          color="black"
          {...iconProps}
          {...iconSize}
        />
      ),
    },
    {
      title: "Koi Ponds",
      caption:
        "Koi ponds are designed to house ornamental fish such as Koi and may be found in residential or commercial landscaping. These ponds may have a more formal design and be landscaped with rocks, plants, and water features.",
      icon: (iconProps, iconSize) => (
        <SiSpond size={20} color="black" {...iconProps} {...iconSize} />
      ),
    },
    {
      title: "Water Gardens",
      caption:
        "Water gardens are designed to incorporate aquatic plants and may be found in residential or commercial landscaping. These ponds may have a naturalistic design and be surrounded by lush vegetation.",
      icon: (iconProps, iconSize) => (
        <GiPlantWatering size={20} color="black" {...iconProps} {...iconSize} />
      ),
    },
  ];
  const ACCESSLEVEL = [
    {
      title: "Public Access",
      caption:
        "Public access ponds are open to the public and typically have designated areas for fishing, swimming, and other recreational activities. These ponds may have facilities such as restrooms, picnic areas, and boat launches, and may be managed by local or state agencies.",
      icon: (iconProps, iconSize) => (
        <MdPublic size={20} color="black" {...iconProps} {...iconSize} />
      ),
    },
    {
      title: "Private Access",
      caption:
        "Private access ponds are located on private property and are only accessible to the owner and their guests. These ponds may be used for recreational activities or as a water source for irrigation or livestock.",
      icon: (iconProps, iconSize) => (
        <RiGitRepositoryPrivateFill
          size={20}
          color="black"
          {...iconProps}
          {...iconSize}
        />
      ),
    },
    {
      title: "Retention Ponds",
      caption:
        "Retention ponds are designed to capture and treat storm water runoff and may be located in residential or commercial areas. These ponds may have limited access and may be fenced off for safety.",
      icon: (iconProps, iconSize) => (
        <MdOutlinePublishedWithChanges
          size={20}
          color="black"
          {...iconProps}
          {...iconSize}
        />
      ),
    },
    {
      title: "Decorative Ponds",
      caption:
        "Decorative ponds are typically found in residential or commercial landscaping and may be used to enhance the aesthetic appeal of an area. These ponds may have limited access and may be designed to prevent drowning or other safety hazards.",
      icon: (iconProps, iconSize) => (
        <BsGearWideConnected
          size={20}
          color="black"
          {...iconProps}
          {...iconSize}
        />
      ),
    },
    {
      title: "Natural Ponds",
      caption:
        "Natural ponds may be located in parks or other public areas and are typically left in their natural state. These ponds may have limited access and may have signs warning visitors of potential hazards such as slippery banks or deep water.",
      icon: (iconProps, iconSize) => (
        <TbBrandCodecov size={20} color="black" {...iconProps} {...iconSize} />
      ),
    },
  ];
  const CIRCULATIONTYPE = [
    {
      title: "Natural",
      value: 0.2,
      caption:
        "Natural ponds do not have any aeration or circulation devices and rely on natural processes to maintain oxygen levels and water circulation. These ponds may have lower oxygen levels and may be prone to algae growth and stratification.",
      icon: (iconProps, iconSize) => (
        <MdOutlineNature size={20} color="black" {...iconProps} {...iconSize} />
      ),
    },
    {
      title: "Aerated",
      value: 0.5,
      caption:
        "Aerated ponds have an aeration device, such as a fountain or diffuser, that adds oxygen to the water and promotes circulation. These ponds may have higher oxygen levels and can support a wider range of aquatic life.",
      icon: (iconProps, iconSize) => (
        <MdEmojiNature size={20} color="black" {...iconProps} {...iconSize} />
      ),
    },
    {
      title: "Circulated",
      value: 0.7,
      caption:
        "Circulated ponds have a circulation device, such as a pump or waterfall, that moves water around the pond and promotes mixing. These ponds may have more consistent water temperatures and can help prevent stratification and algae growth.",
      icon: (iconProps, iconSize) => (
        <GiCircularSawblade
          size={20}
          color="black"
          {...iconProps}
          {...iconSize}
        />
      ),
    },
    {
      title: "Combination",
      value: 1,
      caption:
        "Combination ponds have both aeration and circulation devices, providing the benefits of both methods. These ponds may have higher oxygen levels, more consistent water temperatures, and a wider range of aquatic life.",
      icon: (iconProps, iconSize) => (
        <MdBlurCircular size={20} color="black" {...iconProps} {...iconSize} />
      ),
    },
  ];
  const TEMPERATURELEVELS = [
    {
      title: "Warm-Water",
      caption:
        "Warm-water ponds have a temperature range of 75-85°F (24-29°C) and can support warm-water fish species such as bass, catfish, and sunfish. These ponds are typically found in warmer climates or may be heated to maintain their temperature.",
      icon: (iconProps, iconSize) => (
        <FaTemperatureHigh
          size={20}
          color="black"
          {...iconProps}
          {...iconSize}
        />
      ),
    },
    {
      title: "Cool-Water",
      caption:
        "Cool-water ponds have a temperature range of 60-75°F (15-24°C) and can support cool-water fish species such as trout, perch, and walleye. These ponds may be found in temperate climates or may be naturally fed by cooler water sources.",
      icon: (iconProps, iconSize) => (
        <FaTemperatureLow
          size={20}
          color="black"
          {...iconProps}
          {...iconSize}
        />
      ),
    },
    {
      title: "Cold-Water",
      caption:
        "Cold-water ponds have a temperature range of 50-60°F (10-15°C) and can support cold-water fish species such as salmon and arctic char. These ponds are typically found in colder climates or at high elevations.",
      icon: (iconProps, iconSize) => (
        <TbTemperature size={20} color="black" {...iconProps} {...iconSize} />
      ),
    },
  ];
  const NUTRIENTLEVELS = [
    {
      title: "Eutrophic",
      caption:
        "Eutrophic ponds have high levels of nutrients, particularly nitrogen and phosphorus, which can lead to excessive plant growth and algal blooms. These ponds may be murky or greenish in color and may have low oxygen levels.",
      icon: (iconProps, iconSize) => (
        <GiAcidTube size={20} color="black" {...iconProps} {...iconSize} />
      ),
    },
    {
      title: "Mesotrophic",
      caption:
        "Mesotrophic ponds have moderate levels of nutrients and can support a diverse range of plant and animal life. These ponds may be clear or slightly turbid and may have moderate oxygen levels.",
      icon: (iconProps, iconSize) => (
        <GiVileFluid size={20} color="black" {...iconProps} {...iconSize} />
      ),
    },
    {
      title: "Oligotrophic",
      caption:
        "Oligotrophic ponds have low levels of nutrients and can support only a limited range of plant and animal life. These ponds may be crystal-clear and may have high oxygen levels.",
      icon: (iconProps, iconSize) => (
        <GiHydraShot size={20} color="black" {...iconProps} {...iconSize} />
      ),
    },
  ];
  const OXYGENLEVELS = [
    {
      title: "Hypoxic",
      caption:
        "Hypoxic ponds have very low levels of dissolved oxygen, which can lead to fish kills and other ecological problems. These ponds may occur naturally in areas with high nutrient loads, or they may be the result of human activity such as fertilizer runoff or sewage discharge.",
      icon: (iconProps, iconSize) => (
        <GiHypodermicTest
          size={20}
          color="black"
          {...iconProps}
          {...iconSize}
        />
      ),
    },
    {
      title: "Anaerobic",
      caption:
        "Anaerobic ponds have low levels of dissolved oxygen, which support anaerobic bacteria and other microorganisms. These ponds may have a higher concentration of organic matter and can produce methane gas. They may be used for treating wastewater or for biogas production.",
      icon: (iconProps, iconSize) => (
        <MdOutlineFireHydrantAlt
          size={20}
          color="black"
          {...iconProps}
          {...iconSize}
        />
      ),
    },
    {
      title: "Aerobic",
      caption:
        "Aerobic ponds have high levels of dissolved oxygen, which support aerobic bacteria and aquatic organisms such as fish, snails, and insects. These ponds may have an aerator or other oxygenating device to maintain high oxygen levels.",
      icon: (iconProps, iconSize) => (
        <GiAerosol size={20} color="black" {...iconProps} {...iconSize} />
      ),
    },
  ];
  const PHLEVELS = [
    {
      title: "Acidic < 7.0",
      caption:
        "Acidic ponds have a pH level below 7.0. They may occur naturally in areas with acidic soils, or they may be the result of pollution from acid rain or other sources.",
      icon: (iconProps, iconSize) => (
        <GiAcid size={20} color="black" {...iconProps} {...iconSize} />
      ),
    },
    {
      title: "Neutral ~ 7.0",
      caption:
        "Neutral ponds have a pH level of 7.0. They are neither acidic nor alkaline, and can support a wide range of aquatic plants and animals.",
      icon: (iconProps, iconSize) => (
        <BsEmojiNeutral size={20} color="black" {...iconProps} {...iconSize} />
      ),
    },
    {
      title: "Alkaline > 7.0",
      caption:
        "Alkaline ponds have a pH level above 7.0. They may occur naturally in areas with alkaline soils, or they may be the result of human activity such as runoff from concrete or other construction materials.",
      icon: (iconProps, iconSize) => (
        <GiAcidBlob size={20} color="black" {...iconProps} {...iconSize} />
      ),
    },
  ];
  const CAPACITIES = [
    {
      title: "< 1000",
      caption:
        "Small ponds typically have a volume of less than 1,000 gallons. They are often used for decorative purposes or to attract wildlife such as birds and butterflies.",
      icon: (iconProps, iconSize) => (
        <MdReduceCapacity
          size={20}
          color="black"
          {...iconProps}
          {...iconSize}
        />
      ),
    },
    {
      title: "1000 ↔ 10000",
      caption:
        "Medium ponds typically have a volume of between 1,000 and 10,000 gallons. They are often used for recreational activities such as swimming, boating, and fishing, or for irrigation or storm water management.",
      icon: (iconProps, iconSize) => (
        <TbCircuitCapacitor
          size={20}
          color="black"
          {...iconProps}
          {...iconSize}
        />
      ),
    },
    {
      title: "> 10000",
      caption:
        "Large ponds typically have a volume of more than 10,000 gallons. They are often used for commercial fishing, aquaculture, or as water reservoirs for agricultural or industrial purposes.",
      icon: (iconProps, iconSize) => (
        <TbCircuitCapacitorPolarized
          size={20}
          color="black"
          {...iconProps}
          {...iconSize}
        />
      ),
    },
  ];
  const PONDSHAPES = [
    {
      title: "Rectangular",
      caption:
        "Rectangular ponds are typically characterized by their straight edges and right angles. They are often used in formal garden designs or for practical purposes such as irrigation or storm water management.",
      icon: (iconProps, iconSize) => (
        <BiRectangle size={20} color="black" {...iconProps} {...iconSize} />
      ),
    },
    {
      title: "Circular",
      caption:
        "Circular ponds are characterized by their round shape. They are often used in naturalistic garden designs or as the focal point of a landscape.",
      icon: (iconProps, iconSize) => (
        <BsFillCircleFill
          size={20}
          color="black"
          {...iconProps}
          {...iconSize}
        />
      ),
    },
    {
      title: "L-Shaped",
      caption:
        "L-shaped ponds have two perpendicular sections, creating an L-shape. They are often used in formal garden designs or to fit into a specific site or landscape.",
      icon: (iconProps, iconSize) => (
        <CgShapeZigzag size={20} color="black" {...iconProps} {...iconSize} />
      ),
    },
    {
      title: "Irregular",
      caption:
        "Irregularly shaped ponds can take on a wide range of shapes, from freeform to asymmetrical. They are often used in naturalistic garden designs or to fit into a specific site or landscape.",
      icon: (iconProps, iconSize) => (
        <BiShapePolygon size={20} color="black" {...iconProps} {...iconSize} />
      ),
    },
  ];
  const DEPTHS = [
    {
      title: "Shallow",
      caption:
        "Shallow ponds are typically less than six feet deep. They are often used for decorative purposes and to attract wildlife such as birds and butterflies.",
      icon: (iconProps, iconSize) => (
        <BiBrushAlt size={20} color="black" {...iconProps} {...iconSize} />
      ),
    },
    {
      title: "Medium",
      caption:
        "Medium depth ponds are typically between six and twelve feet deep. They are often used for recreational activities such as swimming, boating, and fishing.",
      icon: (iconProps, iconSize) => (
        <MdBrightnessMedium
          size={20}
          color="black"
          {...iconProps}
          {...iconSize}
        />
      ),
    },
    {
      title: "Deep",
      caption:
        "Deep ponds are typically deeper than twelve feet. They are often used for commercial fishing, aquaculture, or as water reservoirs.",
      icon: (iconProps, iconSize) => (
        <SiDeepin size={20} color="black" {...iconProps} {...iconSize} />
      ),
    },
  ];

  const [formProps, setFormProps] = useState(defaultForm);

  useEffect(() => {
    if (donationId) {
      getDonation();
    }
  }, [donationId]);

  function percentage(min, total) {
    return ((min / total) * 100).toFixed(0);
  }

  function percentage(min, total) {
    return ((min / total) * 100).toFixed(0);
  }

  function handleChange(key, value) {
    setFormData((pre) => ({ ...pre, [key]: value }));
    setFormErrors((pre) => ({ ...pre, [key]: "" }));
  }

  function getDonation() {
    getDonationById(donationId)
      .then((res) => {
        setIsLoading(false);
        if (res.data.status === 200) {
          setDonation(res.data.donation);
          handleChange(
            "title",
            res.data.donation.treeDonation.tree.name +
              " (" +
              res.data.donation.treeDonation.tree.scientific +
              ")"
          );
        }
      })
      .catch((err) => {
        setIsLoading(false);
      });
  }

  function getSelectedItemDetails(list = [], selectedTitle = "") {
    const item = list.find((itm) => itm.title === selectedTitle);
    if (item) return item;
    else return null;
  }

  function setSelectedItemDetails(
    list = [],
    selectedTitle = "",
    selectedLabel = ""
  ) {
    handleAnim("key", "fade-out-down");
    handleAnim("container", "fade-out-down");

    setTimeout(() => {
      const item = getSelectedItemDetails(list, selectedTitle);
      if (item) setItem({ ...item, label: selectedLabel });
      handleAnim("key", "fade-in-up");
      setTimeout(() => handleAnim("container", "fade-in-up"), 300);
    }, 300);
  }

  function submit(data) {
    uploadNFTValidator
      .validate(
        {
          ...data,
          donationId: donationId,
          to: donation.user.account.address,
        },
        { abortEarly: false }
      )
      .then((isValid) => {
        const formData = new FormData();
        for (const key in data) {
          if (key === "image") {
            formData.append(key, data[key].file);
          } else if (key === "to") {
            formData.append(key, donation.user.account.address);
          } else if (key === "donationId") {
            formData.append(key, donationId);
          } else {
            formData.append(key, data[key]);
          }
        }
        mintNFT(formData)
          .then((res) => {
            setFormErrors(uploadNFTValidator.getDefault());
            setFormProps(defaultForm);
            setFormData(uploadNFTValidator.getDefault());
            setClickedId(donationId);
            setIsFormLoading(false);
            dialog(
              "Success!",
              "The new NFT will take upto 1-2 mins to be uploaded on the blockchain network. Please reload the data to fetch the NFT information",
              defaultDialogButtons({
                yesBtn: null,
                noBtn: {
                  title: "Dismiss",
                },
              })
            );
          })
          .catch(() => {
            setIsFormLoading(false);
          });
      })
      .catch((err) => {
        setIsFormLoading(false);
        err.inner.forEach((e) => {
          setFormErrors((pre) => ({ ...pre, [e.path]: e.message }));
          // console.log("error is", err);
        });
      });
  }

  function dialog(
    title = "",
    text = "",
    buttons = defaultDialogButtons(),
    onClose = () => setDialogProps(defaultDialogProps)
  ) {
    setDialogProps({ open: true, title, text, ...buttons, onClose });
  }

  if (isLoading) {
    return <Loading />;
  } else if (donation) {
    const treeDonation = donation.treeDonation;
    const tree = treeDonation ? treeDonation.tree : null;
    const pond = treeDonation ? treeDonation.pond : null;
    const collection = donation ? donation.collection : null;

    return (
      <div className="row">
        <div className="col-lg-12">
          <Button
            style={{ marginBottom: "20px" }}
            onClick={() => history.goBack()}
          >
            <AiOutlineArrowLeft className="mr-1" />
            Go Back
          </Button>
        </div>
        {collection ? (
          <div className={"col-md-12 col-sm-12 col-xs-12 mt-2 " + fadeAnim.key}>
            <div className="card">
              <div className="card-body">
                <div className="row">
                  <div
                    className="col-lg-6 text-center"
                    style={{
                      backgroundImage: `url(${collection.banner.link})`,
                      backgroundSize: "cover",
                      backgroundRepeat: "no-repeat",
                      backgroundPosition: "center",
                      display: "flex",
                      alignItems: "end",
                      justifyContent: "center",
                    }}
                  ></div>
                  <div className="col-lg-6">
                    <div className="row d-flex mb-2">
                      <div className="col-lg-6">
                        <h5>{collection.name}</h5>
                      </div>
                      <div className="col-lg-6">
                        <Badge
                          style={{ justifyContent: "end", fontSize: "10px" }}
                          variant="success light"
                        >
                          {donation.status}
                        </Badge>
                      </div>
                    </div>
                    <p>
                      <small>{collection.description}</small>
                    </p>
                    <p style={{ color: "#558b2f", marginTop: "-10px" }}>
                      <small>
                        Contributed{" "}
                        {(
                          (donation.donationCount /
                            donation.collection.target) *
                          100
                        ).toFixed(0)}
                        % to this project
                      </small>
                    </p>
                    <p>
                      <b>{collection.type}</b>
                    </p>
                    <p>
                      <b>
                        {collection.unitPrice + " " + collection.currency_code}
                      </b>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : null}
        {pond ? (
          <div className="row col-lg-12">
            <div className="col-lg-12 text-center">
              <h4>{pond.name}</h4>
            </div>
          </div>
        ) : null}
        {pond ? (
          <div className="row col-lg-12 col-xl-12 col-xxl-12 d-flex justify-content-center align-items-center text-center mt-3">
            <div
              className={fadeAnim.key}
              style={{
                zIndex: 3,
                position: "absolute",
                fontSize: "xl",
                textAlign: "center",
                fontWeight: "bold",
              }}
            >
              {item ? item.icon({ color: "white", size: 25 }) : null}
              {item ? <p>{item.label}</p> : null}
              {item ? <h4>{item.title}</h4> : null}
              {!item ? <h4>{donation.category.title}</h4> : null}
            </div>
            <Lottie
              loop
              animationData={
                pond.depth == "Shallow"
                  ? LightPond
                  : pond.depth == "Medium"
                  ? NeutralPond
                  : pond.depth == "Deep"
                  ? DarkPond
                  : null
              }
              play
              style={{
                width: 450,
                height: 450,
              }}
              speed={
                getSelectedItemDetails(CIRCULATIONTYPE, pond.circulation)
                  ? getSelectedItemDetails(CIRCULATIONTYPE, pond.circulation)
                      .value
                  : null
              }
            />
            <PondItem
              position="top"
              icon={
                getSelectedItemDetails(LANDSCAPE, pond.landscape)
                  ? getSelectedItemDetails(LANDSCAPE, pond.landscape).icon()
                  : null
              }
              onClick={() =>
                setSelectedItemDetails(LANDSCAPE, pond.landscape, "Landscape")
              }
            />
            <PondItem
              position="topLeftUp"
              icon={
                getSelectedItemDetails(ACCESSLEVEL, pond.access)
                  ? getSelectedItemDetails(ACCESSLEVEL, pond.access).icon()
                  : null
              }
              onClick={() =>
                setSelectedItemDetails(ACCESSLEVEL, pond.access, "Access")
              }
            />
            <PondItem
              position="topLeftDown"
              icon={
                getSelectedItemDetails(CIRCULATIONTYPE, pond.circulation)
                  ? getSelectedItemDetails(
                      CIRCULATIONTYPE,
                      pond.circulation
                    ).icon()
                  : null
              }
              onClick={() =>
                setSelectedItemDetails(
                  CIRCULATIONTYPE,
                  pond.circulation,
                  "Circulation"
                )
              }
            />
            <PondItem
              position="topRightUp"
              icon={
                getSelectedItemDetails(TEMPERATURELEVELS, pond.temperature)
                  ? getSelectedItemDetails(
                      TEMPERATURELEVELS,
                      pond.temperature
                    ).icon()
                  : null
              }
              onClick={() =>
                setSelectedItemDetails(
                  TEMPERATURELEVELS,
                  pond.temperature,
                  "Temperature"
                )
              }
            />
            <PondItem
              position="topRightDown"
              icon={
                getSelectedItemDetails(NUTRIENTLEVELS, pond.nutrient)
                  ? getSelectedItemDetails(NUTRIENTLEVELS, pond.nutrient).icon()
                  : null
              }
              onClick={() =>
                setSelectedItemDetails(
                  NUTRIENTLEVELS,
                  pond.nutrient,
                  "Nutrient"
                )
              }
            />
            <PondItem
              position="bottomLeftUp"
              icon={
                getSelectedItemDetails(OXYGENLEVELS, pond.oxygen)
                  ? getSelectedItemDetails(OXYGENLEVELS, pond.oxygen).icon()
                  : null
              }
              onClick={() =>
                setSelectedItemDetails(OXYGENLEVELS, pond.oxygen, "Oxygen")
              }
            />
            <PondItem
              position="bottomLeftDown"
              icon={getSelectedItemDetails(PHLEVELS, pond.ph)?.icon()}
              onClick={() => setSelectedItemDetails(PHLEVELS, pond.ph, "Ph")}
            />
            <PondItem
              position="bottomRightUp"
              icon={getSelectedItemDetails(CAPACITIES, pond.capacity)?.icon()}
              onClick={() =>
                setSelectedItemDetails(CAPACITIES, pond.capacity, "Capacity")
              }
            />
            <PondItem
              position="bottomRightDown"
              icon={getSelectedItemDetails(PONDSHAPES, pond.shape)?.icon()}
              onClick={() =>
                setSelectedItemDetails(PONDSHAPES, pond.shape, "Shape")
              }
            />
            <PondItem
              position="bottom"
              icon={getSelectedItemDetails(DEPTHS, pond.depth)?.icon()}
              onClick={() =>
                setSelectedItemDetails(DEPTHS, pond.depth, "Depth")
              }
            />
          </div>
        ) : null}
        <div className="col-lg-12 mt-5">
          {pond ? (
            <div className={"card text-center " + fadeAnim.container}>
              <div className="card-body">
                <p>{item ? item.caption : donation?.category?.caption}</p>
              </div>
            </div>
          ) : null}
        </div>
        {pond ? (
          <div className="col-md-12 col-sm-12 col-xs-12 mt-2">
            <div className="card">
              <div className="card-body text-center justify-content-evenly">
                <div>
                  <img
                    src={treeDonation.file.link}
                    width="300px"
                    style={{ objectFit: "contain" }}
                  />
                </div>
                <div className="mb-3 mt-3">
                  <p style={{ marginBottom: 0 }}>Location Code</p>
                  <h3 className="text-primary" color="primary">
                    {treeDonation.words}
                  </h3>
                </div>
                {treeDonation.tokenUrl ? (
                  <Button
                    disabled={isLoading}
                    onClick={() => {
                      window.open(treeDonation.tokenUrl, "_blank");
                    }}
                  >
                    <HiExternalLink size={18} />
                    Preview NFT
                  </Button>
                ) : (roleId == 1 || permissions.includes("add:nft")) &&
                  donationId !== clickedId ? (
                  <Button
                    onClick={() => {
                      setFormProps({
                        open: true,
                        title: "Upload NFT Clip Art",
                        yesBtn: {
                          title: "Approve & Generate NFT",
                          onTap: (data) => {
                            setIsFormLoading(true);
                            submit(data);
                          },
                        },
                        noBtn: {
                          title: "Cancel",
                          onTap: () => {
                            setFormErrors(uploadNFTValidator.getDefault());
                            setFormProps(defaultForm);
                            setFormData(uploadNFTValidator.getDefault());
                          },
                        },
                        onClose: () => {
                          setFormErrors(uploadNFTValidator.getDefault());
                          setFormProps(defaultForm);
                          setFormData(uploadNFTValidator.getDefault());
                        },
                      });
                    }}
                  >
                    Upload NFT Clip Art
                  </Button>
                ) : donationId === clickedId ? (
                  <Button
                    onClick={() => {
                      setIsLoading(true);
                      getDonation();
                    }}
                  >
                    Reload
                  </Button>
                ) : (
                  <p className="text-danger">The NFTs is not generated yet</p>
                )}
              </div>
            </div>
          </div>
        ) : null}

        {tree ? (
          <div className="col-lg-6">
            <div className="row">
              <div
                className="col-lg-6 main_con"
                style={{ backgroundColor: tree.bg }}
              >
                <img src={tree.url} className="donation_image" />
                <h3>{tree.name}</h3>
              </div>
              <div className="col-lg-6 mt-3">
                <div>
                  <div className="">
                    <TbTrees style={{ marginRight: "10px" }} size={50} />
                    <span>Shape : </span>
                    <span>
                      <strong>{tree.form}</strong>
                    </span>
                  </div>
                  <div className="mt-5">
                    <GiTreeGrowth style={{ marginRight: "10px" }} size={50} />
                    <span>Growth Rate : </span>
                    <span>
                      <strong>{tree.growthRate}</strong>
                    </span>
                  </div>
                  <div className="mt-5">
                    <SiGumtree style={{ marginRight: "15px" }} size={50} />
                    <span>Fall Color : </span>
                    <span>
                      <strong>{tree.fallColor}</strong>
                    </span>
                  </div>
                </div>
              </div>
              <div className="col-lg-12 mt-4 ml-1">
                <span>Scientifically known as</span>
                <h4>
                  <strong>{tree.scientific}</strong>
                </h4>
                <p className="mt-4">
                  <strong>Environment Tolerance</strong> - {tree.envTolerance}
                </p>
                <p>
                  <strong>Location Tolerance</strong> - {tree.locationTolerance}
                </p>
                <p>
                  <strong>Notes</strong> - {tree.notes}
                </p>
              </div>
            </div>
          </div>
        ) : null}
        {tree ? (
          <div className="col-md-4 col-sm-6 col-xs-12 mt-3">
            <div className="card">
              <div className="card-body text-center justify-content-evenly">
                <div>
                  <img
                    src={treeDonation.file.link}
                    width={"100%"}
                    height="70%"
                    style={{ objectFit: "contain" }}
                  />
                </div>
                <div className="mb-3 mt-3">
                  <p style={{ marginBottom: 0 }}>Location Code</p>
                  <h3 className="text-primary" color="primary">
                    {treeDonation.words}
                  </h3>
                </div>
                {treeDonation.tokenUrl ? (
                  <Button
                    disabled={isLoading}
                    onClick={() => {
                      window.open(treeDonation.tokenUrl, "_blank");
                    }}
                  >
                    <HiExternalLink size={18} />
                    Preview NFT
                  </Button>
                ) : (roleId == 1 || permissions.includes("add:nft")) &&
                  donationId !== clickedId ? (
                  <Button
                    onClick={() => {
                      setFormProps({
                        open: true,
                        title: "Upload NFT Clip Art",
                        yesBtn: {
                          title: "Approve & Generate NFT",
                          onTap: (data) => {
                            setIsFormLoading(true);
                            submit(data);
                          },
                        },
                        noBtn: {
                          title: "Cancel",
                          onTap: () => {
                            setFormErrors(uploadNFTValidator.getDefault());
                            setFormProps(defaultForm);
                            setFormData(uploadNFTValidator.getDefault());
                          },
                        },
                        onClose: () => {
                          setFormErrors(uploadNFTValidator.getDefault());
                          setFormProps(defaultForm);
                          setFormData(uploadNFTValidator.getDefault());
                        },
                      });
                    }}
                  >
                    Upload NFT Clip Art
                  </Button>
                ) : donationId === clickedId ? (
                  <Button
                    onClick={() => {
                      setIsLoading(true);
                      getDonation();
                    }}
                  >
                    Reload
                  </Button>
                ) : (
                  <p className="text-danger">The NFTs is not generated yet</p>
                )}
              </div>
            </div>
          </div>
        ) : null}
        {isLoading ? <Loading /> : null}
        <Dialog {...dialogProps} />
        <Modal
          {...formProps}
          data={formData}
          submitWithData={true}
          modalSize="lg"
        >
          <UploadNFTForm
            {...formProps}
            {...{ formData }}
            errors={formErrors}
            isLoading={isFormLoading}
            handleChange={handleChange}
          />
        </Modal>
      </div>
    );
  } else {
    return (
      <div className="col-lg-12 text-center mt-3">
        <img src={TNLogo} width="70px" height="70px" />
        <h3 className="mt-1">Record Not Found</h3>
        <p></p>
      </div>
    );
  }
};

export default TreeDonationPreview;
