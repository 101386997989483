import {
  ALL_TOKEN_REWARDS,
  ALL_TOKEN_REQUEST_REWARDS,
  ADD_TOKEN_REWARDS,
  UPDATE_TOKEN_REWARDS,
  REMOVE_TOKEN_REWARDS,
  TRANSFER_TOKEN_REWARDS,
  TRANSFER_ALL_TOKEN_REWARDS,
} from "./TokenRewardTypes";

import {
  getAllTokenRewards as getAllTokenRewardsService,
  addTokenReward as addTokenRewardService,
  getAllTokenRewardsRequests as getAllTokenRewardsRequestsService,
  removeTokenReward as removeTokenRewardService,
  updateTokenReward as updateTokenRewardService,
  transferRewardById as transferRewardByIdService,
  transferAllPendingRewards as transferAllPendingRewardsService,
} from "../../services/TokenRewardService";

export function getTokenRewardRequestsAction() {
  return (dispatch) => {
    getAllTokenRewardsRequestsService()
      .then((res) => {
        if (res.data.status == 200) {
          const { status, message, ...list } = res.data;
          dispatch(confirmGetTokenRewardRequestsAction(list));
        }
      })
      .catch((err) => {});
  };
}

export function getTokenRewardsAction() {
  return (dispatch) => {
    getAllTokenRewardsService()
      .then((res) => {
        if (res.data.status == 200) {
          const { status, message, ...list } = res.data;
          dispatch(confirmGetTokenRewardsAction(list));
        }
      })
      .catch((err) => {});
  };
}

export function addTokenRewardsAction(data, config) {
  return (dispatch) => {
    addTokenRewardService(data)
      .then((res) => {
        if (res.data.status == 200) {
          const { status, message, ...activites } = res.data;
          dispatch(confirmAddTokenRewardsAction(activites));
          config.toast.success("Token Reward Config successfully created!");
          config.history.push("/token-rewards");
        }
        config.setShowLoader(false);
      })
      .catch((err) => {
        config.setShowLoader(false);
        config.toast.error("Something went wrong!");
      });
  };
}

export function updateTokenRewardsAction(id, data, config) {
  return (dispatch) => {
    updateTokenRewardService(id, data)
      .then((res) => {
        if (res.data.status == 200) {
          const { status, message, ...activites } = res.data;
          dispatch(confirmUpdateTokenRewardsAction(activites));
          config.toast.success("Token Reward Config successfully update!");
          config.setShowLoader(false);
          // config.history.push("/token-allocations");
        }
      })
      .catch((err) => {
        config.setShowLoader(false);
      });
  };
}

export function transferRewardByIdAction(tokenRewardId, config) {
  return (dispatch) => {
    transferRewardByIdService(tokenRewardId)
      .then((res) => {
        if (res.data.status == 200) {
          config.toast.success("Logie Token Transfered successfully");
          const { status, message, ...activites } = res.data;
          dispatch(confirmTransferRewardByIdAction(tokenRewardId));
        }
      })
      .catch((err) => {});
  };
}

export function transferAllRewardAction(config) {
  return (dispatch) => {
    transferAllPendingRewardsService()
      .then((res) => {
        if (res.data.status == 200) {
          config.toast.success("Logie Token Transfered successfully");
          const { status, message, ...activites } = res.data;
          dispatch(confirmTransferAllRewardAction(null));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
}

export function removeTokenRewardsAction(tokenRewardId) {
  return (dispatch) => {
    removeTokenRewardService(tokenRewardId)
      .then((res) => {
        if (res.data.status == 200) {
          const { status, message, ...activites } = res.data;
          dispatch(confirmRemoveTokenRewardsAction(activites));
        }
      })
      .catch((err) => {});
  };
}

export function confirmGetTokenRewardRequestsAction(payload) {
  return {
    type: ALL_TOKEN_REQUEST_REWARDS,
    payload,
  };
}

export function confirmGetTokenRewardsAction(payload) {
  return {
    type: ALL_TOKEN_REWARDS,
    payload,
  };
}

export function confirmAddTokenRewardsAction(payload) {
  return {
    type: ADD_TOKEN_REWARDS,
    payload,
  };
}

export function confirmUpdateTokenRewardsAction(payload) {
  return {
    type: UPDATE_TOKEN_REWARDS,
    payload,
  };
}

export function confirmTransferRewardByIdAction(payload) {
  return {
    type: TRANSFER_TOKEN_REWARDS,
    payload,
  };
}

export function confirmTransferAllRewardAction(payload) {
  return {
    type: TRANSFER_ALL_TOKEN_REWARDS,
    payload,
  };
}

export function confirmRemoveTokenRewardsAction(payload) {
  return {
    type: REMOVE_TOKEN_REWARDS,
    payload,
  };
}
