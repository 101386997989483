import React, { Fragment, useEffect, useState } from "react";
import { createCouponSchema } from "../../../services/schema";
import {
  couponList,
  createCoupon,
  updateCoupon,
  removeCoupon,
} from "../../../store/actions/CouponActions";
import { useSelector, useDispatch, connect } from "react-redux";
import FilteringTable from "../table/FilteringTable/FilteringTable";
import { ColumnFilter } from "../table/FilteringTable/ColumnFilter";
import { Badge, Dropdown } from "react-bootstrap";
import Dialog from "../partials/Dialog";
import Modal from "../partials/Modal";
import { getCoupons } from "../../../store/selectors/CouponSelector";
import { getUserId } from "../../../store/selectors/AuthSelectors";
import CreateCouponForm from "./partials/coupons/CreateCouponForm";
import { toast } from "react-toastify";

const ThreeDots = (
  <svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <rect x="0" y="0" width="24" height="24"></rect>
      <circle fill="#000000" cx="5" cy="12" r="2"></circle>
      <circle fill="#000000" cx="12" cy="12" r="2"></circle>
      <circle fill="#000000" cx="19" cy="12" r="2"></circle>
    </g>
  </svg>
);

const CouponList = (props) => {
  const dispatch = useDispatch();
  const data = useSelector(getCoupons);
  const userId = useSelector(getUserId);
  const [formErrors, setFormErrors] = useState(createCouponSchema.getDefault());
  const [formData, setFormData] = useState(createCouponSchema.getDefault());
  const [togglePerms, setTogglePerms] = useState(false);

  const defaultDialog = {
    open: false,
    title: "",
    text: "",
    onClose: () => { },
  };

  const defaultForm = {
    open: false,
    title: "",
    data: {},
    onClose: () => { },
  };

  const headerBtns = [
    {
      title: "+ Add Coupon",
      onClick: () => {
        setFormProps({
          open: true,
          title: "Add Coupon",
          yesBtn: {
            title: "Submit",
            onTap: (data) => submit(data),
          },
          noBtn: {
            title: "Cancel",
            onTap: () => {
              setTogglePerms(false);
              setFormErrors(createCouponSchema.getDefault());
              setFormProps(defaultForm);
              setFormData(createCouponSchema.getDefault());
            },
          },
          onClose: () => {
            setTogglePerms(false);
            setFormErrors(createCouponSchema.getDefault());
            setFormProps(defaultForm);
            setFormData(createCouponSchema.getDefault());
          },
        });
      },
    },
  ];

  const [dialogProps, setDialogProps] = useState(defaultDialog);
  const [formProps, setFormProps] = useState(defaultForm);

  const columns = [
    {
      Header: "#",
      Footer: "#",
      accessor: "id",
      Filter: ColumnFilter,
    },
    {
      Header: "Title",
      Footer: "Title",
      accessor: "title",
      Filter: ColumnFilter,
    },
    {
      Header: "Discount",
      Footer: "Discount",
      accessor: "discount",
      Filter: ColumnFilter,
    },
    {
      Header: "Validation Period",
      Footer: "Validation Period",
      Filter: ColumnFilter,
      accessor: "expiredAt",
      Cell: (cell) => cell.row.original.expiredAt + " Days",
    },
    {
      Header: "Discount Type",
      Footer: "Discount Type",
      Filter: ColumnFilter,
      accessor: "discountType",
    },
    // {
    //   Header: "Used Count",
    //   Footer: "Used Count",
    //   Filter: ColumnFilter,
    //   accessor: "usedCount",
    // },
    {
      Header: "Status",
      Footer: "Status",
      Cell: (cell) => {
        let status = <Badge variant="success light">Active</Badge>;
        if (cell.row.original.status === "InActive") {
          status = <Badge variant="danger light">Inactive</Badge>;
        }
        return status;
      },
      Filter: ColumnFilter,
    },
    {
      Header: "Actions",
      Footer: "Actions",
      Cell: (cell) => {
        let variant = "success";
        const coupon = cell.row.original;
        const status = coupon.status;
        if (status === "Active") {
          variant = "success";
        } else {
          variant = "danger";
        }
        return (
          <Dropdown>
            <Dropdown.Toggle variant={variant} className="light sharp i-false">
              {ThreeDots}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Header>Change Status</Dropdown.Header>
              {status === "Active" ? (
                <Dropdown.Item
                  onSelect={() => updateStatus("InActive", cell.row.original.id)}
                >
                  Deactivate
                </Dropdown.Item>
              ) : (
                <Dropdown.Item
                  onSelect={() => updateStatus("Active", cell.row.original.id)}
                >
                  Activate
                </Dropdown.Item>
              )}
              <Dropdown.Divider />
              <Dropdown.Header>Other Actions</Dropdown.Header>
              <Dropdown.Item
                onSelect={() => {
                  setFormData(coupon);
                  setFormProps({
                    open: true,
                    title: "Edit Coupon",
                    yesBtn: {
                      title: "Submit",
                      onTap: (data) => submit(data),
                    },
                    noBtn: {
                      title: "Cancel",
                      onTap: () => {
                        setTogglePerms(false);
                        setFormErrors(createCouponSchema.getDefault());
                        setFormProps(defaultForm);
                        setFormData(createCouponSchema.getDefault());
                      },
                    },
                    onClose: () => {
                      setTogglePerms(false);
                      setFormErrors(createCouponSchema.getDefault());
                      setFormProps(defaultForm);
                      setFormData(createCouponSchema.getDefault());
                    },
                  });
                }}
                variant="danger"
                className="danger light sharp i-false"
              >
                Edit
              </Dropdown.Item>
              <Dropdown.Item
                onSelect={() => {
                  if (cell.row.original.userCount) {
                    setDialogProps({
                      open: true,
                      title: "Exception",
                      text:
                        "This role cannot be removed because this role is assigned to " +
                        cell.row.original.userCount +
                        "user(s)",
                      yesBtn: {
                        title: "Okay",
                        onTap: () => {
                          setDialogProps(defaultDialog);
                        },
                      },
                      onClose: () => setDialogProps(defaultDialog),
                    });
                  }
                  setDialogProps({
                    open: true,
                    title: "Confirmation",
                    text: "This action is irreversible. Are you sure to proceed?",
                    yesBtn: {
                      title: "Yes",
                      onTap: () => {
                        removeStatus(cell.row.original.id);
                        setDialogProps(defaultDialog);
                      },
                    },
                    noBtn: {
                      title: "No",
                      onTap: () => setDialogProps(defaultDialog),
                    },
                    onClose: () => setDialogProps(defaultDialog),
                  });
                }}
                variant="danger"
                className="danger light sharp i-false"
              >
                Delete
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        );
      },
    },
  ];

  useEffect(() => {
    dispatch(couponList(userId));
  }, []);

  function handleChange(key, value) {
    setFormData((pre) => ({
      ...pre,
      [key]: value,
    }));
    setFormErrors((pre) => ({
      ...pre,
      [key]: "",
    }));
  }

  function submit(data) {
    createCouponSchema
      .validate(data, { abortEarly: false })
      .then((isValid) => {
        try {
          if (data.id) {
            toast.success("Coupon record Updated!")
            dispatch(
              updateCoupon(
                {
                  ...data,
                  discount: Number(data.discount),
                  usagePerCoupon: Number(data.usagePerCoupon),
                  usagePerUser: Number(data.usagePerUser),
                },
                data.id,
              )
            );
          } else {
            const couponFormData = {
              ...data,
              discount: Number(data.discount),
              userId,
              usagePerCoupon: Number(data.usagePerCoupon),
              usagePerUser: Number(data.usagePerUser)
            };
            toast.success("Coupon successfully created!")
            dispatch(createCoupon(couponFormData));
          }
        } finally {
          setTogglePerms(false);
          setFormErrors(createCouponSchema.getDefault());
          setFormProps(defaultForm);
          setFormData(createCouponSchema.getDefault());
        }
      })
      .catch((err) => {
        toast.warning("Oops! ssomething went wrong")
        err.inner.forEach((e) => {
          setFormErrors((pre) => ({ ...pre, [e.path]: e.message }));
        });
      });
  }

  function updateStatus(status, couponId) {
    dispatch(updateCoupon({ status }, couponId));
  }

  function removeStatus(couponId) {
    dispatch(removeCoupon(couponId));
  }


  return (
    <Fragment>
      <div className="col-xl-12 col-xxl-12">
        <FilteringTable
          data={data}
          columns={columns}
          title="Coupons"
          headerBtns={headerBtns}
        />
      </div>
      <Dialog {...dialogProps} />
      <Modal {...formProps} data={formData} submitWithData={true}>
        <CreateCouponForm
          {...formProps}
          {...{ formData }}
          errors={formErrors}
          handleChange={handleChange}
        />
      </Modal>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    successMessage: state.auth.successMessage,
    showLoading: state.auth.showLoading,
  };
};

export default connect(mapStateToProps)(CouponList);
