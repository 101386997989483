import {
  createRole as createRoleService,
  listRoles as listRoleService,
  updateRole as updateRoleService,
  removeRole as removeRoleService,
} from "../../services/RoleService";

import {
  CREATE_ROLE_ACTION,
  DELETE_ROLE_ACTION,
  LIST_ROLE_ACTION,
  UPDATE_ROLE_ACTION,
} from "./RoleTypes";

export function roleList() {
  return (dispatch) =>
    listRoleService()
      .then((res) => {
        if (res.data.status === 200) {
          dispatch(
            listRoleConfirm({
              list: res.data.roles,
              permissions: res.data.permissions,
            })
          );
        }
      })
      .catch((err) => {
        console.log(err);
      });
}

export function listRoleConfirm(payload) {
  return {
    type: LIST_ROLE_ACTION,
    payload,
  };
}

export function createRole(data) {
  return (dispatch) =>
    createRoleService({ ...data })
      .then((res) => {
        if (res.data.status === 200) {
          dispatch(
            createRoleConfirm({
              ...data,
              id: res.data.newRoleId,
              userCount: 0,
              status: true,
            })
          );
        }
      })
      .catch((err) => {
        console.log(err);
      });
}

export function createRoleConfirm(payload) {
  return {
    type: CREATE_ROLE_ACTION,
    payload,
  };
}

export function updateRole(data, roleId) {
  return (dispatch) =>
    updateRoleService(data, roleId)
      .then((res) => {
        if (res.data.status === 200) {
          dispatch(
            updateRoleConfirm(
              {
                ...data,
                permissions: data.permissions
                  ? data.permissions.split(",")
                  : [],
              },
              roleId
            )
          );
        }
      })
      .catch((err) => {
        console.log(err);
      });
}

export function updateRoleConfirm(data, id) {
  return {
    type: UPDATE_ROLE_ACTION,
    payload: {
      data,
      id,
    },
  };
}

export function removeRole(roleId) {
  return (dispatch) =>
    removeRoleService(roleId)
      .then((res) => {
        if (res.data.status === 200) {
          dispatch(removeRoleConfirm(roleId));
        }
      })
      .catch((err) => {
        console.log(err);
      });
}

export function removeRoleConfirm(payload) {
  return {
    type: DELETE_ROLE_ACTION,
    payload,
  };
}
