import api from "./AxiosInstance";

export function getAllTokenRewards() {
  return api.get("token-rewards");
}

export function getAllTokenRewardsRequests() {
  return api.get("token-rewards/requests");
}

export function transferRewardById(id) {
  return api.get("token-rewards/transfer/by/i/" + id);
}

export function transferAllPendingRewards() {
  return api.post("token-rewards/transferAllPending");
}

export function getOneTokenReward(id) {
  return api.get("token-rewards/" + id);
}

export function addTokenReward(data) {
  return api.post("token-rewards", data);
}

export function updateTokenReward(id, data) {
  return api.patch("token-rewards/" + id, data);
}

export function removeTokenReward(id) {
  return api.delete("token-rewards/" + id);
}
