import {
  PREVIEW_TREE_DONATION,
  LIST_TREE_DONATION,
  LIST_POND_DONATION,
  LIST_COLLECTION_DONATION,
  LIST_TREE,
  LIST_POND,
  LIST_COLLECTION,
  MY_PLANTATION_COUNT,
  COLLECTION_ALL_LIST_ACTION,
  COLLECTION_BY_REGION_ACTION,
  REMOVE_COLLECTION_DONATION,
  ADD_COLLECTION_DONATION,
  UPDATE_COLLECTION_DONATION,
} from "../actions/DonationTypes";

const initialState = {
  trees: [],
  ponds: [],
  collections: [],
  regionCollection: [],
  list: [],
  requests: [],
  plantationCount: 0,
  preview: null,
};

export function DonationReducer(state = initialState, action) {
  if (action.type == LIST_TREE) {
    return { ...state, trees: action.payload };
  }
  if (action.type == LIST_POND) {
    return { ...state, ponds: action.payload };
  }
  if (action.type == LIST_COLLECTION) {
    return { ...state, collections: action.payload };
  }

  //   if (action.type == ADD_TREE) {
  //   }

  //   if (action.type == UPDATE_TREE) {
  //   }

  //   if (action.type == REMOVE_TREE) {
  //   }

  // tree donation reducer methods
  if (action.type == PREVIEW_TREE_DONATION) {
    return { ...state, preview: action.payload };
  }
  if (action.type == LIST_TREE_DONATION) {
    return {
      ...state,
      list: action.payload.list,
      requests: action.payload.requests,
    };
  }

  if (action.type == LIST_POND_DONATION) {
    return {
      ...state,
      list: action.payload.list,
      requests: action.payload.requests,
    };
  }

  if (action.type == LIST_COLLECTION_DONATION) {
    return {
      ...state,
      list: action.payload.list,
      requests: action.payload.requests,
    };
  }

  if (action.type == COLLECTION_ALL_LIST_ACTION) {
    return {
      ...state,
      ...action.payload,
    };
  }

  if (action.type == COLLECTION_BY_REGION_ACTION) {
    return {
      ...state,
      ...action.payload,
    };
  }

  if (action.type === REMOVE_COLLECTION_DONATION) {
    const list = [...state.collections];
    const productIndex = list.findIndex(
      (product) => product.id === action.payload
    );
    list.splice(productIndex, 1);
    return {
      ...state,
      list,
    };
  }

  //virtual lookup
  if (action.type == MY_PLANTATION_COUNT) {
    return { ...state, plantationCount: action.payload };
  }

  return state;
}
