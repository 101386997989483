import React, { useEffect, useState } from "react";
import Loading from "../../../partials/Loading";
import { getPaymentIntent } from "../../../../../services/DonationService";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import CheckoutForm from "./CheckoutForm";
import { STRIPE_PUBLIC_KEY } from "../../../../../services/URL";
const stripePromise = loadStripe(STRIPE_PUBLIC_KEY);

function PaymentForm(props) {
  const { donationId, successRoute } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [clientSecret, setClientSecret] = useState(null);

  useEffect(() => {
    if (donationId) {
      getIntent();
    }
  }, [donationId]);

  function getIntent() {
    getPaymentIntent(donationId)
      .then((res) => {
        if (res.data.status === 200) {
          setClientSecret(res.data.intent.paymentIntentClientSecret);
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  }

  return (
    <section>
      <div className="row">
        <div className="col-lg-12 col-xs-12 text-center">
          {clientSecret != null ? (
            <Elements stripe={stripePromise} options={{ clientSecret }}>
              <CheckoutForm
                successRoute={successRoute}
                setIsLoading={setIsLoading}
              />
            </Elements>
          ) : null}
        </div>
      </div>
      {isLoading ? <Loading noBackdrop={true} /> : null}
    </section>
  );
}

export default PaymentForm;
