import React, { Fragment, useEffect } from "react";
import { useSelector, useDispatch, connect } from "react-redux";
import FilteringTable from "../table/FilteringTable/FilteringTable";
import { ColumnFilter } from "../table/FilteringTable/ColumnFilter";
import { getInterests } from "../../../store/selectors/InterestSelector";
import {
  listInterestAction,
  listAllInterestActions,
} from "../../../store/actions/InterestActions";
import { getRoleName, getUserId } from "../../../store/selectors/AuthSelectors";
import defaultProfileImage from "../../../images/profile/default.jpeg";
import moment from "moment";
import { useHistory } from "react-router-dom";
import { Button } from "react-bootstrap";

const Wishlist = (props) => {
  const dispatch = useDispatch();
  const interest = useSelector(getInterests);
  const roleName = useSelector(getRoleName);
  const userId = useSelector(getUserId);
  const history = useHistory();

  const columns = [
    {
      Header: "#",
      Footer: "#",
      accessor: "id",
      Filter: ColumnFilter,
    },
    {
      Header: "Image",
      Footer: "Image",
      Filter: ColumnFilter,
      Cell: (cell) => {
        let avatar =
          cell.row.original.user && cell.row.original.user.avatar !== null
            ? cell.row.original.user.avatar
            : defaultProfileImage;
        return (
          <img
            src={avatar == "null" ? defaultProfileImage : avatar}
            style={{ width: "50px", height: "50px", borderRadius: "10px" }}
          />
        );
      },
    },
    {
      Header: "User",
      Footer: "User",
      Filter: ColumnFilter,
      Cell: (cell) => {
        let first_name = cell.row.original.user
          ? cell.row.original.user.first_name
          : "N/A";
        let last_name = cell.row.original.user
          ? cell.row.original.user.last_name
          : "N/A";
        return (
          <div className="row" style={{ alignItems: "center" }}>
            <span>{first_name + " " + last_name}</span>
          </div>
        );
      },
    },
    {
      Header: "Date",
      Footer: "Date",
      accessor: "createdAt",
      Filter: ColumnFilter,
      Cell: (cell) => {
        if (cell.row.original.createdAt) {
          return moment(cell.row.original.createdAt).format(
            "DD MM YYYY hh:mm a"
          );
        } else return "N/A";
      },
    },
    {
      Header: "Actions",
      Footer: "Actions",
      Cell: (cell) => {
        return (
          <Button
            className="primary light"
            onClick={() => {
              history.push("my-projects/");
            }}
          >
            See Items
          </Button>
        );
      },
    },
  ];

  useEffect(() => {
    if (roleName == "SuperAdmin") {
      dispatch(listAllInterestActions());
    } else {
      dispatch(listInterestAction(userId));
    }
  }, []);

  return (
    <Fragment>
      <div className="col-xl-12 col-xxl-12">
        <FilteringTable data={interest} columns={columns} title="WishList" />
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    successMessage: state.auth.successMessage,
    showLoading: state.auth.showLoading,
  };
};

export default connect(mapStateToProps)(Wishlist);
