import {
    listAndroid as listAndriodService,
    listIOS as listIOSService
} from "../../services/DeviceService";
import { ANDRIOD_LIST_ACTION, IOS_LIST_ACTION } from "./DeviceTypes";

export function andriodList(type) {
    return (dispatch) => {
        listAndriodService(type)
            .then((res) => {
                if (res.data.status == 200) {
                    dispatch(andriodListConfirm(res.data.devices));
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };
}


export function andriodListConfirm(payload) {
    return {
        type: ANDRIOD_LIST_ACTION,
        payload,
    };
}


export function iosList(type) {
    return (dispatch) => {
        listIOSService(type)
            .then((res) => {
                if (res.data.status == 200) {
                    dispatch(IosListConfirm(res.data.devices));
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };
}


export function IosListConfirm(payload) {
    return {
        type: IOS_LIST_ACTION,
        payload,
    };
}
