import { listEditlogs as listEditlogsService } from "../../services/EditlogsService";

import { LIST_EDITLOGS } from "./EditlogsTypes";


export function listAllEditlogs() {
    return (dispatch) => {
        listEditlogsService()
            .then((res) => {
                if (res.data.status == 200) {
                    dispatch(editlogsConfirm({ list: res.data.editlogs }));
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };
}


export function editlogsConfirm(payload) {
    return {
        type: LIST_EDITLOGS,
        payload,
    };
}