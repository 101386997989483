import React from "react";
import { Button } from "react-bootstrap";
import "./TreeForm.css";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { GiPalmTree } from "react-icons/gi";
import { BsTreeFill } from "react-icons/bs";
import { GiFruitTree } from "react-icons/gi";
import { MdSlowMotionVideo } from "react-icons/md";
import { AiOutlineFieldTime } from "react-icons/ai";
import { MdOutlineTimer } from "react-icons/md";
import TextItem from "./TextItem";
import DropZone from "../../../partials/DropZone";
import Loader from "../../../../loader.json";
import Lottie from "lottie-react";

const TreeForm = (props) => {
  const {
    handleChangeTree,
    handleSelectOtherBack,
    dialog,
    defaultDialogButtons,
    data: { tree },
    handleChange,
    showLoader,
    data,
    errors,
    onSubmit,
  } = props;

  console.log("showLoader", showLoader)


  const SHAPES = [
    { label: "Upright", icon: <GiPalmTree size={20} /> },
    { label: "Pyramidal", icon: <BsTreeFill size={20} /> },
    { label: "Rounded", icon: <GiFruitTree size={20} /> },
  ];
  const GROWTH_SPEEDS = [
    { label: "Slow", icon: <MdSlowMotionVideo size={20} /> },
    { label: "Medium", icon: <AiOutlineFieldTime size={20} /> },
    { label: "Fast", icon: <MdOutlineTimer size={20} /> },
  ];
  const FALL_COLORS = [
    { label: "Yellow", color: "#ffd358", bg: "#ffe9ac" },
    { label: "Yellow/Orange", color: "#ffd358", bg: "#ffe9ac" },
    { label: "Yellow/Bronze", color: "#ff5935", bg: "#ffa895" },
    { label: "Maroon", color: "#ee3a4a", bg: "#ffc5ca" },
    { label: "Orange", color: "#ff7e53", bg: "#ffc8b6" },
    { label: "Orange/Brown", color: "#ff7e53", bg: "#ffc8b6" },
    { label: "Red", color: "#ff5935", bg: "#ffa895" },
    { label: "Red/Yellow", color: "#ff5935", bg: "#ffa895" },
    { label: "Red/Bronze", color: "#ff5935", bg: "#ffa895" },
    { label: "Red/Orange", color: "#ff7e53", bg: "#ffc8b6" },
    { label: "Cream", color: "#d7d35b", bg: "#e9f1be" },
  ];

  return (
    <div>
      <Button style={{ marginBottom: "20px" }} onClick={handleSelectOtherBack}>
        <AiOutlineArrowLeft className="mr-1" />
        Select Tree
      </Button>
      <section>
        <div className="row">
          <div className="col-lg-6 col-xs-12">
            <div className="form-group">
              <label className="text-label">
                Name<strong className="text-danger">*</strong>
              </label>
              <input
                type="text"
                name="name"
                value={tree.name}
                onChange={(e) => handleChangeTree("name", e.target.value)}
                className={
                  errors.name ? "form-control border-danger" : "form-control"
                }
              />
              <label className="mt-3 text-label">
                Scientific Name<strong className="text-danger">*</strong>
              </label>
              <input
                type="text"
                name="scientific"
                value={tree.scientific}
                onChange={(e) => handleChangeTree("scientific", e.target.value)}
                className={
                  errors.scientific
                    ? "form-control border-danger"
                    : "form-control"
                }
              />
              <label className="mt-3 text-label">Shape</label>
              <div className="col-md-12 buttons">
                {SHAPES.map((shape, index) => {
                  return (
                    <button
                      className={tree.form == shape.label ? "text-primary" : ""}
                      key={index}
                      onClick={() =>
                        handleChangeTree("form", (tree.form = shape.label))
                      }
                    >
                      {
                        <div>
                          <span>{shape.icon}</span>
                          <span className="ml-1">{shape.label}</span>
                        </div>
                      }
                    </button>
                  );
                })}
              </div>
              <label className="mt-3 text-label">Growth Rate</label>
              <div className="col-md-12 buttons">
                {GROWTH_SPEEDS.map((growth, index) => {
                  return (
                    <button
                      className={
                        tree.growthRate == growth.label ? "text-primary" : ""
                      }
                      key={index}
                      onClick={() => {
                        handleChangeTree(
                          "growthRate",
                          (tree.growthRate = growth.label)
                        );
                      }}
                    >
                      <div>
                        <span>{growth.icon}</span>
                        <span className="ml-1">{growth.label}</span>
                      </div>
                    </button>
                  );
                })}
              </div>
              <label className="mt-3 text-label">Fall Color</label>
              <div className="col-md-12 btn-color">
                {FALL_COLORS.map((fallColor, index) => {
                  return (
                    <button
                      style={{
                        backgroundColor:
                          tree.fallColor == fallColor.label
                            ? fallColor.bg
                            : undefined,
                      }}
                      key={index}
                      label={fallColor.label}
                      onClick={() => {
                        handleChangeTree(
                          "fallColor",
                          (tree.fallColor = fallColor.label)
                        );
                        handleChangeTree(
                          "color",
                          (tree.fallColor = fallColor.color)
                        );
                        handleChangeTree("bg", (tree.fallColor = fallColor.bg));
                      }}
                    >
                      <div className="d-flex justify-content-center">
                        <div
                          style={{
                            width: "20px",
                            height: "20px",
                            background: fallColor.color,
                            border: "none",
                            borderRadius: "50px",
                          }}
                        ></div>
                        <span style={{ marginLeft: "5px" }}>
                          {fallColor.label}
                        </span>
                      </div>
                    </button>
                  );
                })}
              </div>
              <label className="mt-3 text-label">Environment Tolerance</label>
              <input
                value={tree.envTolerance}
                type="text"
                name="envTolerance"
                onChange={(e) =>
                  handleChangeTree("envTolerance", e.target.value)
                }
                className={
                  errors.envTolerance
                    ? "form-control border-danger"
                    : "form-control"
                }
              />
              <label className="mt-3 text-label">Location Tolerance</label>
              <input
                type="text"
                name="locationTolerance"
                value={tree.locationTolerance}
                onChange={(e) =>
                  handleChangeTree("locationTolerance", e.target.value)
                }
                className={
                  errors.locationTolerance
                    ? "form-control border-danger"
                    : "form-control"
                }
              />
            </div>
          </div>
          <div className="col-lg-4 col-xs-12">
            <div className="mt-4">
              <TextItem
                errors={errors}
                required
                data={data}
                dialog={dialog}
                handleChange={handleChange}
                defaultDialogButtons={defaultDialogButtons}
              />
              {errors.words ? (
                <p style={{ marginTop: -20 }} className="text-danger">
                  {errors.words}
                </p>
              ) : null}
            </div>
            <div className="mt-4">
              <DropZone
                errors={errors}
                required
                title="Plantation Image"
                src={data.image ? data.image.uri : null}
                onChange={(file) => handleChange("image", file)}
                onRemove={() => handleChange("image", null)}
                caption="Click to explore the image gallery"
                height="150px"
              />
              {errors.image ? (
                <p style={{ marginTop: -20 }} className="text-danger">
                  {errors.image}
                </p>
              ) : null}
            </div>
            <div className="">
              <p>
                By submitting this form you agreed to our{" "}
                <a href="https://terra-nova.io/terms-and-conditions/">Terms & Conditions</a>
              </p>
              {
                showLoader == true ? (
                  <div className="float-right">
                    <Lottie
                      animationData={Loader}
                      loop={true}
                      style={{
                        width: 50,
                        height: 50,
                      }}
                    />
                  </div>
                ) :
                  <Button className="float-right" onClick={onSubmit}>
                    Submit
                  </Button>
              }
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
export default TreeForm;
