import loadable from "@loadable/component";
import pMinDelay from "p-min-delay";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "bootstrap-daterangepicker/daterangepicker.css";
/// Scroll
import PerfectScrollbar from "react-perfect-scrollbar";
// import { QuickTrade } from "../zenix/MyWallets/QuickTrade";

import letIcon from "./../../../images/logos/logieToken.png";
import { getDashboard } from "../../../store/selectors/DashboardSelector";
import { nFormatter } from "../../../api/constants";
import { useDispatch, useSelector } from "react-redux";
import {
  getTokenActivitiesAction,
  getMyTokenActivitiesAction,
} from "../../../store/actions/TokenActivityAction";
import { getTokenActivities } from "../../../store/selectors/TokenActivitesSelector";
import { Dropdown } from "react-bootstrap";
import { getUserId } from "../../../store/selectors/AuthSelectors";

const ChartBarRunning = loadable(() =>
  pMinDelay(import("../zenix/CoinDetails/ChartBarRunning"), 1000)
);

function CoinDetails(props) {
  const [dataType, setDataType] = useState("All Activities");
  const data = useSelector(getDashboard);
  const activities = useSelector(getTokenActivities);
  const userId = useSelector(getUserId);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getTokenActivitiesAction());
  }, []);

  return (
    <div>
      <div className=" form-head d-flex flex-wrap mb-4 align-items-center">
        <h2 className="text-black mr-auto font-w600 mb-3">Coin Details</h2>
      </div>
      <div className="tab-content">
        <div className="tab-pane active show fade ">
          <div className="row">
            <div className="col-xl-3 col-xxl-4 mt-4">
              <div className="card">
                <PerfectScrollbar className="card-body dz-scroll height400">
                  <div className="d-flex align-items-start mb-3 about-coin">
                    <div>
                      <img src={letIcon} alt="" width="80px" height="80px" />
                    </div>
                    <div className="ml-3">
                      <h2 className="font-w600 text-black mb-0 title">
                        LogiE Token
                      </h2>
                      <p className="font-w600 text-black sub-title">LET</p>
                      <span>1 LET = 0.00 GBP</span>
                    </div>
                  </div>
                  <p className="fs-14">
                    LogiE Token is any currency, money, or money-like asset that
                    is primarily managed, stored or exchanged on digital
                    computer systems, especially over the internet.
                  </p>
                  <p className="fs-14">
                    LogiE Token exhibit properties similar to traditional
                    currencies, but generally do not have a classical physical
                    form of fiat currency historically that you can directly
                    hold in your hand, like currencies with printed banknotes or
                    minted coins - however they do have a physical form in an
                    unclassical sense coming from the computer to computer and
                    computer to human interactions and the information and
                    processing power of the servers that store and keep track of
                    money. This unclassical physical form allows nearly
                    instantaneous transactions over the internet and vastly
                    lowers the cost associated with distributing notes and coins
                    for example of the types of money in the UK economy 3% are
                    notes and coins, 79% as electronic money (in the form of
                    bank deposits). Usually not issued by a governmental body,
                    virtual currencies are not considered a legal tender and
                    they enable ownership transfer across governmental borders.
                  </p>
                  <p className="fs-14">
                    This type of currency may be used to buy physical goods and
                    services, but may also be restricted to certain communities
                    such as for use inside an online game.
                  </p>
                  <p className="fs-14">
                    LogiE Token can either be centralized, where there is a
                    central point of control over the money supply (for
                    instance, a bank), or decentralized, where the control over
                    the money supply is predetermined or agreed upon
                    democratically.
                  </p>
                </PerfectScrollbar>
                <div className="card-footer border-0 p-0 caret">
                  <Link to={"./coin-details"} className="btn-link">
                    <i className="fa fa-caret-down" aria-hidden="true"></i>
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-xl-9 col-xxl-8 mt-4">
              <div className="card">
                <div className="card-header pb-0 d-block d-sm-flex flex-wrap border-0 align-items-center select-btn">
                  <div className="mr-auto mb-3">
                    <h4 className="fs-20 text-black">Activities</h4>
                    <p className="mb-0 fs-12">
                      Brief overview of activities on LogiE Token
                    </p>
                  </div>

                  {/* <div className="input-group detault-daterange mr-3  mb-3 coinDetails-datepiker">
                    <span className="input-group-text">
                      {" "}
                      <i className="las la-calendar" />
                    </span>
                    <DateRangePicker>
                      <input
                        type="text"
                        className="form-control input-daterange-timepicker"
                      />
                    </DateRangePicker>
                  </div>
                   */}
                  <Dropdown>
                    <Dropdown.Toggle
                      variant=""
                      className="form-control style-1 default-select mb-3"
                    >
                      {dataType}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item
                        onClick={() => {
                          dispatch(getTokenActivitiesAction());
                          setDataType("All Activities");
                        }}
                      >
                        All Activities
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => {
                          dispatch(getMyTokenActivitiesAction(userId));
                          setDataType("My Activities");
                        }}
                      >
                        My Activities
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
                <div className="card-body pb-0 pt-sm-3 pt-0">
                  <div className="row sp20 mb-4 align-items-center">
                    <div className="col-lg-4 col-xxl-4 col-sm-4 d-flex flex-wrap align-items-center">
                      <div className="px-2 info-group">
                        <p className="fs-18 mb-1">Balance</p>
                        <h2 className="fs-28 font-w600 text-black">
                          {data.accountTokenBalance}
                        </h2>
                      </div>
                    </div>
                    <div className="d-flex col-lg-8 col-xxl-8 col-sm-8 align-items-center mt-sm-0 mt-3 justify-content-end">
                      <div className="px-2 info-group">
                        <p className="fs-14 mb-1">24h% change</p>
                        <h3 className="fs-20 font-w600">
                          <span className="text-success">0%</span>
                          <svg
                            width="14"
                            height="14"
                            viewBox="0 0 14 14"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M0 7L7.00001 -8.77983e-06L14 7H7.00001H0Z"
                              fill="#2BC155"
                            ></path>
                          </svg>
                        </h3>
                      </div>
                      <div className="px-2 info-group">
                        <p className="fs-14 mb-1">Total Supply</p>
                        <h3 className="fs-20 font-w600 text-black">
                          {nFormatter(data.totalSupplyOfToken)}
                        </h3>
                      </div>
                    </div>
                  </div>
                  <div id="chartBarRunning" className="bar-chart market-line">
                    <ChartBarRunning activities={activities} />
                  </div>
                </div>
              </div>
            </div>
            {/* <QuickTrade /> */}
            {/* <TabDataOrder /> */}
          </div>
        </div>
      </div>
    </div>
  );
}
export default CoinDetails;
