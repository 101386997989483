import React from "react";
import { Button, Col } from "react-bootstrap";

export default function DropZone(props) {
  const {
    onChange,
    onRemove,
    src,
    title,
    caption,
    accept,
    onlyImage = true,
    error,
    width,
    height,
    name,
  } = props;
  const [dragActive, setDragActive] = React.useState(false);
  const [err, setErr] = React.useState("");

  // handle drag events
  function handleDrag(e) {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  }

  function handleDrop(e) {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    handleFileDrop(e);
  }

  function handleFileDrop(e) {
    if (err) {
      setErr("");
    }
    if (e.dataTransfer.files.length) {
      const file = e.dataTransfer.files[0];
      if (onlyImage && !file.type.includes("image")) {
        setErr("Only Images are allowed on this field");
        return;
      }
      const uri = window.URL.createObjectURL(new Blob([file]));
      const _file = {
        file,
        uri,
      };
      onChange(_file);
    }
  }

  function handleFile(e) {
    if (e.target.files.length) {
      const file = e.target.files[0];
      const uri = window.URL.createObjectURL(new Blob([file]));
      const _file = {
        file,
        uri,
      };
      onChange(_file);
    }
  }

  return (
    <div className="form-group" key={name || "drag&Drop"}>
      <label
        onDragEnter={handleDrag}
        onDragLeave={handleDrag}
        onDragOver={handleDrag}
        onDrop={handleDrop}
        className="text-label"
        htmlFor={name || "drag&Drop"}
        style={{
          cursor: "pointer",
          width: width || "100%",
          height: height ? height : undefined,
        }}
      >
        <div
          style={{
            width: "100%",
            height: "100%",
            border:
              error || err
                ? "1px dashed red"
                : dragActive
                ? "1px dashed green"
                : "1px dashed",
            borderRadius: 10,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            overflow: "hidden",
          }}
        >
          {src ? (
            <img
              {...{ src }}
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
              }}
            />
          ) : (
            <Col className="text-center">
              <h6>{title || "Drag & Drop"}</h6>
              <small>{caption}</small>
            </Col>
          )}
        </div>
        {error || err ? (
          <small style={{ color: "red" }}>{error || err}</small>
        ) : null}
      </label>
      <input
        // value={treeData.image ? treeData.coords : ""}
        id={name || "drag&Drop"}
        type="file"
        accept={accept || "image/*"}
        style={{ display: "none" }}
        onChange={(e) => {
          e.preventDefault();
          handleFile(e);
        }}
      />
      <div className="text-center">
        {src ? (
          <Button variant="danger light" onClick={onRemove} disabled={false}>
            <i className="flaticon-381-trash-1"></i>
          </Button>
        ) : null}
      </div>
    </div>
  );
}
