import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import "./forms/donations/TreePreview.css";
import "../../../css/screen-transition.css";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { useHistory, useParams } from "react-router-dom";
import Loading from "../partials/Loading";
import { getProductById } from "../../../services/ProductService";
import TNLogo from "../../../images/logo.png";
import Dialog, {
    defaultDialogButtons,
    defaultDialogProps,
} from "../partials/Dialog";
import Modal from "../partials/Modal";
import { uploadNFTValidator } from "../../../api/validator";
import UploadNFTForm from "./forms/donations/UploadNFTForm";
import moment from "moment";
import ExpiryCounter from "./partials/contestPreview/ExpiryCounter";


const ProductPreview = (props) => {
    let { productId } = useParams();
    const history = useHistory();
    const [product, setProduct] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [isFormLoading, setIsFormLoading] = useState(false);
    const [dialogProps, setDialogProps] = useState(defaultDialogProps);
    const [formErrors, setFormErrors] = useState(uploadNFTValidator.getDefault());
    const [formData, setFormData] = useState(uploadNFTValidator.getDefault());
    const [item, setItem] = useState(null);
    const [fadeAnim, setFadeAnim] = useState({ leftContainer: "fade-in-left" });

    console.log("product", product);

    function handleAnim(key, value) {
        setFadeAnim((pre) => ({ ...pre, [key]: value }));
    }

    function handleAnimControl() {
        handleAnim("leftContainer", "fade-out-left")
    }

    const defaultForm = {
        open: false,
        title: "",
        data: {},
        onClose: () => { },
    };

    const [formProps, setFormProps] = useState(defaultForm);

    useEffect(() => {
        if (productId) {
            getProduct();
        }
    }, [productId]);

    function handleChange(key, value) {
        setFormData((pre) => ({ ...pre, [key]: value }));
        setFormErrors((pre) => ({ ...pre, [key]: "" }));
    }

    function getProduct() {
        getProductById(productId)
            .then((res) => {
                setIsLoading(false);
                if (res.data.status === 200) {
                    setProduct(res.data.product);
                }
            })
            .catch((err) => {
                setIsLoading(false);
            });
    }

    function getSelectedItemDetails(list = [], selectedTitle = "") {
        const item = list.find((itm) => itm.title === selectedTitle);
        if (item) return item;
        else return null;
    }


    function dialog(
        title = "",
        text = "",
        buttons = defaultDialogButtons(),
        onClose = () => setDialogProps(defaultDialogProps)
    ) {
        setDialogProps({ open: true, title, text, ...buttons, onClose });
    }

    if (isLoading) {
        return <Loading />;
    } else if (product) {

        return (
            <div className="row">
                <div className="col-lg-12">
                    <Button
                        style={{ marginBottom: "20px" }}
                        onClick={() => { handleAnimControl(); history.goBack() }}
                    >
                        <AiOutlineArrowLeft className="mr-1" />
                        Back
                    </Button>
                </div>
                <div className="col-lg-12 mt-4">
                    <div className="card">
                        <div className="card-body">
                            <div className="row p-4">
                                <div className="col-lg-6">
                                    <img
                                        style={{ width: "100%", height: "100%" }}
                                        src={product && product.image ? product.image.link : "https://images.pexels.com/photos/236748/pexels-photo-236748.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2"}
                                    />
                                </div>
                                <div className="col-lg-6">
                                    <div className="row">
                                        {product ? (
                                            <div className="col-lg-12">
                                                <h3 style={{ fontWeight: "bold", marginBottom: "30px", color: "#fff", textTransform: "capitalize" }}>{product ? product.title : null}</h3>
                                                <div className="">
                                                    {
                                                        product.stock ? (
                                                            <div>
                                                                <strong style={{ color: "#fff" }}>Stock : </strong>
                                                                <span style={{ marginLeft: "80px" }}>{product.stock}</span>
                                                            </div>
                                                        ) : null
                                                    }
                                                </div>
                                                <div className="mt-3">
                                                    {
                                                        product.sku ? (
                                                            <div>
                                                                <strong style={{ color: "#fff" }}>SKU : </strong>
                                                                <span style={{ marginLeft: "91px" }}>{product.sku}</span>
                                                            </div>
                                                        ) : null
                                                    }
                                                </div>
                                                <div className="mt-3">
                                                    {
                                                        product.brand ? (
                                                            <div>
                                                                <strong style={{ color: "#fff" }}>Brand : </strong>
                                                                <span style={{ marginLeft: "75px" }}>{product.brand}</span>
                                                            </div>
                                                        ) : null
                                                    }
                                                </div>
                                                <div className="mt-3">
                                                    {
                                                        product.purchaseNote ? (
                                                            <div>
                                                                <strong style={{ color: "#fff" }}>Purchase Note : </strong>
                                                                <span style={{ marginLeft: "14px" }}>{product.purchaseNote}</span>
                                                            </div>
                                                        ) : null
                                                    }
                                                </div>
                                                <div className="mt-3">
                                                    <strong style={{ color: "#fff" }}>Price : </strong>
                                                    <span className="ml-1">{
                                                        product.salePrice ? (
                                                            <span><small style={{ color: "#eb8153", textDecoration: "line-through", marginLeft: "79px" }}>{product.regularPrice}</small><b style={{ fontSize: "18px" }}>{product.salePrice}</b></span>
                                                        ) : <span style={{ marginLeft: "89px" }}><b>{product.regularPrice}</b></span>
                                                    }
                                                    </span>
                                                </div>
                                            </div>
                                        ) : null}
                                    </div>
                                </div>
                                <div className="col-lg-12" style={{ marginTop: "50px" }}>
                                    <p>{product ? product.description : null}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {isLoading ? <Loading /> : null}
                <Dialog {...dialogProps} />
                <Modal
                    {...formProps}
                    data={formData}
                    submitWithData={true}
                    modalSize="lg"
                >
                    <UploadNFTForm
                        {...formProps}
                        {...{ formData }}
                        errors={formErrors}
                        isLoading={isFormLoading}
                        handleChange={handleChange}
                    />
                </Modal>
            </div>
        );
    } else {
        return (
            <div className="col-lg-12 text-center mt-3">
                <img src={TNLogo} width="70px" height="70px" />
                <h3 className="mt-1">Record Not Found</h3>
                <p></p>
            </div>
        );
    }
};

export default ProductPreview;
