import { isAuthenticated } from "./AuthSelectors";

export function getMainBalance(state) {
  if (isAuthenticated(state))
    return state.wallet.mainBalance
      ? Number(state.wallet.mainBalance).toFixed(3)
      : 0.0;
  else return 0;
}

export function getWalletTransactions(state) {
  if (isAuthenticated(state)) return state.wallet.transactions || [];
  else return [];
}

export function getTransactions(state) {
  if (isAuthenticated(state)) return state.wallet.transactions || [];
  else return [];
}

export function getWalletTokens(state) {
  if (isAuthenticated(state)) return state.wallet.tokens || [];
  else return [];
}

export function getWalletLoadingState(state) {
  if (isAuthenticated(state)) return state.wallet.isLoading;
  else return false;
}

export function getMyNFTsService(state) {
  if (isAuthenticated(state)) return state.wallet.nfts;
  else return false;
}

export function getNFTsLoadingState(state) {
  if (isAuthenticated(state)) return state.wallet.isNFTsLoading;
  else return false;
}
