export const DURATIONS = [
  { title: "1d", value: 1, type: "Days" },
  { title: "3d", value: 2, type: "Days" },
  { title: "7d", value: 7, type: "Days" },
  { title: "15d", value: 15, type: "Days" },
  { title: "30d", value: 30, type: "Days" },
  { title: "45d", value: 45, type: "Days" },
  { title: "60d", value: 60, type: "Days" },
  { title: "90d", value: 90, type: "Days" },
  { title: "Other" },
];

export const DURATION_TYPES = ["Months", "Year(s)"];

export const DURATION_MONTHS_VALUES = [
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
  "10",
  "11",
  "12",
];

export const DURATION_YEARS_VALUES = [
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
  "10",
];

export const TYPES = [
  { title: "Tree Plantation" },
  { title: "ReForesting" },
  { title: "Water Pond" },
  { title: "Other" },
];

export const REGIONS = [
  { title: "Africa" },
  { title: "Asia" },
  { title: "Europe" },
  { title: "North America" },
  { title: "South America" },
  { title: "Australia" },
];
