import api from "./AxiosInstance";

export function getAllTokenAllocations() {
  return api.get("token-allocations");
}

export function getOneTokenAllocation(id) {
  return api.get("token-allocations/" + id);
}

export function addTokenAllocation(data) {
  return api.post("token-allocations", data);
}

export function updateTokenAllocation(id, data) {
  return api.patch("token-allocations/" + id, data);
}

export function removeTokenAllocation(id) {
  return api.delete("token-allocations/" + id);
}

export function getTokenAllocationFormData() {
  return api.get("token-allocations/d");
}
