import React, { Fragment, useEffect, useState } from "react";
import { createProductSchema } from "../../../services/schema";
import {
  productList,
  createProducts,
  updateProducts,
  removeProducts,
} from "../../../store/actions/ProductAction";
import { Badge, Dropdown } from "react-bootstrap";
import { getProducts } from "../../../store/selectors/ProductSelector";
import { useSelector, useDispatch, connect } from "react-redux";
import FilteringTable from "../table/FilteringTable/FilteringTable";
import { ColumnFilter } from "../table/FilteringTable/ColumnFilter";
import Dialog from "../partials/Dialog";
import Modal from "../partials/Modal";
import { getUserId } from "../../../store/selectors/AuthSelectors";
import CreateProductForm from "./partials/contests/CreateProductForm";
import { useHistory } from "react-router-dom";
import FormData from "form-data";
import { getRoleName } from "../../../store/selectors/AuthSelectors";
import { toast } from "react-toastify";

const ThreeDots = (
  <svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <rect x="0" y="0" width="24" height="24"></rect>
      <circle fill="#000000" cx="5" cy="12" r="2"></circle>
      <circle fill="#000000" cx="12" cy="12" r="2"></circle>
      <circle fill="#000000" cx="19" cy="12" r="2"></circle>
    </g>
  </svg>
);



const ProductList = (props) => {
  const dispatch = useDispatch();
  const data = useSelector(getProducts);
  const userId = useSelector(getUserId);
  const history = useHistory();
  const roleName = useSelector(getRoleName);
  const [formErrors, setFormErrors] = useState(
    createProductSchema.getDefault()
  );
  const [togglePerms, setTogglePerms] = useState(false);
  const [formData, setFormData] = useState(createProductSchema.getDefault());

  const defaultDialog = {
    open: false,
    title: "",
    text: "",
    onClose: () => { },
  };

  const defaultForm = {
    open: false,
    title: "",
    data: {},
    onClose: () => { },
  };

  const headerBtns = [
    {
      title: "+ New Product",
      onClick: () => {
        setFormProps({
          open: true,
          title: "New Product",
          yesBtn: {
            title: "Submit",
            onTap: (data) => submit(data),
          },
          noBtn: {
            title: "Cancel",
            onTap: () => {
              setTogglePerms(false)
              setFormErrors(createProductSchema.getDefault());
              setFormProps(defaultForm);
              setFormData(createProductSchema.getDefault());
            },
          },
          onClose: () => {
            setTogglePerms(false)
            setFormErrors(createProductSchema.getDefault());
            setFormProps(defaultForm);
            setFormData(createProductSchema.getDefault());
          },
        });
      },
    },
  ];

  const [dialogProps, setDialogProps] = useState(defaultDialog);
  const [formProps, setFormProps] = useState(defaultForm);

  const columns = [
    {
      Header: "#",
      Footer: "#",
      accessor: "id",
      Filter: ColumnFilter,
    },
    {
      Header: "Sku",
      Footer: "Sku",
      accessor: "sku",
      Filter: ColumnFilter,
    },
    {
      Header: "Title",
      Footer: "Title",
      accessor: "title",
      Filter: ColumnFilter,
    },
    {
      Header: "Price",
      Footer: "Price",
      accessor: "price",
      Filter: ColumnFilter,
      Cell: (cell) => {
        const product = cell.row.original;
        return (
          <div>
            <p
              style={{
                textDecoration: product.salePrice ? "line-through" : "none",
              }}
            >
              {product.regularPrice}
            </p>
            {product.salePrice ? (
              <p>
                <strong>{product.salePrice}</strong>
              </p>
            ) : null}
          </div>
        );
      },
    },
    {
      Header: "Stock",
      Footer: "Stock",
      accessor: "stock",
      Filter: ColumnFilter,
    },
    {
      Header: "Brand",
      Footer: "Brand",
      accessor: "brand",
      Filter: ColumnFilter,
    },
    {
      Header: "Status",
      Footer: "Status",
      Cell: (cell) => {
        let status = <Badge variant="success light">Active</Badge>;
        if (cell.row.original.status === "InActive") {
          status = <Badge variant="danger light">Inactive</Badge>;
        }
        return status;
      },
      Filter: ColumnFilter,
    },
    // {
    //   Header: "Actions",
    //   Footer: "Actions",
    //   Cell: (cell) => {
    //     const product = cell.row.original;
    //     return (
    //       <Button
    //         className="primary light"
    //         onClick={() => {
    //           history.push("product-preview/" + product.id);
    //         }}
    //       >
    //         Preview
    //       </Button>
    //     );
    //   },
    // },
    {
      Header: "Actions",
      Footer: "Actions",
      Cell: (cell) => {
        let variant = "success";
        const product = cell.row.original;
        const status = product.status;
        if (status === "Active") {
          variant = "success";
        } else {
          variant = "danger";
        }
        return (
          <Dropdown>
            <Dropdown.Toggle variant={variant} className="light sharp i-false">
              {ThreeDots}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {/* <Dropdown.Header>Change Status</Dropdown.Header>
              {status === "Active" ? (
                <Dropdown.Item
                  onSelect={() => updateStatus("InActive", cell.row.original.id)}
                >
                  Deactivate
                </Dropdown.Item>
              ) : (
                <Dropdown.Item
                  onSelect={() => updateStatus("Active", cell.row.original.id)}
                >
                  Activate
                </Dropdown.Item>
              )} */}
              {/* <Dropdown.Divider /> */}
              <Dropdown.Item
                onClick={() => {
                  history.push("product-preview/" + product.id);
                }}
                variant="danger"
                className="danger light sharp i-false"
              >
                Preview
              </Dropdown.Item>
              <Dropdown.Header>Other Actions</Dropdown.Header>
              {/*  <Dropdown.Item
                onSelect={() => {
                  setFormData(product);
                  setFormProps({
                    open: true,
                    title: "Edit Product",
                    yesBtn: {
                      title: "Submit",
                      onTap: (data) => submit(data),
                    },
                    noBtn: {
                      title: "Cancel",
                      onTap: () => {
                        setTogglePerms(false)
                        setFormErrors(createProductSchema.getDefault());
                        setFormProps(defaultForm);
                        setFormData(createProductSchema.getDefault());
                      },
                    },
                    onClose: () => {
                      setTogglePerms(false)
                      setFormErrors(createProductSchema.getDefault());
                      setFormProps(defaultForm);
                      setFormData(createProductSchema.getDefault());
                    },
                  });
                }}
                variant="danger"
                className="danger light sharp i-false"
              >
                Edit
              </Dropdown.Item> */}
              <Dropdown.Item
                onSelect={() => {
                  if (cell.row.original.participantsCount) {
                    setDialogProps({
                      open: true,
                      title: "Exception",
                      text:
                        "This product can no longer be removed because " +
                        cell.row.original.participantsCount +
                        "user(s) are now participating",
                      yesBtn: {
                        title: "Okay",
                        onTap: () => {
                          setDialogProps(defaultDialog);
                        },
                      },
                      onClose: () => setDialogProps(defaultDialog),
                    });
                  }
                  setDialogProps({
                    open: true,
                    title: "Confirmation",
                    text: "This action is irreversible. Are you sure to proceed?",
                    yesBtn: {
                      title: "Yes",
                      onTap: () => {
                        remove(cell.row.original.id);
                        setDialogProps(defaultDialog);
                      },
                    },
                    noBtn: {
                      title: "No",
                      onTap: () => setDialogProps(defaultDialog),
                    },
                    onClose: () => setDialogProps(defaultDialog),
                  });
                }}
                variant="danger"
                className="danger light sharp i-false"
              >
                Delete
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        );
      },
    },
  ];

  useEffect(() => {
    dispatch(productList(userId));
  }, []);

  function handleChange(key, value) {
    setFormData((pre) => ({
      ...pre,
      [key]: value,
    }));
    if (formErrors[key]) delete formErrors[key];
    if (key === "saleFrom") {
      setFormData((prevFormData) => ({
        ...prevFormData,
        saleFrom: value,
        saleTo: "re-select", // Set "Sale To" to "re-select"
      }));
    }
  }

  function submit(data) {
    createProductSchema
      .validate(data, { abortEarly: false })
      .then((isValid) => {
        try {
          const formdata = new FormData();
          for (const key in data) {
            if (key === 'banner') {
              formdata.append(key, data[key].file);
            } else {
              formdata.append(key, data[key]);
            }
          }
          toast.success("Product successfully created!")
          formdata.append("userId", String(userId));
          if (data.id) {
            dispatch(updateProducts(formdata, data.id));
          } else {
            dispatch(createProducts(formdata));
          }
        } finally {
          setTogglePerms(false)
          setFormErrors(createProductSchema.getDefault());
          setFormProps(defaultForm);
          setFormData(createProductSchema.getDefault());
        }
      })
      .catch((err) => {
        toast.warning("Oops! ssomething went wrong")
        err.inner.forEach((e) => {
          setFormErrors((pre) => ({ ...pre, [e.path]: e.message }));
        });
      });
  }

  function updateStatus(status, productId) {
    dispatch(updateProducts({ status }, productId));
  }

  function remove(productId) {
    dispatch(removeProducts(productId));
  }

  return (
    <Fragment>
      <div className="col-xl-12 col-xxl-12">
        <FilteringTable
          data={data}
          columns={columns}
          title="Products"
          headerBtns={roleName != 'Individual' ? headerBtns : null}
        />
        {/* {data.map((contest, index) => (
          <div key={index} className="col-md-4 text-center">
            <ContestItem data={contest} />
          </div>
        ))} */}
      </div>
      <Dialog {...dialogProps} />
      <Modal
        {...formProps}
        data={formData}
        submitWithData={true}
        modalSize="lg"
        footerCaption={
          <p>
            By submitting this form you agreed to our{" "}
            <a target="_blank" href="https://terra-nova.io/terms-and-conditions/">Terms & Conditions</a>
          </p>
        }
      >
        <CreateProductForm
          {...formProps}
          {...{ formData }}
          errors={formErrors}
          handleChange={handleChange}
        />
      </Modal>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    successMessage: state.auth.successMessage,
    showLoading: state.auth.showLoading,
  };
};

export default connect(mapStateToProps)(ProductList);
