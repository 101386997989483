import { isAuthenticated } from "./AuthSelectors";

export function getTokenRewards(state) {
  if (isAuthenticated(state)) return state.tokenReward.list || [];
  return [];
}

export function getTokenRewardRequests(state) {
  if (isAuthenticated(state)) return state.tokenReward.requests || [];
  return [];
}
