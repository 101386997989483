import React, { Fragment, useEffect } from "react";
import { iosList } from "../../../store/actions/DeviceAction";
import { useSelector, useDispatch, connect } from "react-redux";
import FilteringTable from "../table/FilteringTable/FilteringTable";
import { ColumnFilter } from "../table/FilteringTable/ColumnFilter";
import { getIos } from "../../../store/selectors/DeviceSelector";
import moment from "moment";

const IOSList = (props) => {
    const dispatch = useDispatch();
    const data = useSelector(getIos);

    const columns = [
        {
            Header: "#",
            Footer: "#",
            accessor: "id",
            Filter: ColumnFilter,
        },
        {
            Header: "Title",
            Footer: "Title",
            accessor: "title",
            Filter: ColumnFilter,
        },
        {
            Header: "Device Type",
            Footer: "Device Type",
            accessor: "type",
            Filter: ColumnFilter,
        },
        {
            Header: "Token",
            Footer: "Token",
            accessor: "token",
            Filter: ColumnFilter,
        },
        {
            Header: "Created At",
            Footer: "Created At",
            Filter: ColumnFilter,
            accessor: "createdAt",
            Cell: (cell) => {
                if (cell.row.original.createdAt) {
                    return moment(cell.row.original.createdAt).format("DD MM YYYY hh:mm a");
                }
            },
        },
    ];

    useEffect(() => {
        dispatch(iosList('IOS'));
    }, []);


    return (
        <Fragment>
            <div className="col-xl-12 col-xxl-12">
                <FilteringTable
                    data={data}
                    columns={columns}
                    title="IOS"
                />
            </div>
        </Fragment>
    );
};

const mapStateToProps = (state) => {
    return {
        successMessage: state.auth.successMessage,
        showLoading: state.auth.showLoading,
    };
};

export default connect(mapStateToProps)(IOSList);
