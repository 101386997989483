import React, { Fragment, useEffect, useState } from "react";
import { getRoleId, getUserId } from "../../../store/selectors/AuthSelectors";
import { listTreeDonations } from "../../../store/actions/DonationActions";
import {
  getTreeDonationList,
  getTreeDonationRequests,
} from "../../../store/selectors/DonationSelector";
import { useSelector, useDispatch, connect } from "react-redux";
import FilteringTable from "../table/FilteringTable/FilteringTable";
import { ColumnFilter } from "../table/FilteringTable/ColumnFilter";
import { Badge } from "react-bootstrap";
import Modal from "../partials/Modal";
import moment from "moment";
import { Button } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import Avatar from "react-avatar";
import PaymentForm from "./forms/donations/UploadPaymentCollection";
import { getPermissions } from "../../../store/selectors/AuthSelectors";

const TreeDonationList = (props) => {
  const dispatch = useDispatch();
  const data = useSelector(getTreeDonationList);
  const requests = useSelector(getTreeDonationRequests);
  const roleId = useSelector(getRoleId);
  const userId = useSelector(getUserId);
  const permissions = useSelector(getPermissions);
  const history = useHistory();
  const [activeTab, setActiveTab] = useState("my");

  const defaultForm = {
    open: false,
    title: "",
    data: {},
    onClose: () => {},
  };

  const [formProps, setFormProps] = useState(defaultForm);

  useEffect(() => {
    dispatch(listTreeDonations(userId));
  }, []);

  let tabData = [];
  if (roleId == 1 || permissions.includes("add:nft")) {
    tabData = [
      {
        name: "My Donations",
        onClick: () => setActiveTab("my"),
        badge: data.length,
      },
      {
        name: "Pending Donations",
        onClick: () => setActiveTab("pending"),
        badge: requests.length,
      },
    ];
  }
  const columns = [
    {
      Header: "#",
      Footer: "#",
      accessor: "id",
      Filter: ColumnFilter,
    },
    {
      Header: "Image",
      Footer: "Image",
      Cell: (cell) => {
        let image = cell.row.original.treeDonation
          ? cell.row.original.treeDonation.file.link
          : null;
        return (
          <div className="row" style={{ alignItems: "center" }}>
            <Avatar src={image} round={"5px"} size="55" />
          </div>
        );
      },
      Filter: ColumnFilter,
    },
    {
      Header: "Count",
      Footer: "Count",
      accessor: "donationCount",
      Filter: ColumnFilter,
      Cell: (cell) => {
        let type = cell.row.original.treeDonation
          ? cell.row.original.treeDonation.tree
          : null;
        if (type != null) {
          type = cell.row.original.donationCount + "x Tree Donation";
        }
        if (type == null) {
          type = cell.row.original.donationCount + "x Pond Donation";
        }
        return type;
      },
    },
    {
      Header: "Date",
      Footer: "Date",
      Filter: ColumnFilter,
      accessor: "createdAt",
      Cell: (cell) => {
        if (cell.row.original.status == "Payment Pending") {
          return (
            <Badge variant="warning light">
              expire {moment(cell.row.original.expiredAt).fromNow()}
            </Badge>
          );
        } else {
          return moment(cell.row.original.createdAt).format("DD MMMM YYYY");
        }
      },
    },
    {
      Header: "Location Code",
      Footer: "Location Code",
      Filter: ColumnFilter,
      Cell: (cell) => {
        const donation = cell.row.original;
        let code = cell.row.original.treeDonation
          ? cell.row.original.treeDonation.words
          : null;
        return cell.row.original.treeDonation ? (
          <div className="row" style={{ alignItems: "center" }}>
            {code}
          </div>
        ) : (
          <div className="row" style={{ alignItems: "center" }}>
            Contributed{" "}
            {(
              (donation && donation.collection
                ? donation.donationCount / donation.collection.target
                : null) * 100
            ).toFixed(0)}
            % to this project
          </div>
        );
      },
    },
    {
      Header: "Status",
      Footer: "Status",
      Cell: (cell) => {
        let status = <Badge variant="success light">Active</Badge>;
        if (cell.row.original.status == "Payment Pending") {
          status = (
            <Badge variant="warning light">{cell.row.original.status}</Badge>
          );
        } else if (cell.row.original.status == "Pending") {
          status = (
            <Badge variant="info light">{cell.row.original.status}</Badge>
          );
        } else {
          status = (
            <Badge variant="success light">{cell.row.original.status}</Badge>
          );
        }
        return status;
      },
      Filter: ColumnFilter,
    },
    {
      Header: "Actions",
      Footer: "Actions",
      Cell: (cell) => {
        const donation = cell.row.original;
        if (cell.row.original.status == "Payment Pending") {
          return (
            <Button
              className="primary light"
              onClick={() => {
                setFormProps({
                  open: true,
                  title: "Confirm your payment",
                  donationId: donation.id,
                  yesBtn: null,
                  noBtn: {
                    title: "Not Now",
                    onTap: () => {
                      setFormProps(defaultForm);
                    },
                  },
                  onClose: () => {
                    setFormProps(defaultForm);
                  },
                });
              }}
            >
              Pay Now
            </Button>
          );
        }
        return (
          <Button
            className="primary light"
            onClick={() => {
              history.push("tree-donation/" + donation.id);
            }}
          >
            Preview
          </Button>
        );
      },
    },
  ];

  return (
    <Fragment>
      <div className="col-xl-12 col-xxl-12">
        <FilteringTable
          data={
            activeTab === "my" ? data : activeTab === "pending" ? requests : []
          }
          columns={columns}
          title="My Donations"
          tabData={tabData}
        />
      </div>
      <Modal {...formProps} modalSize="md">
        <PaymentForm {...formProps} />
      </Modal>
      {/* <Dialog {...dialogProps} /> */}
      {/* <Modal {...formProps} data={formData} submitWithData={true}></Modal> */}
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    successMessage: state.auth.successMessage,
    showLoading: state.auth.showLoading,
  };
};

export default connect(mapStateToProps)(TreeDonationList);
