import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import "./forms/donations/TreePreview.css";
import "../../../css/screen-transition.css";
import "../../pages/assets/vendor/style.css";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { useHistory, useParams } from "react-router-dom";
import Loading from "../partials/Loading";
import { getCollectionById } from "../../../services/DonationService";
import TNLogo from "../../../images/logo.png";
import { nFormatter } from "../../../api/constants";

const CollectionDonationPre = (props) => {
  let { collectionId } = useParams();
  const history = useHistory();
  const [collection, setCollection] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [fadeAnim, setFadeAnim] = useState({ leftContainer: "fade-in-left" });

  function handleAnim(key, value) {
    setFadeAnim((pre) => ({ ...pre, [key]: value }));
  }

  function handleAnimControl() {
    handleAnim("leftContainer", "fade-out-left");
  }

  useEffect(() => {
    if (collectionId) {
      getCollection();
    }
  }, [collectionId]);

  function getCollection() {
    getCollectionById(collectionId)
      .then((res) => {
        setIsLoading(false);
        if (res.data.status === 200) {
          setCollection(res.data.collection);
        }
      })
      .catch((err) => {
        setIsLoading(false);
      });
  }

  if (isLoading) {
    return <Loading />;
  } else if (collection) {
    return (
      <div className="row">
        <div className="col-lg-12">
          <Button
            style={{ marginBottom: "20px" }}
            onClick={() => {
              handleAnimControl();
              history.goBack();
            }}
          >
            <AiOutlineArrowLeft className="mr-1" />
            Go Back
          </Button>
        </div>
        <div className={"col-md-8 col-sm-8 col-xs-8 " + fadeAnim.leftContainer}>
          <div className="card">
            <div className="card-body">
              <section className="content-inner position-relative">
                <div className="container">
                  <div className="row">
                    <div className="col-xl-12 col-lg-12">
                      <div className="blog-single pt-20 sidebar dz-card">
                        <div className="dz-media dz-media-rounded rounded">
                          {collection.banner ? (
                            <img src={collection.banner.link} alt="" />
                          ) : null}
                        </div>
                        <div className="dz-info m-b30">
                          <div className="dz-meta">
                            <ul>
                              <li className="post-author">
                                <a style={{ cursor: "pointer" }}>
                                  <span>
                                    By{" "}
                                    <strong>
                                      {collection.oName !== null
                                        ? collection.oName
                                        : "N/A"}
                                    </strong>
                                  </span>
                                </a>
                              </li>
                              <li className="post-date">
                                <a style={{ cursor: "pointer" }}>
                                  <span>
                                    Project of{" "}
                                    <strong>
                                      {collection.region !== null
                                        ? collection.region
                                        : "N/A"}
                                    </strong>
                                  </span>
                                </a>
                              </li>
                              <li className="post-date">
                                <a style={{ cursor: "pointer" }}>
                                  <span>
                                    Project target is{" "}
                                    <strong>
                                      {nFormatter(collection.budget) +
                                        " " +
                                        collection.currency_code}
                                    </strong>
                                  </span>
                                </a>
                              </li>
                            </ul>
                          </div>
                          <h3 className="dz-title">{collection.title}</h3>
                          <div className="widget-title">
                            <h4 className="title">Contact Details</h4>
                          </div>
                          <ol className="m-b30">
                            <li>
                              <h6>
                                <strong>
                                  {collection.oName !== null
                                    ? collection.oName
                                    : "N/A"}
                                </strong>
                              </h6>
                            </li>
                            <li>
                              <a
                                href={"mailto:" + collection.oEmail}
                                target="_blank"
                              >
                                {collection.oEmail !== null
                                  ? collection.oEmail
                                  : null}
                              </a>
                            </li>
                            <li>
                              <a
                                href={
                                  typeof collection.oPhone === "string" &&
                                  collection.oPhone.startsWith("+")
                                    ? "tel:" + collection.oPhone
                                    : "tel:" +
                                      collection.oPhone_code +
                                      collection.oPhone
                                }
                                target="_blank"
                              >
                                {typeof collection.oPhone === "string" &&
                                collection.oPhone.startsWith("+")
                                  ? collection.oPhone
                                  : collection.oPhone_code + collection.oPhone}
                              </a>
                            </li>
                            <li>{collection.oAddress}</li>
                          </ol>
                          <div className="dz-post-text">
                            <div className="widget-title">
                              <h4 className="title">Project Goal</h4>
                            </div>
                            <p>
                              {collection.goal !== null
                                ? collection.goal
                                : "N/A"}
                            </p>
                            <div className="widget-title">
                              <h4 className="title">Summary</h4>
                            </div>
                            <p>
                              {collection.s_description !== ""
                                ? collection.s_description
                                : "N/A"}
                            </p>
                            <div className="widget-title">
                              <h4 className="title">More about this project</h4>
                            </div>
                            <p>
                              {collection.l_description !== ""
                                ? collection.l_description
                                : "N/A"}
                            </p>
                            {collection.documents ? (
                              <div className="widget-title">
                                <h4 className="title">Documents</h4>
                              </div>
                            ) : null}
                            {collection.documents
                              ? collection.documents.map((document, i) => (
                                  <a
                                    key={i}
                                    href={document.link}
                                    target="_blank"
                                    className="btn btn-outline-primary mx-1"
                                  >
                                    {document.name}
                                  </a>
                                ))
                              : null}
                            {/* <blockquote className="block-quote style-1">
                        <p>
                          “You can make anything look good. Only a quarter of
                          young adults are financially literate. You don’t want
                          to overwhelm them with terrible advice.”
                        </p>
                        <cite> Zenix </cite>
                      </blockquote> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
        {isLoading ? <Loading /> : null}
      </div>
    );
  } else {
    return (
      <div className="col-lg-12 text-center mt-3">
        <img src={TNLogo} width="70px" height="70px" />
        <h3 className="mt-1">Record Not Found</h3>
        <p></p>
      </div>
    );
  }
};

export default CollectionDonationPre;
