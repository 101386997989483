import React, { Fragment, useEffect } from "react";
import { useSelector, useDispatch, connect } from "react-redux";
import FilteringTable from "../table/FilteringTable/FilteringTable";
import { ColumnFilter } from "../table/FilteringTable/ColumnFilter";
import moment from "moment";
import { listAllEditlogs } from "../../../store/actions/EditlogsAction";
import { getEditlogs } from "../../../store/selectors/EditlogsSelector";
import defaultProfileImage from "../../../images/profile/default.jpeg";

const Editlogs = (props) => {
  const dispatch = useDispatch();
  const data = useSelector(getEditlogs);

  const columns = [
    {
      Header: "#",
      Footer: "#",
      accessor: "id",
      Filter: ColumnFilter,
    },
    {
      Header: "Image",
      Footer: "Image",
      Filter: ColumnFilter,
      Cell: (cell) => {
        let avatar =
          cell.row.original.user && cell.row.original.user.avatar !== null
            ? cell.row.original.user.avatar
            : defaultProfileImage;
        return (
          <img
            src={avatar == "null" ? defaultProfileImage : avatar}
            style={{ width: "50px", height: "50px", borderRadius: "10px" }}
          />
        );
      },
    },
    {
      Header: "User",
      Footer: "User",
      Filter: ColumnFilter,
      Cell: (cell) => {
        let first_name = cell.row.original.user
          ? cell.row.original.user.first_name
          : "N/A";
        let last_name = cell.row.original.user
          ? cell.row.original.user.last_name
          : "N/A";
        return (
          <div className="row" style={{ alignItems: "center" }}>
            <span>{first_name + " " + last_name}</span>
          </div>
        );
      },
    },
    {
      Header: "Change",
      Footer: "Change",
      accessor: "change",
      Filter: ColumnFilter,
    },
    {
      Header: "From",
      Footer: "From",
      accessor: "from",
      Filter: ColumnFilter,
      Cell: (cell) => {
        if (cell.row.original.from) return cell.row.original.from;
        else return "N/A";
      },
    },
    {
      Header: "To",
      Footer: "To",
      accessor: "to",
      Filter: ColumnFilter,
    },
  ];

  useEffect(() => {
    dispatch(listAllEditlogs());
  }, []);

  return (
    <Fragment>
      <div className="col-xl-12 col-xxl-12">
        <FilteringTable data={data} columns={columns} title="Audit logs" />
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    successMessage: state.auth.successMessage,
    showLoading: state.auth.showLoading,
  };
};

export default connect(mapStateToProps)(Editlogs);
