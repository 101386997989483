import React, { Fragment, useEffect, useState } from "react";
import { createHelpSupportSchema } from "../../../services/schema";
import {
  helpList,
  createHelp,
  updateHelp,
  removeHelp,
} from "../../../store/actions/HelpSupportActions";
import { getTickets } from "../../../store/selectors/HelpSelector";

import { useSelector, useDispatch, connect } from "react-redux";
import FilteringTable from "../table/FilteringTable/FilteringTable";
import { ColumnFilter } from "../table/FilteringTable/ColumnFilter";
import { Badge, Dropdown } from "react-bootstrap";
import Dialog from "../partials/Dialog";
import Modal from "../partials/Modal";
import CreateHelpSupportForm from "./partials/help&support/CreateHelpSupportForm";
import InfoHelpSupport from "./partials/help&support/InfoHelpSupport";
import {
  getRoleId,
  getUserId,
} from "../../../store/selectors/AuthSelectors";
import { Button } from "react-bootstrap";
import FormData from "form-data";

const HelpSupport = (props) => {
  const dispatch = useDispatch();
  const userId = useSelector(getUserId);
  const roleId = useSelector(getRoleId);
  const data = useSelector(getTickets);
  const [formErrors, setFormErrors] = useState(
    createHelpSupportSchema.getDefault()
  );
  const [formData, setFormData] = useState(
    createHelpSupportSchema.getDefault()
  );
  const [ticketPreview, setTicketPreview] = useState(null);

  const defaultDialog = {
    open: false,
    title: "",
    text: "",
    onClose: () => { },
  };

  const defaultForm = {
    open: false,
    title: "",
    data: {},
    onClose: () => { },
  };

  const headerBtns =
    roleId == 1
      ? null
      : [
        {
          title: "+ Add",
          onClick: () => {
            setFormProps({
              open: true,
              title: "Help & Support",
              yesBtn: {
                title: "Submit",
                onTap: (data) => submit(data),
              },
              noBtn: {
                title: "Cancel",
                onTap: () => {
                  setFormErrors(createHelpSupportSchema.getDefault());
                  setFormProps(defaultForm);
                  setFormData(createHelpSupportSchema.getDefault());
                },
              },
              onClose: () => {
                setFormErrors(createHelpSupportSchema.getDefault());
                setFormProps(defaultForm);
                setFormData(createHelpSupportSchema.getDefault());
              },
            });
          },
        },
      ];
  const [dialogProps, setDialogProps] = useState(defaultDialog);
  const [formProps, setFormProps] = useState(defaultForm);
  const [ticketPreviewProps, setTicketPreviewProps] = useState(defaultForm);

  const columns = [
    {
      Header: "#",
      Footer: "#",
      accessor: "id",
      Filter: ColumnFilter,
    },
    {
      Header: "Ticket",
      Footer: "Ticket",
      accessor: "hash",
      Filter: ColumnFilter,
    },
    {
      Header: "Topic",
      Footer: "Topic",
      accessor: "topic",
      Filter: ColumnFilter,
    },
    {
      Header: "Date",
      Footer: "Date",
      accessor: "createdAt",
      Filter: ColumnFilter,
    },
    {
      Header: "Status",
      Footer: "Status",
      Cell: (cell) => {
        let status = (
          <Badge variant="info light">{cell.row.original.status}</Badge>
        );
        if (cell.row.original.status === "Pending") {
          status = (
            <Badge variant="info light">{cell.row.original.status}</Badge>
          );
        }
        if (cell.row.original.status === "InReview") {
          status = (
            <Badge variant="info light">{cell.row.original.status}</Badge>
          );
        }
        if (cell.row.original.status === "InProgress") {
          status = (
            <Badge variant="info light">{cell.row.original.status}</Badge>
          );
        }
        if (cell.row.original.status === "On Hold") {
          status = (
            <Badge variant="danger light">{cell.row.original.status}</Badge>
          );
        }
        if (cell.row.original.status === "Awaiting Response") {
          status = (
            <Badge variant="info light" style={{ color: "#A569BD " }}>{cell.row.original.status}</Badge>
          );
        }
        if (cell.row.original.status === "Resolved") {
          status = (
            <Badge variant="success light">{cell.row.original.status}</Badge>
          );
        }
        return status;
      },
      Filter: ColumnFilter,
    },
    {
      Header: "Actions",
      Footer: "Actions",
      Cell: (cell) => {
        const ticket = cell.row.original;
        return (
          <Button
            className="primary light"
            onClick={() => {
              setTicketPreview(ticket);
              setTicketPreviewProps({
                open: true,
                title: ticket.hash,
                // yesBtn: {
                //     title: "Submit",
                //     onTap: (data) => submit(data),
                // },
                noBtn: {
                  title: "Dismiss",
                  onTap: () => {
                    setFormErrors(createHelpSupportSchema.getDefault());
                    setTicketPreviewProps(defaultForm);
                    setFormData(createHelpSupportSchema.getDefault());
                  },
                },
                onClose: () => {
                  setFormErrors(createHelpSupportSchema.getDefault());
                  setTicketPreviewProps(defaultForm);
                  setFormData(createHelpSupportSchema.getDefault());
                },
              });
            }}
          >
            {/* <Dropdown.Menu>
                            <Dropdown.Header>Change Status</Dropdown.Header>
                            {status === "Active" ? (
                                <Dropdown.Item
                                    onSelect={() => updateStatus("InActive", help.id)}
                                >
                                    Deactivate
                                </Dropdown.Item>
                            ) : (
                                <Dropdown.Item
                                    onSelect={() => updateStatus("Active", help.id)}
                                >
                                    Activate
                                </Dropdown.Item>
                            )}
                            <Dropdown.Divider />
                            <Dropdown.Header>Other Actions</Dropdown.Header>
                            <Dropdown.Item
                                onSelect={() => {
                                    setFormData(help);
                                    setFormEditProps({
                                        open: true,
                                        title: "Help & Support",
                                        yesBtn: {
                                            title: "Submit",
                                            onTap: (data) => submit(data),
                                        },
                                        noBtn: {
                                            title: "Cancel",
                                            onTap: () => {
                                                setTogglePerms(false);
                                                setFormErrors(createHelpSupportSchema.getDefault());
                                                setFormEditProps(defaultForm);
                                                setFormData(createHelpSupportSchema.getDefault());
                                            },
                                        },
                                        onClose: () => {
                                            setTogglePerms(false);
                                            setFormErrors(createHelpSupportSchema.getDefault());
                                            setFormEditProps(defaultForm);
                                            setFormData(createHelpSupportSchema.getDefault());
                                        },
                                    });
                                }}
                                variant="danger"
                                className="danger light sharp i-false"
                            >
                                Edit
                            </Dropdown.Item>
                            {removable ? (
                                <Dropdown.Item
                                    onSelect={() => {
                                        if (cell.row.original.userCount) {
                                            setDialogProps({
                                                open: true,
                                                title: "Exception",
                                                text:
                                                    "This help cannot be removed because this help is assigned to " +
                                                    cell.row.original.userCount +
                                                    "user(s)",
                                                yesBtn: {
                                                    title: "Okay",
                                                    onTap: () => {
                                                        setDialogProps(defaultDialog);
                                                    },
                                                },
                                                onClose: () => setDialogProps(defaultDialog),
                                            });
                                        }
                                        setDialogProps({
                                            open: true,
                                            title: "Confirmation",
                                            text: "This action is irreversible. Are you sure to proceed?",
                                            yesBtn: {
                                                title: "Yes",
                                                onTap: () => {
                                                    remove(cell.row.original.id);
                                                    setDialogProps(defaultDialog);
                                                },
                                            },
                                            noBtn: {
                                                title: "No",
                                                onTap: () => setDialogProps(defaultDialog),
                                            },
                                            onClose: () => setDialogProps(defaultDialog),
                                        });
                                    }}
                                    variant="danger"
                                    className="danger light sharp i-false"
                                >
                                    Delete
                                </Dropdown.Item>
                            ) : null}
                        </Dropdown.Menu> */}
            Preview
          </Button>
        );
      },
    },
  ];

  useEffect(() => {
    dispatch(helpList(userId));
  }, []);

  function handleChange(key, value) {
    setFormData((pre) => ({
      ...pre,
      [key]: value,
    }));
    setFormErrors((pre) => ({
      ...pre,
      [key]: "",
    }));
  }

  function submit(data) {
    createHelpSupportSchema
      .validate(data, { abortEarly: false })
      .then((isValid) => {
        try {
          if (data.id) {
            dispatch(updateHelp({ ...data }, data.id));
          } else {
            const formData = new FormData();
            for (const key in data) {
              if (key === "image" && data[key]) {
                formData.append(key, data[key].file);
              } else {
                formData.append(key, data[key]);
              }
            }
            formData.append("userId", String(userId));
            dispatch(createHelp(formData));
          }
        } finally {
          setFormErrors(createHelpSupportSchema.getDefault());
          setFormProps(defaultForm);
          setFormData(createHelpSupportSchema.getDefault());
        }
      })
      .catch((err) => {
        // console.log(err);
        err.inner.forEach((e) => {
          setFormErrors((pre) => ({ ...pre, [e.path]: e.message }));
        });
      });
  }
  function updateStatus(status, ticketId) {
    dispatch(updateHelp({ status }, ticketId));
  }

  return (
    <Fragment>
      <div className="col-xl-12 col-xxl-12">
        <FilteringTable
          data={data}
          columns={columns}
          title="Help & Support"
          headerBtns={headerBtns}
        />
      </div>
      <Dialog {...dialogProps} />
      <Modal {...formProps} data={formData} submitWithData={true}>
        <CreateHelpSupportForm
          {...formProps}
          formData={formData}
          handleChange={handleChange}
          errors={formErrors}
        />
      </Modal>
      <Modal
        modalSize="xl"
        {...ticketPreviewProps}
        data={formData}
        submitWithData={true}
      >
        <InfoHelpSupport
          {...ticketPreviewProps}
          {...{ ticketPreview }}
        />
      </Modal>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    successMessage: state.auth.successMessage,
    showLoading: state.auth.showLoading,
  };
};

export default connect(mapStateToProps)(HelpSupport);
