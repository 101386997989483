import React from "react";
import { Link } from "react-router-dom";
import { Dropdown } from "react-bootstrap";
import SLogiEW from "./../../../../images/svg/LogiETokenWhite.svg";

export function QuickTransfer() {
  return (
    <div className="col-xl-6 mt-2">
      <div className="card">
        <div className="card-header d-sm-flex d-block pb-0 border-0">
          <div>
            <h4 className="fs-20 text-black">Quick Transfer</h4>
            <p className="mb-0 fs-12">
              Quickly Transfer any amount to any wallet & any time
            </p>
          </div>
          <Dropdown className="custom-dropdown d-block mt-3 mt-sm-0 mb-0">
            <Dropdown.Toggle
              variant=""
              className="btn btn-light btn-sm d-flex align-items-center svg-btn i-false"
              data-toggle="dropdown"
            >
              <img src={SLogiEW} className="mr-2" width="25" height="25" />
              <span className="text-black fs-16">Yearly (2021)</span>
              <i className="fa fa-angle-down text-black scale3 ml-2"></i>
            </Dropdown.Toggle>
            <Dropdown.Menu className="dropdown-menu-right" alignRight={true}>
              <Link className="dropdown-item" to="#">
                {" "}
                Weekly (2021){" "}
              </Link>
              <Link className="dropdown-item" to="#">
                {" "}
                Daily (2021)
              </Link>
            </Dropdown.Menu>
          </Dropdown>
        </div>
        <div className="card-body">
          <div className="form-wrapper">
            <div className="form-group">
              <div className="input-group input-group-lg">
                <div className="input-group-prepend">
                  <span className="input-group-text">Amount BTC</span>
                </div>
                <input
                  type="number"
                  className="form-control"
                  placeholder="742.2"
                />
              </div>
            </div>
          </div>
          <div className="d-flex mb-3 mt-3 justify-content-between align-items-center">
            <h4 className="text-black fs-20 mb-0">Recent Contacts</h4>
            <Link to={"#"} className="btn-link">
              View more
            </Link>
          </div>
          <div className="testimonial-one px-4 owl-right-nav owl-carousel owl-loaded owl-drag">
            {/* <ContactSlider /> */}
          </div>
          <div className="row pt-sm-5 pt-3 align-items-center">
            <div className="col-sm-6 mb-sm-0 mb-3">
              <p className="mb-0 fs-14">
                By Clicking 'Transfer Now' you agreed to our{" "}
                <a href="#">Term & Conditions</a>
              </p>
            </div>
            <div className="col-sm-6">
              <Link
                to={"#"}
                className="btn btn-secondary d-block btn-lg disabled"
              >
                TRANSFER NOW
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
