import React, { Fragment } from "react";
import { Button } from "react-bootstrap";
import Loader from "../../../../loader.json";
import Lottie from "lottie-react";

function Form(props) {
  const {
    errors,
    onSubmit,
    handleChange,
    data,
    showLoader,
    isEditing,
    cities,
  } = props;

  return (
    <div className="col-md-6">
      <Fragment>
        <div className="row">
          <div className="col-sm-6">
            <label className="text-label mt-3">
              First Name<strong className="text-danger">*</strong>
            </label>
            <input
              type="text"
              name="first_name"
              placeholder="First Name"
              value={data.first_name || ""}
              required
              onChange={(e) => handleChange("first_name", e.target.value)}
              className={
                errors.first_name
                  ? "form-control border-danger"
                  : "form-control"
              }
            ></input>
            {errors.first_name ? (
              <small className="text-danger">{errors.first_name}</small>
            ) : null}
          </div>
          <div className="col-sm-6">
            <label className="text-label mt-3">
              Last Name<strong className="text-danger">*</strong>
            </label>
            <input
              type="text"
              name="last_name"
              placeholder="Last Name"
              value={data.last_name || ""}
              onChange={(e) => handleChange("last_name", e.target.value)}
              className={
                errors.last_name ? "form-control border-danger" : "form-control"
              }
            />
            {errors.last_name ? (
              <small className="text-danger">{errors.last_name}</small>
            ) : null}
          </div>
          <div className="col-sm-12">
            <label className="text-label mt-3">Email Address</label>
            <input
              type="email"
              name="email"
              placeholder="Email"
              value={data.email || ""}
              onChange={(e) => handleChange("email", e.target.value)}
              className={
                errors.email ? "form-control border-danger" : "form-control"
              }
            />
            {errors.email ? (
              <small className="text-danger">{errors.email}</small>
            ) : null}
          </div>
          <div className="col-sm-12">
            <label className="text-label mt-3">Phone Number</label>
            <input
              type="number"
              name="phone"
              placeholder="phone"
              value={data.phone || ""}
              onChange={(e) => handleChange("phone", e.target.value)}
              className={
                errors.phone ? "form-control border-danger" : "form-control"
              }
            />
            {errors.phone ? (
              <small className="text-danger">{errors.phone}</small>
            ) : null}
          </div>
          <div className="col-sm-6">
            <label className="text-label mt-3">Wallet (Public Address)</label>
            <input
              type="text"
              name="public_address"
              placeholder="Public Address"
              value={data.public_address || ""}
              onChange={(e) => handleChange("public_address", e.target.value)}
              className={
                errors.public_address
                  ? "form-control border-danger"
                  : "form-control"
              }
            />
            {errors.public_address ? (
              <small className="text-danger">{errors.public_address}</small>
            ) : null}
          </div>
          <div className="col-sm-6">
            <label className="text-label mt-3">LogiE Token (Amount)</label>
            <input
              type="number"
              name="amount"
              placeholder="LogiE Token (Amount)"
              value={data.amount || ""}
              onChange={(e) => handleChange("amount", e.target.value)}
              className={
                errors.amount ? "form-control border-danger" : "form-control"
              }
            />
            {errors.amount ? (
              <small className="text-danger">{errors.amount}</small>
            ) : null}
          </div>
        </div>
        <div className="col-lg-12 mt-2 text-center">
          <p>
            By submitting this form you agreed to our{" "}
            <a
              target="_blank"
              href="https://terra-nova.io/terms-and-conditions/"
            >
              Terms & Conditions
            </a>
          </p>
          {showLoader == true ? (
            <div className="float-right">
              <Lottie
                animationData={Loader}
                loop={true}
                style={{
                  width: 50,
                  height: 50,
                }}
              />
            </div>
          ) : (
            <Button className="float-right" onClick={onSubmit}>
              {isEditing ? "Update" : "Submit"}
            </Button>
          )}
        </div>
      </Fragment>
    </div>
  );
}

export default Form;
