import * as yup from "yup";

export const EMPTY_FIELD_MESSAGE = "This field is required";

export const createRoleSchema = yup.object().shape({
  name: yup.string().required(EMPTY_FIELD_MESSAGE).default(""),
  permissions: yup.array().default([]),
});

export const createHelpSupportSchema = yup.object().shape({
  topic: yup.string().required(EMPTY_FIELD_MESSAGE).default(""),
  message: yup.string().required(EMPTY_FIELD_MESSAGE).default(""),
  image: yup.mixed().default(null),
});

export const createUserSchema = yup.object().shape({
  address: yup.string().required(EMPTY_FIELD_MESSAGE).default(""),
});

export const createNGOSchema = yup.object().shape({
  email: yup.string().required(EMPTY_FIELD_MESSAGE).default(""),
});

export const createCouponSchema = yup.object().shape({
  title: yup.string().required(EMPTY_FIELD_MESSAGE).default(""),
  description: yup.string().required(EMPTY_FIELD_MESSAGE).default(""),
  discountType: yup.string().required(EMPTY_FIELD_MESSAGE).default(""),
  discount: yup.string().required(EMPTY_FIELD_MESSAGE).default(""),
  expiredAt: yup.string().required(EMPTY_FIELD_MESSAGE).default(""),
  minSpend: yup.string().required(EMPTY_FIELD_MESSAGE).default(""),
  maxSpend: yup.string().required(EMPTY_FIELD_MESSAGE).default(""),
  individualUse: yup.boolean().required(EMPTY_FIELD_MESSAGE).default(true),
  usagePerCoupon: yup.string().required(EMPTY_FIELD_MESSAGE).default(""),
  usagePerUser: yup.string().required(EMPTY_FIELD_MESSAGE).default(""),
});

export const createFeedbackSchema = yup.object().shape({
  rating: yup.string().required(EMPTY_FIELD_MESSAGE).default(""),
  type: yup.string().required(EMPTY_FIELD_MESSAGE).default(""),
  message: yup.string().required(EMPTY_FIELD_MESSAGE).default("")
});


export const createProductSchema = yup.object().shape({
  banner: yup.mixed().default(null),
  title: yup.string().required(EMPTY_FIELD_MESSAGE).default(""),
  description: yup.string().required(EMPTY_FIELD_MESSAGE).default(""),
  regularPrice: yup.string().required(EMPTY_FIELD_MESSAGE).default(""),
  salePrice: yup.string().default(""),
  saleFrom: yup
    .string()
    .when("salePrice", (salePrice) => {
      if (salePrice)
        return yup.string().required(EMPTY_FIELD_MESSAGE).default("");
    })
    .default(""),
  saleTo: yup
    .string()
    .when("salePrice", (salePrice) => {
      if (salePrice)
        return yup.string().required(EMPTY_FIELD_MESSAGE).default("");
    })
    .default(""),
  sku: yup.string().required(EMPTY_FIELD_MESSAGE).default(""),
  manageStock: yup.boolean().default(false),
  stock: yup.string().default(""),
  attributes: yup.string().default(""),
  purchaseNote: yup.string().default(""),
  brand: yup.string().default(""),
});

export const approveAllowanceSchema = yup.object().shape({
  to: yup.string().required(EMPTY_FIELD_MESSAGE).default(""),
  amount: yup.string().required(EMPTY_FIELD_MESSAGE).default(""),
});

export const transferAmountSchema = yup.object().shape({
  from: yup.string().required(EMPTY_FIELD_MESSAGE).default(""),
  to: yup.string().required(EMPTY_FIELD_MESSAGE).default(""),
  amount: yup.string().required(EMPTY_FIELD_MESSAGE).default(""),
});
