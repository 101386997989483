import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getUserByAddress } from "../../../../../services/WalletService";
import {
  getAvatar,
  getFullName,
} from "../../../../../store/selectors/AuthSelectors";
import Avatar from "react-avatar";

function TransferDir(props) {
  const { to } = props;
  const avatar = useSelector(getAvatar);
  const fullname = useSelector(getFullName);
  const [toUser, setToUser] = useState(null);

  useEffect(() => {
    if (to) {
      getData(to);
    }
  }, [to]);

  function getData(to) {
    getUserByAddress(to)
      .then((res) => {
        if (res.data.status == 200) {
          setToUser(res.data.user);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  return toUser ? (
    <section>
      <div className="row text-center mb-3 align-items-center">
        <div className="col-lg-3" />
        <div className="col-lg-2">
          <Avatar src={avatar} name={fullname} round={true} size="55" />
          <p>{fullname}</p>
        </div>
        <div className="col-lg-2">
          <i class="fa-solid fa-arrow-right" style={{ fontSize: "2em" }}></i>
        </div>
        <div className="col-lg-2">
          <Avatar src={toUser.avatar} name={fullname} round={true} size="55" />
          {toUser.first_name == null && toUser.last_name == null ?
            <p>N/A</p> : <p>{toUser.first_name + " " + toUser.last_name}</p>
          }
        </div>
        <div className="col-lg-3" />
      </div>
    </section>
  ) : null;
}

export default TransferDir;
