import React, { Fragment, useEffect, useState } from "react";
import { useSelector, useDispatch, connect } from "react-redux";
import FilteringTable from "../table/FilteringTable/FilteringTable";
import { ColumnFilter } from "../table/FilteringTable/ColumnFilter";
import { Dropdown } from "react-bootstrap";
import Dialog from "../partials/Dialog";
import {
  getTokenAllocationsAction,
  removeTokenAllocationsAction,
} from "../../../store/actions/TokenAllocationAction";
import { getTokenAllocations } from "../../../store/selectors/TokenAllocationSelector";
import { useHistory } from "react-router-dom";
import moment from "moment";

const ThreeDots = (
  <svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <rect x="0" y="0" width="24" height="24"></rect>
      <circle fill="#000000" cx="5" cy="12" r="2"></circle>
      <circle fill="#000000" cx="12" cy="12" r="2"></circle>
      <circle fill="#000000" cx="19" cy="12" r="2"></circle>
    </g>
  </svg>
);

const TokenAllocationList = (props) => {
  const dispatch = useDispatch();
  const data = useSelector(getTokenAllocations);
  const history = useHistory();

  const defaultDialog = {
    open: false,
    title: "",
    text: "",
    onClose: () => {},
  };

  const headerBtns = [
    {
      title: "+ Add",
      onClick: () => {
        history.push("add-token-allocation");
      },
    },
  ];

  const [dialogProps, setDialogProps] = useState(defaultDialog);

  const columns = [
    {
      Header: "#",
      Footer: "#",
      accessor: "id",
      Filter: ColumnFilter,
    },
    {
      Header: "Amount (local currency)",
      Footer: "Amount",
      Filter: ColumnFilter,
      Cell: (cell) => {
        if (cell.row.original.amount && cell.row.original.currency) {
          return cell.row.original.amount + " " + cell.row.original.currency;
        } else {
          return "N/A";
        }
      },
    },
    {
      Header: "No. of USD",
      Footer: "No. of USD",
      Filter: ColumnFilter,
      Cell: (cell) => {
        if (cell.row.original.no_of_usd) {
          return cell.row.original.no_of_usd + " USD";
        } else {
          return "N/A";
        }
      },
    },
    {
      Header: "No. of LogiE Token",
      Footer: "No. of LogiE Token",
      Filter: ColumnFilter,
      Cell: (cell) => {
        if (cell.row.original.no_of_logie_token) {
          return cell.row.original.no_of_logie_token + " LET";
        } else {
          return "N/A";
        }
      },
    },
    {
      Header: "Start Date",
      Footer: "Start Date",
      Filter: ColumnFilter,
      Cell: (cell) => {
        if (cell.row.original.start_date) {
          return moment(cell.row.original.start_date).format("DD MMMM YYYY");
        } else {
          return "N/A";
        }
      },
    },
    {
      Header: "End Date",
      Footer: "End Date",
      Filter: ColumnFilter,
      Cell: (cell) => {
        if (cell.row.original.end_date) {
          return moment(cell.row.original.end_date).format("DD MMMM YYYY");
        } else {
          return "N/A";
        }
      },
    },
    {
      Header: "Actions",
      Footer: "Actions",
      Cell: (cell) => {
        let variant = "success";
        return (
          <Dropdown>
            <Dropdown.Toggle variant={variant} className="light sharp i-false">
              {ThreeDots}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Header>Actions</Dropdown.Header>
              <Dropdown.Item
                onSelect={() => {
                  history.push(
                    "update-token-allocation/" + cell.row.original.id
                  );
                }}
                variant="danger"
                className="danger light sharp i-false"
              >
                Edit
              </Dropdown.Item>
              <Dropdown.Item
                onSelect={() => {
                  setDialogProps({
                    open: true,
                    title: "Confirmation",
                    text: "This action is irreversible. Are you sure to proceed?",
                    yesBtn: {
                      title: "Yes",
                      onTap: () => {
                        remove(cell.row.original.id);
                        setDialogProps(defaultDialog);
                      },
                    },
                    noBtn: {
                      title: "No",
                      onTap: () => setDialogProps(defaultDialog),
                    },
                    onClose: () => setDialogProps(defaultDialog),
                  });
                }}
                variant="danger"
                className="danger light sharp i-false"
              >
                Delete
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        );
      },
    },
  ];

  useEffect(() => {
    dispatch(getTokenAllocationsAction());
  }, []);

  function remove(tokenAllocationId) {
    dispatch(removeTokenAllocationsAction(tokenAllocationId));
  }

  return (
    <Fragment>
      <div className="col-xl-12 col-xxl-12">
        <FilteringTable
          data={data}
          columns={columns}
          title="Token Allocations"
          headerBtns={headerBtns}
        />
      </div>
      <Dialog {...dialogProps} />
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    successMessage: state.auth.successMessage,
    showLoading: state.auth.showLoading,
  };
};

export default connect(mapStateToProps)(TokenAllocationList);
