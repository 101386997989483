import api from "./AxiosInstance";

export function createInterests(data) {
    return api.post("interest", data);
}

export function listInterests(userId) {
    return api.get("interest/by/u/" + userId);
}

export function removeInterests(userId, collectionId) {
    return api.delete("interest/by/u/" + userId + '/' + collectionId);
}

export function listAllInterests() {
    return api.get("interest");
}


