import React from "react";
import { Button } from "react-bootstrap";
import "./TreeForm.css";
import "../../../../../css/screen-transition.css";
import { BiRectangle } from "react-icons/bi";
import { BsFillCircleFill } from "react-icons/bs";
import { CgShapeZigzag } from "react-icons/cg";
import { BiShapePolygon } from "react-icons/bi";
import { BiBrushAlt } from "react-icons/bi";
import { MdBrightnessMedium } from "react-icons/md";
import { SiDeepin } from "react-icons/si";
import { MdReduceCapacity } from "react-icons/md";
import { TbCircuitCapacitorPolarized } from "react-icons/tb";
import { TbCircuitCapacitor } from "react-icons/tb";
import { GiAcid } from "react-icons/gi";
import { BsEmojiNeutral } from "react-icons/bs";
import { GiAcidBlob } from "react-icons/gi";
import { GiHypodermicTest } from "react-icons/gi";
import { MdOutlineFireHydrantAlt } from "react-icons/md";
import { GiAerosol } from "react-icons/gi";
import { GiAcidTube } from "react-icons/gi";
import { GiVileFluid } from "react-icons/gi";
import { GiHydraShot } from "react-icons/gi";
import { FaTemperatureLow } from "react-icons/fa";
import { TbTemperature } from "react-icons/tb";
import { FaTemperatureHigh } from "react-icons/fa";
import { MdOutlineNature } from "react-icons/md";
import { MdEmojiNature } from "react-icons/md";
import { GiCircularSawblade } from "react-icons/gi";
import { MdBlurCircular } from "react-icons/md";
import { MdPublic } from "react-icons/md";
import { RiGitRepositoryPrivateFill } from "react-icons/ri";
import { MdOutlinePublishedWithChanges } from "react-icons/md";
import { BsGearWideConnected } from "react-icons/bs";
import { TbBrandCodecov } from "react-icons/tb";
import { BiLandscape } from "react-icons/bi";
import { MdLandscape } from "react-icons/md";
import { MdOutlineLandslide } from "react-icons/md";
import { SiSpond } from "react-icons/si";
import { GiPlantWatering } from "react-icons/gi";
import TextItem from "./TextItem";
import DropZone from "../../../partials/DropZone";
import Loader from "../../../../loader.json";
import Lottie from "lottie-react";

const PondForm = (props) => {
    const {
        handleChangePond,
        dialog,
        defaultDialogButtons,
        data: { pond },
        handleChange,
        data,
        showLoader,
        errors,
        onSubmit,
    } = props;

    const LANDSCAPE = [
        {
            title: "Natural Ponds",
            caption:
                "Natural ponds are designed to blend in with their surroundings and may be left in their natural state. These ponds may have natural banks and vegetation, and may be home to a variety of wildlife. They are often used to create a natural, tranquil atmosphere in a backyard or public park.",
            icon: <BiLandscape />
        },
        {
            title: "Decorative Ponds",
            caption:
                "Decorative ponds are designed to enhance the aesthetic appeal of an area and may be found in residential or commercial landscaping. These ponds may be landscaped with rocks, plants, and water features such as fountains or waterfalls.",
            icon: <MdLandscape />
        },
        {
            title: "Formal Ponds",
            caption:
                "Formal ponds are typically found in formal gardens and are designed to be symmetrical and geometrically shaped. These ponds may have straight edges and be surrounded by formal landscaping such as hedges or topiaries.",
            icon: <MdOutlineLandslide />
        },
        {
            title: "Koi Ponds",
            caption:
                "Koi ponds are designed to house ornamental fish such as Koi and may be found in residential or commercial landscaping. These ponds may have a more formal design and be landscaped with rocks, plants, and water features.",
            icon: <SiSpond />
        },
        {
            title: "Water Gardens",
            caption:
                "Water gardens are designed to incorporate aquatic plants and may be found in residential or commercial landscaping. These ponds may have a naturalistic design and be surrounded by lush vegetation.",
            icon: <GiPlantWatering />
        },
    ];
    const ACCESSLEVEL = [
        {
            title: "Public Access",
            caption:
                "Public access ponds are open to the public and typically have designated areas for fishing, swimming, and other recreational activities. These ponds may have facilities such as restrooms, picnic areas, and boat launches, and may be managed by local or state agencies.",
            icon: <MdPublic size={20} />
        },
        {
            title: "Private Access",
            caption:
                "Private access ponds are located on private property and are only accessible to the owner and their guests. These ponds may be used for recreational activities or as a water source for irrigation or livestock.",
            icon: <RiGitRepositoryPrivateFill size={20} />
        },
        {
            title: "Retention Ponds",
            caption:
                "Retention ponds are designed to capture and treat storm water runoff and may be located in residential or commercial areas. These ponds may have limited access and may be fenced off for safety.",
            icon: <MdOutlinePublishedWithChanges size={20} />
        },
        {
            title: "Decorative Ponds",
            caption:
                "Decorative ponds are typically found in residential or commercial landscaping and may be used to enhance the aesthetic appeal of an area. These ponds may have limited access and may be designed to prevent drowning or other safety hazards.",
            icon: <BsGearWideConnected size={20} />
        },
        {
            title: "Natural Ponds",
            caption:
                "Natural ponds may be located in parks or other public areas and are typically left in their natural state. These ponds may have limited access and may have signs warning visitors of potential hazards such as slippery banks or deep water.",
            icon: <TbBrandCodecov size={20} />
        },
    ];
    const CIRCULATIONTYPE = [
        {
            title: "Natural",
            caption:
                "Natural ponds do not have any aeration or circulation devices and rely on natural processes to maintain oxygen levels and water circulation. These ponds may have lower oxygen levels and may be prone to algae growth and stratification.",
            icon: <MdOutlineNature size={20} />
        },
        {
            title: "Aerated",
            caption:
                "Aerated ponds have an aeration device, such as a fountain or diffuser, that adds oxygen to the water and promotes circulation. These ponds may have higher oxygen levels and can support a wider range of aquatic life.",
            icon: <MdEmojiNature size={20} />
        },
        {
            title: "Circulated",
            caption:
                "Circulated ponds have a circulation device, such as a pump or waterfall, that moves water around the pond and promotes mixing. These ponds may have more consistent water temperatures and can help prevent stratification and algae growth.",
            icon: <GiCircularSawblade size={20} />
        },
        {
            title: "Combination",
            caption:
                "Combination ponds have both aeration and circulation devices, providing the benefits of both methods. These ponds may have higher oxygen levels, more consistent water temperatures, and a wider range of aquatic life.",
            icon: <MdBlurCircular size={20} />
        },
    ];
    const TEMPERATURELEVELS = [
        {
            title: "Warm-Water",
            caption:
                "Warm-water ponds have a temperature range of 75-85°F (24-29°C) and can support warm-water fish species such as bass, catfish, and sunfish. These ponds are typically found in warmer climates or may be heated to maintain their temperature.",
            icon: <FaTemperatureHigh size={20} />
        },
        {
            title: "Cool-Water",
            caption:
                "Cool-water ponds have a temperature range of 60-75°F (15-24°C) and can support cool-water fish species such as trout, perch, and walleye. These ponds may be found in temperate climates or may be naturally fed by cooler water sources.",
            icon: <FaTemperatureLow size={20} />
        },
        {
            title: "Cold-Water",
            caption:
                "Cold-water ponds have a temperature range of 50-60°F (10-15°C) and can support cold-water fish species such as salmon and arctic char. These ponds are typically found in colder climates or at high elevations.",
            icon: <TbTemperature size={20} />
        },
    ];
    const NUTRIENTLEVELS = [
        {
            title: "Eutrophic",
            caption:
                "Eutrophic ponds have high levels of nutrients, particularly nitrogen and phosphorus, which can lead to excessive plant growth and algal blooms. These ponds may be murky or greenish in color and may have low oxygen levels.",
            icon: <GiAcidTube size={20} />
        },
        {
            title: "Mesotrophic",
            caption:
                "Mesotrophic ponds have moderate levels of nutrients and can support a diverse range of plant and animal life. These ponds may be clear or slightly turbid and may have moderate oxygen levels.",
            icon: <GiVileFluid size={20} />
        },
        {
            title: "Oligotrophic",
            caption:
                "Oligotrophic ponds have low levels of nutrients and can support only a limited range of plant and animal life. These ponds may be crystal-clear and may have high oxygen levels.",
            icon: <GiHydraShot size={20} />
        },
    ];
    const OXYGENLEVELS = [
        {
            title: "Hypoxic",
            caption:
                "Hypoxic ponds have very low levels of dissolved oxygen, which can lead to fish kills and other ecological problems. These ponds may occur naturally in areas with high nutrient loads, or they may be the result of human activity such as fertilizer runoff or sewage discharge.",
            icon: <GiHypodermicTest size={20} />
        },
        {
            title: "Anaerobic",
            caption:
                "Anaerobic ponds have low levels of dissolved oxygen, which support anaerobic bacteria and other microorganisms. These ponds may have a higher concentration of organic matter and can produce methane gas. They may be used for treating wastewater or for biogas production.",
            icon: <MdOutlineFireHydrantAlt size={20} />
        },
        {
            title: "Aerobic",
            caption:
                "Aerobic ponds have high levels of dissolved oxygen, which support aerobic bacteria and aquatic organisms such as fish, snails, and insects. These ponds may have an aerator or other oxygenating device to maintain high oxygen levels.",
            icon: <GiAerosol size={20} />
        },
    ];
    const PHLEVELS = [
        {
            title: "Acidic < 7.0",
            caption:
                "Acidic ponds have a pH level below 7.0. They may occur naturally in areas with acidic soils, or they may be the result of pollution from acid rain or other sources.",
            icon: <GiAcid size={20} />
        },
        {
            title: "Neutral ~ 7.0",
            caption:
                "Neutral ponds have a pH level of 7.0. They are neither acidic nor alkaline, and can support a wide range of aquatic plants and animals.",
            icon: <BsEmojiNeutral size={20} />
        },
        {
            title: "Alkaline > 7.0",
            caption:
                "Alkaline ponds have a pH level above 7.0. They may occur naturally in areas with alkaline soils, or they may be the result of human activity such as runoff from concrete or other construction materials.",
            icon: <GiAcidBlob size={20} />
        },
    ];
    const CAPACITIES = [
        {
            title: "< 1000",
            caption:
                "Small ponds typically have a volume of less than 1,000 gallons. They are often used for decorative purposes or to attract wildlife such as birds and butterflies.",
            icon: <MdReduceCapacity size={20} />
        },
        {
            title: "1000 ↔ 10000",
            caption:
                "Medium ponds typically have a volume of between 1,000 and 10,000 gallons. They are often used for recreational activities such as swimming, boating, and fishing, or for irrigation or storm water management.",
            icon: <TbCircuitCapacitor size={20} />
        },
        {
            title: "> 10000",
            caption:
                "Large ponds typically have a volume of more than 10,000 gallons. They are often used for commercial fishing, aquaculture, or as water reservoirs for agricultural or industrial purposes.",
            icon: <TbCircuitCapacitorPolarized size={20} />
        },
    ];
    const PONDSHAPES = [
        {
            title: "Rectangular",
            caption:
                "Rectangular ponds are typically characterized by their straight edges and right angles. They are often used in formal garden designs or for practical purposes such as irrigation or storm water management.",
            icon: <BiRectangle size={20} />
        },
        {
            title: "Circular",
            caption:
                "Circular ponds are characterized by their round shape. They are often used in naturalistic garden designs or as the focal point of a landscape.",
            icon: <BsFillCircleFill size={20} />
        },
        {
            title: "L-Shaped",
            caption:
                "L-shaped ponds have two perpendicular sections, creating an L-shape. They are often used in formal garden designs or to fit into a specific site or landscape.",
            icon: <CgShapeZigzag size={20} />
        },
        {
            title: "Irregular",
            caption:
                "Irregularly shaped ponds can take on a wide range of shapes, from freeform to asymmetrical. They are often used in naturalistic garden designs or to fit into a specific site or landscape.",
            icon: <BiShapePolygon size={20} />
        },
    ];
    const DEPTHS = [
        {
            title: "Shallow",
            caption:
                "Shallow ponds are typically less than six feet deep. They are often used for decorative purposes and to attract wildlife such as birds and butterflies.",
            icon: <BiBrushAlt size={20} />
        },
        {
            title: "Medium",
            caption:
                "Medium depth ponds are typically between six and twelve feet deep. They are often used for recreational activities such as swimming, boating, and fishing.",
            icon: <MdBrightnessMedium size={20} />
        },
        {
            title: "Deep",
            caption:
                "Deep ponds are typically deeper than twelve feet. They are often used for commercial fishing, aquaculture, or as water reservoirs.",
            icon: <SiDeepin size={20} />
        },
    ];

    return (
        <div>
            <section>
                <div className="row">
                    <div className="col-lg-6 col-xs-12">
                        <div className="form-group">
                            <label className="text-label">
                                Name<strong className="text-danger">*</strong>
                                {errors['pond.name'] ? (
                                    <small className="text-danger ml-1"> {errors['pond.name']}</small>
                                ) : null}
                            </label>
                            <input
                                type="text"
                                name="name"
                                placeholder="Write your pond name"
                                value={pond.name}
                                onChange={(e) => handleChangePond("name", e.target.value)}
                                className={
                                    errors['pond.name'] ? "form-control border-danger" : "form-control"
                                }
                            />
                            <label className="mt-3 text-label">Shape</label>
                            {errors['pond.shape'] ? (
                                <small className="text-danger ml-1"> {errors['pond.shape']}</small>
                            ) : null}
                            <div className="col-md-12 buttons">
                                {PONDSHAPES.map((shape, index) => {
                                    return (
                                        <button
                                            className={
                                                errors['pond.shape'] ? "border-danger" : ""
                                            }
                                            style={{ borderColor: pond.shape == shape.title ? '#558b2f' : "white" }}
                                            key={index}
                                            onClick={() => {
                                                handleChangePond(
                                                    "shape", shape.title
                                                );
                                            }}
                                        >
                                            <div>
                                                <span>{shape.icon}</span>
                                                <span className="ml-2">{shape.title}</span>
                                            </div>
                                        </button>
                                    );
                                })}
                            </div>
                            <label className="mt-3 text-label">Depth</label>
                            {errors['pond.depth'] ? (
                                <small className="text-danger ml-1"> {errors['pond.depth']}</small>
                            ) : null}
                            <div className="col-md-12 buttons">
                                {DEPTHS.map((depth, index) => {
                                    return (
                                        <button
                                            className={
                                                errors['pond.depth'] ? "border-danger" : ""
                                            }
                                            style={{ borderColor: pond.depth == depth.title ? '#558b2f' : "white" }}
                                            key={index}
                                            onClick={() => {
                                                handleChangePond(
                                                    "depth", depth.title
                                                );
                                            }}
                                        >
                                            <div>
                                                <span>{depth.icon}</span>
                                                <span className="ml-2">{depth.title}</span>
                                            </div>
                                        </button>
                                    );
                                })}
                            </div>
                            <label className="mt-3 text-label">Capacity</label>
                            {errors['pond.capacity'] ? (
                                <small className="text-danger ml-1"> {errors['pond.capacity']}</small>
                            ) : null}
                            <div className="col-md-12 buttons">
                                {CAPACITIES.map((capacity, index) => {
                                    return (
                                        <button
                                            className={
                                                errors['pond.capacity'] ? "border-danger" : ""
                                            }
                                            style={{ borderColor: pond.capacity == capacity.title ? '#558b2f' : "white" }}

                                            key={index}
                                            onClick={() => {
                                                handleChangePond(
                                                    "capacity", capacity.title
                                                );
                                            }}
                                        >
                                            <div>
                                                <span>{capacity.icon}</span>
                                                <span className="ml-2">{capacity.title}</span>
                                            </div>
                                        </button>
                                    );
                                })}
                            </div>
                            <label className="mt-3 text-label">PH Level</label>
                            {errors['pond.ph'] ? (
                                <small className="text-danger ml-1"> {errors['pond.ph']}</small>
                            ) : null}
                            <div className="col-md-12 buttons">
                                {PHLEVELS.map((ph, index) => {
                                    return (
                                        <button
                                            className={
                                                errors['pond.ph'] ? "border-danger" : ""
                                            }
                                            style={{ borderColor: pond.ph == ph.title ? '#558b2f' : "white" }}

                                            key={index}
                                            onClick={() => {
                                                handleChangePond(
                                                    "ph", ph.title
                                                );
                                            }}
                                        >
                                            <div>
                                                <span>{ph.icon}</span>
                                                <span className="ml-2">{ph.title}</span>
                                            </div>
                                        </button>
                                    );
                                })}
                            </div>
                            <label className="mt-3 text-label">Oxygen Level</label>
                            {errors['pond.oxygen'] ? (
                                <small className="text-danger ml-1"> {errors['pond.oxygen']}</small>
                            ) : null}
                            <div className="col-md-12 buttons">
                                {OXYGENLEVELS.map((oxygen, index) => {
                                    return (
                                        <button
                                            className={
                                                errors['pond.oxygen'] ? "border-danger" : ""
                                            }
                                            style={{ borderColor: pond.oxygen == oxygen.title ? '#558b2f' : "white" }}
                                            key={index}
                                            onClick={() => {
                                                handleChangePond(
                                                    "oxygen", oxygen.title
                                                );
                                            }}
                                        >
                                            <div>
                                                <span>{oxygen.icon}</span>
                                                <span className="ml-2">{oxygen.title}</span>
                                            </div>
                                        </button>
                                    );
                                })}
                            </div>
                            <label className="mt-3 text-label">Nutrient Level</label>
                            {errors['pond.nutrient'] ? (
                                <small className="text-danger ml-1"> {errors['pond.nutrient']}</small>
                            ) : null}
                            <div className="col-md-12 buttons">
                                {NUTRIENTLEVELS.map((nutrient, index) => {
                                    return (
                                        <button
                                            className={
                                                errors['pond.nutrient'] ? "border-danger" : ""
                                            }
                                            style={{ borderColor: pond.nutrient == nutrient.title ? '#558b2f' : "white" }}
                                            key={index}
                                            onClick={() => {
                                                handleChangePond(
                                                    "nutrient", nutrient.title
                                                );
                                            }}
                                        >
                                            <div>
                                                <span>{nutrient.icon}</span>
                                                <span className="ml-2">{nutrient.title}</span>
                                            </div>
                                        </button>
                                    );
                                })}
                            </div>
                            <label className="mt-3 text-label">Temperature Level</label>
                            {errors['pond.temperature'] ? (
                                <small className="text-danger ml-1"> {errors['pond.temperature']}</small>
                            ) : null}
                            <div className="col-md-12 buttons">
                                {TEMPERATURELEVELS.map((temperature, index) => {
                                    return (
                                        <button
                                            className={
                                                errors['pond.temperature'] ? "border-danger" : ""
                                            }
                                            style={{ borderColor: pond.temperature == temperature.title ? '#558b2f' : "white" }}
                                            key={index}
                                            onClick={() => {
                                                handleChangePond(
                                                    "temperature", temperature.title
                                                );
                                            }}
                                        >
                                            <div>
                                                <span>{temperature.icon}</span>
                                                <span className="ml-2">{temperature.title}</span>
                                            </div>
                                        </button>
                                    );
                                })}
                            </div>
                            <label className="mt-3 text-label">Aeration and Circulation</label>
                            {errors['pond.circulation'] ? (
                                <small className="text-danger ml-1"> {errors['pond.circulation']}</small>
                            ) : null}
                            <div className="col-md-12 buttons">
                                {CIRCULATIONTYPE.map((circulation, index) => {
                                    return (
                                        <button
                                            className={
                                                errors['pond.circulation'] ? "border-danger" : ""
                                            }
                                            style={{ borderColor: pond.circulation == circulation.title ? '#558b2f' : "white" }}
                                            key={index}
                                            onClick={() => {
                                                handleChangePond(
                                                    "circulation", circulation.title
                                                );
                                            }}
                                        >
                                            <div>
                                                <span>{circulation.icon}</span>
                                                <span className="ml-2">{circulation.title}</span>
                                            </div>
                                        </button>
                                    );
                                })}
                            </div>
                            <label className="mt-3 text-label">Access Level</label>
                            {errors['pond.access'] ? (
                                <small className="text-danger ml-1"> {errors['pond.access']}</small>
                            ) : null}
                            <div className="col-md-12 buttons">
                                {ACCESSLEVEL.map((access, index) => {
                                    return (
                                        <button
                                            className={
                                                errors['pond.access'] ? "border-danger" : ""
                                            }
                                            style={{ borderColor: pond.access == access.title ? '#558b2f' : "white" }}
                                            key={index}
                                            onClick={() => {
                                                handleChangePond(
                                                    "access", access.title
                                                );
                                            }}
                                        >
                                            <div>
                                                <span>{access.icon}</span>
                                                <span className="ml-2">{access.title}</span>
                                            </div>
                                        </button>
                                    );
                                })}
                            </div>
                            <label className="mt-3 text-label">Landscape</label>
                            {errors['pond.landscape'] ? (
                                <small className="text-danger ml-1"> {errors['pond.landscape']}</small>
                            ) : null}
                            <div className="col-md-12 buttons">
                                {LANDSCAPE.map((landscape, index) => {
                                    return (
                                        <button
                                            className={
                                                errors['pond.landscape'] ? "border-danger" : ""
                                            }
                                            style={{ borderColor: pond.landscape == landscape.title ? '#558b2f' : "white" }}
                                            key={index}
                                            onClick={() => {
                                                handleChangePond(
                                                    "landscape", landscape.title
                                                );
                                            }}
                                        >
                                            <div>
                                                <span>{landscape.icon}</span>
                                                <span className="ml-2">{landscape.title}</span>
                                            </div>
                                        </button>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-1 col-xs-12"></div>
                    <div className="col-lg-4 col-xs-12">
                        <div className="mt-4">
                            <TextItem
                                errors={errors}
                                required
                                data={data}
                                dialog={dialog}
                                handleChange={handleChange}
                                defaultDialogButtons={defaultDialogButtons}
                            />
                            {errors.words ? (
                                <p style={{ marginTop: -20 }} className="text-danger">
                                    {errors.words}
                                </p>
                            ) : null}
                        </div>
                        <div className="mt-4">
                            <DropZone
                                errors={errors}
                                required
                                title="Plantation Image"
                                src={data.image ? data.image.uri : null}
                                onChange={(file) => handleChange("image", file)}
                                onRemove={() => handleChange("image", null)}
                                caption="Click to explore the image gallery"
                                height="150px"
                            />
                            {errors.image ? (
                                <p style={{ marginTop: -20 }} className="text-danger">
                                    {errors.image}
                                </p>
                            ) : null}
                        </div>
                        <div className="">
                            <p>
                                By submitting this form you agreed to our{" "}
                                <a target="_blank" href="https://terra-nova.io/terms-and-conditions/">Terms & Conditions</a>
                            </p>
                            {
                                showLoader == true ? (
                                    <div className="float-right">
                                        <Lottie
                                            animationData={Loader}
                                            loop={true}
                                            style={{
                                                width: 50,
                                                height: 50,
                                            }}
                                        />
                                    </div>
                                ) :
                                    <Button className="float-right" onClick={onSubmit}>
                                        Submit
                                    </Button>
                            }
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};
export default PondForm;
