import React, { Fragment, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import STree from "../../../../images/svg/tree_t.svg";
import SContest from "../../../../images/svg/contest_t.svg";
import SReward from "../../../../images/svg/reward_t.svg";
import SNFT from "../../../../images/svg/nft_t.svg";
import PageTitle from "../../../layouts/PageTitle";
import Formdata from "form-data";

import {
  getRoleName,
  getAvatar,
  getCover,
  getUsername,
  getFullName,
  getPilotCity,
  getEmail,
  getPhone,
  getTagLine,
  getUID,
  getUserId,
  getBearerToken,
} from "../../../../store/selectors/AuthSelectors";
import { updateProfile } from "../../../../services/AuthService";
import { loginConfirmedAction } from "../../../../store/actions/AuthActions";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const AppProfile = () => {
  const roleName = useSelector(getRoleName);
  const username = useSelector(getUsername);
  const userId = useSelector(getUserId);
  const cover = useSelector(getCover);
  const fullname = useSelector(getFullName);
  const pilotCity = useSelector(getPilotCity);
  const email = useSelector(getEmail);
  const dispatch = useDispatch();
  const phone = useSelector(getPhone);
  const bearerToken = useSelector(getBearerToken);
  const tagLine = useSelector(getTagLine);
  const uid = useSelector(getUID);
  const history = useHistory();
  const [show, setshow] = useState(false);
  const [activeToggle, setActiveToggle] = useState("aboutMe");
  const avatar = useSelector((state) => getAvatar(state, true));
  const [ava, setAva] = useState({
    avatar: null
  })
  function handleChange(key, e) {
    setAva((pre) => ({
      ...pre,
      [key]: e.target.files[0],
    }));
  }
  function handleShow() {
    setshow(true)
  }
  function handleDismiss() {
    setAva((pre) => ({
      ...pre,
      avatar: null
    }));
    setshow(false)
  }

  function onSubmit(e) {
    e.preventDefault();
    const formdata = new Formdata();
    for (const key in ava) {
      if (ava.hasOwnProperty(key)) {
        formdata.append(key, ava[key]);
      }
    }
    formdata.append("status", "Pending Review");
    updateProfile(userId, formdata, { Authorization: bearerToken })
      .then((res) => {
        if (res.data.status == 200) {
          dispatch(loginConfirmedAction(res.data));
          history.push("dashboard")
        }
      })
      .catch((err) => {
        // console.log(err);
      });
  }

  return (
    <Fragment>
      <PageTitle activeMenu="Profile" motherMenu="App" />

      <div className="row">
        <div className="col-lg-12">
          <div className="profile card card-body px-3 pt-3 pb-0">
            <div className="profile-head">
              <div className="photo-content">
                <div
                  className="cover-photo"
                  style={{ background: `url(${cover})` }}
                ></div>
              </div>
              <div className="profile-info">
                <div className="profile-photo">
                  <img
                    src={avatar}
                    className="img-fluid rounded-circle"
                    alt="profile"
                    width="100px"
                    height="100px"
                    style={{ objectFit: "cover", backgroundColor: "white" }}
                  />
                </div>
                <div className="profile-details">
                  <div className="profile-name px-3 pt-2">
                    <h4 className="text-primary mb-0">{fullname}</h4>
                    <p>{username}</p>
                  </div>
                  <div className="profile-email px-2 pt-2">
                    <h4 className="text-muted mb-0">{email}</h4>
                    <p>{phone}</p>
                  </div>
                  {/* <Dropdown className="dropdown ml-auto">
                    <Dropdown.Toggle
                      variant="primary"
                      className="btn btn-primary light sharp i-false"
                      data-toggle="dropdown"
                      aria-expanded="true"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        //    xmlns:xlink="http://www.w3.org/1999/xlink"
                        width="18px"
                        height="18px"
                        viewBox="0 0 24 24"
                        version="1.1"
                      >
                        <g
                          stroke="none"
                          strokeWidth="1"
                          fill="none"
                          fillRule="evenodd"
                        >
                          <rect x="0" y="0" width="24" height="24"></rect>
                          <circle fill="#000000" cx="5" cy="12" r="2"></circle>
                          <circle fill="#000000" cx="12" cy="12" r="2"></circle>
                          <circle fill="#000000" cx="19" cy="12" r="2"></circle>
                        </g>
                      </svg>
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="dropdown-menu dropdown-menu-right">
                      <Dropdown.Item className="dropdown-item">
                        <i className="fa fa-user-circle text-primary mr-2" />
                        View profile
                      </Dropdown.Item>
                      <Dropdown.Item className="dropdown-item">
                        <i className="fa fa-users text-primary mr-2" />
                        Add to close friends
                      </Dropdown.Item>
                      <Dropdown.Item className="dropdown-item">
                        <i className="fa fa-plus text-primary mr-2" />
                        Add to group
                      </Dropdown.Item>
                      <Dropdown.Item className="dropdown-item">
                        <i className="fa fa-ban text-primary mr-2" />
                        Block
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown> */}
                  {
                    roleName !== "SuperAdmin" ? (
                      <div className="ml-2"><Button className="info light" onClick={handleShow}>Change Profile Picture</Button></div>
                    ) : null
                  }
                </div>
              </div>
              {roleName !== "SuperAdmin" ? (
                <div className="row mb-2" style={{ justifyContent: "center" }}>
                  <div className="col-lg-8">
                    {
                      show == true ? (
                        <div style={{ display: "flex" }}>
                          <div className="custom-file">
                            <input
                              type="file"
                              accept="image/jpeg, image/png"
                              name="avatar"
                              className="custom-file-input"
                              onChange={(e) => handleChange("avatar", e)}
                            />
                            <label className="custom-file-label">
                              {ava.avatar
                                ? typeof ava.avatar === "string"
                                  ? ava.avatar
                                  : ava.avatar.fileName
                                : "Choose file"}
                            </label>
                          </div>
                          <h4><Button onClick={onSubmit} className="info light ml-5" type="submit">Submit</Button></h4>
                          <h4><Button onClick={handleDismiss} className="info light ml-2">Dismiss</Button></h4>
                        </div>
                      ) : null
                    }
                  </div>
                </div>
              ) : null
              }
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-xl-4">
          <div className="row">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-body">
                  <div className="profile-statistics">
                    <div className="text-center">
                      <div className="row">
                        <div className="col">
                          <div className="mb-2">
                            <img src={STree} width="40px" height="40px" />
                          </div>
                          <h3 className="m-b-0">0</h3>
                        </div>
                        <div className="col">
                          <div className="mb-2">
                            <img src={SContest} width="40px" height="40px" />
                          </div>
                          <h3 className="m-b-0">0</h3>
                        </div>
                        <div className="col">
                          <div className="mb-2">
                            <img src={SReward} width="40px" height="40px" />
                          </div>
                          <h3 className="m-b-0">0</h3>
                        </div>
                        <div className="col">
                          <div className="mb-2">
                            <img src={SNFT} width="40px" height="40px" />
                          </div>
                          <h3 className="m-b-0">0</h3>
                        </div>
                      </div>
                      {/* <div className="mt-4">
                        <Link
                          to="/post-details"
                          className="btn btn-primary mb-1 mr-1"
                        >
                          Follow
                        </Link>
                        <Button
                          as="a"
                          href="#"
                          className="btn btn-primary mb-1 ml-1"
                          onClick={() => setSendMessage(true)}
                        >
                          Send Message
                        </Button>
                      </div> */}
                    </div>
                    {/* send Modal */}
                    {/* <Modal
                      className="modal fade"
                      show={sendMessage}
                      onHide={setSendMessage}
                      centered
                    >
                      <div className="modal-content">
                        <div className="modal-header">
                          <h5 className="modal-title">Send Message</h5>
                          <Button
                            variant=""
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            onClick={() => setSendMessage(false)}
                          >
                            <span>×</span>
                          </Button>
                        </div>
                        <div className="modal-body">
                          <form
                            className="comment-form"
                            onSubmit={(e) => {
                              e.preventDefault();
                              setSendMessage(false);
                            }}
                          >
                            <div className="row">
                              <div className="col-lg-6">
                                <div className="form-group">
                                  <label
                                    htmlFor="author"
                                    className="text-black font-w600"
                                  >
                                    {" "}
                                    Name <span className="required">
                                      *
                                    </span>{" "}
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    defaultValue="Author"
                                    name="Author"
                                    placeholder="Author"
                                  />
                                </div>
                              </div>
                              <div className="col-lg-6">
                                <div className="form-group">
                                  <label
                                    htmlFor="email"
                                    className="text-black font-w600"
                                  >
                                    {" "}
                                    Email <span className="required">*</span>
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    defaultValue="Email"
                                    placeholder="Email"
                                    name="Email"
                                  />
                                </div>
                              </div>
                              <div className="col-lg-12">
                                <div className="form-group">
                                  <label
                                    htmlFor="comment"
                                    className="text-black font-w600"
                                  >
                                    Comment
                                  </label>
                                  <textarea
                                    rows={8}
                                    className="form-control"
                                    name="comment"
                                    placeholder="Comment"
                                    defaultValue={""}
                                  />
                                </div>
                              </div>
                              <div className="col-lg-12">
                                <div className="form-group">
                                  <input
                                    type="submit"
                                    value="Post Comment"
                                    className="submit btn btn-primary"
                                    name="submit"
                                  />
                                </div>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </Modal> */}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-12">
              <div className="card">
                <div className="card-header border-0 pb-0">
                  <h5 className="text-black">Today Highlights</h5>
                </div>
                <div className="card-body pt-3">
                  {/* <div className="profile-blog ">
                    <img
                      src={profile01}
                      alt="profile"
                      className="img-fluid  mb-4 w-100"
                    />
                    <Link to="/post-details">
                      {" "}
                      <h4>Darwin Creative Agency Theme</h4>{" "}
                    </Link>
                    <p className="mb-0">
                      A small river named Duden flows by their place and
                      supplies it with the necessary regelialia. It is a
                      paradisematic country, in which roasted parts of sentences
                      fly into your mouth.
                    </p>
                  </div> */}
                </div>
              </div>
            </div>
            <div className="col-lg-12">
              <div className="card">
                <div className="card-header border-0 pb-0">
                  <h5 className="text-black">Latest From Terra Nova</h5>
                </div>
                <div className="card-body pt-3">
                  <div className="profile-news">
                    {/* <div className="media pt-3 pb-3">
                      <img
                        src={profile05}
                        alt=""
                        className="mr-3 rounded"
                        width={75}
                      />
                      <div className="media-body">
                        <h5 className="m-b-5">
                          <Link to="/post-details" className="text-black">
                            Collection of textile samples
                          </Link>
                        </h5>
                        <p className="mb-0">
                          I shared this on my fb wall a few months back, and I
                          thought.{" "}
                        </p>
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-8">
          <div className="card">
            <div className="card-body">
              <div className="profile-tab">
                <div className="custom-tab-1">
                  <ul className="nav nav-tabs">
                    {/* <li
                      className="nav-item"
                      onClick={() => setActiveToggle("posts")}
                    >
                      <Link
                        to="#my-posts"
                        data-toggle="tab"
                        className={`nav-link ${
                          activeToggle === "posts" ? "active show" : ""
                        }`}
                      >
                        Posts
                      </Link>
                    </li> */}
                    <li
                      className="nav-item"
                      onClick={() => setActiveToggle("aboutMe")}
                    >
                      <Link
                        to="#about-me"
                        data-toggle="tab"
                        className={`nav-link ${activeToggle === "aboutMe" ? "active show" : ""
                          }`}
                      >
                        About Me
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        to="#profile-settings"
                        data-toggle="tab"
                        onClick={() => setActiveToggle("setting")}
                        className={`nav-link ${activeToggle === "setting" ? "active show" : ""
                          } ${roleName == "SuperAdmin" ? "disabled opacity-3" : ""
                          }`}
                      >
                        Setting
                      </Link>
                    </li>
                  </ul>
                  <div className="tab-content">
                    <div
                      id="about-me"
                      className={`tab-pane fade ${activeToggle === "aboutMe" ? "active show" : ""
                        }`}
                    >
                      <div className="profile-about-me">
                        <div className="pt-4 border-bottom-1 pb-3">
                          <h4 className="text-primary">About Me</h4>
                          {tagLine ? (
                            <p>{tagLine}</p>
                          ) : (
                            <p className="mb-2 text-danger">
                              Terra Nova Administrator have most of the
                              permissions available in the web portal but for
                              some security reasons the admin cannot
                              add/change/update his profile information
                            </p>
                          )}
                        </div>
                      </div>
                      {/* <div className="profile-skills mb-5">
                        <h4 className="text-primary mb-2">Skills</h4>
                        <Link
                          to="/app-profile"
                          className="btn btn-primary light btn-xs mb-1 mr-1"
                        >
                          {" "}
                          Admin
                        </Link>
                        <Link
                          to="/app-profile"
                          className="btn btn-primary light btn-xs mb-1 mr-1"
                        >
                          {" "}
                          Dashboard
                        </Link>
                        <Link
                          to="/app-profile"
                          className="btn btn-primary light btn-xs mb-1 mr-1"
                        >
                          Photoshop
                        </Link>
                        <Link
                          to="/app-profile"
                          className="btn btn-primary light btn-xs mb-1 mr-1"
                        >
                          Bootstrap
                        </Link>
                        <Link
                          to="/app-profile"
                          className="btn btn-primary light btn-xs mb-1 mr-1"
                        >
                          Responsive
                        </Link>
                        <Link
                          to="/app-profile"
                          className="btn btn-primary light btn-xs mb-1 mr-1"
                        >
                          Crypto
                        </Link>
                      </div>
                      <div className="profile-lang  mb-5">
                        <h4 className="text-primary mb-2">Language</h4>
                        <Link
                          to="/app-profile"
                          className="text-muted pr-3 f-s-16"
                        >
                          <i className="flag-icon flag-icon-us" />
                          English
                        </Link>
                        <Link
                          to="/app-profile"
                          className="text-muted pr-3 f-s-16"
                        >
                          <i className="flag-icon flag-icon-fr" />
                          French
                        </Link>
                        <Link
                          to="/app-profile"
                          className="text-muted pr-3 f-s-16"
                        >
                          <i className="flag-icon flag-icon-bd" />
                          Bangla
                        </Link>
                      </div> */}
                      <div className="profile-personal-info">
                        <h4 className="text-primary mb-4">
                          Personal Information
                        </h4>
                        <div className="row mb-2">
                          <div className="col-3">
                            <h5 className="f-w-500">
                              {" "}
                              UID<span className="pull-right">:</span>
                            </h5>
                          </div>
                          <div className="col-9">
                            <span>{uid}</span>
                          </div>
                        </div>
                        <div className="row mb-2">
                          <div className="col-3">
                            <h5 className="f-w-500">
                              {" "}
                              Name<span className="pull-right">:</span>
                            </h5>
                          </div>
                          <div className="col-9">
                            <span>{fullname}</span>
                          </div>
                        </div>
                        <div className="row mb-2">
                          <div className="col-3">
                            <h5 className="f-w-500">
                              Email<span className="pull-right">:</span>
                            </h5>
                          </div>
                          <div className="col-9">
                            <span>{email || "N/A"}</span>
                          </div>
                        </div>
                        <div className="row mb-2">
                          <div className="col-3">
                            <h5 className="f-w-500">
                              {" "}
                              Phone<span className="pull-right">:</span>
                            </h5>
                          </div>
                          <div className="col-9">
                            <span>{phone || "N/A"}</span>
                          </div>
                        </div>
                        <div className="row mb-2">
                          <div className="col-3">
                            <h5 className="f-w-500">
                              Pilot City<span className="pull-right">:</span>
                            </h5>
                          </div>
                          <div className="col-9">
                            <span>
                              {pilotCity
                                ? pilotCity.name +
                                ", " +
                                pilotCity.country +
                                " " +
                                pilotCity.country_code
                                : "N/A"}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      id="profile-settings"
                      className={`tab-pane fade ${activeToggle === "setting" ? "active show" : ""
                        }`}
                    >
                      <div className="pt-3">
                        <div className="settings-form">
                          <h4 className="text-primary">Account Setting</h4>
                          <form onSubmit={(e) => e.preventDefault()}>
                            <div className="form-row">
                              <div className="form-group col-md-6">
                                <label>Email</label>
                                <input
                                  type="email"
                                  placeholder="Email"
                                  className="form-control"
                                />
                              </div>
                              <div className="form-group col-md-6">
                                <label>Password</label>
                                <input
                                  type="password"
                                  placeholder="Password"
                                  className="form-control"
                                />
                              </div>
                            </div>
                            <div className="form-group">
                              <label>Address</label>
                              <input
                                type="text"
                                placeholder="1234 Main St"
                                className="form-control"
                              />
                            </div>
                            <div className="form-group">
                              <label>Address 2</label>
                              <input
                                type="text"
                                placeholder="Apartment, studio, or floor"
                                className="form-control"
                              />
                            </div>
                            <div className="form-row">
                              <div className="form-group col-md-6">
                                <label>City</label>
                                <input type="text" className="form-control" />
                              </div>
                              <div className="form-group col-md-4">
                                <label>State</label>
                                <select
                                  className="form-control"
                                  id="inputState"
                                  defaultValue="option-1"
                                >
                                  <option value="option-1">Choose...</option>
                                  <option value="option-2">Option 1</option>
                                  <option value="option-3">Option 2</option>
                                  <option value="option-4">Option 3</option>
                                </select>
                              </div>
                              <div className="form-group col-md-2">
                                <label>Zip</label>
                                <input type="text" className="form-control" />
                              </div>
                            </div>
                            <div className="form-group">
                              <div className="custom-control custom-checkbox">
                                <input
                                  type="checkbox"
                                  className="custom-control-input"
                                  id="gridCheck"
                                />
                                <label
                                  className="custom-control-label"
                                  htmlFor="gridCheck"
                                >
                                  Check me out
                                </label>
                              </div>
                            </div>
                            <button className="btn btn-primary" type="submit">
                              Sign in
                            </button>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default AppProfile;
