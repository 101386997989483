import React, { useEffect, useState } from "react";
import Lottie from "lottie-react";
import success from "../../forms/donations/success.json";

function Success() {
    const [showAnimation, setShowAnimation] = useState(false);

    useEffect(() => {
        setTimeout(() => {
            setShowAnimation(true);
        }, 100);
    }, []);

    return (
        <div className="row" style={{ justifyContent: "center" }}>
            <div className="col-lg-12"
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                {showAnimation ? (
                    <Lottie
                        style={{ width: "100px", height: "100px" }}
                        animationData={success}
                        loop={false}
                    />
                ) : null}
            </div>
            <h4 className="fs-20 mt-3" style={{ alignItems: "center" }}>Success</h4>
            <div className="">
                <p>Thank you for your feedback, your feedback has been successfully done</p>
            </div>
        </div>
    );
}

export default Success;
