import { LIST_TOKEN_ACTIVITIES_ACTION } from "./TokenActivityTypes";

import {
  getTokenActivities as getTokenActivitiesService,
  getMyTokenActivities as getMyTokenActivitiesService,
} from "../../services/TokenActivitiesService";

export function getTokenActivitiesAction() {
  return (dispatch) => {
    getTokenActivitiesService()
      .then((res) => {
        if (res.data.status == 200) {
          const { status, message, ...activites } = res.data;
          dispatch(confirmGetTokenActivitiesAction(activites));
        }
      })
      .catch((err) => {});
  };
}

export function getMyTokenActivitiesAction(userId) {
  return (dispatch) => {
    getMyTokenActivitiesService(userId)
      .then((res) => {
        if (res.data.status == 200) {
          const { status, message, ...activites } = res.data;
          dispatch(confirmGetTokenActivitiesAction(activites));
        }
      })
      .catch((err) => {});
  };
}

export function confirmGetTokenActivitiesAction(payload) {
  return {
    type: LIST_TOKEN_ACTIVITIES_ACTION,
    payload,
  };
}
