import { listErrors as listErrorsService } from "../../services/ErrorService";
import { ERROR_LIST_ACTION } from "./ErrorTypes";

export function errorList() {
  return (dispatch) => {
    listErrorsService()
      .then((res) => {
        if (res.data.status == 200) {
          dispatch(errorListConfirm(res.data.errorLogs));
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
}

export function errorListConfirm(payload) {
  return {
    type: ERROR_LIST_ACTION,
    payload,
  };
}
