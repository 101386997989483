import { lazy, Suspense, useEffect } from "react";

/// Components
import Index from "./jsx";
import { connect } from "react-redux";
import { Route, Switch, withRouter } from "react-router-dom";
import { isAuthenticated, isLocked } from "./store/selectors/AuthSelectors";
/// Style
import "./vendor/swiper/swiper-bundle.css";
import "./vendor/bootstrap-select/dist/css/bootstrap-select.min.css";
import "./css/style.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Lottie from "react-lottie-player";
import Preloader from "./images/preloader.json";

const Login = lazy(() => {
  return new Promise((resolve) => {
    setTimeout(() => resolve(import("./jsx/pages/Login")), 500);
  });
});

// const Login = lazy(() => {
//   return new Promise((resolve) => {
//     setTimeout(() => resolve(import("./jsx/pages/DamusLogin")), 500);
//   });
// });

const LoginEmail = lazy(() => {
  return new Promise((resolve) => {
    setTimeout(() => resolve(import("./jsx/pages/LoginEmail")), 500);
  });
});

const LockScreen = lazy(() => {
  return new Promise((resolve) => {
    setTimeout(() => resolve(import("./jsx/pages/LockScreen")), 500);
  });
});

function App(props) {
  useEffect(() => {
    if (props.isLocked && props.history.location.pathname !== "/lock") {
      props.history.replace("/lock");
    }
  }, [props.isLocked]);

  const paths = ["", "/", undefined];

  useEffect(() => {
    if (
      paths.includes(props.history.location.pathname) &&
      props.isAuthenticated
    ) {
      props.history.replace("/dashboard");
    } else if (paths.includes(props.history.location.pathname)) {
      props.history.replace("/login");
    }
  }, [props.isAuthenticated]);

  let routes = (
    <Switch>
      <Route path="/Login" component={Login} />
      <Route path="/ngo-login" component={LoginEmail} />
      <Route path="/lock" component={LockScreen} />
    </Switch>
  );

  if (props.isAuthenticated) {
    return (
      <>
        <Suspense
          fallback={
            <div
              id="preloader"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Lottie
                loop
                animationData={Preloader}
                play
                style={{
                  width: 150,
                  height: 150,
                }}
              />
            </div>
          }
        >
          <Index />
          <ToastContainer position="top-left" theme="dark" />
        </Suspense>
      </>
    );
  } else {
    return (
      <div className="vh-100">
        <Suspense
          fallback={
            <div
              id="preloader"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Lottie
                loop
                animationData={Preloader}
                play
                style={{
                  width: 150,
                  height: 150,
                }}
              />
            </div>
          }
        >
          {routes}
          <ToastContainer position="top-left" theme="dark" />
        </Suspense>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isAuthenticated: isAuthenticated(state),
    isLocked: isLocked(state),
  };
};

export default withRouter(connect(mapStateToProps)(App));
