import {
    listProducts as listProductService,
    createProducts as createProductService,
    updateProducts as updateProductService,
    removeProducts as removeProductService,
} from "../../services/ProductService";

import {
    CREATE_PRODUCT_ACTION,
    DELETE_PRODUCT_ACTION,
    LIST_PRODUCT_ACTION,
    UPDATE_PRODUCT_ACTION,
} from "./ProductTypes";

export function productList(userId) {
    return (dispatch) =>
        listProductService(userId)
            .then((res) => {
                if (res.data.status === 200) {
                    dispatch(
                        listProductConfirm({
                            list: res.data.products,
                        })
                    );
                }
            })
            .catch((err) => {
                console.log(err);
            });
}


export function listProductConfirm(payload) {
    return {
        type: LIST_PRODUCT_ACTION,
        payload,
    };
}

export function createProducts(formdata) {
    return (dispatch) =>
        createProductService(formdata)
            .then((res) => {
                if (res.data.status === 200) {
                    dispatch(
                        createProductConfirm(res.data.newProduct)
                    );
                }
            })
            .catch((err) => {
                console.log(err);
            });
}

export function createProductConfirm(payload) {
    return {
        type: CREATE_PRODUCT_ACTION,
        payload,
    };
}




export function updateProducts(data, productId) {
    return (dispatch) =>
        updateProductService(productId, data)
            .then((res) => {
                if (res.data.status === 200) {
                    dispatch(
                        updateProductConfirm(res.data.updatedProduct)
                    );
                }
            })
            .catch((err) => {
                console.log(err);
            });
}

export function updateProductConfirm(payload) {
    return {
        type: UPDATE_PRODUCT_ACTION,
        payload
    };
}

export function removeProducts(productId) {
    return (dispatch) =>
        removeProductService(productId)
            .then((res) => {
                if (res.data.status === 200) {
                    dispatch(removeProductConfirm(productId));
                }
            })
            .catch((err) => {
                console.log(err);
            });
}

export function removeProductConfirm(payload) {
    return {
        type: DELETE_PRODUCT_ACTION,
        payload,
    };
}
