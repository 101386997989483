import React from "react";
import Switch from "react-switch";
import { Row, Col, Button } from "react-bootstrap";
import DropZone from "../../../partials/DropZone";

function EventForm(props) {
  const {
    handleChangeEvent,
    errors,
    event,
    onAddOption,
    onRemoveOption,
    handleChangeOption,
  } = props;

  return (
    <div className="form-group">
      <h3>Event</h3>
      <label className="text-label">Question*</label>
      <input
        value={event ? event.title : ""}
        type="text"
        name="name"
        className={
          errors.cEvent && errors.cEvent.title
            ? "form-control border-danger"
            : "form-control"
        }
        placeholder="e.g. Which team wins Football world cup..."
        required
        onChange={(e) => handleChangeEvent("title", e.target.value)}
      />
      <small className="text-danger">
        {errors.cEvent && errors.cEvent.title}
      </small>
      <Row className="align-items-center mt-2">
        <Col>
          <h6>Options</h6>
          <small>Add options for the event</small>
          {errors.option ? (
            <>
              <br />
              <small className="text-danger">{errors.option}</small>
            </>
          ) : null}
        </Col>
        <Button
          variant={errors.option ? "danger light" : "primary light"}
          onClick={() => {
            onAddOption();
          }}
          className="mr-1"
        >
          + Add Option
        </Button>
      </Row>
      <Col className="mt-2">
        {event && event.options &&
          event.options.map((option, index) => {
            let error = null;
            if (errors.options) {
              error = errors.options.find(
                (bene) => bene.optionKey == index
              );
            }
            return (
              <Row style={{ alignItems: "center" }}>
                <div className="col-lg-6 mb-2">
                  <div className="form-group">
                    <label className="text-label">Option {index + 1}</label>
                    <input
                      value={option || ""}
                      type="text"
                      name="name"
                      className={
                        error && error.title
                          ? "form-control border-danger"
                          : "form-control"
                      }
                      placeholder="Write something..."
                      required
                      onChange={(e) =>
                        handleChangeOption(index, e.target.value)
                      }
                    />
                    <small className="text-danger">
                      {error && error.title ? error.title : null}
                    </small>
                  </div>
                </div>
                <Button
                  variant={"danger light"}
                  onClick={() => {
                    onRemoveOption(index);
                  }}
                  className="mr-1"
                >
                  <i className="flaticon-381-trash-1"></i>
                </Button>
              </Row>
            );
          })}
      </Col>
    </div>
  );
}

export default EventForm;
