import React from "react";
import Lottie from "react-lottie-player";
import Preloader from "../../../images/preloader.json";

const Loading = (props) => {
  return (
    <div
      id="preloader"
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        position: "absolute",
        zIndex: 1000,
        backgroundColor: props.noBackdrop
          ? "rgba(0,0,0,0)"
          : "rgba(0,0,0, 0.3)",
      }}
    >
      <div style={{ padding: 20, borderRadius: 10, backgroundColor: "white" }}>
        <Lottie
          loop
          animationData={Preloader}
          play
          style={{
            width: 150,
            height: 150,
          }}
        />
      </div>
    </div>
  );
};

export default Loading;
