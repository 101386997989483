import { isAuthenticated } from "./AuthSelectors";

export function getTreeDonationPreview(state) {
  if (isAuthenticated(state)) {
    return state.donations.preview;
  } else return null;
}

export function getTreesList(state) {
  if (isAuthenticated(state)) {
    return state.donations.trees;
  } else return [];
}

export function getPondsList(state) {
  if (isAuthenticated(state)) {
    return state.donations.ponds || [];
  } else return [];
}

export function getCollectionsList(state) {
  if (isAuthenticated(state)) {
    return state.donations.collections;
  } else return [];
}

export function getTreeDonationList(state) {
  if (isAuthenticated(state)) {
    return state.donations.list;
  } else return [];
}

export function getPondDonationList(state) {
  if (isAuthenticated(state)) {
    return state.donations.list;
  } else return [];
}
export function getCollectionDonationList(state) {
  if (isAuthenticated(state)) {
    return state.donations.list;
  } else return [];
}

export function getTreeDonationRequests(state) {
  if (isAuthenticated(state)) {
    return state.donations.requests;
  } else return [];
}

export function getPondDonationRequests(state) {
  if (isAuthenticated(state)) {
    return state.donations.requests;
  } else return [];
}

export function getCollectionDonationRequests(state) {
  if (isAuthenticated(state)) {
    return state.donations.requests;
  } else return [];
}

export function getTreePlantationCount(state) {
  if (isAuthenticated(state)) {
    return state.donations.plantationCount;
  } else return [];
}

export function getCollectionsforIndividual(state) {
  if (isAuthenticated(state)) return state.donations.collections || [];
  return [];
}

export function getCollectionsforRegions(state) {
  return state.donations.regionCollection || [];
}
