import React, { useEffect, useState } from "react";
import { Button, Modal as BSModal, Row, Col } from "react-bootstrap";
import Lottie from "lottie-react";
import Loader from "../../../../src/jsx/loader.json";

const Modal = (props) => {
  const {
    open,
    title,
    children,
    yesBtn,
    showLoader,
    noBtn,
    onClose,
    data,
    submitWithData,
    modalSize,
    footerCaption,
    btnContainerStyle,
    onlyCancel,
  } = props;
  const [isVisible, setIsVisible] = useState(false);
  const [isReady, setIsReady] = useState(false);

  useEffect(() => {
    if (!isReady) {
      setIsReady(true);
    } else {
      setIsVisible(open);
    }
  }, [open]);

  function handleClose(status) {
    setTimeout(() => onClose(status), 300);
    setIsVisible(false);
  }
  return (
    <BSModal
      className="fade"
      show={isVisible}
      onHide={() => handleClose(false)}
      size={modalSize || "lg"}
    >
      <BSModal.Header>
        <h4 className="text-black">{title}</h4>
        <Button variant="" className="close" onClick={() => handleClose(false)}>
          <span>&times;</span>
        </Button>
      </BSModal.Header>
      <BSModal.Body>{children}</BSModal.Body>
      <BSModal.Footer>
        {onlyCancel && noBtn ? (
          <Button onClick={noBtn.onTap} variant="danger light">
            {noBtn.title}
          </Button>
        ) : (
          <Row
            style={{
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
            }}
          >
            <Col>{footerCaption ? footerCaption : null}</Col>
            <Row style={btnContainerStyle}>
              {noBtn ? (
                <Button onClick={noBtn.onTap} variant="danger light">
                  {noBtn.title}
                </Button>
              ) : null}
              {yesBtn && showLoader == false ? (
                <Button
                  className="ml-1"
                  variant="primary light"
                  onClick={
                    submitWithData
                      ? () => yesBtn.onTap(data)
                      : () => yesBtn.onTap()
                  }
                >
                  {yesBtn.title}
                </Button>
              ) : yesBtn && showLoader == true ? (
                <Lottie
                  className="ml-3"
                  animationData={Loader}
                  loop={true}
                  style={{
                    width: 50,
                    height: 50,
                  }}
                />
              ) : yesBtn ? (
                <Button
                  className="ml-1"
                  variant="primary light"
                  onClick={
                    submitWithData
                      ? () => yesBtn.onTap(data)
                      : () => yesBtn.onTap()
                  }
                >
                  {yesBtn.title}
                </Button>
              ) : null}
            </Row>
          </Row>
        )}
      </BSModal.Footer>
    </BSModal>
  );
};

export default Modal;
