import React from "react";
import GasEST from "./GasEST";
import TransferDir from "./TransferDir";

function TransferForm(props) {
  const { handleChangeForm, to, amount, errors } = props;

  return (
    <section>
      {to ? <TransferDir to={to} /> : null}
      <div className="row">
        <div className="col-lg-12 mb-2">
          <div className="form-group">
            <label className="text-label">Wallet Address*</label>
            <input
              value={to}
              type="text"
              name="to"
              className={
                errors.to ? "form-control border-danger" : "form-control"
              }
              placeholder="Wallet Address"
              required
              onChange={(e) => handleChangeForm("to", e.target.value)}
            />
            {errors.to ? (
              <small className="text-danger">{errors.to}</small>
            ) : null}
          </div>
        </div>
        {to ? (
          <div className="col-lg-12 mb-2">
            <div className="form-group">
              <label className="text-label">Amount*</label>
              <input
                value={amount}
                type="number"
                name="amount"
                className={
                  errors.amount ? "form-control border-danger" : "form-control"
                }
                placeholder="Amount (LET)"
                required
                onChange={(e) => handleChangeForm("amount", Number(e.target.value))}
              />
              {errors.amount ? (
                <small className="text-danger">{errors.amount}</small>
              ) : null}
            </div>
          </div>
        ) : null}
      </div>
      {to ? <GasEST method="transfer" to={to} amount={amount || 0} /> : null}
    </section>
  );
}

export default TransferForm;
