import React from "react";
import Switch from "react-switch";
import { Row, Col, Button } from "react-bootstrap";
import DropZone from "../../../partials/DropZone";

const BENEFITS = ["LogiE Tokens", "Ethereum", "Coupon", "Other"];

function RewardForm(props) {
  const {
    handleChange,
    errors,
    formData,
    onAddBenefit,
    onRemoveBenefit,
    handleChangeBenefit,
    coupons,
  } = props;

  return (
    <div className="form-group">
      <h3>Reward</h3>
      <label className="text-label">Title*</label>
      <input
        value={formData.rewardTitle}
        type="text"
        name="name"
        className={
          errors.rewardTitle ? "form-control border-danger" : "form-control"
        }
        placeholder="Title"
        required
        onChange={(e) => handleChange("rewardTitle", e.target.value)}
      />
      <small className="text-danger">{errors.rewardTitle}</small>
      <div className="form-group">
        <label className="text-label">Description*</label>
        <textarea
          value={formData.rewardDescription}
          type="text"
          name="description"
          style={{ minHeight: "150px", maxHeight: "150px" }}
          className={
            errors.rewardDescription
              ? "form-control border-danger"
              : "form-control"
          }
          placeholder="Description"
          required
          onChange={(e) => handleChange("rewardDescription", e.target.value)}
        ></textarea>
        <small className="text-danger">{errors.rewardDescription}</small>
      </div>
      <div className="col-lg-12 mb-2">
        <Row className="align-items-center mt-4">
          <Col>
            <h6>Benefits</h6>
            <small>Add benefits to the reward</small>
            {errors.benefits ? (
              <>
                <br />
                <small className="text-danger">{errors.benefits}</small>
              </>
            ) : null}
          </Col>
          <Button
            variant={errors.benefits ? "danger light" : "primary light"}
            onClick={() => {
              onAddBenefit();
            }}
            className="mr-1"
          >
            + Add Benefit
          </Button>
        </Row>
        <div className="row mt-4">
          {formData.benefits && formData.benefits.length
            ? formData.benefits.map((benefit, index) => {
              let error = null;
              if (errors.benefit) {
                error = errors.benefit.find(
                  (bene) => bene.benefitKey == index
                );
              }
              return (
                <div
                  className="card ml-2"
                  style={{
                    width: "18rem",
                    borderWidth: 1,
                    borderColor: "darkgreen",
                  }}
                >
                  <div className="card-body">
                    <div className="row align-items-center justify-content-between">
                      <h5 class="card-title">{benefit.type || "Benefit"}</h5>
                      <Button
                        variant="danger light"
                        onClick={() => onRemoveBenefit(index)}
                        disabled={false}
                      >
                        <i className="flaticon-381-trash-1"></i>
                      </Button>
                    </div>
                    <div className="form-group">
                      <label className="text-label">Type*</label>
                      <select
                        value={benefit.type}
                        defaultValue={""}
                        className={
                          error && error.type
                            ? "form-control custom-select border-danger"
                            : "form-control custom-select"
                        }
                        onChange={(e) =>
                          handleChangeBenefit("type", e.target.value, index)
                        }
                      >
                        <option value={""}>Select</option>
                        {BENEFITS.map((bnfit, index) => (
                          <option key={index} value={bnfit}>
                            {bnfit}
                          </option>
                        ))}
                      </select>
                      <small className="text-danger">
                        {error && error.type ? error.type : null}
                      </small>
                    </div>
                    {benefit.type == "Other" ? (
                      <div className="form-group">
                        <label className="text-label">Title*</label>
                        <input
                          value={benefit.title}
                          type="text"
                          name="name"
                          className={
                            error && error.title
                              ? "form-control border-danger"
                              : "form-control"
                          }
                          placeholder="Title"
                          required
                          onChange={(e) =>
                            handleChangeBenefit(
                              "title",
                              e.target.value,
                              index
                            )
                          }
                        />
                        <small className="text-danger">
                          {error && error.title ? error.title : null}
                        </small>
                      </div>
                    ) : null}
                    {benefit.type == "Other" ? (
                      <div className="form-group">
                        <label className="text-label">Description</label>
                        <input
                          value={benefit.description}
                          type="text"
                          name="name"
                          className={
                            error && error.description
                              ? "form-control border-danger"
                              : "form-control"
                          }
                          placeholder="Description"
                          required
                          onChange={(e) =>
                            handleChangeBenefit(
                              "description",
                              e.target.value,
                              index
                            )
                          }
                        />
                        <small className="text-danger">
                          {error && error.description
                            ? error.description
                            : null}
                        </small>
                      </div>
                    ) : null}
                    {benefit.type &&
                      benefit.type !== "Other" &&
                      benefit.type !== "Coupon" ? (
                      <div className="form-group">
                        <label className="text-label">Amount*</label>
                        <input
                          value={benefit.amount}
                          type="number"
                          name="name"
                          className={
                            error && error.amount
                              ? "form-control border-danger"
                              : "form-control"
                          }
                          placeholder="Amount"
                          required
                          onChange={(e) => {
                            const amount = parseFloat(e.target.value);
                            if (amount >= 0) {
                              handleChangeBenefit("amount", amount, index);
                            }
                          }}
                        />
                        <small className="text-danger">
                          {error && error.amount ? error.amount : null}
                        </small>
                      </div>
                    ) : null}
                    {benefit.type == "Coupon" ? (
                      <div className="form-group">
                        <label className="text-label">Coupon*</label>
                        <select
                          value={benefit.couponId}
                          defaultValue={""}
                          className={
                            error && error.couponId
                              ? "form-control custom-select border-danger"
                              : "form-control custom-select"
                          }
                          onChange={(e) =>
                            handleChangeBenefit(
                              "couponId",
                              e.target.value,
                              index
                            )
                          }
                        >
                          <option value={""}>Select</option>
                          {coupons.map((coupon, index) => (
                            <option key={index} value={coupon.id}>
                              {coupon.title}
                            </option>
                          ))}
                        </select>
                        <small className="text-danger">
                          {error && error.couponId ? error.couponId : null}
                        </small>
                      </div>
                    ) : null}
                  </div>
                </div>
              );
            })
            : null}
        </div>
      </div>
    </div>
  );
}

export default RewardForm;
