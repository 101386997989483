import React, { useState } from "react";
import { Button, Spinner } from "react-bootstrap";
import { getTreeCodeAsync } from "../../../../../../src/services/DonationService"

const TextItem = (props) => {
    const {
        treeKey,
        data,
        handleChange,
        dialog,
        defaultDialogButtons,
        errors = {},
    } = props;

    const [isGeoLoading, setIsGeoLoading] = useState(false);

    async function getTreeCode() {
        try {
            if ("geolocation" in navigator) {
                navigator.geolocation.getCurrentPosition(
                    async (geo) => {
                        const coords = {
                            lat: geo.coords.latitude,
                            lng: geo.coords.longitude,
                        };
                        const words = await getTreeCodeAsync(coords);
                        handleChange("lat", coords.lat, treeKey);
                        handleChange("lng", coords.lng, treeKey);
                        handleChange("words", words, treeKey);
                        setIsGeoLoading(false);
                    },
                    (err) => {
                        setIsGeoLoading(false);
                    }
                );
            } else {
                setIsGeoLoading(false);
                dialog(
                    "Geolocation Not Available!",
                    "The Geo Location is disabled on your browser. You've to manually enable the geolocation feature from your browser's settings",
                    defaultDialogButtons({
                        noBtn: null,
                        yesBtn: { title: "Okay" },
                    })
                );
            }
        } catch (error) {
            setIsGeoLoading(false);
        }
    }
    return (
        <div className="mb-4 from-control" style={{
            width: "100%",
            height: "130px",
            border: " 1px dashed",
            borderRadius: 10,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            padding: "10px"
        }}>
            <div className="float-left">
                <h6 className="d-flex">Tree Location (Code)<small className="text-danger">*</small></h6>
                {
                    data.words ? (
                        <strong className="" style={{ color: "green" }}>
                            {data.words}
                        </strong>
                    ) : (
                        <small className="">
                            Please stand facing the plantation location where
                            you've planted the tree. This is required to fetch the
                            exact location of planted tree.
                        </small>
                    )
                }
            </div>
            <Button
                variant={errors.words ? "danger light" : "primary light"}
                onClick={() => {
                    dialog(
                        "Confirmation!",
                        "Please stand right next to the site where you've planted the tree",
                        defaultDialogButtons({
                            noBtn: { title: "Cancel" },
                            yesBtn: {
                                title: "Confirm",
                                onTap: () => {
                                    setIsGeoLoading(true);
                                    getTreeCode();
                                },
                            },
                        })
                    );
                }}
                disabled={isGeoLoading}
            >
                {isGeoLoading ? (
                    <Spinner animation="grow" size="sm" />
                ) : (
                    <i className="flaticon-381-location-4" />
                )}
            </Button>
        </div>
    );
}

export default TextItem;
