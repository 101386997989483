import api from "./AxiosInstance";

export function listUsers(userId, roleId) {
  return api.get("users/list/" + userId + "/" + roleId);
}

export function matchByUsername(userName) {
  return api.get("users/match/by/u/" + userName);
}

export function matchByEmail(email) {
  return api.get("users/match/by/e/" + email);
}

export function createSubUser(userId, data) {
  return api.post("users/by/" + userId, data);
}

export function updateProfile(userId, data) {
  return api.put("users/update/" + userId, data);
}

export function removeUser(userId) {
  return api.delete("users/" + userId);
}
