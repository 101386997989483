import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import "./forms/donations/TreePreview.css";
import "../../../css/screen-transition.css";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getPilotCity,
  getUserId,
} from "../../../store/selectors/AuthSelectors";
import Loading from "../partials/Loading";
import {
  addCollectionPayment,
  getCollectionById,
} from "../../../services/DonationService";
import TNLogo from "../../../images/logo.png";
import Dialog, {
  defaultDialogButtons,
  defaultDialogProps,
} from "../partials/Dialog";
import Modal from "../partials/Modal";
import { AiOutlinePlus } from "react-icons/ai";
import { AiOutlineMinus } from "react-icons/ai";
import { TbTargetArrow } from "react-icons/tb";
import ExpiryCounter from "./partials/contestPreview/ExpiryCounter";
import PaymentForm from "./forms/donations/UploadPaymentCollection";
import { addTreeDonation } from "../../../store/actions/DonationActions";

const CollectionPayment = (props) => {
  let { collectionId } = useParams();
  const history = useHistory();
  const [collection, setCollection] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isFormLoading, setIsFormLoading] = useState(false);
  const [dialogProps, setDialogProps] = useState(defaultDialogProps);
  const [formErrors, setFormErrors] = useState({});
  const [formData, setFormData] = useState({});
  const [number, setNumber] = useState(1);
  const [fadeAnim, setFadeAnim] = useState({ leftContainer: "fade-in-left" });
  const dispatch = useDispatch();
  const userId = useSelector(getUserId);
  const pilotCity = useSelector(getPilotCity);

  function handleAnim(key, value) {
    setFadeAnim((pre) => ({ ...pre, [key]: value }));
  }

  function percentage(min, total) {
    return ((min / total) * 100).toFixed(0);
  }

  function handleAnimControl() {
    handleAnim("leftContainer", "fade-out-left");
  }

  const defaultForm = {
    open: false,
    title: "",
    data: {},
    onClose: () => {},
  };

  const [formProps, setFormProps] = useState(defaultForm);

  useEffect(() => {
    if (collectionId) {
      getDonation();
    }
  }, [collectionId]);

  function handleChange(key, value) {
    setFormData((pre) => ({ ...pre, [key]: value }));
    setFormErrors((pre) => ({ ...pre, [key]: "" }));
  }

  function handleCollectionDonation(data) {
    addCollectionPayment({ ...data })
      .then((res) => {
        if (res.data.status == 200) {
          dispatch(addTreeDonation(res.data.newDonation));
          setFormProps({
            open: true,
            title: "Confirm your payment",
            donationId: res.data.newDonation.id,
            yesBtn: null,
            noBtn: {
              title: "Not Now",
              onTap: () => {
                setFormErrors();
                setFormProps(defaultForm);
                history.push("/my-donations");
              },
            },
            onClose: () => {
              setFormProps(defaultForm);
            },
          });
          // setTrans(res.data.transEst);
        }
      })
      .catch((err) => {
        // console.log(err);
      });
  }

  function getDonation() {
    getCollectionById(collectionId)
      .then((res) => {
        setIsLoading(false);
        if (res.data.status === 200) {
          setCollection(res.data.collection);
        }
      })
      .catch((err) => {
        setIsLoading(false);
      });
  }

  function plusFunction() {
    setNumber(number + 1);
  }

  function minusFunction() {
    setNumber(number - 1);
  }

  function dialog(
    title = "",
    text = "",
    buttons = defaultDialogButtons(),
    onClose = () => setDialogProps(defaultDialogProps)
  ) {
    setDialogProps({ open: true, title, text, ...buttons, onClose });
  }

  if (isLoading) {
    return <Loading />;
  } else if (collection) {
    const banner = collection.banner.link;

    return (
      <div className="row">
        <div className="col-lg-12">
          <Button
            style={{ marginBottom: "20px" }}
            onClick={() => {
              handleAnimControl();
              history.goBack();
            }}
          >
            <AiOutlineArrowLeft className="mr-1" />
            {collection.name}
          </Button>
        </div>
        <div
          className={
            "col-md-12 col-sm-12 col-xs-12 mt-2 " + fadeAnim.leftContainer
          }
        >
          <div className="card">
            <div className="card-body p-0">
              <div className="row">
                <div
                  className="col-lg-6 text-center"
                  style={{
                    backgroundImage: `url(${banner})`,
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                    display: "flex",
                    alignItems: "end",
                    justifyContent: "center",
                  }}
                ></div>
                <div className="col-lg-6 p-5">
                  <div className="row d-flex">
                    <div className="col-lg-6">
                      <p style={{ color: "#eb8153" }}>
                        <small>
                          <ExpiryCounter expiryDate={collection.expiredAt} />
                        </small>
                      </p>
                    </div>
                  </div>
                  <div
                    className="row d-flex"
                    style={{ marginTop: "-20px", alignItems: "center" }}
                  >
                    <div className="col-lg-6">
                      <h5>{collection.name}</h5>
                    </div>
                    <div className="col-lg-6">
                      <p style={{ textAlign: "end" }}>
                        <b>
                          {collection.unitPrice + " "}
                          <small>
                            <b>{collection.currency_code}</b>
                          </small>
                        </b>
                      </p>
                    </div>
                  </div>
                  <p style={{ marginTop: "-13px" }}>
                    <small>{collection.description}</small>
                  </p>
                  <div className="row mt-3">
                    <div
                      className="col-lg-12"
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        color: "#eb8153",
                      }}
                    >
                      <TbTargetArrow size={25} className="mr-1" />
                      <small>
                        <b>
                          {"Progress" +
                            `(${
                              percentage(
                                collection.transactions.length,
                                collection.target
                              ) + " %"
                            })`}
                        </b>
                      </small>
                    </div>
                  </div>
                  <div
                    className="row d-flex mt-2"
                    style={{ marginTop: "-10px", alignItems: "center" }}
                  >
                    <div className="col-lg-6">
                      <h6>Item</h6>
                    </div>
                    <div className="col-lg-6">
                      <p style={{ textAlign: "end" }}>
                        <b>
                          {collection.unitPrice +
                            " " +
                            collection.currency_code}
                        </b>
                      </p>
                    </div>
                  </div>
                  <div
                    className="row d-flex"
                    style={{ marginTop: "-10px", alignItems: "center" }}
                  >
                    <div className="col-lg-6">
                      <h6>Quantity</h6>
                    </div>
                    <div className="col-lg-6">
                      <p style={{ textAlign: "end" }}>
                        <b>{"×" + number}</b>
                      </p>
                    </div>
                  </div>
                  <hr />
                  <div
                    className="row d-flex"
                    style={{ marginTop: "-10px", alignItems: "center" }}
                  >
                    <div className="col-lg-6">
                      <h6>Total</h6>
                    </div>
                    <div className="col-lg-6">
                      <p style={{ textAlign: "end" }}>
                        <b>
                          {collection.unitPrice * number +
                            " " +
                            collection.currency_code}
                        </b>
                      </p>
                    </div>
                  </div>
                  <div className="row d-flex" style={{ alignItems: "center" }}>
                    <div className="col-lg-6">
                      <Button
                        className="info light"
                        style={{
                          float: "left",
                          height: "30px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        {number <
                        collection.target - collection.transactions.length ? (
                          <AiOutlinePlus
                            size={15}
                            className="mr-2"
                            onClick={() => plusFunction()}
                          />
                        ) : (
                          <AiOutlinePlus
                            size={15}
                            className="mr-2"
                            style={{ color: "#eb8153" }}
                          />
                        )}
                        {number}
                        {number > 1 ? (
                          <AiOutlineMinus
                            size={15}
                            className="ml-2"
                            onClick={() => minusFunction()}
                          />
                        ) : (
                          <AiOutlineMinus
                            size={15}
                            className="ml-2"
                            style={{ color: "#eb8153" }}
                          />
                        )}
                      </Button>
                    </div>
                    <div
                      className="col-lg-6"
                      style={{ display: "flex", justifyContent: "end" }}
                    >
                      <Button
                        onClick={() => {
                          handleCollectionDonation({
                            collectionId: +collectionId,
                            userId,
                            cityId: pilotCity.id,
                            qty: number,
                          });
                        }}
                        className="info light btn-link"
                      >
                        Donate
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {isLoading ? <Loading /> : null}
        <Dialog {...dialogProps} />
        <Modal
          {...formProps}
          data={formData}
          submitWithData={true}
          modalSize="md"
        >
          <PaymentForm
            {...formProps}
            isLoading={isFormLoading}
            handleChange={handleChange}
          />
        </Modal>
      </div>
    );
  } else {
    return (
      <div className="col-lg-12 text-center mt-3">
        <img src={TNLogo} width="70px" height="70px" />
        <h3 className="mt-1">Record Not Found</h3>
        <p></p>
      </div>
    );
  }
};

export default CollectionPayment;
