import React from "react";
import { Button } from "react-bootstrap";
import "./TreeDetail.css";
import { TbTrees } from "react-icons/tb";
import { GiTreeGrowth } from "react-icons/gi";
import { SiGumtree } from "react-icons/si";
import DropZone from "../../../partials/DropZone";
import TextItem from "./TextItem";
import { AiOutlineArrowLeft } from "react-icons/ai";
import Loader from "../../../../loader.json";
import Lottie from "lottie-react";

const TreeDetail = (props) => {
  const {
    dialog,
    showLoader,
    defaultDialogButtons,
    handleChange,
    data,
    handleSelectTree,
    errors,
    onSubmit,
    treeData,
  } = props;

  return (
    <div className="row">
      <div className="col-lg-12">
        <Button style={{ marginBottom: "20px" }} onClick={handleSelectTree}>
          <AiOutlineArrowLeft className="mr-1" />
          Select Tree
        </Button>
      </div>
      <div className="col-lg-4 main_contain">
        <div className="tree_contain" style={{ backgroundColor: treeData.bg }}>
          <img src={treeData.url} />
          <p>{treeData.name}</p>
        </div>
      </div>
      <div className="col-lg-4 mt-4 tree_detail">
        {treeData ? (
          <div>
            <div className="">
              <TbTrees style={{ marginRight: "10px" }} size={50} />
              <span>Shape : </span>
              <span>
                <strong>{treeData.form}</strong>
              </span>
            </div>
            <div className="mt-5">
              <GiTreeGrowth style={{ marginRight: "10px" }} size={50} />
              <span>Growth Rate : </span>
              <span>
                <strong>{treeData.growthRate}</strong>
              </span>
            </div>
            <div className="mt-5">
              <SiGumtree style={{ marginRight: "15px" }} size={50} />
              <span>Fall Color : </span>
              <span>
                <strong>{treeData.fallColor}</strong>
              </span>
            </div>
          </div>
        ) : null}
      </div>
      <div className="col-lg-4">
        <div>
          <TextItem
            errors={errors}
            required
            data={data}
            dialog={dialog}
            handleChange={handleChange}
            defaultDialogButtons={defaultDialogButtons}
          />
          {errors.words ? (
            <p style={{ marginTop: -20 }} className="text-danger">
              {errors.words}
            </p>
          ) : null}
        </div>
        <div>
          <DropZone
            errors={errors}
            required
            title="Plantation Image"
            src={data.image ? data.image.uri : null}
            onChange={(file) => handleChange("image", file)}
            onRemove={() => handleChange("image", null)}
            caption="Click to explore the image gallery"
            height="150px"
          />
          {errors.image ? (
            <p style={{ marginTop: -20 }} className="text-danger">
              {errors.image}
            </p>
          ) : null}
        </div>
        {
          showLoader == true ? (
            <div className="float-right">
              <Lottie
                animationData={Loader}
                loop={true}
                style={{
                  width: 50,
                  height: 50,
                }}
              />
            </div>
          ) : <Button className="float-right" onClick={onSubmit}>
            Submit
          </Button>
        }
      </div>
      <div className="col-lg-12">
        <span>Scientifically known as</span>
        <h4>
          <strong>{treeData.scientific}</strong> - '{treeData.name}'
        </h4>
        <p className="mt-4">
          <strong>Environment Tolerance</strong> - {treeData.envTolerance}
        </p>
        <p>
          <strong>Location Tolerance</strong> - {treeData.locationTolerance}
        </p>
        <p>
          <strong>Notes</strong> - {treeData.notes}
        </p>
      </div>
    </div>
  );
};

export default TreeDetail;
