import api from "./AxiosInstance";

export function listHelp(userId) {
  return api.get("tickets/list/" + userId);
}

export function createHelp(data) {
  return api.post("tickets", data);
}

export function updateHelp(data, ticketId) {
  return api.patch("tickets/" + ticketId, data);
}

export function removeHelp(ticketId) {
  return api.delete("tickets/" + ticketId);
}

export function getOneTicketById(ticketId) {
  return api.get("tickets/" + ticketId);
}

export function getTicketMessages(data) {
  return api.post("ticket-messages", data);
}
