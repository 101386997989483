import {
  listCoupons as listCouponsService,
  createCoupon as createCouponService,
  updateCoupon as updateCouponService,
  removeCoupon as removeCouponService,
} from "../../services/CouponService";

import {
  COUPON_LIST_ACTION,
  COUPON_ADD_ACTION,
  COUPON_EDIT_ACTION,
  COUPON_REMOVE_ACTION,
  COUPON_MARK_AS_USED_ACTION,
} from "./CouponTypes";

export function couponList(userId) {
  return (dispatch) => {
    listCouponsService(userId)
      .then((res) => {
        if (res.data.status == 200) {
          dispatch(couponListConfirm(res.data.coupons));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
}

export function createCoupon(data) {
  return (dispatch) =>
    createCouponService({ ...data })
      .then((res) => {
        if (res.data.status === 200) {
          dispatch(
            createCouponConfirm({
              ...data,
              id: res.data.newCouponId,
              usedCount: 0,
              status: "Active",
            })
          );
        }
      })
      .catch((err) => {
        console.log(err);
      });
}

export function updateCoupon(data, couponId) {
  return (dispatch) =>
    updateCouponService(couponId, data)
      .then((res) => {
        if (res.data.status === 200) {
          dispatch(updateCouponConfirm(res.data.updatedCoupon));
        }
      })
      .catch((err) => {
        console.log(err);
      });
}

export function removeCoupon(couponId) {
  return (dispatch) =>
    removeCouponService(couponId)
      .then((res) => {
        if (res.data.status === 200) {
          dispatch(removeCouponConfirm(couponId));
        }
      })
      .catch((err) => {
        console.log(err);
      });
}

export function removeCouponConfirm(payload) {
  return {
    type: COUPON_REMOVE_ACTION,
    payload,
  };
}

export function updateCouponConfirm(payload) {
  return {
    type: COUPON_EDIT_ACTION,
    payload
  };
}

export function createCouponConfirm(payload) {
  return {
    type: COUPON_ADD_ACTION,
    payload,
  };
}

export function couponListConfirm(payload) {
  return {
    type: COUPON_LIST_ACTION,
    payload,
  };
}
