import {
  ALL_TOKEN_ALLOCATIONS,
  ADD_TOKEN_ALLOCATIONS,
  UPDATE_TOKEN_ALLOCATIONS,
  REMOVE_TOKEN_ALLOCATIONS,
} from "./TokenAllocationTypes";

import {
  getAllTokenAllocations as getAllTokenAllocationsService,
  addTokenAllocation as addTokenAllocationService,
  updateTokenAllocation as updateTokenAllocationService,
  removeTokenAllocation as removeTokenAllocationService,
} from "../../services/TokenAllocationService";

export function getTokenAllocationsAction() {
  return (dispatch) => {
    getAllTokenAllocationsService()
      .then((res) => {
        if (res.data.status == 200) {
          const { status, message, ...list } = res.data;
          dispatch(confirmGetTokenAllocationsAction(list));
        }
      })
      .catch((err) => {});
  };
}

export function addTokenAllocationsAction(data, config) {
  return (dispatch) => {
    addTokenAllocationService(data)
      .then((res) => {
        if (res.data.status == 200) {
          const { status, message, ...activites } = res.data;
          dispatch(confirmAddTokenAllocationsAction(activites));
          config.toast.success("Token Allocation Config successfully created!");
          config.history.push("/token-allocations");
        }
        config.setShowLoader(false);
      })
      .catch((err) => {
        config.setShowLoader(false);
        config.toast.error("Something went wrong!");
      });
  };
}

export function updateTokenAllocationsAction(id, data, config) {
  return (dispatch) => {
    updateTokenAllocationService(id, data)
      .then((res) => {
        if (res.data.status == 200) {
          const { status, message, ...activites } = res.data;
          dispatch(confirmUpdateTokenAllocationsAction(activites));
          config.toast.success("Token Allocation Config successfully update!");
          config.setShowLoader(false);
          // config.history.push("/token-allocations");
        }
      })
      .catch((err) => {
        config.setShowLoader(false);
      });
  };
}

export function removeTokenAllocationsAction(tokenAllocationId) {
  return (dispatch) => {
    removeTokenAllocationService(tokenAllocationId)
      .then((res) => {
        if (res.data.status == 200) {
          const { status, message, ...activites } = res.data;
          dispatch(confirmRemoveTokenAllocationsAction(activites));
        }
      })
      .catch((err) => {});
  };
}

export function confirmGetTokenAllocationsAction(payload) {
  return {
    type: ALL_TOKEN_ALLOCATIONS,
    payload,
  };
}

export function confirmAddTokenAllocationsAction(payload) {
  return {
    type: ADD_TOKEN_ALLOCATIONS,
    payload,
  };
}

export function confirmUpdateTokenAllocationsAction(payload) {
  return {
    type: UPDATE_TOKEN_ALLOCATIONS,
    payload,
  };
}

export function confirmRemoveTokenAllocationsAction(payload) {
  return {
    type: REMOVE_TOKEN_ALLOCATIONS,
    payload,
  };
}
