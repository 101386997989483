import api from "./AxiosInstance";

export function listTrees() {
  return api.get("trees/list");
}

export function listCategories(type) {
  return api.get("tree-categories/by/t/" + type);
}

export function listCollection(userId) {
  return api.get("collections/by/u/" + userId);
}

export function createTree(data) {
  return api.post("trees", data);
}

export function updateTree(data, id) {
  return api.patch("trees/" + id, data);
}

export function removeTree(id) {
  return api.delete("trees/" + id);
}

export function getTreeCode(data) {
  return api.post("trees/l/code", data);
}
export function getDonationList(userid) {
  return api.get("donations/by/u/" + userid);
}

export function getDonationById(donationId) {
  return api.get("donations/" + donationId);
}

export function getCollectionById(collectionId) {
  return api.get("collections/" + collectionId);
}

export function getCollectionByRegion(region) {
  return api.get("collections/by/r/" + region);
}

export function getCollectionByIdForIndivi(id) {
  return api.get("collections/for/u/" + id);
}

export function removeCollections(collectionId) {
  return api.delete("collections/" + collectionId);
}

export function approveCollectionById(id, data) {
  return api.put("collections/approve/by/i/" + id, data);
}

export async function getTreeCodeAsync(coords = new Object()) {
  try {
    const res = await api.post("trees/l/code", coords);
    if (res.data.status == 200) {
      return res.data.words;
    } else return null;
  } catch (error) {
    return null;
  }
}

export function createTreeDonation(data) {
  return api.post("tree-donations", data, {
    headers: {
      "Content-Type": "multipart/form-data",
      Accept: "application/json",
    },
  });
}

export function createPondDonation(data) {
  return api.post("tree-donations", data, {
    headers: {
      "Content-Type": "multipart/form-data",
      Accept: "application/json",
    },
  });
}

export function createCollectionDonation(data) {
  return api.put("collections", data, {
    headers: {
      "Content-Type": "multipart/form-data",
      Accept: "application/json",
    },
  });
}

export function editCollectionDonation(data) {
  return api.put("collections/edit", data, {
    headers: {
      "Content-Type": "multipart/form-data",
      Accept: "application/json",
    },
  });
}

export function myPlantationCount(userId) {
  return api.get("tree-donations/c/by/u/" + userId);
}

export function getTreesForVL(data) {
  return api.post("tree-donations/vl", data);
}

export function addCollectionPayment(data) {
  return api.post("donations/collection", data);
}

export function getPaymentIntent(donationId) {
  return api.get("donations/paymentIntent/" + donationId);
}

export function getGroupProjects(userId, collectionId = undefined) {
  if (collectionId) {
    return api.get("collections/group/e/" + userId + "/" + collectionId);
  } else {
    return api.get("collections/group/e/" + userId);
  }
}
