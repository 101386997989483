import React, { Fragment, useEffect, useState } from "react";
import {
  competitionAllList,
  createCompetition,
  updateCompetition,
  removeCompetition,
} from "../../../store/actions/CompetitionActions";
import { Button } from "react-bootstrap";
import { useSelector, useDispatch, connect } from "react-redux";
import FilteringTable from "../table/FilteringTable/FilteringTable";
import { ColumnFilter } from "../table/FilteringTable/ColumnFilter";
import { Badge, Dropdown } from "react-bootstrap";
import Dialog from "../partials/Dialog";
import Modal from "../partials/Modal";
import {
  getMyCompetitions,
  getRequestedCompetitions,
} from "../../../store/selectors/CompetitionSelector";
import {
  getUserId,
  getPilotCity,
  getRoleName,
} from "../../../store/selectors/AuthSelectors";
import CreateContestForm from "./partials/contests/CreateContestForm";


import {
  BENEFITS_PER_REWARD_LIMIT,
  competitionFormValidator,
  OPTIONS_PER_EVENT_LIMIT,
} from "../../../api/validator";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";

const ThreeDots = (
  <svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <rect x="0" y="0" width="24" height="24"></rect>
      <circle fill="#000000" cx="5" cy="12" r="2"></circle>
      <circle fill="#000000" cx="12" cy="12" r="2"></circle>
      <circle fill="#000000" cx="19" cy="12" r="2"></circle>
    </g>
  </svg>
);

const ContestList = (props) => {
  const roleName = useSelector(getRoleName);
  const userId = useSelector(getUserId);
  const pilotCity = useSelector(getPilotCity);
  const history = useHistory();
  const [activeTab, setActiveTab] = useState("My Contests");

  const defaultFormData = {
    userId,
    cityId: pilotCity ? pilotCity.id : null,
    productId: null,
    banner: null,
    title: "",
    description: "",
    reach: "",
    duration: "",
    bord: "",
    target: "",
    type: "",
    rewardTitle: "",
    rewardDescription: "",
    benefits: [],
    cEvent: { title: "", options: [] },
  };


  const dispatch = useDispatch();
  const myContests = useSelector(getMyCompetitions);
  const requestedContests = useSelector(getRequestedCompetitions);
  const [formErrors, setFormErrors] = useState({});
  const [formData, setFormData] = useState(defaultFormData);

  const defaultDialog = {
    open: false,
    title: "",
    text: "",
    onClose: () => { },
  };

  const defaultForm = {
    open: false,
    title: "",
    data: {},
    onClose: () => { },
  };

  const headerBtns = pilotCity
    ? [
      {
        title: "+ New Contest",
        onClick: () => {
          setFormProps({
            open: true,
            title: "New Contest",
            yesBtn: {
              title: "Submit",
              onTap: (data) => submit(data),
            },
            noBtn: {
              title: "Cancel",
              onTap: () => {
                setFormErrors({});
                setFormProps(defaultForm);
                setFormData(defaultFormData);
              },
            },
            onClose: () => {
              setFormErrors({});
              setFormProps(defaultForm);
              setFormData(defaultFormData);
            },
          });
        },
      },
    ]
    : [];

  const tabData =
    roleName == "SuperAdmin"
      ? [
        {
          name: "My Contests",
          onClick: () => setActiveTab("My Contests"),
          badge: myContests.length,
        },
        {
          name: "Pending Requests",
          onClick: () => setActiveTab("Pending Requests"),
          badge: requestedContests.length,
        },
      ]
      : [];

  const [dialogProps, setDialogProps] = useState(defaultDialog);
  const [formProps, setFormProps] = useState(defaultForm);

  const columns = [
    {
      Header: "#",
      Footer: "#",
      accessor: "id",
      Filter: ColumnFilter,
    },
    {
      Header: "Title",
      Footer: "Title",
      accessor: "title",
      Filter: ColumnFilter,
    },
    {
      Header: "Type",
      Footer: "Type",
      accessor: "type",
      Filter: ColumnFilter,
      Cell: (cell) =>
        cell.row.original.bord == "Single" ? (
          <p>
            {cell.row.original.type}
            <br />
            <small>
              <strong>Target:</strong> {cell.row.original.target}
            </small>
          </p>
        ) : (
          cell.row.original.type
        ),
    },
    {
      Header: "Reach",
      Footer: "Reach",
      Filter: ColumnFilter,
      accessor: "reach",
      Cell: (cell) =>
        cell.row.original.reach == "City Wide"
          ? cell.row.original.city.name + " Only"
          : cell.row.original.reach == "Private"
            ? "Only available to your users"
            : cell.row.original.reach == "Global"
              ? "Available in all Pilot Cities"
              : null,
    },
    {
      Header: "Duration",
      Footer: "Duration",
      Filter: ColumnFilter,
      accessor: "duration",
      Cell: (cell) => cell.row.original.duration + "Day(s)",
    },
    {
      Header: "Participants",
      Footer: "Participants",
      Filter: ColumnFilter,
      accessor: "participantsCount",
    },
    {
      Header: "Status",
      Footer: "Status",
      Cell: (cell) => {
        let status = <Badge variant="info light">{cell.row.original.status}</Badge>;
        if (cell.row.original.status === "Not Submitted") {
          status = <Badge variant="light">Not Submitted</Badge>;
        } else if (cell.row.original.status === "Pending Review") {
          status = <Badge variant="info light">Pending Review</Badge>;
        } else if (cell.row.original.status === "Rejected") {
          status = <Badge variant="danger light">Rejected</Badge>;
        } else if (cell.row.original.status == "Approved") {
          status = <Badge variant="success light">Approved</Badge>;
        }
        return status;
      },
      Filter: ColumnFilter,
    },
    {
      Header: "Actions",
      Footer: "Actions",
      Cell: (cell) => {
        let variant = "info light";
        const contest = cell.row.original;
        const status = contest.status;
        if (status === "Not Submitted") variant = "light";
        else if (status === "Pending Review") variant = "info light";
        else if (status === "Rejected") variant = "danger light";
        else if (status === "Approved") variant = "success light";


        return (
          <div>
            {
              roleName == "Individual" ? (
                <Button
                  className="primary light"
                  onClick={() => {
                    history.push("contest-participate/" + contest.id);
                  }}
                >
                  Participate
                </Button>
              ) : <Dropdown>
                <Dropdown.Toggle variant={variant} className="light sharp i-false">
                  {ThreeDots}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item
                    onSelect={() => {
                      history.push("contests-preview", {
                        competitionId: contest.id,
                      });
                    }}
                    variant="danger"
                    className="danger light sharp i-false"
                  >
                    Preview
                  </Dropdown.Item>
                  {activeTab != "My Contests" ? (
                    <Dropdown.Item
                      onSelect={() =>
                        updateStatus("Approved", contest.id)
                      }
                    >
                      Approve
                    </Dropdown.Item>
                  ) : null
                  }
                  {
                    activeTab != "My Contests" ? (
                      <Dropdown.Divider />
                    ) : null
                  }
                  <Dropdown.Header>Other Actions</Dropdown.Header>
                  <Dropdown.Item
                    onSelect={() => {
                      setFormData(contest);
                      setFormProps({
                        open: true,
                        title: "Edit Contest",
                        yesBtn: {
                          title: "Submit",
                          onTap: (data) => submit(data),
                        },
                        noBtn: {
                          title: "Cancel",
                          onTap: () => {
                            setFormErrors({});
                            setFormProps(defaultForm);
                            setFormData(defaultFormData);
                          },
                        },
                        onClose: () => {
                          setFormErrors({});
                          setFormProps(defaultForm);
                          setFormData(defaultFormData);
                        },
                      });
                    }}
                    variant="danger"
                    className="danger light sharp i-false"
                  >
                    Edit
                  </Dropdown.Item>
                  <Dropdown.Item
                    onSelect={() => {
                      if (cell.row.original.participantsCount) {
                        setDialogProps({
                          open: true,
                          title: "Exception",
                          text:
                            "This contest can no longer be removed because " +
                            cell.row.original.participantsCount +
                            "user(s) are now participating",
                          yesBtn: {
                            title: "Okay",
                            onTap: () => {
                              setDialogProps(defaultDialog);
                            },
                          },
                          onClose: () => setDialogProps(defaultDialog),
                        });
                      }
                      setDialogProps({
                        open: true,
                        title: "Confirmation",
                        text: "This action is irreversible. Are you sure to proceed?",
                        yesBtn: {
                          title: "Yes",
                          onTap: () => {
                            remove(cell.row.original.id);
                            setDialogProps(defaultDialog);
                          },
                        },
                        noBtn: {
                          title: "No",
                          onTap: () => setDialogProps(defaultDialog),
                        },
                        onClose: () => setDialogProps(defaultDialog),
                      });
                    }}
                    variant="danger"
                    className="danger light sharp i-false"
                  >
                    Delete
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            }
          </div>
        );
      },
    },
  ];

  if (roleName === 'Individual') columns.splice(6, 1)

  useEffect(() => {
    dispatch(competitionAllList(userId));
  }, []);

  function onAddBenefit() {
    const benefits = [...formData.benefits];
    if (benefits.length === BENEFITS_PER_REWARD_LIMIT) {
      setFormErrors((err) => ({
        ...err,
        benefits:
          "You can only add " +
          BENEFITS_PER_REWARD_LIMIT +
          " benefits per reward",
      }));
      return;
    }

    benefits.push({
      type: "",
      title: "",
      description: "",
      amount: "",
      couponId: null,
    });

    setFormData((pre) => ({
      ...pre,
      benefits,
    }));
  }

  function onRemoveBenefit(benefitKey) {
    const benefits = formData.benefits.filter(
      (_tree, index) => index !== benefitKey
    );
    setFormData((pre) => ({
      ...pre,
      benefits,
    }));
    if (formErrors.benefits) delete formErrors.benefits;
  }

  function onAddOption() {
    const options = [...formData.cEvent.options];
    if (options.length === OPTIONS_PER_EVENT_LIMIT) {
      setFormErrors((err) => ({
        ...err,
        option:
          "You can only add " + OPTIONS_PER_EVENT_LIMIT + " options per event",
      }));
      return;
    }

    options.push("");

    setFormData((pre) => ({
      ...pre,
      cEvent: { ...pre.cEvent, options },
    }));
    if (formErrors.option) delete formErrors.option;
  }

  function onRemoveOption(optionKey) {
    const options = formData.cEvent.options.filter(
      (_tree, index) => index !== optionKey
    );
    setFormData((pre) => ({
      ...pre,
      cEvent: { ...pre.cEvent, options },
    }));
    if (formErrors.option) delete formErrors.option;
  }

  function handleChange(key, value) {
    setFormData((pre) => ({
      ...pre,
      [key]: value,
    }));
    if (formErrors[key]) delete formErrors[key];
  }

  function handleChangeProduct(key, value) {
    setFormData((pre) => ({
      ...pre,
      product: { ...pre.product, [key]: value },
    }));
    if (formErrors["product"] && formErrors["product"][key])
      delete formErrors["product"][key];
  }

  function handleChangeEvent(key, value) {
    setFormData((pre) => ({
      ...pre,
      cEvent: { ...pre.cEvent, [key]: value },
    }));
    if (formErrors["cEvent"] && formErrors["cEvent"][key])
      delete formErrors["cEvent"][key];
  }

  function handleChangeOption(key, value) {
    const _options = formData.cEvent.options.map((_option, index) => {
      if (index == key) return value;
      else return _option;
    });
    setFormData((pre) => ({
      ...pre,
      cEvent: { ...pre.cEvent, options: _options },
    }));
    if (formErrors.options && formErrors.options) {
      const _errors = formErrors.options.filter((_option, index) => {
        if (index !== key) return true;
        else return false;
      });
      setFormErrors((err) => ({
        ...err,
        options: _errors,
      }));
    }
  }

  function handleChangeBenefit(key, value, benefitKey) {
    const _benefits = formData.benefits.map((_benefit, index) => {
      if (index == benefitKey) {
        if (key == "type") {
          _benefit["couponId"] = null;
          _benefit["title"] = "";
          _benefit["description"] = "";
          _benefit["amount"] = "";
        }
        _benefit[key] = value;
        return _benefit;
      } else return _benefit;
    });
    setFormData((pre) => ({
      ...pre,
      benefits: _benefits,
    }));
    if (formErrors.benefit) {
      const _errors = formErrors.benefit.map((_benefits, index) => {
        if (index == benefitKey) {
          delete _benefits[key];
          return _benefits;
        } else return _benefits;
      });
      setFormErrors((err) => ({ ...err, benefit: _errors }));
    }
  }

  function submit(data) {
    try {
      const _errors = competitionFormValidator(data, [
        "banner",
        "title",
        "description",
        "reach",
        "duration",
        "bord",
        "rewardTitle",
        "rewardDescription",
      ]);
      if (Object.keys(_errors).length) {
        setFormErrors(_errors);
        toast.warning("Oops! ssomething went wrong")
        return;
      }

      const formdata = new FormData();

      const { banner, product, cEvent, benefits, ...contest } = data;

      if (banner.uri) delete banner.uri;
      formdata.append("banner", banner.file);

      // if (contest.type == "Purchase Product" && product) {
      //   formdata.append("productImage", product.image.file);
      //   formdata.append("product", JSON.stringify(product));
      // }

      if (contest.type == "Event")
        formdata.append(
          "event",
          JSON.stringify({ ...cEvent, options: cEvent.options.join("***") })
        );

      formdata.append("benefits", JSON.stringify(benefits));

      for (const key in contest) formdata.append(key, String(contest[key]));

      dispatch(createCompetition(formdata));
      setFormErrors({});
      setFormProps(defaultForm);
      setFormData(defaultFormData);
      toast.success("Contest created successfully!")
    } catch (err) {
      // console.log("Error is", err);
      toast.warning("Oops! ssomething went wrong")
    }
  }

  function updateStatus(status, roleId) {
    dispatch(updateCompetition({ status }, roleId));
  }

  function remove(roleId) {
    dispatch(removeCompetition(roleId));
  }
  return (
    <Fragment>
      <div className="col-xl-12 col-xxl-12">
        <FilteringTable
          data={activeTab == "My Contests" ? myContests : requestedContests}
          columns={columns}
          title="Contests"
          headerBtns={roleName != "Individual" ? headerBtns : null}
          tabData={tabData}
        />
        {/* {data.map((contest, index) => (
          <div key={index} className="col-md-4 text-center">
            <ContestItem data={contest} />
          </div>
        ))} */}
      </div>
      <Dialog {...dialogProps} />
      <Modal
        {...formProps}
        data={formData}
        submitWithData={true}
        modalSize="xl"
        footerCaption={
          <p>
            By submitting this form you agreed to our{" "}
            <a target="_blank" href="https://terra-nova.io/terms-and-conditions/">Terms & Conditions</a>
          </p>
        }
      >
        <CreateContestForm
          {...formProps}
          {...{
            formData,
            onAddBenefit,
            onRemoveBenefit,
            handleChangeBenefit,
            handleChangeProduct,
            handleChangeEvent,
            handleChangeOption,
            onAddOption,
            onRemoveOption,
          }}
          errors={formErrors}
          handleChange={handleChange}
        />
      </Modal>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    successMessage: state.auth.successMessage,
    showLoading: state.auth.showLoading,
  };
};

export default connect(mapStateToProps)(ContestList);
