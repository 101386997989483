import React from "react";
import { Button, Col } from "react-bootstrap";
import { BsFillFileEarmarkMinusFill } from "react-icons/bs";

export default function MultiFileDropZone(props) {
  const {
    onChange,
    onRemove,
    srcs,
    title,
    caption,
    accept,
    error,
    width,
    height,
    name,
    fileLimit,
    helperText,
  } = props;

  const [dragActive, setDragActive] = React.useState(false);
  const [err, setErr] = React.useState("");

  // handle drag events
  function handleDrag(e) {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  }

  function handleDrop(e) {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    handleFileDrop(e);
  }

  function handleFileDrop(e) {
    if (err) {
      setErr("");
    }
    if (e.dataTransfer.files.length) {
      const files = e.dataTransfer.files;
      const _files = [];
      for (const oneFile of files) {
        if (files.length > fileLimit) {
          setErr("Only " + fileLimit + " documents are allowed on a project");
          return;
        } else {
          setErr("");
        }
        const uri = window.URL.createObjectURL(new Blob([oneFile]));
        const _file = {
          file: oneFile,
          uri,
        };
        _files.push(_file);
      }
      onChange(_files);
    }
  }

  function handleFile(e) {
    if (e.target.files.length) {
      const files = e.target.files;
      const _files = [];
      for (const oneFile of files) {
        if (files.length > fileLimit) {
          setErr("Only " + fileLimit + " documents are allowed on a project");
          return;
        } else {
          setErr("");
        }
        const uri = window.URL.createObjectURL(new Blob([oneFile]));
        const _file = {
          file: oneFile,
          uri,
        };
        _files.push(_file);
      }
      onChange(_files);
    }
  }

  return (
    <div className="form-group" key={name || "drag&Drop"}>
      <label
        onDragEnter={handleDrag}
        onDragLeave={handleDrag}
        onDragOver={handleDrag}
        onDrop={handleDrop}
        className="text-label"
        htmlFor={name || "drag&Drop"}
        style={{
          cursor: "pointer",
          width: width || "100%",
          height: height ? height : undefined,
        }}
      >
        <div
          style={{
            width: "100%",
            height: "100%",
            border:
              error || err
                ? "1px dashed red"
                : dragActive
                ? "1px dashed green"
                : "1px dashed",
            borderRadius: 10,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            overflow: "hidden",
          }}
        >
          <Col className="text-center">
            <h6>{title || "Drag & Drop"}</h6>
            <small>{caption}</small>
          </Col>
        </div>
        {error || err ? (
          <small style={{ color: "red" }}>{error || err}</small>
        ) : null}
        {helperText && !(error || err) ? <small>{helperText}</small> : null}
      </label>
      <input
        // value={treeData.image ? treeData.coords : ""}
        id={name || "drag&Drop"}
        type="file"
        accept={accept || "image/*"}
        style={{ display: "none" }}
        onChange={(e) => {
          e.preventDefault();
          handleFile(e);
        }}
        multiple="multiple"
      />
      <div
        className="row mt-3"
        style={{
          textAlign: "center",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {srcs && srcs.length
          ? srcs.map((src, i) => (
              <div className="col-lg-2" style={{ width: "100px" }}>
                <div>
                  {src ? (
                    <div
                      style={{
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        width: "100%",
                      }}
                      {...{ src }}
                    >
                      <BsFillFileEarmarkMinusFill size={25} />
                      <br />
                      {src.file.name}
                    </div>
                  ) : null}
                </div>
                <Button
                  key={i}
                  variant="danger light"
                  onClick={() => onRemove(i)}
                  disabled={false}
                >
                  <i className="flaticon-381-trash-1"></i>
                </Button>
              </div>
            ))
          : null}
      </div>
    </div>
  );
}
