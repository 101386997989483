import React, { useState } from "react";
import { useSelector } from "react-redux";
import { getAvatar, getFullName } from "../../../../../store/selectors/AuthSelectors";
import Avatar from "react-avatar";
import { Button } from "react-bootstrap";


function Detail(props) {
    const { data } = props;
    const avatar = useSelector(getAvatar);
    const fullname = useSelector(getFullName);
    const [toUser, setToUser] = useState(null);
    const amount = data.amount;
    const gas = data.gasUsed;

    return (
        <section>
            <div className="row align-items-center justify-content-center ml-5">
                <div className="col-md-3">
                    <Avatar src={avatar} name={fullname} round={true} size="55" />
                    <p>{fullname}</p>
                </div>
                <div className="col-md-3">
                    <i class="fa-solid fa-arrow-right" style={{ fontSize: "2em" }}></i>
                </div>
                {toUser && toUser.first_name && toUser.last_name ?
                    <div className="col-md-3">
                        <Avatar src={toUser.avatar} name={fullname} round={true} size="55" />
                        {toUser.first_name == null && toUser.last_name == null}
                        <p>{toUser.first_name + " " + toUser.last_name}</p>
                    </div>
                    :
                    <div className="col-md-3">
                        <Avatar name={fullname} round={true} size="55" />
                        <p className="ml-3">N/A</p>
                    </div>
                }
            </div>
            <hr />
            <div className="row align-items-center justify-content-center ml-2">
                <div className="col-md-8">
                    <Button className="mb-3">Sending LogieToken</Button>
                    <p>{amount} LET</p>
                    <p>{gas} wei</p>
                </div>
                <div className="">
                </div>
            </div>
            <hr />
            <div className="row align-items-center justify-content-center ml-2">
                <div className="col-md-8">
                    <h5>{data.title}</h5><br />
                    <p><strong>From Wallet Account : </strong>{data.from}</p>
                    <p><strong>To Wallet Account : </strong>{data.to}</p>
                </div>
            </div>
        </section>
    );
}

export default Detail;
