import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import "./forms/donations/TreePreview.css";
import "../../../css/screen-transition.css";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { useHistory, useParams } from "react-router-dom";
import Loading from "../partials/Loading";
import { getFeedbackById } from "../../../services/FeedbackService";
import TNLogo from "../../../images/logo.png";
import Lottie from "lottie-react";
import sad from "./sad.json";
import neutral from "./neutral.json";
import happy from "./happy.json";

const FeedbackPreview = (props) => {
  let { feedbackId } = useParams();
  const history = useHistory();
  const [feedback, setFeedback] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (feedbackId) {
      getFeedback();
    }
  }, [feedbackId]);

  function getFeedback() {
    getFeedbackById(feedbackId)
      .then((res) => {
        setIsLoading(false);
        if (res.data.status === 200) {
          setFeedback(res.data.feedback);
        }
      })
      .catch((err) => {
        setIsLoading(false);
      });
  }

  if (isLoading) {
    return <Loading />;
  } else if (feedback) {
    return (
      <div className="row" style={{ justifyContent: "center" }}>
        <div className="col-lg-12">
          <Button
            style={{ marginBottom: "20px" }}
            onClick={() => {
              history.goBack();
            }}
          >
            <AiOutlineArrowLeft className="mr-1" />
            Back
          </Button>
        </div>
        <div className="col-md-10 col-sm-10 col-xs-10 mt-2">
          <div className="card">
            <div className="card-body">
              <div className="row d-flex">
                <div className="col-sm-6">
                  {feedback.user ? (
                    <h5>
                      {feedback.user.first_name + " " + feedback.user.last_name}
                    </h5>
                  ) : (
                    <h5>{"N/A"}</h5>
                  )}
                </div>
                <div
                  className="col-sm-6"
                  style={{
                    display: "flex",
                    justifyContent: "end",
                    alignItems: "center",
                    marginTop: "-5px",
                  }}
                >
                  <span
                    style={{
                      fontSize: "16px",
                      color: "#f1c40f",
                      marginRight: "3px",
                    }}
                  >
                    <strong>{feedback.type}</strong>
                  </span>
                  {feedback.rating == "Sad" ? (
                    <Lottie
                      animationData={sad}
                      style={{ width: "30px", height: "30px" }}
                      loop={true}
                    />
                  ) : feedback.rating == "Neutral" ? (
                    <Lottie
                      animationData={neutral}
                      style={{ width: "30px", height: "30px" }}
                      loop={true}
                    />
                  ) : feedback.rating == "Happy" ? (
                    <Lottie
                      animationData={happy}
                      style={{ width: "30px", height: "30px" }}
                      loop={true}
                    />
                  ) : null}
                </div>
              </div>
              <p>
                <small>{feedback.message}</small>
              </p>
            </div>
          </div>
        </div>
        {isLoading ? <Loading /> : null}
      </div>
    );
  } else {
    return (
      <div className="col-lg-12 text-center mt-3">
        <img src={TNLogo} width="70px" height="70px" />
        <h3 className="mt-1">Record Not Found</h3>
        <p></p>
      </div>
    );
  }
};

export default FeedbackPreview;
