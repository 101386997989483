import {
  LIST_WALLET_DETAILS,
  LIST_TRANSACTIONS_ACTION,
  LIST_NFTS_ACTION,
} from "./WalletTypes";

import {
  listWalletDetails as listWalletDetailsService,
  listTransactions as listTransactionsService,
  getMyNFTs,
} from "../../services/WalletService";

export function listWalletDetails(userId) {
  return (dispatch) => {
    listWalletDetailsService(userId)
      .then((res) => {
        if (res.data.status == 200) {
          const { status, message, ...walletDetails } = res.data;
          dispatch(
            listWalletDetailsConfirm({ ...walletDetails, isLoading: false })
          );
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
}

export function listWalletDetailsConfirm(payload) {
  return { type: LIST_WALLET_DETAILS, payload };
}

export function listTransactionsAction(address) {
  return (dispatch) => {
    listTransactionsService(address)
      .then((res) => {
        if (res.data.status == 200) {
          const { status, message, ...walletDetails } = res.data;
          dispatch(
            confirmListTransactionsAction({
              ...walletDetails,
              isLoading: false,
            })
          );
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
}

export function confirmListTransactionsAction(payload) {
  return { type: LIST_TRANSACTIONS_ACTION, payload };
}

export function listMyNFTsAction(walletAddress) {
  return (dispatch) => {
    getMyNFTs(walletAddress)
      .then((res) => {
        if (res.data.status == 200) {
          dispatch(
            confirmListMyNFTsAction({
              nfts: res.data.nfts,
              isNFTsLoading: false,
            })
          );
        }
      })
      .catch((err) => {
        console.log("walletAddress", walletAddress);
        console.log(err);
      });
  };
}

export function confirmListMyNFTsAction(payload) {
  return { type: LIST_NFTS_ACTION, payload };
}
