import React, { Fragment, useEffect, useState } from "react";
import { useSelector, useDispatch, connect } from "react-redux";
import FilteringTable from "../table/FilteringTable/FilteringTable";
import { ColumnFilter } from "../table/FilteringTable/ColumnFilter";
import { Button } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import moment from "moment";
import { getRoleName, getUserId } from "../../../store/selectors/AuthSelectors";
import { createFeedbackSchema } from "../../../services/schema";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { BiSad } from "react-icons/bi";
import { MdSentimentNeutral } from "react-icons/md";
import { BiHappy } from "react-icons/bi";
import { MdWifiTetheringErrorRounded } from "react-icons/md";
import { MdOutlineSettingsSuggest } from "react-icons/md";
import { RiPsychotherapyLine } from "react-icons/ri";
import {
  createFeedBack,
  listAllFeedback,
} from "../../../store/actions/FeedbackActions";
import { getFeedbacks } from "../../../store/selectors/FeedbackSelector";
import Modal from "../partials/Modal";
import Success from "./partials/success/Success";
import defaultImage from "../../../images/profile/default.jpeg";

const Feedback = (props) => {
  const dispatch = useDispatch();
  const data = useSelector(getFeedbacks);
  const history = useHistory();
  const roleName = useSelector(getRoleName);
  const userId = useSelector(getUserId);
  const [errors, setErrors] = useState({});
  const [formData, setFormData] = useState(createFeedbackSchema.getDefault());

  const defaultForm = {
    open: false,
    title: "",
    data: {},
    onClose: () => {},
  };
  const [formProps, setFormProps] = useState(defaultForm);

  function handleChange(key, value) {
    setFormData((pre) => ({ ...pre, [key]: value }));
    setErrors((pre) => ({ ...pre, [key]: "" }));
  }

  const RATINGS = [
    {
      title: "Sad",
      icon: <BiSad size={25} />,
    },
    {
      title: "Neutral",
      icon: <MdSentimentNeutral size={25} />,
    },
    {
      title: "Happy",
      icon: <BiHappy size={25} />,
    },
  ];

  const TYPE = [
    {
      title: "Bug",
      icon: <MdWifiTetheringErrorRounded size={25} />,
    },
    {
      title: "Suggetion",
      icon: <MdOutlineSettingsSuggest size={25} />,
    },
    {
      title: "Other",
      icon: <RiPsychotherapyLine size={25} />,
    },
  ];

  const columns = [
    {
      Header: "#",
      Footer: "#",
      accessor: "id",
      Filter: ColumnFilter,
    },
    {
      Header: "Image",
      Footer: "Image",
      Filter: ColumnFilter,
      Cell: (cell) => {
        let avatar = cell.row.original.user
          ? cell.row.original.user.avatar
          : null;
        return (
          <img
            src={avatar == null ? defaultImage : avatar}
            style={{ width: "50px", height: "50px", borderRadius: "10px" }}
          />
        );
      },
    },
    {
      Header: "User",
      Footer: "User",
      Filter: ColumnFilter,
      Cell: (cell) => {
        let first_name = cell.row.original.user
          ? cell.row.original.user.first_name
          : "N /";
        let last_name = cell.row.original.user
          ? cell.row.original.user.last_name
          : "A";
        return (
          <div className="row" style={{ alignItems: "center" }}>
            <span>{first_name + " " + last_name}</span>
          </div>
        );
      },
    },
    {
      Header: "Rating",
      Footer: "Rating",
      accessor: "rating",
      Filter: ColumnFilter,
    },
    {
      Header: "Type",
      Footer: "Type",
      accessor: "type",
      Filter: ColumnFilter,
    },
    {
      Header: "Created At",
      Footer: "Created At",
      Filter: ColumnFilter,
      accessor: "createdAt",
      Cell: (cell) => {
        if (cell.row.original.createdAt) {
          return moment(cell.row.original.createdAt).format(
            "DD MM YYYY hh:mm a"
          );
        }
      },
    },
    {
      Header: "Actions",
      Footer: "Actions",
      Cell: (cell) => {
        const feedback = cell.row.original;
        return (
          <Button
            className="primary light"
            onClick={() => {
              history.push("feedback/" + feedback.id);
            }}
          >
            Preview
          </Button>
        );
      },
    },
  ];

  function onSubmit() {
    createFeedbackSchema
      .validate(formData, { abortEarly: false })
      .then((isValid) => {
        try {
          const feedbackFormData = {
            ...formData,
            userId: +userId,
          };
          dispatch(createFeedBack(feedbackFormData));
        } finally {
          setFormProps({
            open: true,
            title: "Congratulations ! ",
            noBtn: {
              title: "Dismiss",
              onTap: () => {
                setFormProps(defaultForm);
              },
            },
            onClose: () => {
              setFormProps(defaultForm);
            },
          });
          setErrors(createFeedbackSchema.getDefault());
          setFormData(createFeedbackSchema.getDefault());
        }
      })
      .catch((err) => {
        if (err.inner) {
          err.inner.forEach((e) => {
            setErrors((pre) => ({ ...pre, [e.path]: e.message }));
          });
        }
      });
  }

  useEffect(() => {
    dispatch(listAllFeedback());
  }, []);

  return (
    <Fragment>
      <div>
        {roleName == "SuperAdmin" ? (
          <div className="col-xl-12 col-xxl-12">
            <FilteringTable data={data} columns={columns} title="Feedback" />
          </div>
        ) : (
          <section>
            <div className="row">
              <Button onClick={() => history.goBack()}>
                <AiOutlineArrowLeft className="mr-1" /> Back
              </Button>
              <div className="col-lg-12 col-xs-12">
                <div className="form-group">
                  <label className="mt-4 text-label">
                    Rating<span style={{ color: "red" }}>*</span>
                  </label>
                  <div className="col-md-12 buttons">
                    {RATINGS.map((rating, index) => {
                      return (
                        <button
                          className={errors.rating ? "border-danger" : ""}
                          style={{
                            borderColor:
                              formData.rating == rating.title
                                ? "#558b2f"
                                : "white",
                          }}
                          key={index}
                          required
                          onClick={() => {
                            handleChange("rating", rating.title);
                          }}
                        >
                          <div>
                            <span>{rating.title}</span>
                            <span className="ml-2">{rating.icon}</span>
                          </div>
                        </button>
                      );
                    })}
                  </div>
                  {errors.rating ? (
                    <p
                      style={{ fontSize: "11px" }}
                      className="text-danger ml-1"
                    >
                      {" "}
                      {errors.rating}
                    </p>
                  ) : null}
                  <label className="mt-3 text-label">
                    Type<span style={{ color: "red" }}>*</span>
                  </label>
                  <div className="col-md-12 buttons">
                    {TYPE.map((type, index) => {
                      return (
                        <button
                          className={errors.type ? "border-danger" : ""}
                          required
                          style={{
                            borderColor:
                              formData.type == type.title ? "#558b2f" : "white",
                          }}
                          key={index}
                          onClick={() => {
                            handleChange("type", type.title);
                          }}
                        >
                          <div>
                            <span>{type.title}</span>
                            <span className="ml-2">{type.icon}</span>
                          </div>
                        </button>
                      );
                    })}
                  </div>
                  {errors.type ? (
                    <p
                      style={{ fontSize: "11px" }}
                      className="text-danger ml-1"
                    >
                      {" "}
                      {errors.type}
                    </p>
                  ) : null}
                  <label className="mt-3 text-label">
                    Message<span style={{ color: "red" }}>*</span>
                  </label>
                  <textarea
                    type="text"
                    name="description"
                    value={formData.message}
                    style={{ height: "170px", marginTop: "13px" }}
                    className={
                      errors.message
                        ? "form-control border-danger"
                        : "form-control"
                    }
                    placeholder="Message"
                    required
                    onChange={(e) => handleChange("message", e.target.value)}
                  ></textarea>
                  {errors.message ? (
                    <p
                      style={{ fontSize: "11px" }}
                      className="text-danger mt-2 ml-1"
                    >
                      {" "}
                      {errors.message}
                    </p>
                  ) : null}
                  <div className="mt-4">
                    <p>
                      By submitting this form you agreed to our{" "}
                      <a
                        target="_blank"
                        href="https://terra-nova.io/terms-and-conditions/"
                      >
                        Terms & Conditions
                      </a>
                    </p>
                    <Button className="float-right" onClick={onSubmit}>
                      Submit
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </section>
        )}
      </div>
      <Modal modalSize="md" {...formProps} submitWithData={true}>
        <Success {...formProps} />
      </Modal>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    successMessage: state.auth.successMessage,
    showLoading: state.auth.showLoading,
  };
};

export default connect(mapStateToProps)(Feedback);
