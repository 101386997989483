import React from "react";
import { Col } from "react-bootstrap";
import Avatar from "react-avatar";
import moment from "moment";

function PreviewUser(props) {
  const { user } = props;

  if (!user) return <div />;

  let first_name = user.first_name;
  let last_name = user.last_name;
  let username = user.username;
  let avatar = user.avatar;
  let u_name = user ? user.username : "N/A";
  let name = "";
  if (first_name && last_name) {
    if (first_name) {
      name += first_name;
    }
    if (last_name) {
      name += " " + last_name;
    }
  } else if (first_name == null && last_name == null && username) {
    name = username;
  } else {
    name = "N/A";
  }
  if (u_name == null || u_name == "") {
    u_name = "N/A";
  }

  return (
    <section>
      <div className="row">
        <div className="col-lg-12 mb-2">
          <div style={{ alignItems: "center", textAlign: "center" }}>
            <Avatar
              src={avatar}
              name={name == "N/A" ? "Terra Nova" : name}
              round={true}
              size="100"
            />
            <Col>
              <h1 className="mt-2">{name}</h1>
              <div>{"@" + u_name}</div>
            </Col>
          </div>
          <div className="row">
            <div className="col-lg-6 mb-2 mt-4">
              <h4>Personal Info</h4>
              <div>
                <small>Email</small>
                <p>{user.email || "N/A"}</p>
              </div>
              <div>
                <small>Phone</small>
                <p>{user.phone || "N/A"}</p>
              </div>
              <div>
                <small>Pilot City</small>
                <p>
                  {user.city.name +
                    (user.city.country_code
                      ? ", " + user.city.country_code.toUpperCase()
                      : "") || "N/A"}
                </p>
              </div>
            </div>
            <div className="col-lg-6 mb-2 mt-4">
              <h4>Profile Info</h4>
              <div>
                <small>UID</small>
                <p>{user.uid || "N/A"}</p>
              </div>
              <div>
                <small>Profile Type</small>
                <p>{user.role.name || "N/A"}</p>
              </div>
              <div>
                <small>Created At</small>
                <p>
                  {user.createdAt
                    ? moment(user.createdAt).format("DD MMM YYYY hh:mm a")
                    : "N/A"}
                </p>
              </div>
            </div>
            <div className="col-lg-12 mb-2 mt-4">
              {user.role.id == 2 ? (
                <h4>Representative Legal Info</h4>
              ) : (
                <h4>Legal Info</h4>
              )}
              <div>
                <small>Proof of address</small>
                <br />
                <a
                  href={user.proof_of_address ? user.proof_of_address : "#"}
                  target="_blank"
                >
                  {user.proof_of_address ? user.proof_of_address : "N/A"}
                </a>
              </div>
              <br />
              <div>
                <small>ID Card / Driving License / Passport</small>
                <br />
                <a
                  href={user.license_id ? user.license_id : "#"}
                  target="_blank"
                >
                  {user.license_id ? user.license_id : "N/A"}
                </a>
              </div>
              <br />
              <div>
                <small>Selfie</small>
                <br />
                <a href={user.selfie ? user.selfie : "#"} target="_blank">
                  {user.selfie ? user.selfie : "N/A"}
                </a>
              </div>
            </div>
            {user.role.id == 2 ? (
              <div className="col-lg-12 mb-2 mt-4">
                <h4>Company's Legal Info</h4>
                <div>
                  <small>Company Name</small>
                  <br />
                  <p>{user.company_name ? user.company_name : "N/A"}</p>
                </div>
                <br />
                <div>
                  <small>Registration No.</small>
                  <br />
                  <p>{user.registration ? user.registration : "N/A"}</p>
                </div>
                <br />
                <div>
                  <small>Registration Certificate</small>
                  <br />
                  <a
                    href={user.certificate ? user.certificate : "#"}
                    target="_blank"
                  >
                    {user.certificate ? user.certificate : "N/A"}
                  </a>
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </section>
  );
}

export default PreviewUser;
