import React, { useEffect, useState } from "react";
import DropZone from "../../../partials/DropZone";
import { useSelector, useDispatch } from "react-redux";
import { getCoupons } from "../../../../../store/selectors/CouponSelector";
import { couponList } from "../../../../../store/actions/CouponActions";
import {
  getUserId
} from "../../../../../store/selectors/AuthSelectors";


function CreateProductForm(props) {
  const {
    handleChange,
    errors,
    formData,
  } = props;

  const userId = useSelector(getUserId);
  const coupons = useSelector(getCoupons);
  const dispatch = useDispatch();
  const [showSaleBlock, setshowSaleBlock] = useState(false);
  const [showManageStock, setShowManageStock] = useState(false);
  const today = new Date().toISOString().split('T')[0];

  useEffect(() => {
    if (coupons.length == 0) {
      dispatch(couponList(userId));
    }
  }, []);

  return (
    <section>
      <div className="row">
        <div className="col-lg-12 mb-2 text-center">
          <DropZone
            src={formData.banner ? formData.banner.uri : null}
            title="Product Banner"
            caption="Drag & Drop an image here"
            onlyImage={true}
            onChange={(file) => handleChange("banner", file)}
            onRemove={() => handleChange("banner", null)}
            error={errors.banner}
            width="70%"
            height="150px"
          />
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12 mb-2">
          <div className="form-group">
            <label className="text-label">Title*</label>
            <input
              value={formData.title}
              type="text"
              name="title"
              className={
                errors.title ? "form-control border-danger" : "form-control"
              }
              placeholder="Title"
              required
              onChange={(e) => handleChange("title", e.target.value)}
            />
            <small className="text-danger">{errors.title}</small>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-12 mb-2">
          <div className="form-group">
            <label className="text-label">Description*</label>
            <textarea
              value={formData.description}
              type="text"
              name="description"
              style={{ height: "100px" }}
              className={
                errors.description
                  ? "form-control border-danger"
                  : "form-control"
              }
              placeholder="Description"
              required
              onChange={(e) => handleChange("description", e.target.value)}
            ></textarea>
            <small className="text-danger">{errors.description}</small>
          </div>
        </div>
      </div>
      <hr />
      <div className="row">
        <div className="col-lg-6 mb-2">
          <div className="form-group">
            <label className="text-label">Regular Price*</label>
            <input
              value={formData.regularPrice}
              type="text"

              name="regularPrice"
              className={
                errors.regularPrice ? "form-control border-danger" : "form-control"
              }
              placeholder="Regular Price"
              required
              onChange={(e) => {
                const validateValue = e.target.value.replace(/[^0-9]/g, '');
                handleChange("regularPrice", validateValue)
              }
              }
            />
            <small className="text-danger">{errors.regularPrice}</small>
          </div>
        </div>
        <div className="col-lg-6 mb-2">
          <div className="form-group">
            <label className="text-label">Sale Price*</label>
            <input
              value={formData.salePrice}
              type="text"
              name="salePrice"
              className={
                errors.salePrice
                  ? "form-control border-danger"
                  : "form-control"
              }
              placeholder="Sale Price"
              onChange={(e) => {
                const validateValue = e.target.value.replace(/[^0-9]/g, '');
                handleChange("salePrice", validateValue);
                if (validateValue) { setshowSaleBlock(true) } else { setshowSaleBlock(false) }
              }}
            ></input>
            <small className="text-danger">{errors.salePrice}</small>
          </div>
        </div>
      </div>

      {
        showSaleBlock ? (
          <div className="row">
            <div className="col-lg-6 mb-2">
              <div className="form-group">
                <label className="text-label">Sale From*</label>
                <input
                  value={formData.saleFrom}
                  type="date"
                  name="saleFrom"
                  min={today}
                  onKeyPress={(e) => e.preventDefault()} // Prevent typing
                  onPaste={(e) => e.preventDefault()} // Prevent pasting
                  onCut={(e) => e.preventDefault()} // Prevent cutting
                  className={
                    errors.saleFrom ? "form-control border-danger" : "form-control"
                  }
                  onChange={(e) => handleChange("saleFrom", e.target.value)}
                />
                <small className="text-danger">{errors.saleFrom}</small>
              </div>
            </div>
            <div className="col-lg-6 mb-2">
              <div className="form-group">
                <label className="text-label">Sale To*</label>
                <input
                  value={formData.saleTo}
                  type="date"
                  name="saleTo"
                  min={formData.saleFrom}
                  onKeyPress={(e) => e.preventDefault()} // Prevent typing
                  onPaste={(e) => e.preventDefault()} // Prevent pasting
                  onCut={(e) => e.preventDefault()} // Prevent cutting
                  className={
                    errors.saleTo
                      ? "form-control border-danger"
                      : "form-control"
                  }
                  onChange={(e) => handleChange("saleTo", e.target.value)}
                ></input>
                <small className="text-danger">{errors.saleTo}</small>
              </div>
            </div>
          </div>
        ) : null
      }
      <hr />
      <div className="row">
        <div className="col-lg-6 mb-2">
          <div className="form-group">
            <label className="text-label">Sku*</label>
            <input
              value={formData.sku}
              type="text"
              name="sku"
              className={
                errors.sku ? "form-control border-danger" : "form-control"
              }
              placeholder="Sku"
              required
              onChange={(e) => {
                const validateValue = e.target.value.replace(/[^0-9]/g, '');
                handleChange("sku", validateValue)
              }
              }
            />
            <small className="text-danger">{errors.sku}</small>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-6 mb-2">
          <div className="form-group">
            <label className="text-label">Manage Stock*</label><br />
            <input
              value={formData.manageStock}
              type="checkbox"
              name="manageStock"
              // className={
              //   errors.manageStock
              //     ? "form-control border-danger"
              //     : "form-control"
              // }
              onChange={(e) => handleChange("manageStock", e.target.value)}
              onClick={() => { setShowManageStock(!showManageStock) }}
            />
            <small className="text-primary ml-4">Check this to Manage Stock</small>
            <small className="text-danger">{errors.manageStock}</small>
          </div>
        </div>
      </div>
      {
        showManageStock ? (
          <div className="row">
            <div className="col-lg-12 mb-2">
              <div className="form-group">
                <label className="text-label">Stock*</label>
                <input
                  value={formData.stock}
                  type="text"
                  name="stock"
                  className={
                    errors.stock ? "form-control border-danger" : "form-control"
                  }
                  placeholder="Stock"
                  onChange={(e) => {
                    const validateValue = e.target.value.replace(/[^0-9]/g, '');
                    handleChange("stock", validateValue)
                  }
                  }
                />
                <small className="text-danger">{errors.stock}</small>
              </div>
            </div>
            {/* <div className="col-lg-6 mb-2">
              <div className="form-group">
                <label className="text-label">Attributes*</label>
                <input
                  value={formData.attributes}
                  type="text"
                  name="attributes"
                  className={
                    errors.attributes
                      ? "form-control border-danger"
                      : "form-control"
                  }
                  placeholder="Attributes"
                  onChange={(e) => handleChange("attributes", e.target.value)}
                ></input>
                <small className="text-danger">{errors.attributes}</small>
              </div>
            </div> */}
          </div>
        ) : null
      }
      <hr />
      <div className="row">
        <div className="col-lg-6 mb-2">
          <div className="form-group">
            <label className="text-label">Purchase Note*</label>
            <input
              value={formData.purchaseNote}
              type="text"
              name="purchaseNote"
              className={
                errors.purchaseNote ? "form-control border-danger" : "form-control"
              }
              placeholder="Purchase Note"
              onChange={(e) => handleChange("purchaseNote", e.target.value)}
            />
            <small className="text-danger">{errors.purchaseNote}</small>
          </div>
        </div>
        <div className="col-lg-6 mb-2">
          <div className="form-group">
            <label className="text-label">Brand*</label>
            <input
              value={formData.brand}
              type="text"
              name="brand"
              className={
                errors.brand
                  ? "form-control border-danger"
                  : "form-control"
              }
              placeholder="Brand"
              onChange={(e) => handleChange("brand", e.target.value)}
            ></input>
            <small className="text-danger">{errors.brand}</small>
          </div>
        </div>
      </div>
    </section>
  );
}

export default CreateProductForm;
