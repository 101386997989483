import React, { Fragment, useEffect, useState } from "react";
import { createRoleSchema } from "../../../services/schema";
import {
  roleList,
  createRole,
  updateRole,
  removeRole,
} from "../../../store/actions/RoleActions";
import {
  getRoles,
  getPermissions,
} from "../../../store/selectors/RoleSelector";
import { useSelector, useDispatch, connect } from "react-redux";
import FilteringTable from "../table/FilteringTable/FilteringTable";
import { ColumnFilter } from "../table/FilteringTable/ColumnFilter";
import { Badge, Col, Dropdown } from "react-bootstrap";
import Dialog from "../partials/Dialog";
import Modal from "../partials/Modal";
import CreateRoleForm from "./partials/roles/CreateRoleForm";

const ThreeDots = (
  <svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <rect x="0" y="0" width="24" height="24"></rect>
      <circle fill="#000000" cx="5" cy="12" r="2"></circle>
      <circle fill="#000000" cx="12" cy="12" r="2"></circle>
      <circle fill="#000000" cx="19" cy="12" r="2"></circle>
    </g>
  </svg>
);

const RoleList = (props) => {
  const dispatch = useDispatch();
  const data = useSelector(getRoles);
  const permissions = useSelector(getPermissions);
  const [formErrors, setFormErrors] = useState(createRoleSchema.getDefault());
  const [formData, setFormData] = useState(createRoleSchema.getDefault());
  const [togglePerms, setTogglePerms] = useState(false);

  const defaultDialog = {
    open: false,
    title: "",
    text: "",
    onClose: () => { },
  };

  const defaultForm = {
    open: false,
    title: "",
    data: {},
    onClose: () => { },
  };

  const headerBtns = [
    {
      title: "+ Add",
      onClick: () => {
        setFormProps({
          open: true,
          title: "Add Role",
          yesBtn: {
            title: "Submit",
            onTap: (data) => submit(data),
          },
          noBtn: {
            title: "Cancel",
            onTap: () => {
              setTogglePerms(false);
              setFormErrors(createRoleSchema.getDefault());
              setFormProps(defaultForm);
              setFormData(createRoleSchema.getDefault());
            },
          },
          onClose: () => {
            setTogglePerms(false);
            setFormErrors(createRoleSchema.getDefault());
            setFormProps(defaultForm);
            setFormData(createRoleSchema.getDefault());
          },
        });
      },
    },
  ];

  const [dialogProps, setDialogProps] = useState(defaultDialog);
  const [formProps, setFormProps] = useState(defaultForm);

  const columns = [
    {
      Header: "#",
      Footer: "#",
      accessor: "id",
      Filter: ColumnFilter,
    },
    {
      Header: "Role",
      Footer: "Role",
      accessor: "name",
      Filter: ColumnFilter,
    },
    {
      Header: "Users",
      Footer: "Users",
      Filter: ColumnFilter,
      accessor: "userCount",
    },
    {
      Header: "Status",
      Footer: "Status",
      Cell: (cell) => {
        let status = <Badge variant="success light">Active</Badge>;
        if (cell.row.original.status === false) {
          status = <Badge variant="danger light">Inactive</Badge>;
        }
        return status;
      },
      Filter: ColumnFilter,
    },
    {
      Header: "Actions",
      Footer: "Actions",
      Cell: (cell) => {
        let variant = "success";
        const role = cell.row.original;
        const status = role.status;
        const removable = role.removable;
        if (status === true) {
          variant = "success";
        } else {
          variant = "danger";
        }
        return (
          <Dropdown>
            <Dropdown.Toggle variant={variant} className="light sharp i-false">
              {ThreeDots}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Header>Change Status</Dropdown.Header>
              {status === true ? (
                <Dropdown.Item onSelect={() => updateStatus(false, role.id)}>
                  Deactivate
                </Dropdown.Item>
              ) : (
                <Dropdown.Item onSelect={() => updateStatus(true, role.id)}>
                  Activate
                </Dropdown.Item>
              )}
              <Dropdown.Divider />
              <Dropdown.Header>Other Actions</Dropdown.Header>
              <Dropdown.Item
                onSelect={() => {
                  // const perms = role.permissions.map((perm) => perm.id);
                  // setFormData((pre) => ({
                  //   ...pre,
                  //   ...role,
                  //   permissions: perms,
                  // }));
                  setFormData(role);
                  setFormProps({
                    open: true,
                    title: "Edit Role",
                    yesBtn: {
                      title: "Submit",
                      onTap: (data) => submit(data),
                    },
                    noBtn: {
                      title: "Cancel",
                      onTap: () => {
                        setTogglePerms(false);
                        setFormErrors(createRoleSchema.getDefault());
                        setFormProps(defaultForm);
                        setFormData(createRoleSchema.getDefault());
                      },
                    },
                    onClose: () => {
                      setTogglePerms(false);
                      setFormErrors(createRoleSchema.getDefault());
                      setFormProps(defaultForm);
                      setFormData(createRoleSchema.getDefault());
                    },
                  });
                }}
                variant="danger"
                className="danger light sharp i-false"
              >
                Edit
              </Dropdown.Item>
              {removable ? (
                <Dropdown.Item
                  onSelect={() => {
                    if (cell.row.original.userCount) {
                      setDialogProps({
                        open: true,
                        title: "Exception",
                        text:
                          "This role cannot be removed because this role is assigned to " +
                          cell.row.original.userCount +
                          "user(s)",
                        yesBtn: {
                          title: "Okay",
                          onTap: () => {
                            setDialogProps(defaultDialog);
                          },
                        },
                        onClose: () => setDialogProps(defaultDialog),
                      });
                    }
                    setDialogProps({
                      open: true,
                      title: "Confirmation",
                      text: "This action is irreversible. Are you sure to proceed?",
                      yesBtn: {
                        title: "Yes",
                        onTap: () => {
                          remove(cell.row.original.id);
                          setDialogProps(defaultDialog);
                        },
                      },
                      noBtn: {
                        title: "No",
                        onTap: () => setDialogProps(defaultDialog),
                      },
                      onClose: () => setDialogProps(defaultDialog),
                    });
                  }}
                  variant="danger"
                  className="danger light sharp i-false"
                >
                  Delete
                </Dropdown.Item>
              ) : null}
            </Dropdown.Menu>
          </Dropdown>
        );
      },
    },
  ];

  useEffect(() => {
    dispatch(roleList());
  }, []);

  function handleChangeName(e) {
    setFormData((pre) => ({
      ...pre,
      name: e.target.value,
    }));
    setFormErrors((pre) => ({
      ...pre,
      name: "",
    }));
  }

  function handleChangePermissions(e) {
    setTogglePerms(false);
    if (formData.permissions.includes(e.target.value)) {
      setFormData((pre) => ({
        ...pre,
        permissions: formData.permissions.filter(
          (permId) => permId !== e.target.value
        ),
      }));
    } else {
      const permissions = formData.permissions || [];
      permissions.push(e.target.value);
      setFormData((pre) => ({
        ...pre,
        permissions: permissions,
      }));
    }
  }

  function handleChangeAllPermissions(status) {
    setTogglePerms(status);
    if (status) {
      setFormData((pre) => ({
        ...pre,
        permissions: permissions.map((perm) => String(perm.id)),
      }));
    } else {
      setFormData((pre) => ({
        ...pre,
        permissions: [],
      }));
    }
  }

  function submit(data) {
    createRoleSchema
      .validate(data, { abortEarly: false })
      .then((isValid) => {
        try {
          if (data.id) {
            dispatch(
              updateRole(
                { ...data, permissions: data.permissions.join(",") },
                data.id
              )
            );
          } else {
            const roleFormData = {
              name: data.name,
              removable: true,
              permissions: data.permissions.join(","),
            };
            dispatch(createRole(roleFormData));
          }
        } finally {
          setTogglePerms(false);
          setFormErrors(createRoleSchema.getDefault());
          setFormProps(defaultForm);
          setFormData(createRoleSchema.getDefault());
        }
      })
      .catch((err) => {
        err.inner.forEach((e) => {
          setFormErrors((pre) => ({ ...pre, [e.path]: e.message }));
        });
      });
  }

  function updateStatus(status, roleId) {
    dispatch(updateRole({ status }, roleId));
  }

  function remove(roleId) {
    dispatch(removeRole(roleId));
  }

  return (
    <Fragment>
      <div className="col-xl-12 col-xxl-12">
        <FilteringTable
          data={data}
          columns={columns}
          title="Roles"
          headerBtns={headerBtns}
        />
      </div>
      <Dialog {...dialogProps} />
      <Modal {...formProps} data={formData} submitWithData={true}>
        <CreateRoleForm
          {...formProps}
          {...formData}
          errors={formErrors}
          togglePerms={togglePerms}
          setTogglePerms={setTogglePerms}
          data={{ permissions }}
          handleChangeName={handleChangeName}
          handleChangePermissions={handleChangePermissions}
          handleChangeAllPermissions={handleChangeAllPermissions}
        />
      </Modal>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    successMessage: state.auth.successMessage,
    showLoading: state.auth.showLoading,
  };
};

export default connect(mapStateToProps)(RoleList);
