import {
  listUsers as listUsersService,
  updateProfile as updateProfileService,
  removeUser as removeUserService,
  createSubUser as createSubUserService,
} from "../../services/UserServices";
import {
  USER_LIST_ACTION,
  USER_UPDATE_STATUS_ACTION,
  USER_DELETE_ACTION,
  USER_SUB_CREATE_ACTION,
} from "./UserTypes";

export function listUsers(userId, roleId) {
  return (dispatch) =>
    listUsersService(userId, roleId)
      .then((res) => {
        if (res.data.status === 200) {
          dispatch(listUsersConfirm(res.data));
        }
      })
      .catch((err) => {
        console.log(err);
      });
}

export function createSubUser(userId, data) {
  return (dispatch) =>
    createSubUserService(userId, data)
      .then((res) => {
        console.log("res.data", res.data);
        if (res.data.status === 200) {
          dispatch({
            type: USER_SUB_CREATE_ACTION,
            payload: res.data.user,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
}

export function updateUserStatus(status, userId, config = {}) {
  return (dispatch) =>
    updateProfileService(userId, { status })
      .then((res) => {
        if (config.setShowLoader) config.setShowLoader(null)
        if (res.data.status === 200) {
          dispatch(updateUserStatusConfirm(userId, status));
        }
      })
      .catch((err) => {
        if (config.setShowLoader) config.setShowLoader(null)
        console.log(err);
      });
}

export function removeUser(userId) {
  return (dispatch) =>
    removeUserService(userId)
      .then((res) => {
        if (res.data.status === 200) {
          dispatch(removeUserConfirm(userId));
        }
      })
      .catch((err) => {
        console.log(err);
      });
}

export function createSubUserConfirm(data) {
  return {
    type: USER_SUB_CREATE_ACTION,
    payload: data,
  };
}

export function removeUserConfirm(id) {
  return {
    type: USER_DELETE_ACTION,
    payload: id,
  };
}

export function updateUserStatusConfirm(id, status) {
  return {
    type: USER_UPDATE_STATUS_ACTION,
    payload: {
      id,
      status,
    },
  };
}

export function listUsersConfirm(data) {
  return {
    type: USER_LIST_ACTION,
    payload: {
      list: data.users,
      individualRoleId: data.individualRoleId,
      businessRoleId: data.businessRoleId,
    },
  };
}
