import api from "./AxiosInstance";

export function listCompetitions(userId) {
  return api.get("competitions/by/u/" + userId);
}

export function listAllCompetitions(userId) {
  return api.get("competitions/for/" + userId);
}

export function getCompetitionById(competitionId) {
  return api.get("competitions/" + competitionId);
}

export function createCompetition(data) {
  return api.post("competitions", data, {
    headers: {
      "Content-Type": "multipart/form-data",
      Accept: "application/json",
    },
  });
}

export function updateCompetition(data, competitionId) {
  return api.patch("competitions/" + competitionId, data);
}

export function removeCompetition(competitionId) {
  return api.delete("competitions/" + competitionId);
}
