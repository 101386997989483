import axios from "axios";
import { store } from "../store/store";
import { URL } from "./URL";

const axiosInstance = axios.create({
  baseURL: URL,
});

axiosInstance.interceptors.request.use((config) => {
  const state = store.getState();
  const token = state.auth.auth.tokenType + " " + state.auth.auth.token;
  config.headers = config.headers || {};
  config.headers["Authorization"] = token;
  return config;
});

export default axiosInstance;
