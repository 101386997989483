import React from "react";
import Nouislider from "nouislider-react";
import "nouislider/distribute/nouislider.css";

function Slider(props) {
  const { start, min, max, onChange, showPips } = props;

  function onSkipSlide(render, handle, value, un, percent) {
    onChange(value[0]);
  }

  return (
    <Nouislider
      start={[start]}
      // pips={{
      //   mode: "range",
      //   density: 3,
      // }}
      // clickablePips={showPips}
      behaviour="tap"
      range={{
        min,
        max,
      }}
      onUpdate={onSkipSlide}
    />
  );
}

export default Slider;
