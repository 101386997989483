import {
  listDashboard as listDashboardService,
  listDashboardMarket as listDashboardMarketService,
} from "../../services/DashboardService";
import {
  DASHBOARD_LIST_ACTION,
  DASHBOARD_LIST_MARKET__ACTION,
} from "./DashboardTypes";

export function dashboardList(userId) {
  return (dispatch) => {
    listDashboardService(userId)
      .then((res) => {
        if (res.data.status == 200) {
          dispatch(dashboardListConfirm(res.data));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
}
export function dashboardListConfirm(payload) {
  return {
    type: DASHBOARD_LIST_ACTION,
    payload,
  };
}

export function listDashboardMarket(userId) {
  return (dispatch) => {
    listDashboardMarketService(userId)
      .then((res) => {
        if (res.data.status == 200) {
          const { status, message, ...marketData } = res.data;
          dispatch(
            dashboardListMarketConfirm({
              ...marketData,
              isMarketDataLoading: false,
            })
          );
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
}
export function dashboardListMarketConfirm(payload) {
  return {
    type: DASHBOARD_LIST_MARKET__ACTION,
    payload,
  };
}
