import {
  listCompetitions as listCompetitionService,
  listAllCompetitions as listAllCompetitionsService,
  createCompetition as createCompetitionService,
  updateCompetition as updateCompetitionService,
  removeCompetition as removeCompetitionService,
  getCompetitionById as getCompetitionByIdService,
} from "../../services/CompetitionService";


import {
  COMPETITION_LIST_ACTION,
  COMPETITION_ALL_LIST_ACTION,
  COMPETITION_ADD_ACTION,
  COMPETITION_EDIT_ACTION,
  COMPETITION_REMOVE_ACTION,
  COPETITION_BYID_ACTION,
} from "./CompetitionTypes";

export function competitionList(userId) {
  return (dispatch) => {
    listCompetitionService(userId)
      .then((res) => {
        if (res.data.status == 200) {
          dispatch(
            competitionListConfirm({
              competitions: res.data.competitions,
              requests: res.data.requests,
            })
          );
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
}

export function competitionAllList(userId) {
  return (dispatch) => {
    listAllCompetitionsService(userId)
      .then((res) => {
        if (res.data.status == 200) {
          dispatch(
            competitionAllListConfirm({
              competitions: res.data.competitions,
              requests: res.data.requests,
            })
          );
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
}

export function createCompetition(data) {
  return (dispatch) =>
    createCompetitionService(data)
      .then((res) => {
        if (res.data.status === 200) {
          dispatch(createCompetitionConfirm(res.data.competition));
        }
      })
      .catch((err) => {
        console.log(err);
      });
}

export function updateCompetition(data, competitionId) {
  return (dispatch) =>
    updateCompetitionService(data, competitionId)
      .then((res) => {
        if (res.data.status === 200) {
          dispatch(updateCompetitionConfirm(data, competitionId));
        }
      })
      .catch((err) => {
        console.log(err);
      });
}

export function removeCompetition(competitionId) {
  return (dispatch) =>
    removeCompetitionService(competitionId)
      .then((res) => {
        if (res.data.status === 200) {
          dispatch(removeCompetitionConfirm(competitionId));
        }
      })
      .catch((err) => {
        console.log(err);
      });
}
export function getCompetitionById(competitionId) {
  return (dispatch) =>
    getCompetitionByIdService(competitionId)
      .then((res) => {
        if (res.data.status === 200) {
          dispatch(getCompetitionByIdConfirm(res.data.competition));
        }
      })
      .catch((err) => {
        console.log(err);
      });
}

export function removeCompetitionConfirm(payload) {
  return {
    type: COMPETITION_REMOVE_ACTION,
    payload,
  };
}

export function updateCompetitionConfirm(data, id) {
  return {
    type: COMPETITION_EDIT_ACTION,
    payload: {
      data,
      id,
    },
  };
}

export function createCompetitionConfirm(payload) {
  return {
    type: COMPETITION_ADD_ACTION,
    payload,
  };
}

export function competitionListConfirm(payload) {
  return { type: COMPETITION_LIST_ACTION, payload };
}
export function competitionAllListConfirm(payload) {
  return { type: COMPETITION_ALL_LIST_ACTION, payload };
}

export function getCompetitionByIdConfirm(payload) {
  return { type: COPETITION_BYID_ACTION, payload };
}
