import React, { Fragment, useState, useEffect } from "react";
import PageTitle from "../../../../layouts/PageTitle";
import { reduxForm } from "redux-form";
import { loadingToggleAction } from "../../../../../store/actions/AuthActions";
import { useDispatch, connect, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { AiOutlineArrowLeft } from "react-icons/ai";
import Loading from "../../../partials/Loading";
import { tokenRewardValidator } from "../../../../../api/validator";
import "../../../../../css/screen-transition.css";
import { Button } from "react-bootstrap";
import { toast } from "react-toastify";
import Form from "./Form";
import { getTokenAllocationFormData } from "../../../../../services/TokenAllocationService";
import { getUserId } from "../../../../../store/selectors/AuthSelectors";
import { addTokenRewardsAction } from "../../../../../store/actions/TokenRewardActions";

const AddForm = (props) => {
  const { showLoading } = props;
  const dispatch = useDispatch();
  const [cities, setCities] = useState([]);
  const [errors, setErrors] = useState({});
  const history = useHistory();
  const userId = useSelector(getUserId);
  const [showLoader, setShowLoader] = useState(false);

  const [data, setData] = useState({
    first_name: "",
    last_name: "",
    public_address: "",
    amount: "",
    email: "",
    phone: "",
  });

  const [fadeAnim, setFadeAnim] = useState({
    leftContainer: "fade-in-left",
    rightContainer: "fade-in-right",
  });

  useEffect(() => {
    getData();
  }, []);

  function handleAnim(key, value) {
    setFadeAnim((pre) => ({ ...pre, [key]: value }));
  }

  function handleAnimControl() {
    handleAnim("leftContainer", "fade-out-left");
    handleAnim("rightContainer", "fade-out-right");
  }

  function handleChange(key, value) {
    setData((pre) => ({ ...pre, [key]: value }));
    setErrors((pre) => ({ ...pre, [key]: "" }));
  }

  function getData() {
    getTokenAllocationFormData()
      .then((res) => {
        if (res.data.status === 200) {
          setCities(res.data.cities);
        }
      })
      .catch((err) => {
        // console.log("err", err);
      });
  }

  function onSubmit() {
    setShowLoader(true);
    try {
      tokenRewardValidator
        .validate(data, {
          abortEarly: false,
        })
        .then(async (valid) => {
          dispatch(
            addTokenRewardsAction(
              { ...data, createdBy: userId },
              {
                history,
                toast,
                setShowLoader,
              }
            )
          );
        })
        .catch((err) => {
          setShowLoader(false);
          // toast.warning("Oops! something went wrong");

          if (err.inner) {
            let errs = {};
            err.inner.forEach((e) => {
              errs = { ...errs, [e.path]: e.message };
            });
            setErrors(errs);
          }
          // console.log("err", err);
        });
    } catch (error) {
      dispatch(loadingToggleAction(false));
    } finally {
      dispatch(loadingToggleAction(false));
    }
  }
  return (
    <Fragment>
      {showLoading ? <Loading /> : null}
      <PageTitle
        pageTitle={"Request LogiE Token to reward your donar"}
        pageContent={" "}
        activeMenu="Request LogiE Token Reward"
        motherMenu="Dashboard"
      />
      <Button
        style={{ marginBottom: "20px" }}
        onClick={() => {
          handleAnimControl();
          setTimeout(() => {
            history.goBack();
          }, 300);
        }}
      >
        <AiOutlineArrowLeft className="mr-1" />
        Back
      </Button>
      <div className={fadeAnim.rightContainer}>
        <Form
          {...{
            showLoader,
            data,
            errors,
            onSubmit,
            handleChange,
            cities,
          }}
        />
      </div>
    </Fragment>
  );
};

const formWizard = reduxForm({
  form: "donationForm",
})(AddForm);

const TokenAllocationAddForm = connect((state) => {
  return { state };
})(formWizard);

const mapStateToProps = (state) => {
  return {
    errorMessage: state.auth.errorMessage,
    successMessage: state.auth.successMessage,
    showLoading: state.auth.showLoading,
  };
};

export default connect(mapStateToProps)(TokenAllocationAddForm);
