import React from "react";

import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { FreeMode, Scrollbar, Mousewheel } from "swiper";
import { useSelector } from "react-redux";
import { getFullName } from "../../../../store/selectors/AuthSelectors";
import {
  getMainBalance,
  getWalletTokens,
  getWalletTransactions,
  getWalletLoadingState,
} from "../../../../store/selectors/WalletSelector";

//import 'swiper/css';

//import "swiper/swiper-bundle.min.css";
//import './../../../../vendor/swiper/swiper-bundle.css';

// Import Swiper styles
//import "swiper/swiper.min.css";
//import "swiper/components/scrollbar/scrollbar.min.css"

import Card1 from "./../../../../images/card/card1.jpg";
import Card2 from "./../../../../images/card/card2.jpg";
import Card3 from "./../../../../images/card/card3.jpg";
import Card4 from "./../../../../images/card/card4.jpg";
import LogieTokenWhite from "../../../../images/svg/LogiETokenWhite.svg";
import SEth from "../../../../images/svg/eth2.svg";
import { nFormatter } from "../../../../api/constants";
import Loading from "../../partials/Loading";

SwiperCore.use([FreeMode, Scrollbar, Mousewheel]);

export default function SwiperSlider2() {
  const mainBalance = useSelector(getMainBalance);
  const tokens = useSelector(getWalletTokens);
  const isLoading = useSelector(getWalletLoadingState);

  return (
    <>
      <Swiper
        direction={"vertical"}
        slidesPerView={"auto"}
        freeMode={true}
        scrollbar={true}
        mousewheel={true}
        modules={[FreeMode, Scrollbar, Mousewheel]}
        breakpoints={{
          360: {
            direction: "horizontal",
            slidesPerView: "auto",
          },
          650: {
            direction: "horizontal",
            slidesPerView: 2,
            scrollbar: {
              el: ".swiper-scrollbar",
            },
          },
          1200: {
            direction: "vertical",
            slidesPerView: "auto",
            scrollbar: {
              el: ".swiper-scrollbar",
            },
          },
        }}
        className="mySwiper swiper-container card-swiper"
      >
        {isLoading ? (
          <Loading />
        ) : (
          <SwiperSlide>
            <div className="card-bx stacked card">
              <img src={Card4} alt="" />
              <div className="card-info">
                <p className="mb-1 text-white fs-14">Balance</p>
                <div className="d-flex justify-content-between">
                  <h2 className="num-text text-white mb-5 font-w600">
                    {mainBalance}
                  </h2>
                  <img
                    src={SEth}
                    alt=""
                    style={{
                      width: "40px",
                      height: "40px",
                      right: 10,
                      top: 10,
                    }}
                  />
                </div>
                <div className="d-flex">
                  <div className="mr-4 text-white">
                    <p className="fs-12 mb-1 op6">Currency</p>
                    <span>Ethereum</span>
                  </div>
                  <div className="text-white">
                    <p className="fs-12 mb-1 op6">Short Code</p>
                    <span>ETH</span>
                  </div>
                </div>
              </div>
            </div>
          </SwiperSlide>
        )}
        {tokens.map((data, index) => (
          <SwiperSlide key={index}>
            <div className="card-bx stacked card">
              <img src={Card2} alt="" />
              <div className="card-info">
                <p className="mb-1 text-white fs-14">Balance</p>
                <div className="d-flex justify-content-between">
                  <h2 className="num-text text-white mb-5 font-w600">
                    {nFormatter(data.balance, 0)}
                  </h2>
                  {data.logo ? (
                    <img
                      src={{ uri: data.logo }}
                      alt=""
                      style={{
                        width: "40px",
                        height: "40px",
                        right: 10,
                        top: 10,
                      }}
                    />
                  ) : null}
                </div>
                <div className="d-flex">
                  <div className="mr-4 text-white">
                    <p className="fs-12 mb-1 op6">Currency</p>
                    <span>{data.name}</span>
                  </div>
                  <div className="text-white">
                    <p className="fs-12 mb-1 op6">Short Code</p>
                    <span>{data.symbol}</span>
                  </div>
                </div>
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </>
  );
}
