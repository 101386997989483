import React, { useEffect } from "react";
import { Col } from "react-bootstrap";
import { getProducts } from "../../../../../store/selectors/ProductSelector";
import { productList } from "../../../../../store/actions/ProductAction";
import { getUserId } from "../../../../../store/selectors/AuthSelectors";
import { useDispatch, useSelector } from "react-redux";

function ProductForm(props) {
  const { handleChange, errors, productId } = props;
  const products = useSelector(getProducts);
  const userId = useSelector(getUserId);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(productList(userId));
  }, []);

  return (
    <div className="form-group">
      <h3>Product</h3>
      <label className="text-label">Select Product*</label>
      <select
        defaultValue={""}
        value={productId}
        className={
          errors.productId
            ? "form-control custom-select border-danger"
            : "form-control custom-select"
        }
        onChange={(e) => handleChange("productId", e.target.value)}
      >
        <option value={""}>Select</option>
        {products.map((product, index) => (
          <option key={index} value={product.id}>
            {product.title}
          </option>
        ))}
      </select>
      {errors.productId ?
        <small className="text-danger">
          {errors.productId}
        </small> : null}
      {/* {errors.duration ? (
              <small className="text-danger">{errors.duration}</small>
            ) : formData.duration ? (
              <small>
                Upon approval the contest will run for {formData.duration}{" "}
                Day(s)
              </small>
            ) : null} */}
      {/* <div className="col-lg-6 mb-2">
          <DropZone
            name={"product.image"}
            src={product.image ? product.image.uri : null}
            title="Product Image"
            caption="Drag & Drop an image of the product"
            onlyImage={true}
            onChange={(file) => {
              handleChangeProduct("image", file);
            }}
            onRemove={() => handleChangeProduct("image", null)}
            error={errors.product && errors.product.image}
          width="70%"
          />
        </div> */}
      {/* <div className="col-lg-6 mb-2">
          <div className="form-group">
            <label className="text-label">Title*</label>
            <input
              value={product ? product.title : ""}
              type="text"
              name="name"
              className={
                errors.product && errors.product.title
                  ? "form-control border-danger"
                  : "form-control"
              }
              placeholder="Title"
              required
              onChange={(e) => handleChangeProduct("title", e.target.value)}
            />
            <small className="text-danger">
              {errors.product && errors.product.title}
            </small>
          </div>
          <div className="form-group">
            <label className="text-label">Price*</label>
            <input
              value={product ? product.price : ""}
              type="number"
              max={9999}
              maxLength={4}
              name="name"
              className={
                errors.product && errors.product.price
                  ? "form-control border-danger"
                  : "form-control"
              }
              placeholder="Price"
              required
              onChange={(e) => handleChangeProduct("price", e.target.value)}
              onBlur={() => {
                if (product && product.price.length > 4) {
                  handleChangeProduct("price", 9999);
                }
              }}
            />
            <small className="text-danger">
              {errors.product && errors.product.price}
            </small>
          </div>
        </div> */}
      {/* <div className="col-lg-12 mb-2">
          <div className="form-group">
            <label className="text-label">Description*</label>
            <textarea
              value={product ? product.description : ""}
              type="text"
              name="description"
              style={{ minHeight: "150px", maxHeight: "150px" }}
              className={
                errors.product && errors.product.description
                  ? "form-control border-danger"
                  : "form-control"
              }
              placeholder="Description"
              required
              onChange={(e) =>
                handleChangeProduct("description", e.target.value)
              }
            ></textarea>
            <small className="text-danger">
              {errors.product && errors.product.description}
            </small>
          </div>
        </div> */}
    </div>
  );
}

export default ProductForm;
