import React from "react";
import Switch from "react-switch";
import { Row } from "react-bootstrap";

function CreateRoleForm(props) {
  const {
    handleChangeName,
    handleChangePermissions,
    handleChangeAllPermissions,
    name,
    permissions,
    data,
    errors,
    togglePerms,
  } = props;

  const userPermissions = data.permissions.filter((perm) =>
    perm.key.includes(":user")
  );
  const rolePermissions = data.permissions.filter((perm) =>
    perm.key.includes(":role")
  );
  const appPermissions = data.permissions.filter((perm) =>
    perm.key.includes(":app")
  );
  const errorPermissions = data.permissions.filter((perm) =>
    perm.key.includes(":error")
  );
  const nftPermissions = data.permissions.filter((perm) =>
    perm.key.includes(":nft")
  );
  const treePermissions = data.permissions.filter((perm) =>
    perm.key.includes(":tree")
  );
  const financePermissions = data.permissions.filter((perm) =>
    perm.key.includes(":finance")
  );
  const settingPermissions = data.permissions.filter((perm) =>
    perm.key.includes(":setting")
  );
  const coinPermissions = data.permissions.filter((perm) =>
    perm.key.includes(":coin")
  );

  return (
    <section>
      <div className="row">
        <div className="col-lg-6 mb-2">
          <div className="form-group">
            <label className="text-label">Title*</label>
            <input
              value={name}
              type="text"
              name="name"
              className={
                errors.name ? "form-control border-danger" : "form-control"
              }
              placeholder="Title"
              required
              onChange={handleChangeName}
            />
            <small className="text-danger">{errors.name}</small>
          </div>
        </div>
        <div className="col-lg-12 mb-2">
          <div className="table-responsive">
            <div id="job_data" className="dataTables_wrapper ">
              <table
                className="display w-100 dataTable "
                id="example5"
                role="grid"
                aria-describedby="example5_info"
              >
                <thead>
                  <tr role="row">
                    <th className="sorting_asc" style={{ width: "35%" }}>
                      <Row style={{ alignItems: "center" }}>
                        <div className="mr-2">Module</div>
                        <Switch
                          onChange={() =>
                            handleChangeAllPermissions(!togglePerms)
                          }
                          checked={togglePerms}
                          onColor="#98C17A"
                          onHandleColor="#558B2F"
                          handleDiameter={20}
                          uncheckedIcon={false}
                          checkedIcon={false}
                          boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                          activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                          height={10}
                          width={35}
                        />
                      </Row>
                    </th>
                    <th className="sorting" style={{ textAlign: "center" }}>
                      View
                    </th>
                    <th className="sorting" style={{ textAlign: "center" }}>
                      Add
                    </th>
                    <th className="sorting" style={{ textAlign: "center" }}>
                      Edit
                    </th>
                    <th className="sorting" style={{ textAlign: "center" }}>
                      Delete
                    </th>
                  </tr>
                </thead>

                <tbody>
                  <tr className="odd" role="row">
                    <td className="sorting_1">
                      <strong>Users</strong>
                    </td>
                    {userPermissions.map((perm, index) => (
                      <td
                        key={index}
                        style={{
                          textAlign: "center",
                        }}
                      >
                        <input
                          value={perm.id}
                          type="checkbox"
                          className={""}
                          onChange={handleChangePermissions}
                          checked={permissions.includes(String(perm.id))}
                        />
                      </td>
                    ))}
                  </tr>
                  <tr className="odd" role="row">
                    <td className="sorting_1">
                      <strong>Roles</strong>
                    </td>
                    {rolePermissions.map((perm, index) => (
                      <td
                        key={index}
                        style={{
                          textAlign: "center",
                        }}
                      >
                        <input
                          value={perm.id}
                          type="checkbox"
                          className={""}
                          onChange={handleChangePermissions}
                          checked={permissions.includes(String(perm.id))}
                        />
                      </td>
                    ))}
                  </tr>
                  <tr className="odd" role="row">
                    <td className="sorting_1">
                      <strong>Mobile Apps</strong>
                    </td>
                    {appPermissions.map((perm, index) => (
                      <td
                        key={index}
                        style={{
                          textAlign: "center",
                        }}
                      >
                        <input
                          value={perm.id}
                          type="checkbox"
                          className={""}
                          onChange={handleChangePermissions}
                          checked={permissions.includes(String(perm.id))}
                        />
                      </td>
                    ))}
                  </tr>
                  <tr className="odd" role="row">
                    <td className="sorting_1">
                      <strong>NFts</strong>
                    </td>
                    {nftPermissions.map((perm, index) => (
                      <td
                        key={index}
                        style={{
                          textAlign: "center",
                        }}
                      >
                        <input
                          value={perm.id}
                          type="checkbox"
                          className={""}
                          onChange={handleChangePermissions}
                          checked={permissions.includes(String(perm.id))}
                        />
                      </td>
                    ))}
                  </tr>
                  <tr className="odd" role="row">
                    <td className="sorting_1">
                      <strong>Tree Donation</strong>
                    </td>
                    {treePermissions.map((perm, index) => (
                      <td
                        key={index}
                        style={{
                          textAlign: "center",
                        }}
                      >
                        <input
                          value={perm.id}
                          type="checkbox"
                          className={""}
                          onChange={handleChangePermissions}
                          checked={permissions.includes(String(perm.id))}
                        />
                      </td>
                    ))}
                  </tr>
                  <tr className="odd" role="row">
                    <td className="sorting_1">
                      <strong>Transactions & Wallet</strong>
                    </td>
                    {financePermissions.map((perm, index) => (
                      <td
                        key={index}
                        style={{
                          textAlign: "center",
                        }}
                      >
                        <input
                          value={perm.id}
                          type="checkbox"
                          className={""}
                          onChange={handleChangePermissions}
                          checked={permissions.includes(String(perm.id))}
                        />
                      </td>
                    ))}
                  </tr>
                  <tr className="odd" role="row">
                    <td className="sorting_1">
                      <strong>Coin Details</strong>
                    </td>
                    {coinPermissions.map((perm, index) => (
                      <td
                        key={index}
                        style={{
                          textAlign: "center",
                        }}
                      >
                        <input
                          value={perm.id}
                          type="checkbox"
                          className={""}
                          onChange={handleChangePermissions}
                          checked={permissions.includes(String(perm.id))}
                        />
                      </td>
                    ))}
                  </tr>
                  <tr className="odd" role="row">
                    <td className="sorting_1">
                      <strong>Settings</strong>
                    </td>
                    {settingPermissions.map((perm, index) => (
                      <td
                        key={index}
                        style={{
                          textAlign: "center",
                        }}
                      >
                        <input
                          value={perm.id}
                          type="checkbox"
                          className={""}
                          onChange={handleChangePermissions}
                          checked={permissions.includes(String(perm.id))}
                        />
                      </td>
                    ))}
                  </tr>
                  <tr className="odd" role="row">
                    <td className="sorting_1">
                      <strong>Error Logs</strong>
                    </td>
                    {errorPermissions.map((perm, index) => (
                      <td
                        key={index}
                        style={{
                          textAlign: "center",
                        }}
                      >
                        <input
                          value={perm.id}
                          type="checkbox"
                          className={""}
                          onChange={handleChangePermissions}
                          checked={permissions.includes(String(perm.id))}
                        />
                      </td>
                    ))}
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default CreateRoleForm;
