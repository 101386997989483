import React from "react";

const PERIODS = [
  { title: "24 Hours", value: 1 },
  { title: "3 Days", value: 3 },
  { title: "7 Days", value: 7 },
  { title: "15 Days", value: 15 },
  { title: "30 Days", value: 30 },
  { title: "45 Days", value: 45 },
  { title: "60 Days", value: 60 },
  { title: "90 Days", value: 90 },
];

function CreateCouponForm(props) {
  const { handleChange, errors, formData } = props;

  return (
    <section>
      <div className="row">
        <div className="col-lg-6 mb-2">
          <div className="form-group">
            <label className="text-label">Title (Coupon Code)*</label>
            <input
              value={formData.title}
              type="text"
              name="title"
              className={
                errors.title ? "form-control border-danger" : "form-control"
              }
              placeholder="Coupon Code"
              required
              onChange={(e) => handleChange("title", e.target.value)}
            />
            <small className="text-danger">{errors.title}</small>
          </div>
        </div>

        <div className="col-lg-6 mb-2">
          <div className="form-group">
            <label className="text-label">Description*</label>
            <input
              value={formData.description}
              type="text"
              name="description"
              className={
                errors.description
                  ? "form-control border-danger"
                  : "form-control"
              }
              placeholder="Description"
              required
              onChange={(e) => handleChange("description", e.target.value)}
            />
            <small className="text-danger">{errors.description}</small>
          </div>
        </div>
        <div className="col-lg-6 mb-2">
          <div className="form-group">
            <label className="text-label">Discount Type*</label>
            <select
              value={formData.discountType}
              defaultValue={""}
              className={
                errors.discountType
                  ? "form-control custom-select border-danger"
                  : "form-control custom-select"
              }
              onChange={(e) => handleChange("discountType", e.target.value)}
            >
              <option value={""}>Select</option>
              <option
                value={"Fixed"}
                selected={formData.discountType == "Fixed"}
              >
                Fixed
              </option>
              <option
                value={"Percentage"}
                selected={formData.discountType == "Percentage"}
              >
                Percentage
              </option>
            </select>
            <small className="text-danger">{errors.discountType}</small>
          </div>
        </div>
        <div className="col-lg-6 mb-2">
          <div className="form-group">
            <label className="text-label">Coupon Amount*</label>
            <input
              value={formData.discount}
              type="number"
              name="discount"
              className={
                errors.discount ? "form-control border-danger" : "form-control"
              }
              placeholder="Amount"
              required
              onChange={(e) => handleChange("discount", e.target.value)}
            />
            <small className="text-danger">{errors.discount}</small>
          </div>
        </div>
        {/* <div className="col-lg-6 mb-2">
          <div className="form-group">
            <label className="text-label">Reuseable*</label>
            <select
              value={formData.reuseable}
              defaultValue={""}
              className={
                errors.reuseable
                  ? "form-control custom-select border-danger"
                  : "form-control custom-select"
              }
              onChange={(e) => handleChange("reuseable", e.target.value)}
            >
              <option value={""}>Select</option>
              <option value={true} selected={formData.reuseable == true}>
                Yes
              </option>
              <option value={false} selected={formData.reuseable == false}>
                No
              </option>
            </select>
            {errors.reuseable ? (
              <small className="text-danger">{errors.reuseable}</small>
            ) : (
              <small>
                Select 'Yes' if this coupon can be used multiple times by the
                user
              </small>
            )}
          </div>
        </div> */}

        <div className="col-lg-6 mb-2">
          <div className="form-group">
            <label className="text-label">Coupon Expiry Date*</label>
            <input
              value={formData.expiredAt}
              type="date"
              name="expiredAt"
              className={
                errors.discount ? "form-control border-danger" : "form-control"
              }
              placeholder=""
              required
              onChange={(e) => handleChange("expiredAt", e.target.value)}
            />
            <small className="text-danger">{errors.expiredAt}</small>
            {errors.expiredAt ? (
              <small className="text-danger">{errors.expiredAt}</small>
            ) : formData.expiredAt ? (
              <small>
                upon adding to a user's account, This coupon will expire after{" "}
                {formData.expiredAt} Day(s) for the user
              </small>
            ) : null}
          </div>
        </div>
      </div>
      <hr />
      <div className="row">
        <div className="col-lg-6 mb-2">
          <div className="form-group">
            <label className="text-label">Minimum spend*</label>
            <input
              value={formData.minSpend}
              type="number"
              name="minSpend"
              className={
                errors.discount ? "form-control border-danger" : "form-control"
              }
              placeholder="No Minimum"
              required
              onChange={(e) => handleChange("minSpend", e.target.value)}
            />
            <small className="text-danger">{errors.minSpend}</small>
          </div>
        </div>
        <div className="col-lg-6 mb-2">
          <div className="form-group">
            <label className="text-label">Maximum spend*</label>
            <input
              value={formData.maxSpend}
              type="number"
              name="maxSpend"
              className={
                errors.discount ? "form-control border-danger" : "form-control"
              }
              placeholder="No Maximum"
              required
              onChange={(e) => handleChange("maxSpend", e.target.value)}
            />
            <small className="text-danger">{errors.maxSpend}</small>
          </div>
        </div>
      </div>
      <hr />
      <div className="col-lg-12 mb-2">
        <div className="form-group">
          <label className="text-label">Individual use only* </label>
          <input value={formData.individualUse} type="checkbox" name="individual" checked="true" className="ml-2" /><br />
          <small className="text-primary">Check this box if the coupon cannot be used in conjunction with other coupons.</small>
        </div>
      </div>
      <hr />
      <div className="col-lg-6 mb-2">
        <div className="form-group">
          <label className="text-label">Usage limit per coupon*</label>
          <input
            value={formData.usagePerCoupon}
            type="number"
            name="usagePerCoupon"
            className={
              errors.usagePerCoupon ? "form-control border-danger" : "form-control"
            }
            placeholder="Unlimited Usage"
            required
            onChange={(e) => handleChange("usagePerCoupon", e.target.value)}
          />
          <small className="text-danger">{errors.usagePerCoupon}</small>
        </div>
      </div>

      <div className="col-lg-6 mb-2">
        <div className="form-group">
          <label className="text-label">Usage limit per user*</label>
          <input
            value={formData.usagePerUser}
            type="number"
            name="usagePerUser"
            className={
              errors.usagePerUser ? "form-control border-danger" : "form-control"
            }
            placeholder="Unlimited Usage"
            required
            onChange={(e) => handleChange("usagePerUser", e.target.value)}
          />
          <small className="text-danger">{errors.usagePerUser}</small>
        </div>
      </div>


      {/* 
        <div className="col-lg-6 mb-2">
          <div className="form-group">
            <label className="text-label">Coupon Expiry Date*</label>
            <select
              value={formData.expiredAt}
              defaultValue={""}
              className={
                errors.expiredAt
                  ? "form-control custom-select border-danger"
                  : "form-control custom-select"
              }
              onChange={(e) => handleChange("expiredAt", e.target.value)}
            >
              <option value={""}>Select</option>
              {PERIODS.map((period, index) => (
                <option
                  key={index}
                  value={period.value}
                  selected={formData.expiredAt == period.value}
                >
                  {period.title}
                </option>
              ))}
            </select>


            {errors.expiredAt ? (
              <small className="text-danger">{errors.expiredAt}</small>
            ) : formData.expiredAt ? (
              <small>
                upon adding to a user's account, This coupon will expire after{" "}
                {formData.expiredAt} Day(s) for the user
              </small>
            ) : null}
          </div>
        </div> */}
    </section>
  );
}

export default CreateCouponForm;
