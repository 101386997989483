import {
    createFeedback as createFeedbackService,
    listAllFeedback as listAllFeedbackService,
    getFeedbackById as getFeedbackByIdService
} from "../../services/FeedbackService";

import { FEEDBACK_ADD_ACTION, FEEDBACK_LIST_ACTION, FEEDBACK_GET_ONE_ACTION } from "./FeedbackTypes";

export function createFeedBack(data) {
    return (dispatch) =>
        createFeedbackService({ ...data })
            .then((res) => {
                if (res.data.status === 200) {
                    dispatch(
                        createFeedbackConfirm({
                            ...data,
                            id: res.data.newFeedbackId,
                        })
                    );
                }
            })
            .catch((err) => {
                console.log(err);
            });
}

export function listAllFeedback() {
    return (dispatch) => {
        listAllFeedbackService()
            .then((res) => {
                if (res.data.status == 200) {
                    dispatch(feedbackListConfirm({ list: res.data.feedbacks }));
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };
}

export function getFeedbacksById(id) {
    return (dispatch) => {
        getFeedbackByIdService(id)
            .then((res) => {
                if (res.data.status == 200) {
                    dispatch(onefeedbackListConfirm({ list: res.data.feedbacks }));
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };
}

export function createFeedbackConfirm(payload) {
    return {
        type: FEEDBACK_ADD_ACTION,
        payload,
    };
}

export function feedbackListConfirm(payload) {
    return {
        type: FEEDBACK_LIST_ACTION,
        payload,
    };
}

export function onefeedbackListConfirm(payload) {
    return {
        type: FEEDBACK_GET_ONE_ACTION,
        payload,
    };
}

