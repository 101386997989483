import React, { Fragment, useState, useEffect, useRef } from "react";
import PageTitle from "../../../../layouts/PageTitle";
import { reduxForm } from "redux-form";
import {
  getUserId,
  getPilotCity,
} from "../../../../../store/selectors/AuthSelectors";
import { listTrees as listTreesAction } from "../../../../../store/actions/DonationActions";
import { loadingToggleAction } from "../../../../../store/actions/AuthActions";
import { getTreesList } from "../../../../../store/selectors/DonationSelector";
import { useSelector, useDispatch, connect } from "react-redux";
import { useHistory } from "react-router-dom";
import TreeWidget from "./treeWidget/TreeWidget";
import TreeCategory from "./TreeCategory";
import Dialog, {
  defaultDialogButtons,
  defaultDialogProps,
} from "../../../partials/Dialog";
import FormData from "form-data";
import { createTreeDonation } from "../../../../../services/DonationService";
import Loading from "../../../partials/Loading";
import TreeDetail from "./TreeDetail";
import { treeDonationValidator } from "../../../../../api/validator";
import { addTreeDonation } from "../../../../../store/actions/DonationActions";
import TreeForm from "./TreeForm";
import "../../../../../css/screen-transition.css";
import { RiSearchLine } from "react-icons/ri";
import { Button } from "react-bootstrap";
import { BiArrowBack } from 'react-icons/bi';
import { toast } from "react-toastify";


const DonationForm = (props) => {
  const { showLoading } = props;
  const dispatch = useDispatch();
  const userId = useSelector(getUserId);
  const pilotCity = useSelector(getPilotCity);
  const [category, setCategory] = useState(null);
  const categories = useSelector(getTreesList);
  const [treeData, setTreeData] = useState(null);
  const [errors, setErrors] = useState({});
  const [search, setSearch] = useState("");
  const [otherCategory, setotherCategory] = useState(false);
  const treeList = useRef();
  const [dialogProps, setDialogProps] = useState(defaultDialogProps);
  const history = useHistory();
  const [fadeAnim, setFadeAnim] = useState({ leftContainer: 'fade-in-left', rightContainer: 'fade-in-right', bottomContainer: 'container-down' })
  const [data, setData] = useState({
    userId,
    cityId: pilotCity.id,
    treeId: "",
    image: null,
    words: "",
    lat: "",
    lng: "",
    tree: {},
  });

  const [showLoader, setShowLoader] = useState(false);

  const searchItem = category ? category.trees.filter((item) => {
    if (search == "") {
      return item
    }
    else if (item.name.toLowerCase().includes(search.toLowerCase())) {
      return item
    }
  }) : null;

  function handleAnim(key, value) {
    setFadeAnim(pre => ({ ...pre, [key]: value }));
  }

  function handleSelectTree() {
    handleAnim('bottomContainer', 'fade-out-down');
    setTimeout(() => {
      setTreeData(null);
      setTimeout(() => {
        handleAnim('leftContainer', 'fade-in-right')
        handleAnim('rightContainer', 'fade-in-left')
        handleAnim('bottomContainer', 'container-down');
      }, 500)
    }, 500);
  }
  function handleSelectOtherBack() {
    handleAnim('bottomContainer', 'fade-out-down');
    setTimeout(() => {
      setotherCategory(false);
      setTimeout(() => {
        handleAnim('leftContainer', 'fade-in-right')
        handleAnim('rightContainer', 'fade-in-left')
        handleAnim('bottomContainer', 'container-down');
      }, 500)
    }, 500);
  }
  function handleChangeTree(key, value) {
    setData((pre) => ({ ...pre, tree: { ...pre.tree, [key]: value } }));
  }

  function onSubmit() {
    setShowLoader(true);
    try {
      dispatch(loadingToggleAction(true));
      treeDonationValidator
        .validate(data, {
          abortEarly: false,
        })
        .then(async (valid) => {
          const formdata = new FormData();
          for (const key in data) {
            if (key !== "image" && key !== "tree") {
              formdata.append(key, String(data[key]));
            } else if (key == "tree") {
              formdata.append(key, JSON.stringify(data[key]));
            } else {
              formdata.append(key, data.image.file);
            }
          }

          const res = await createTreeDonation(formdata);
          if (res.data.status == 200) {
            toast.success("Your tree successfully donated!")
            dispatch(addTreeDonation(res.data.newDonation));
            setShowLoader(false);
            history.push("/my-donations");
          }
        })
        .catch((err) => {
          setShowLoader(false);
          toast.warning("Oops! something went wrong")
          err.inner.forEach((e) => {
            setErrors((pre) => ({ ...pre, [e.path]: e.message }));
          });
        });
    } catch (error) {
      dispatch(loadingToggleAction(false));
    } finally {
      dispatch(loadingToggleAction(false));
    }
  }

  useEffect(() => {
    dispatch(listTreesAction('tree'));
  }, []);

  useEffect(() => {
    if (categories && categories.length && category == null) {
      setTimeout(() => {
        setCategory(categories[0]);
        treeList?.current?.show();
      }, 300);
    }
  }, [categories]);


  function handleChange(key, value) {
    setData((pre) => ({
      ...pre,
      [key]: value,
    }));
  }

  function handleChangeCategory(cat) {
    if (cat.title == "Other") {
      handleAnim('leftContainer', 'fade-out-left');
      handleAnim('rightContainer', 'fade-out-right');
      setTimeout(() => {
        setotherCategory(true);
        setTimeout(() => {
          handleAnim('bottomContainer', 'fade-in-up');
        }, 500)
      }, 500)
    } else {
      handleAnim('rightContainer', 'fade-out-right');
      setTimeout(() => {
        setCategory(cat);
        handleAnim('rightContainer', 'fade-in-left');
      }, 500)
    }
  }

  function handleOnTreeClick(tree) {
    handleAnim('leftContainer', 'fade-out-left');
    handleAnim('rightContainer', 'fade-out-right');
    setTimeout(() => {
      setTreeData(tree);
      setTimeout(() => {
        handleAnim('bottomContainer', 'fade-in-up');
      }, 500)
    }, 500)
    handleChange("treeId", tree.id);
  }

  function handleAnimControl() {
    handleAnim('leftContainer', 'fade-out-left');
    handleAnim('rightContainer', 'fade-out-right');
  }

  function dialog(
    title = "",
    text = "",
    buttons = defaultDialogButtons(),
    onClose = () => setDialogProps(defaultDialogProps)
  ) {
    setDialogProps({ open: true, title, text, ...buttons, onClose });
  }

  return (
    <Fragment>
      {showLoading ? <Loading /> : null}
      <PageTitle
        pageTitle={"Add Tree Donation"}
        pageContent={" "}
        activeMenu="Add Donation"
        motherMenu="Dashboard"
      />
      {otherCategory == true ? (
        <div className={fadeAnim.bottomContainer}>
          <TreeForm
            data={data}
            errors={errors}
            showLoader={showLoader}
            handleChangeTree={handleChangeTree}
            onSubmit={onSubmit}
            handleSelectTree={handleSelectTree}
            setTreeData={setTreeData}
            handleChange={handleChange}
            defaultDialogButtons={defaultDialogButtons}
            dialog={dialog}
            handleSelectOtherBack={handleSelectOtherBack}
          />
        </div>
      ) : treeData == null ? (
        <div className="row">
          <div className={"col-xl-2 col-xxl-3 " + fadeAnim.leftContainer}>
            <Button className="primary mb-3" onClick={() => { handleAnimControl(); history.goBack() }}>
              <BiArrowBack size={12} /> Back
            </Button>
            <TreeCategory
              categories={categories}
              {...{ handleChangeCategory }}
            />
          </div>
          <div className={"row col-xl-10 col-xxl-9 " + fadeAnim.rightContainer}>
            <div className="col-lg-12 d-flex justify-content-center">
              {
                category && category.trees ? (
                  <div className="mb-3">
                    <input
                      type="text"
                      className="form-control"
                      onChange={(e) => setSearch(e.target.value)}
                      placeholder="Search Tree"
                      style={{
                        width: "250px",
                        height: "35px",
                        borderRadius: "30px",
                        marginLeft: "10px"
                      }}
                    />
                  </div>
                ) : null
              }
            </div>
            {category ? (
              <div className={"col-lg-12"}>
                <h3>{category.title}</h3>
              </div>
            ) : null}
            {
              category ? searchItem.map((tree, index) => (
                <div className={"col-md-4"}>
                  <TreeWidget
                    key={index}
                    tree={tree}
                    handleOnTreeClick={handleOnTreeClick}
                  />
                </div>
              ))
                : null}
          </div>
        </div>
      ) : (
        <div className={fadeAnim.bottomContainer}>
          <TreeDetail
            showLoader={showLoader}
            treeData={treeData}
            data={data}
            errors={errors}
            onSubmit={onSubmit}
            handleSelectTree={handleSelectTree}
            setTreeData={setTreeData}
            handleChange={handleChange}
            defaultDialogButtons={defaultDialogButtons}
            dialog={dialog}
          />
        </div>
      )}
      <Dialog {...dialogProps} />
    </Fragment>
  );
};

const formWizard = reduxForm({
  form: "donationForm",
})(DonationForm);

const submitFormWizard = connect((state) => {
  return { state };
})(formWizard);

const mapStateToProps = (state) => {
  return {
    errorMessage: state.auth.errorMessage,
    successMessage: state.auth.successMessage,
    showLoading: state.auth.showLoading,
  };
};

export default connect(mapStateToProps)(submitFormWizard);
