import api from "./AxiosInstance";

export function listProducts(userId) {
    return api.get("products/by/u/" + userId);
}

export function createProducts(data) {
    return api.post("products", data);
}

export function updateProducts(productId, data) {
    return api.patch("products/" + productId, data);
}


export function removeProducts(productId) {
    return api.delete("products/" + productId);
}

export function getProductById(productId) {
    return api.get("products/by/" + productId);
}