import React, { Fragment, useEffect, useState } from "react";
import { getWalletAccount } from "../../../store/selectors/AuthSelectors";
import { useSelector, useDispatch, connect } from "react-redux";
import FilteringTable from "../table/FilteringTable/FilteringTable";
import { ColumnFilter } from "../table/FilteringTable/ColumnFilter";
import moment from "moment";
import { ArrowUp, ArrowDown, Check } from "../zenix/Transactions/TableData";
import { getTransactions } from "../../../store/selectors/WalletSelector";
import { listTransactionsAction } from "../../../store/actions/WalletActions";
import { Button } from "react-bootstrap";
import Modal from "../partials/Modal";
import Detail from "./partials/wallet/Detail";

const Transactions = (props) => {
  const dispatch = useDispatch();
  const data = useSelector(getTransactions);
  const account = useSelector(getWalletAccount);

  const defaultForm = {
    open: false,
    title: "",
    data: {},
    onClose: () => {},
  };

  const [approveFormProps, setApproveFormProps] = useState(defaultForm);
  const headerBtns = [];

  useEffect(() => {
    dispatch(listTransactionsAction(account.address));
  }, []);

  const columns = [
    {
      Header: "#",
      Footer: "",
      accessor: "method",
      Filter: ColumnFilter,
      Cell: (cell) => {
        const method = cell.row.original.method;
        const address = account.address.toLowerCase();
        const from = cell.row.original.from.toLowerCase();
        const to = cell.row.original.to.toLowerCase();
        return (method == "transfer_tokenAmount" || method == "nft_transfer") &&
          address == from ? (
          <ArrowUp />
        ) : (method == "transfer_tokenAmount" || method == "nft_transfer") &&
          address == to ? (
          <ArrowDown />
        ) : method == "approve_tokenAmount" ? (
          <Check />
        ) : null;
      },
    },
    {
      Header: "Title",
      Footer: "Title",
      accessor: "title",
      Filter: ColumnFilter,
    },
    {
      Header: "Amount",
      Footer: "Amount",
      Filter: ColumnFilter,
      accessor: "amount",
      Cell: (cell) => {
        if (cell.row.original.amount) return cell.row.original.amount + " LET";
        else return "N/A";
      },
    },
    {
      Header: "Gas Used",
      Footer: "Gas Used",
      Filter: ColumnFilter,
      accessor: "gasUsed",
      Cell: (cell) => {
        return cell.row.original.gasUsed + " wei";
      },
    },
    {
      Header: "Age",
      Footer: "Age",
      Filter: ColumnFilter,
      accessor: "createdAt",
      Cell: (cell) => {
        if (cell.row.original.createdAt) {
          return moment(cell.row.original.createdAt).fromNow();
        }
      },
    },
    {
      Header: "Actions",
      Footer: "Actions",
      Filter: ColumnFilter,
      accessor: "tx",
      Cell: (cell) => {
        return (
          <Button
            onClick={() => {
              window.open(
                "https://mumbai.polygonscan.com/tx/" + cell.row.original.tx,
                "_blank"
              );
              // setApproveFormProps({
              //   open: true,
              //   title: "Your Details",
              //   data: cell.row.original,
              //   yesBtn: {
              //     title: "More Details",
              //     onTap: () => {
              //       window.open(
              //         "https://mumbai.polygonscan.com/tx/" +
              //           cell.row.original.tx,
              //         "_blank"
              //       );
              //     },
              //   },
              //   noBtn: {
              //     title: "Dismiss",
              //     onTap: () => {
              //       setApproveFormProps(defaultForm);
              //     },
              //   },
              //   onClose: () => {
              //     setApproveFormProps(defaultForm);
              //   },
              // });
            }}
            className="btn-link mr-3"
          >
            Detail
          </Button>
        );
      },
    },
  ];

  return (
    <Fragment>
      <div className="col-xl-12 col-xxl-12">
        <FilteringTable
          data={data}
          columns={columns}
          title="Transactions"
          headerBtns={headerBtns}
        />
      </div>
      <Modal {...approveFormProps}>
        <Detail data={data} {...approveFormProps} />
      </Modal>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    successMessage: state.auth.successMessage,
    showLoading: state.auth.showLoading,
  };
};

export default connect(mapStateToProps)(Transactions);
