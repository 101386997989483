import React, { useEffect, useState } from "react";
import DropZone from "../../../partials/DropZone";
import Loading from "../../../partials/Loading";

function UploadNFTForm(props) {
  const { handleChange, errors, formData, isLoading } = props;

  return (
    <section>
      <div className="row">
        <div className="col-lg-6 col-xs-12 text-center">
          <DropZone
            src={formData.image ? formData.image.uri : null}
            title="NFT Clip Art*"
            caption="Drag & Drop a image here. (350 x 350 recommended)"
            onlyImage={true}
            onChange={(file) => handleChange("image", file)}
            onRemove={() => handleChange("image", null)}
            error={errors.image}
            width="350px"
            height="350px"
          />
        </div>
        <div className="col-lg-6 col-xs-12">
          <div className="col-lg-12 mb-2">
            <div className="form-group">
              <label className="text-label">NFT Title</label>
              <input
                value={formData.title}
                type="text"
                name="title"
                className={
                  errors.title ? "form-control border-danger" : "form-control"
                }
                placeholder="Title"
                onChange={(e) => handleChange("title", e.target.value)}
              />
              <small className="text-danger">{errors.title}</small>
            </div>
          </div>
          <div className="col-lg-12">
            <div className="form-group">
              <label className="text-label">NFT Description</label>
              <textarea
                value={formData.description}
                type="text"
                name="description"
                style={{ height: "220px" }}
                className={
                  errors.description
                    ? "form-control border-danger"
                    : "form-control"
                }
                placeholder='This NFT represents a photograph of a tree that was planted by the owner. The image captures the natural beauty of the tree as it grows and thrives. By owning this NFT, you not only support the value of digital art and blockchain technology, but also contribute to the preservation of nature. As a unique and one-of-a-kind NFT, this "TerraNova" NFT is a meaningful addition to any digital art collection. The NFT is stored on the blockchain, ensuring that the ownership and authenticity of the digital asset are verifiable and secure. Join the movement for sustainable living and own a piece of natural beauty with "TerraNova" NFT.'
                onChange={(e) => handleChange("description", e.target.value)}
              ></textarea>
              <small className="text-danger">{errors.description}</small>
            </div>
          </div>
        </div>
      </div>
      {isLoading ? <Loading noBackdrop={true} /> : null}
    </section>
  );
}

export default UploadNFTForm;
