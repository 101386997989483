import {
    createInterests as createInterestService,
    removeInterests as removeInterestService,
    listInterests as listInterestService,
    listAllInterests as listAllInterestsService
} from "../../services/InterestService";

import {
    CREATE_INTEREST_ACTION,
    DELETE_INTEREST_ACTION,
    LIST_INTEREST_ACTION,
    LIST_ALL_INTEREST_ACTION
} from "./InterestType";


export function createInterestActions(data) {
    return (dispatch) =>
        createInterestService(data)
            .then((res) => {
                if (res.data.status === 200) {
                    dispatch(createInterestConfirm(res.data.interest));
                }
            })
            .catch((err) => {
                console.log(err);
            });
}


export function createInterestConfirm(payload) {
    return {
        type: CREATE_INTEREST_ACTION,
        payload,
    };
}


export function removeInterestAction(userId, collectionId) {
    return (dispatch) =>
        removeInterestService(userId, collectionId)
            .then((res) => {
                if (res.data.status === 200) {
                    dispatch(removeInterestConfirm(userId, collectionId));
                }
            })
            .catch((err) => {
                console.log(err);
            });
}

export function removeInterestConfirm(payload) {
    return {
        type: DELETE_INTEREST_ACTION,
        payload,
    };
}

export function listInterestAction(userId) {
    return (dispatch) => {
        listInterestService(userId)
            .then((res) => {
                if (res.data.status == 200) {
                    dispatch(listInterestConfirm(res.data.interests));
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };
}


export function listInterestConfirm(payload) {
    return {
        type: LIST_INTEREST_ACTION,
        payload,
    };
}



export function listAllInterestActions() {
    return (dispatch) => {
        listAllInterestsService()
            .then((res) => {
                if (res.data.status == 200) {
                    dispatch(listAllInterestConfirmAction({ list: res.data.interests }));
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };
}


export function listAllInterestConfirmAction(payload) {
    return {
        type: LIST_ALL_INTEREST_ACTION,
        payload,
    };
}



