import React from "react";
import { Pagination } from "react-bootstrap";
import { Tab, Nav, Badge, Button } from "react-bootstrap";
import {
  useTable,
  useGlobalFilter,
  useFilters,
  usePagination,
} from "react-table";
import { GlobalFilter } from "./GlobalFilter";
import "./filtering.css";

export const FilteringTable = (props) => {
  const { data, columns, title, tabData, headerBtns, searchText } = props;
  //   const columns = useMemo(() => COLUMNS, []);
  //   const data = useMemo(() => MOCK_DATA, []);
  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0 },
    },
    useFilters,
    useGlobalFilter,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    state,
    page,
    gotoPage,
    pageCount,
    pageOptions,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    setGlobalFilter,
  } = tableInstance;

  const { globalFilter, pageIndex } = state;

  const active = pageIndex + 1;
  let items = [];

  for (let number = 1; number <= pageCount; number++) {
    items.push(
      <Pagination.Item
        onClick={() => gotoPage(number - 1)}
        key={number}
        active={number === active}
      >
        {number}
      </Pagination.Item>
    );
  }
  const pagination = (size, gutter, variant, bg, circle) => (
    <Pagination
      size={size}
      style={{ cursor: "pointer" }}
      className={`mt-4  ${gutter ? "pagination-gutter" : ""} ${
        variant && `pagination-${variant}`
      } ${!bg && "no-bg"} ${circle && "pagination-circle"}`}
    >
      <Pagination.First active={canPreviousPage} onClick={() => gotoPage(0)}>
        {"<<"}
      </Pagination.First>
      <li className="page-item page-indicator">
        <Pagination.Prev
          onClick={() => previousPage()}
          active={canPreviousPage}
        >
          {"<"}
        </Pagination.Prev>
      </li>
      {items}
      <li className="page-item page-indicator">
        <Pagination.Next onClick={() => nextPage()} active={canNextPage}>
          {">"}
        </Pagination.Next>
      </li>
      <li className="page-item page-indicator">
        <Pagination.Last
          active={canNextPage}
          onClick={() => gotoPage(pageCount - 1)}
        >
          {">>"}
        </Pagination.Last>
      </li>
    </Pagination>
  );

  return (
    <>
      <div className="card">
        <div className="card-header">
          <h4 className="card-title">{title}</h4>
          <div>
            {headerBtns && headerBtns.length
              ? headerBtns.map((btn, index) => (
                  <Button
                    key={index}
                    variant="primary"
                    onClick={btn.onClick}
                    className="ml-1"
                  >
                    {btn.title}
                  </Button>
                ))
              : null}
          </div>
        </div>
        <div className="card-body">
          {tabData && tabData.length ? (
            <div className="default-tab mb-5">
              <Tab.Container defaultActiveKey={tabData[0].name.toLowerCase()}>
                <Nav as="ul" className="nav-tabs">
                  {tabData.map((data, i) => (
                    <Nav.Item as="li" key={i}>
                      <Nav.Link
                        eventKey={data.name.toLowerCase()}
                        onClick={data.onClick}
                      >
                        {data.name}
                        {data.badge ? (
                          <Badge className="ml-2" variant="">
                            {data.badge}
                          </Badge>
                        ) : null}
                      </Nav.Link>
                    </Nav.Item>
                  ))}
                </Nav>
              </Tab.Container>
            </div>
          ) : null}
          <div className="table-responsive">
            <GlobalFilter
              searchText={searchText}
              filter={globalFilter}
              setFilter={setGlobalFilter}
            />
            <table
              {...getTableProps()}
              className="table filtering-table table-responsive-lg"
            >
              <thead>
                {headerGroups.map((headerGroup) => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                      <th {...column.getHeaderProps()}>
                        {column.render("Header")}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()} className="">
                {page.map((row) => {
                  prepareRow(row);
                  return (
                    <tr {...row.getRowProps()}>
                      {row.cells.map((cell) => {
                        return (
                          <td {...cell.getCellProps()}>
                            {" "}
                            {cell.render("Cell")}{" "}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
            <div className="d-flex justify-content-between">
              <span>
                Page{" "}
                <strong>
                  {pageIndex + 1} of {pageOptions.length}
                </strong>
                {""}
              </span>
              <span className="table-index">
                Go to page :{" "}
                <input
                  type="number"
                  className="ml-2"
                  defaultValue={pageIndex + 1}
                  onChange={(e) => {
                    const pageNumber = e.target.value
                      ? Number(e.target.value) - 1
                      : 0;
                    gotoPage(pageNumber);
                  }}
                />
              </span>
            </div>
            <div
              className=""
              style={{
                display: "flex",
                justifyContent: "center",
                marginBlock: "15px",
              }}
            >
              {pagination("", true, "xl", true, true)}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default FilteringTable;
