import React, { Fragment, useState, useEffect } from "react";
import PageTitle from "../../../../layouts/PageTitle";
import SContest from "../../../../../images/svg/contest.svg";
import { Button } from "react-bootstrap";

const CitiesForm = () => {
  return (
    <Fragment>
      <PageTitle
        pageTitle={"Add Cities"}
        pageContent={" "}
        activeMenu="Add Cities"
        motherMenu="Dashboard"
      />
      <div className="row">
        <div className="col-md-12">
          <div className="card">
            <div className="card-header justify-content-start">
              <img src={SContest} alt="" width="50px" height="50px" />
            </div>
            <div className="card-body text-start">
              <h4 className="card-title">Add Cities</h4>
              <label>Country</label>
              <input type="text" className="form-control"></input>
              <label className="mt-2">City</label>
              <input type="text" className="form-control"></input>
              <label className="mt-2">Country Code</label>
              <input type="text" className="form-control"></input>
              <label className="mt-2">Phone Code</label>
              <input type="text" className="form-control"></input>
              <label className="mt-2">Phone Pattern</label>
              <input type="text" className="form-control"></input>
              <label className="mt-2">Currency Code</label>
              <input type="text" className="form-control"></input>
              <label className="mt-2">Latitude</label>
              <input type="text" className="form-control"></input>
              <label className="mt-2">Longitude</label>
              <input type="text" className="form-control"></input>
              <Button className="mt-2">Submit</Button>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
export default CitiesForm;
