import React, { Fragment, useEffect, useState } from "react";
import PageTitle from "../../../../layouts/PageTitle";
import { reduxForm } from "redux-form";
import {
  getUserId,
  getUser,
} from "../../../../../store/selectors/AuthSelectors";
import { useSelector, useDispatch, connect } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { AiOutlineArrowLeft } from "react-icons/ai";
import FormData from "form-data";
import {
  editCollectionDonation,
  getCollectionById,
} from "../../../../../services/DonationService";
import Loading from "../../../partials/Loading";
import { editCollectionValidator } from "../../../../../api/validator";
import { updateCollectionDonation } from "../../../../../store/actions/DonationActions";
import "../../../../../css/screen-transition.css";
import { Button } from "react-bootstrap";
import CollectionForm from "./CollectionForm";
import { toast } from "react-toastify";
import { removeDocumentService } from "../../../../../services/DocumentService";
import Dialog from "../../../partials/Dialog";

const EditCollection = (props) => {
  const { showLoading } = props;
  let { collectionId } = useParams();
  const dispatch = useDispatch();
  const user = useSelector(getUser);
  const userId = useSelector(getUserId);
  const [errors, setErrors] = useState({});
  const history = useHistory();
  const [showLoader, setShowLoader] = useState(true);

  const [data, setData] = useState({
    userId,
    image: null,
    banner: null,
    title: "",
    s_description: "",
    l_description: "",
    type: "",
    type_value: "",
    countryId: "",
    region: "",
    goal: "",
    duration: "",
    duration_type: "",
    duration_value: "",
    oName: user ? (user.first_name || "") + " " + (user.last_name || "") : "",
    oAddress: "",
    oEmail: user ? user.email : "",
    oPhone: "",
    oPhone_code: "",
    otherCon: "",
    otherCon_code: "",
    whatsapp_number: "",
    wa_num_code: "",
    budget: "",
    currency_code: "",
    documents: [],
    isGroupProject: false,
    groupProjectId: null,
    current_stage:"",
  });

  const [helperData, setHelperData] = useState({
    oPhone_city: null,
    otherCon_city: null,
    whatsapp_number_city: null,
  });

  const [previewDocs, setPreviewDocs] = useState({
    banner: null,
    image: null,
    docs: [],
  });

  const [fadeAnim, setFadeAnim] = useState({
    leftContainer: "fade-in-left",
    rightContainer: "fade-in-right",
  });

  const defaultDialog = {
    open: false,
    title: "",
    text: "",
    onClose: () => {},
  };

  const [dialogProps, setDialogProps] = useState(defaultDialog);

  useEffect(() => {
    getData(collectionId);
  }, []);

  function handleAnim(key, value) {
    setFadeAnim((pre) => ({ ...pre, [key]: value }));
  }

  function handleAnimControl() {
    handleAnim("leftContainer", "fade-out-left");
    handleAnim("rightContainer", "fade-out-right");
  }

  function removeDocument(key) {
    setData((pre) => ({
      ...pre,
      documents: pre.documents.filter((_, index) => index !== key),
    }));
    setErrors((pre) => ({ ...pre, [key]: "" }));
  }

  async function removePreviewDoc(id) {
    try {
      const { data } = await removeDocumentService(id);
      if (data.status === 200) {
        setPreviewDocs((pre) => ({
          ...pre,
          docs: pre.docs.filter((d) => d.id !== id),
        }));
        toast.success(data.message);
      } else {
        toast.error(data.message);
      }
    } catch (error) {}
  }

  function dialog(
    title = "",
    caption = "",
    btns = {
      yesBtn: { title: "", onTap: () => {} },
      noBtn: { title: "", onTap: () => {} },
    },
    onClose = null
  ) {
    setDialogProps({
      open: true,
      title: title,
      text: caption,
      yesBtn: {
        title: "Yes",
        ...btns.yesBtn,
      },
      noBtn: {
        title: "No",
        ...btns.noBtn,
      },
      onClose: onClose ? onClose() : () => setDialogProps(defaultDialog),
    });
  }

  function handleHelperData(key, value) {
    setHelperData((pre) => ({ ...pre, [key]: value }));
  }

  function handleChange(key, value) {
    if (key === "documents") {
      const uploadedFilesCount = value && value.length ? value.length : 0;
      const totalFilesCount = data.documents.length + uploadedFilesCount;
      if (data.documents.length === 5 || totalFilesCount > 5) {
        setErrors((pre) => ({ ...pre, [key]: "Max 5 documents are allowed" }));
      } else {
        setData((pre) => ({ ...pre, [key]: [...pre[key], ...value] }));
        setErrors((pre) => ({ ...pre, [key]: "" }));
      }
    } else {
      setData((pre) => ({ ...pre, [key]: value }));
      setErrors((pre) => ({ ...pre, [key]: "" }));
    }
  }

  function handleChangeType(key, value) {
    if (key === "type") {
      let types = data[key] ? data[key].split(",") : [];
      if (types.includes(value)) {
        types.splice(
          types.findIndex((t) => t === value),
          1
        );
        setData((pre) => ({
          ...pre,
          [key]: types.join(","),
        }));
      } else {
        types.push(value);
        setData((pre) => ({
          ...pre,
          [key]: types.join(","),
        }));
      }
      setErrors((pre) => ({ ...pre, [key]: "" }));
    } else {
      setData((pre) => ({ ...pre, [key]: value }));
      setErrors((pre) => ({ ...pre, [key]: "" }));
    }
  }

  function getData(collectionId) {
    getCollectionById(collectionId)
      .then((res) => {
        if (res.data.status == 200) {
          const collection = res.data.collection;
          setData((pre) => ({
            ...pre,
            ...collection,
            duration: collection.duration.includes("day")
              ? collection.duration.replace("day", "d")
              : collection.duration.includes("days")
              ? collection.duration.replace("days", "d")
              : !isNaN(collection.duration)
              ? collection.duration + "d"
              : collection.duration,
            duration_value:
              typeof collection.duration_value !== "string"
                ? collection.duration
                : collection.duration_value,
            duration_type:
              typeof collection.duration_type !== "string"
                ? "Days"
                : collection.duration_type,
            oPhone: collection.oPhone
              ? collection.oPhone.includes(collection.oPhone_code)
                ? collection.oPhone.replace(collection.oPhone_code, "")
                : collection.oPhone.includes(user.city.phone_code)
                ? collection.oPhone.replace(user.city.phone_code, "")
                : collection.oPhone
              : "",
            otherCon: collection.otherCon
              ? collection.otherCon.includes(collection.otherCon_code)
                ? collection.otherCon.replace(collection.otherCon_code, "")
                : collection.otherCon.includes(user.city.phone_code)
                ? collection.otherCon.replace(user.city.phone_code, "")
                : collection.otherCon
              : "",
            whatsapp_number: collection.whatsapp_number
              ? collection.whatsapp_number.includes(collection.wa_num_code)
                ? collection.whatsapp_number.replace(collection.wa_num_code, "")
                : collection.whatsapp_number.includes(user.city.phone_code)
                ? collection.whatsapp_number.replace(user.city.phone_code, "")
                : collection.whatsapp_number
              : "",
            oPhone_code: collection.oPhone_code
              ? collection.oPhone_code
              : collection.oPhone.includes(user.city.phone_code)
              ? user.city.phone_code
              : "",
            otherCon_code: collection.otherCon_code
              ? collection.otherCon_code
              : collection.otherCon.includes(user.city.phone_code)
              ? user.city.phone_code
              : "",
            wa_num_code: collection.wa_num_code
              ? collection.wa_num_code
              : collection.whatsapp_number.includes(user.city.phone_code)
              ? user.city.phone_code
              : "",
            image: null,
            banner: null,
            documents: [],
          }));
          setPreviewDocs((pre) => ({
            image: collection.image || null,
            banner: collection.banner || null,
            docs: collection.documents || [],
          }));
        }
      })
      .catch((err) => {
        console.log("err", err);
      })
      .finally(() => {
        setShowLoader(false);
      });
  }

  function onSubmit() {
    setShowLoader(true);
    try {
      editCollectionValidator
        .validate(data, {
          abortEarly: false,
        })
        .then(async (valid) => {
          let err = {};
          if (data.image == null && previewDocs.image == null) {
            err = { ...err, image: "This field is required" };
          }
          if (data.banner == null && previewDocs.banner == null) {
            err = { ...err, banner: "This field is required" };
          }
          if (Object.keys(err).length) {
            setErrors((pre) => ({ ...pre, ...err }));
            setShowLoader(false);
            toast.warning("Please address all exceptions");
            return;
          }
          const formdata = new FormData();
          for (const key in data) {
            if (
              !["donations", "user", "groupProjects", "transactions"].includes(
                key
              )
            ) {
              if (
                (key == "image" && data.image) ||
                (key == "banner" && data.banner)
              ) {
                formdata.append(key, data[key].file);
              } else if (key === "documents") {
                if (data[key] && data[key].length) {
                  for (let i = 0; i < data[key].length; i++) {
                    formdata.append("documents", data[key][i].file);
                  }
                }
              }
              //  else if (key === "oPhone" || key === "otherCon") {
              //   formdata.append(key, String(user.city.phone_code + data[key]));
              // }
              else {
                formdata.append(key, String(data[key]));
              }
            }
          }
          const res = await editCollectionDonation(formdata);
          if (res.data.status == 200) {
            toast.success("Project successfully updated!");
            dispatch(updateCollectionDonation(res.data.updatedCollection));
            // history.push("/my-projects");
          }
          setShowLoader(false);
        })
        .catch((err) => {
          setShowLoader(false);
          toast.warning("Please address all exceptions");
          if (err.inner) {
            let errs = {};
            err.inner.forEach((e) => {
              errs = { ...errs, [e.path]: e.message };
            });
            setErrors(errs);
          }
        });
    } catch (error) {}
  }

  return (
    <Fragment>
      <PageTitle
        pageTitle={"Edit Project"}
        pageContent={" "}
        activeMenu="Edit Project"
        motherMenu="Dashboard"
      />
      <Button
        style={{ marginBottom: "20px" }}
        onClick={() => {
          handleAnimControl();
          setTimeout(() => {
            history.goBack();
          }, 300);
        }}
      >
        <AiOutlineArrowLeft className="mr-1" />
        Back
      </Button>
      <div className={fadeAnim.rightContainer}>
        <CollectionForm
          {...{
            showLoader,
            data,
            removeDocument,
            errors,
            onSubmit,
            handleChange,
            handleChangeType,
            handleHelperData,
            isEditing: true,
            previewDocs,
            collectionId,
            helperData,
            removePreviewDoc,
            dialog,
          }}
        />
      </div>
      {showLoading ? <Loading /> : null}
      <Dialog {...dialogProps} />
    </Fragment>
  );
};

const formWizard = reduxForm({
  form: "donationForm",
})(EditCollection);

const submitFormWizard = connect((state) => {
  return { state };
})(formWizard);

const mapStateToProps = (state) => {
  return {
    errorMessage: state.auth.errorMessage,
    successMessage: state.auth.successMessage,
    showLoading: state.auth.showLoading,
  };
};

export default connect(mapStateToProps)(submitFormWizard);
