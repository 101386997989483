import React, { Fragment, useContext, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";
import loadable from "@loadable/component";
import pMinDelay from "p-min-delay";
import { ThemeContext } from "../../../context/ThemeContext";
import {
  getFullName,
  getRoleId,
  getUserCreateDate,
  getUserId,
  getWalletAccount,
} from "../../../store/selectors/AuthSelectors";
import moment from "moment";

import Donut from "../zenix/MyWallets/Donut";
import WalletTab from "../zenix/MyWallets/WalletTab";
import ContactSlider from "../zenix/Home/ContactSlider";
import SwiperSlider2 from "../zenix/MyWallets/SwiperSlider2";
import { QuickTransfer } from "../zenix/MyWallets/QuickTransfer";
import { useDispatch, useSelector } from "react-redux";
import { getWalletTransactions } from "../../../store/selectors/WalletSelector";
import { listWalletDetails } from "../../../store/actions/WalletActions";
import { nFormatter } from "../../../api/constants";
import { getDashboard } from "../../../store/selectors/DashboardSelector";
import Dialog from "../partials/Dialog";
import Modal from "../partials/Modal";
import ApproveAllowanceForm from "./partials/wallet/ApproveAllowanceForm";
import TransferForm from "./partials/wallet/TransferForm";
import {
  approveAllowanceSchema,
  transferAmountSchema,
} from "../../../services/schema";
import { getApprove, getTransfer } from "../../../services/WalletService";
import { dashboardList } from "../../../store/actions/DashboardAction";
import Loader from "../../../../src/jsx/loader.json";
import Lottie from "lottie-react";
import { toast } from "react-toastify";

const Wallet = () => {
  const { background } = useContext(ThemeContext);
  const dispatch = useDispatch();
  const data = useSelector(getDashboard);
  const userId = useSelector(getUserId);
  const fullName = useSelector(getFullName);
  const userCreatedAt = useSelector(getUserCreateDate);
  const walletAccount = useSelector(getWalletAccount);
  const roleId = useSelector(getRoleId);
  const walletAddress = walletAccount.address;
  const [showLoader, setShowLoader] = useState(false);

  const defaultDialog = {
    open: false,
    title: "",
    text: "",
    onClose: () => {},
  };
  const defaultForm = {
    open: false,
    title: "",
    data: {},
    onClose: () => {},
  };
  const [dialogProps, setDialogProps] = useState(defaultDialog);

  const [approveFormErrors, setApproveFormErrors] = useState(
    approveAllowanceSchema.getDefault()
  );
  const [approveFormData, setApproveFormData] = useState(
    approveAllowanceSchema.getDefault()
  );
  const [approveFormProps, setApproveFormProps] = useState(defaultForm);

  const [transferFormErrors, setTransferFormErrors] = useState(
    transferAmountSchema.getDefault()
  );
  const [transferFormData, setTransferFormData] = useState(
    transferAmountSchema.getDefault()
  );
  const [transferFormProps, setTransferFormProps] = useState(defaultForm);

  useEffect(() => {
    dispatch(listWalletDetails(userId));
  }, []);

  function handleChangeApproveForm(key, value) {
    setApproveFormData((pre) => ({
      ...pre,
      [key]: value,
    }));
    setApproveFormErrors((pre) => ({
      ...pre,
      [key]: "",
    }));
  }

  function handleChangeTransferForm(key, value) {
    setTransferFormData((pre) => ({
      ...pre,
      [key]: value,
    }));
    setTransferFormErrors((pre) => ({
      ...pre,
      [key]: "",
    }));
  }

  function transferAmount(data) {
    setShowLoader(true);
    getTransfer({ ...data, from: walletAddress })
      .then((res) => {
        if (res.data.status == 200) {
          toast.success("Transaction successful!");
          dispatch(dashboardList(userId));
          setTransferFormErrors(transferAmountSchema.getDefault());
          setTransferFormProps(defaultForm);
          setTransferFormData(transferAmountSchema.getDefault());
          // setTrans(res.data.transEst);
        }
        setShowLoader(false);
      })
      .catch((err) => {
        toast.warning("Oops! something went wrong");
        setShowLoader(false);
        // console.log("err", err)
      });
  }

  async function approveAmount(data) {
    const exampleMessage = "Example `personal_sign` message.";
    try {
      setShowLoader(true);
      const from = walletAddress;
      const msg = `0x${Buffer.from(exampleMessage, "utf8").toString("hex")}`;
      const sign = await window.ethereum.request({
        method: "personal_sign",
        params: [msg, from],
      });

      const dataToApprove = { ...data, signature: sign };
      getApprove(dataToApprove)
        .then((res) => {
          if (res.data.status === 200) {
            toast.success("Transaction approved successfully!");
            dispatch(dashboardList(userId));
            setApproveFormErrors(approveAllowanceSchema.getDefault());
            setApproveFormProps(defaultForm);
            setApproveFormData(approveAllowanceSchema.getDefault());
          }
          setShowLoader(false);
        })
        .catch((err) => {
          toast.warning("Oops! something went wrong");
          setShowLoader(false);
        });
    } catch (err) {
      toast.warning("Oops! something went wrong");
      setShowLoader(false);
    }
  }
  return (
    <Fragment>
      <div className="form-head mb-sm-5 mb-3 d-flex align-items-center flex-wrap">
        <h2 className="font-w600 mb-0 mr-auto mb-2 text-black">My Wallet</h2>
      </div>
      <div className="row">
        <div className="col-xl-3 col-xxl-4">
          <div className="swiper-box">
            <SwiperSlider2 />
          </div>
        </div>
        <div className="col-xl-9 col-xxl-8">
          <div className="row">
            <div className="col-xl-12">
              <div className="d-block d-sm-flex mb-4">
                <h4 className="mb-0 text-black fs-20 mr-auto">Details</h4>
                <div className="d-flex mt-sm-0">
                  {roleId == 1 ? (
                    <Button
                      onClick={() => {
                        setApproveFormProps({
                          open: true,
                          title: "Approve Allowance",
                          yesBtn: {
                            title: "Submit",
                            onTap: (data) => {
                              approveAmount(data);
                            },
                          },
                          noBtn: {
                            title: "Cancel",
                            onTap: () => {
                              setApproveFormErrors(
                                approveAllowanceSchema.getDefault()
                              );
                              setApproveFormProps(defaultForm);
                              setApproveFormData(
                                approveAllowanceSchema.getDefault()
                              );
                            },
                          },
                          onClose: () => {
                            setApproveFormErrors(
                              approveAllowanceSchema.getDefault()
                            );
                            setApproveFormProps(defaultForm);
                            setApproveFormData(
                              approveAllowanceSchema.getDefault()
                            );
                          },
                        });
                      }}
                      className="btn-link mr-3"
                    >
                      Approve Allowance
                    </Button>
                  ) : null}
                  <Button
                    onClick={() => {
                      setTransferFormProps({
                        open: true,
                        title: "Transfer Amount (LET)",
                        yesBtn: {
                          title: "Submit",
                          onTap: (data) => {
                            transferAmount(data);
                          },
                        },
                        noBtn: {
                          title: "Cancel",
                          onTap: () => {
                            setTransferFormErrors(
                              transferAmountSchema.getDefault()
                            );
                            setTransferFormProps(defaultForm);
                            setTransferFormData(
                              transferAmountSchema.getDefault()
                            );
                          },
                        },
                        onClose: () => {
                          setTransferFormErrors(
                            transferAmountSchema.getDefault()
                          );
                          setTransferFormProps(defaultForm);
                          setTransferFormData(
                            transferAmountSchema.getDefault()
                          );
                        },
                      });
                    }}
                    className="btn-link"
                  >
                    Quick Transfer (LET)
                  </Button>
                </div>
              </div>
            </div>
            <div className="col-xl-12">
              <div className="card">
                <div className="card-body">
                  <div className="row align-items-end">
                    <div className="col-xl-6 col-lg-12 col-xxl-12">
                      <div className="row">
                        <div className="col-sm-6">
                          <div className="mb-4">
                            <p className="mb-2">Account Name</p>
                            <h4 className="text-black">{fullName}</h4>
                          </div>
                          <div className="mb-4">
                            <p className="mb-2">Account Creation Date</p>
                            <h4 className="text-black">
                              {userCreatedAt
                                ? moment(userCreatedAt).format("DD/MM/YYYY")
                                : "N/A"}
                            </h4>
                          </div>
                          <div>
                            <p className="mb-2">Wallet Address</p>
                            <h4 className="text-black">[Hidden]</h4>
                          </div>
                        </div>
                        <div className="col-sm-6">
                          <div className="mb-4">
                            <p className="mb-2">Main Currency</p>
                            <h4 className="text-black">Ethereum</h4>
                          </div>
                          <div className="mb-4">
                            <p className="mb-2">Wallet Provider</p>
                            <h4 className="text-black">N/A</h4>
                          </div>
                          {/* <div>
                            <p className="mb-2">Card Theme</p>
                            <div
                              className="btn-group theme-colors"
                              data-toggle="buttons"
                            >
                              <label className="btn btn-primary btn-sm active">
                                <input
                                  type="radio"
                                  className="position-absolute invisible"
                                  name="options"
                                  id="option5"
                                />{" "}
                                <i className="las la-check-circle"></i>
                              </label>
                              <label className="btn btn-warning btn-sm">
                                <input
                                  type="radio"
                                  className="position-absolute invisible"
                                  name="options"
                                  id="option1"
                                  defaultChecked=""
                                />
                                <i className="las la-check-circle"></i>
                              </label>
                              <label className="btn btn-success btn-sm">
                                <input
                                  type="radio"
                                  className="position-absolute invisible"
                                  name="options"
                                  id="option2"
                                />{" "}
                                <i className="las la-check-circle"></i>
                              </label>
                              <label className="btn btn-info btn-sm">
                                <input
                                  type="radio"
                                  className="position-absolute invisible"
                                  name="options"
                                  id="option3"
                                />{" "}
                                <i className="las la-check-circle"></i>
                              </label>
                              <label className="btn btn-secondary btn-sm">
                                <input
                                  type="radio"
                                  className="position-absolute invisible"
                                  name="options"
                                  id="option4"
                                />{" "}
                                <i className="las la-check-circle"></i>
                              </label>
                            </div>
                          </div> */}
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-6 col-lg-12 col-xxl-12 mb-lg-0 mb-3">
                      {/* <p>Your Allowance</p> */}
                      <div className="row text-right">
                        <div className="col-sm-4 mb-sm-0 mb-4 text-center">
                          <div className="d-inline-block position-relative donut-chart-sale mb-3">
                            <Donut
                              value={
                                (data.accountCurrentAllowance /
                                  walletAccount.maxAllowance) *
                                100
                              }
                              backgroundColor="rgb(255, 104, 38)"
                              backgroundColor2={
                                background.value === "dark" ? "#2c254a" : "#eee"
                              }
                            />
                            <small>
                              {(
                                (data.accountCurrentAllowance /
                                  walletAccount.maxAllowance) *
                                100
                              ).toFixed(2)}
                              %
                            </small>
                          </div>
                          <h5 className="fs-18 text-black">Allowance</h5>
                          <p>
                            {data.accountCurrentAllowance}/
                            {nFormatter(walletAccount.maxAllowance, 2)}
                          </p>
                        </div>
                        <div className="col-sm-4 mb-sm-0 mb-4 text-center">
                          <div className="d-inline-block position-relative donut-chart-sale mb-3">
                            <Donut
                              value={
                                (data.accountTokenBalance /
                                  walletAccount.maxAllowance) *
                                100
                              }
                              backgroundColor="#1DC624"
                              backgroundColor2={
                                background.value === "dark" ? "#2c254a" : "#eee"
                              }
                            />
                            <small>
                              {(
                                (data.accountTokenBalance /
                                  walletAccount.maxAllowance) *
                                100
                              ).toFixed(2)}
                              %
                            </small>
                          </div>
                          <h5 className="fs-18 text-black">Balance</h5>
                          <p>{data.accountTokenBalance} LET</p>
                        </div>
                        {/* <div className="col-sm-4 text-center">
                          <div className="d-inline-block position-relative donut-chart-sale mb-3">
                            {background.value === "dark" ? (
                              <Donut
                                value={0}
                                backgroundColor="#9E9E9E"
                                backgroundColor2="#2c254a"
                              />
                            ) : (
                              <Donut
                                value={0}
                                backgroundColor="#9E9E9E"
                                backgroundColor2="#eee"
                              />
                            )}
                            <small>0%</small>
                          </div>
                          <h5 className="fs-18 text-black">Others</h5>
                          <i
                            className="flaticon-381-infinity"
                            style={{ fontSize: "1.5em" }}
                          ></i>
                        </div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="col-xl-12">
              <div className="card">
                <div className="card-header d-block d-sm-flex border-0 pb-sm-0 pb-0 align-items-center select-btn">
                  <div className="mr-auto mb-sm-0 mb-3">
                    <h4 className="fs-20 text-black">LogiE Token</h4>
                    <p className="mb-0 fs-12">
                      A brief overview of market value of LogiE Token
                    </p>
                  </div>
                  <Dropdown>
                    <Dropdown.Toggle
                      variant=""
                      className="form-control style-1 default-select"
                    >
                      {crrency1}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item
                        onClick={() => setCrrency1("Monthly (2021)")}
                      >
                        Monthly (2021)
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => setCrrency1("Daily (2021)")}
                      >
                        Daily (2021)
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => setCrrency1("Weekly (2021)")}
                      >
                        Weekly (2021)
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
                <div className="card-body pt-3">
                  <div className="flex-wrap mb-sm-4 mb-2 align-items-center">
                    <div className="d-flex align-items-center">
                      <span className="fs-32 text-black font-w600 pr-3">
                        0 LET
                      </span>
                      <span className="fs-22 text-success">
                        0%{" "}
                        <i
                          className="fa fa-caret-up scale5 ml-2 text-success"
                          aria-hidden="true"
                        ></i>
                      </span>
                    </div>
                    <p className="mb-0 text-black mr-auto">
                      Last Week <span className="text-success">$0</span>
                    </p>
                  </div>
                  <div
                    id="chartTimeline"
                    className="timeline-chart market-line"
                  >
                    <CoinChart />
                  </div>
                </div>
              </div>
            </div> */}

            <div className="col-xl-12  mt-2">
              <div className="row">
                <div className="col-xl-12">
                  <WalletTab
                    tabTitle="My NFts"
                    tabCaption={
                      "List of NFTs you owe on the TerraNova platform"
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <QuickTransfer /> */}
      </div>
      <Dialog {...dialogProps} />
      <Modal
        {...approveFormProps}
        showLoader={showLoader}
        data={approveFormData}
        submitWithData={true}
      >
        <ApproveAllowanceForm
          showLoader={showLoader}
          {...approveFormProps}
          {...approveFormData}
          errors={approveFormErrors}
          handleChangeForm={handleChangeApproveForm}
        />
      </Modal>
      <Modal
        {...transferFormProps}
        data={transferFormData}
        submitWithData={true}
        showLoader={showLoader}
      >
        <TransferForm
          showLoader={showLoader}
          {...transferFormProps}
          {...transferFormData}
          errors={transferFormErrors}
          handleChangeForm={handleChangeTransferForm}
        />
      </Modal>
    </Fragment>
  );
};
export default Wallet;
