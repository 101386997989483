export function getBoth(state) {
  let list = [];
  if (state.auth.auth.userId) {
    const users = state.users.list || [];
    // const individualRoleId = state.users.individualRoleId;
    // const businessRoleId = state.users.businessRoleId;
    // list = users.filter(
    //   (user) =>
    //     user.roleId === individualRoleId || user.roleId === businessRoleId
    // );
    list = users;
  }
  return list;
}
export function getIndividuals(state) {
  let list = [];
  if (state.auth.auth.userId) {
    const users = state.users.list || [];
    const individualRoleId = state.users.individualRoleId;
    list = users.filter((user) => user.roleId === individualRoleId);
  }
  return list;
}

export function getBusinesses(state) {
  let list = [];
  if (state.auth.auth.userId) {
    const users = state.users.list || [];
    const businessRoleId = state.users.businessRoleId;
    list = users.filter((user) => user.roleId === businessRoleId);
  }
  return list;
}
