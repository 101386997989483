import React, { useEffect, useRef, useState } from "react";
import GoogleMapReact from "google-map-react";
import "./map.css";
import TreePin from "../../../../../images/lottie/treePin.json";
import Lottie from "react-lottie-player";

const LocationPin = ({ tree }) => (
  <div className="pin">
    <Lottie
      loop={false}
      animationData={TreePin}
      play
      style={{
        width: 35,
        height: 35,
      }}
    />
    {/* <i className="flaticon-381-pin" style={{ fontSize: "30px" }}></i> */}
    <pre className="pin-text">
      <a href="#">
        <strong className="">{tree.user.first_name}</strong>
      </a>
      <br />
      planted a tree here
    </pre>
  </div>
);

const Map = ({ location, zoomLevel, mapKey, defaultLocation, trees }) => {
  const handleApiLoaded = (map, maps) => {
    // use map and maps objects
  };

  return (
    <GoogleMapReact
      bootstrapURLKeys={{ key: mapKey }}
      defaultCenter={defaultLocation}
      center={location}
      defaultZoom={12}
      zoom={zoomLevel}
      yesIWantToUseGoogleMapApiInternals
      onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
    >
      {trees && trees.length
        ? trees.map((tree, index) => (
          <LocationPin
            key={index}
            {...{ lat: tree.lat, lng: tree.lng, tree }}
          />
        ))
        : null}
    </GoogleMapReact>
  );
};

export default Map;
