import React from "react";
import DropZone from "../../../partials/DropZone";

function CreateHelpSupportForm(props) {
  const { handleChange, errors, formData } = props;


  return (
    <section>
      <div className="row">
        <div className="col-lg-12 mb-2">
          <div className="form-group">
            <label className="text-label">Topic*</label>
            <select
              name="topic"
              value={formData.topic}
              defaultValue={""}
              className={
                errors.topic
                  ? "form-control custom-select border-danger"
                  : "form-control custom-select"
              }
              required
              onChange={(e) => handleChange("topic", e.target.value)}
            >
              <option value={""}>Select</option>
              <option
                value={"Technical"}
                selected={formData.topic == "Technical"}
              >
                Technical
              </option>
              <option
                value={"LogiE Token"}
                selected={formData.topic == "LogiE Token"}
              >
                LogiE Token
              </option>
              <option
                value={"Web 3"}
                selected={formData.topic == "Web 3"}
              >
                Web 3
              </option>
              <option
                value={"NFT"}
                selected={formData.topic == "NFT"}
              >
                NFT
              </option>
              <option
                value={"Other"}
                selected={formData.topic == "Other"}
              >
                Other
              </option>
            </select>
            <small className="text-danger">{errors.topic}</small>
          </div>
        </div>
        <div className="col-lg-12 mb-2">
          <div className="form-group">
            <label className="text-label">Your Query*</label>
            <textarea
              style={{ height: "100px" }}
              value={formData.message}
              type="text"
              name="message"
              className={
                errors.message
                  ? "form-control border-danger"
                  : "form-control"
              }
              required
              onChange={(e) => handleChange("message", e.target.value)}
            />
            <small className="text-danger">{errors.message}</small>
          </div>
        </div>
        <div className="col-lg-12">
          <div className="form-group mt-3">
            <DropZone
              errors={errors}
              name="screenshot"
              src={formData.image ? formData.image.uri : null}
              title="Screenshot"
              caption="Add a screenshot of your problem if required"
              onlyImage={true}
              onChange={(file) => handleChange("image", file)}
              onRemove={() => handleChange("image", null)}
              error={errors.image}
              height="200px"
            />
          </div>
        </div>
      </div>
    </section>
  );
}

export default CreateHelpSupportForm;
