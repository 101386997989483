import React, { useState } from "react";
import {
  useStripe,
  useElements,
  PaymentElement,
} from "@stripe/react-stripe-js";
import { useHistory } from "react-router-dom";
import { Button } from "react-bootstrap";
import { BASE_URL } from "../../../../../services/URL";

const CheckoutForm = (props) => {
  const { successRoute, setIsLoading } = props;
  const stripe = useStripe();
  const elements = useElements();
  const history = useHistory();
  const [errorMessage, setErrorMessage] = useState(null);

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!stripe || !elements) {
      return;
    }
    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: successRoute
          ? BASE_URL + successRoute
          : BASE_URL + "/payment-message",
      },
    });
    if (error) {
      setIsLoading(false);
      setErrorMessage(error.message);
    } else {
      setIsLoading(false);
      history.goBack();
    }
  };

  return (
    <form>
      <PaymentElement />
      <Button
        className="mt-3"
        disabled={!stripe}
        onClick={(e) => {
          if (setIsLoading) setIsLoading(true);
          handleSubmit(e);
        }}
      >
        Pay
      </Button>
      {errorMessage && <div>{errorMessage}</div>}
    </form>
  );
};

export default CheckoutForm;
