import React, { Fragment, useEffect } from "react";
import { useSelector, useDispatch, connect } from "react-redux";
import PageTitle from "../../layouts/PageTitle";
import defaultImage from "../../../images/default.jpg";
import { getCompetitionPreview } from "../../../store/selectors/CompetitionSelector";
import { getCompetitionById } from "../../../store/actions/CompetitionActions";
import { useHistory, useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { Button } from "react-bootstrap";
import { AiOutlineAreaChart } from "react-icons/ai";
import { TbTargetArrow } from "react-icons/tb";
import { GiDuration } from "react-icons/gi";
import { QRCodeSVG } from "qrcode.react";
import TNLOGO from "../../../images/logo.png";
import ExpiryCounter from "./partials/contestPreview/ExpiryCounter";

const ContestPreview = (props) => {
  const { state } = useLocation();
  const { competitionId } = state || {};
  const competition = useSelector(getCompetitionPreview);
  const _participants = competition.participants;
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getCompetitionById(competitionId));
  }, []);



  return (
    <Fragment>
      <PageTitle
        pageTitle={"Preview Competition"}
        pageContent={" "}
        activeMenu="Competition Preview"
        motherMenu="Dashboard"
      />
      <Button onClick={() => history.goBack()}>
        Back
      </Button>
      <div className="row" style={{ marginTop: "-4px" }}>
        <div className="col-lg-12 text-center">
          <span style={{ color: "#BE1619" }}><ExpiryCounter expiryDate={competition && competition.expiredAt ? competition.expiredAt : null} /></span>
          <h5 className="fs-18" style={{ marginTop: "-5px" }}>{competition ? competition.title : null}</h5>
          <hr />
        </div>
      </div>
      <div className="row mt-2">
        <div className="col-lg-4 mt-3">
          <div className="card card-coin" style={{ width: "230px", height: "280px", borderRadius: "10px", overflow: "hidden" }}>
            <div className="card-body text-center p-0">
              <img
                style={{ width: "230px", height: "280px" }}
                src={competition && competition.banner && competition.banner !== null ? competition.banner.link : defaultImage}
              />
              <p style={{ fontWeight: "bold", marginTop: "-33px", textTransform: "capitalize", color: competition && competition.banner == null ? "black" : "white" }}>{competition ? competition.title : null}</p>
            </div>
          </div>
          <span>Contest known as</span>
          <h4>
            <strong>{competition.title}</strong> - '{competition.description}'
          </h4>
          {
            competition && competition.benefits && competition.benefits?.length > 0 ? (
              <div>
                <span>Benefits known as</span>
                {
                  competition.benefits.map((benefit, index) => {
                    return (
                      <h4 key={index}>
                        <strong>{benefit.amount !== " " ? benefit.amount : "N/A"}</strong> - '{benefit.type}'
                      </h4>
                    )
                  })
                }
              </div>
            ) : null
          }
          {
            competition ? (
              <div>
                <span>Reward known as</span>
                <h4>
                  <strong>{competition.rewardTitle}</strong> - '{competition.rewardDescription}'
                </h4>
              </div>
            ) : null
          }
          {
            competition && competition.event ? (
              <div>
                <span>Eevent known as</span>
                <h4>
                  <strong>{competition.event.title !== null ? competition.event.title : "N/A"}</strong> - '{competition.event.options !== null ? competition.event.options : "N/A"}'
                </h4>
              </div>
            ) : null
          }
          {
            competition && competition.participants && competition.participants?.length > 0 ? (
              <div style={{ display: "flex" }}>
                {"Participants are - "}<h4 style={{ marginLeft: "3px" }}>{competition.participants.length}</h4>
              </div>
            ) : null
          }
        </div>
        <div className="col-lg-4 mt-5">
          {competition ? (
            <div>
              <div className="">
                <AiOutlineAreaChart style={{ marginRight: "10px" }} size={40} />
                <span>Reach : </span>
                <span>
                  <strong>{competition.reach}</strong>
                </span>
              </div>
              <div className="mt-5">
                <TbTargetArrow style={{ marginRight: "10px" }} size={40} />
                <span>Target : </span>
                <span>
                  <strong>{competition.target + " "}</strong><span>{competition.type === "Tree Plantation" ? "Trees" : competition.type === "Purchase Product" ? "Products" : competition.type === "Event" ? "Event" : null}</span>
                </span>
              </div>
              <div className="mt-5">
                <GiDuration style={{ marginRight: "15px" }} size={40} />
                <span>Duration : </span>
                <span>
                  <strong>{competition.duration + " "}</strong><span>Day's</span>
                </span>
              </div>
            </div>
          ) : null}
        </div>
        <div className="col-lg-4">
          {
            competition && competition.product !== null ? (
              <div>
                <div className="card card-coin" style={{ borderRadius: "10px", width: "290px" }}>
                  <div className="card-body">
                    <div className="align-items-center mt-2">
                      <QRCodeSVG
                        value={`terranova:contest:${competition.productId}`}
                        size={230}
                        bgColor={"#ffffff"}
                        level={"L"}
                        includeMargin={true}
                        style={{ borderRadius: 10 }}
                        imageSettings={{
                          src: TNLOGO,
                          x: undefined,
                          y: undefined,
                          height: 50,
                          width: 50,
                          excavate: true,
                        }}
                      />
                    </div>
                  </div>
                </div>
                <span>Product known as</span>
                <h4>
                  <strong>{competition.product && competition.product.title ? competition.product.title : null}</strong> - '{competition.product && competition.product.description ? competition.product.description : null}'
                </h4>
              </div>
            ) : null
          }
        </div>
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    successMessage: state.auth.successMessage,
    showLoading: state.auth.showLoading,
  };
};

export default connect(mapStateToProps)(ContestPreview);
