import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getGasEST } from "../../../../../services/WalletService";
import letIcon from "../../../../../images/logos/logieToken.png";
import { getWalletAccount } from "../../../../../store/selectors/AuthSelectors";
import "../../../../../css/screen-transition.css";

function GasEST(props) {
  const { method, to, amount } = props;
  const [gas, setGas] = useState(null);
  const [gasAnimation, setGasAnimation] = useState("fade-in");
  const account = useSelector(getWalletAccount);

  useEffect(() => {
    let interval = null;
    if (to) {
      getData();
      interval = setInterval(() => {
        setGasAnimation("fade-out");
        getData();
      }, 8000);
    }
    return () => {
      clearInterval(interval);
    };
  }, [to]);

  function getData() {
    getGasEST({ method, from: account.address, to, amount })
      .then((res) => {
        if (res.data.status == 200) {
          setGasAnimation("fade-in");
          setGas(res.data.gasEst);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  return gas ? (
    <section>
      <div className="row mb-3 align-items-center">
        <div className="col-lg-3">
          <div>
            <strong>Asset:</strong>
          </div>
        </div>
        <div className="col-lg-5 row align-items-center">
          <img src={letIcon} width="30px" height="30px" />
          <div className="ml-1">LogiE Token</div>
        </div>
      </div>
      <div className="row mb-3 align-items-center">
        <div className="col-lg-3">
          <div>
            <strong>Gas</strong>(estimated)
          </div>
        </div>
        <div className="col-lg-5 row align-items-center">
          <div className={gasAnimation}>
            <strong>{gas} ETH</strong>
          </div>
        </div>
      </div>
    </section>
  ) : null;
}

export default GasEST;
