import React, { Fragment, useEffect, useRef, useState } from "react";
import PageTitle from "../../../../layouts/PageTitle";
import { reduxForm } from "redux-form";
import {
  getUserId,
  getUser,
} from "../../../../../store/selectors/AuthSelectors";
import { listCollections as listCollectionsAction } from "../../../../../store/actions/DonationActions";
import { loadingToggleAction } from "../../../../../store/actions/AuthActions";
import { getCollectionsList } from "../../../../../store/selectors/DonationSelector";
import { useSelector, useDispatch, connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { AiOutlineArrowLeft } from "react-icons/ai";
import FormData from "form-data";
import { createCollectionDonation } from "../../../../../services/DonationService";
import Loading from "../../../partials/Loading";
import { collectionDonationValidator } from "../../../../../api/validator";
import { addCollectionDonation } from "../../../../../store/actions/DonationActions";
import "../../../../../css/screen-transition.css";
import { Button } from "react-bootstrap";
import CollectionForm from "./CollectionForm";
import { toast } from "react-toastify";

const CollectionDonationForm = (props) => {
  const { showLoading } = props;
  const dispatch = useDispatch();
  const user = useSelector(getUser);
  const userId = useSelector(getUserId);
  const [category, setCategory] = useState(null);
  const collections = useSelector(getCollectionsList);
  const [errors, setErrors] = useState({});
  const history = useHistory();
  const collectionList = useRef();
  const [showLoader, setShowLoader] = useState(false);

  const [data, setData] = useState({
    userId,
    image: null,
    banner: null,
    title: "",
    s_description: "",
    l_description: "",
    type: "",
    type_value: "",
    countryId: "",
    region: "",
    goal: "",
    duration: "",
    oName: user
      ? (user.first_name || "") +
        (user.last_name ? " " : "") +
        (user.last_name || "")
      : "",
    oAddress: "",
    oEmail: user ? user.email || "" : "",
    oPhone: "",
    oPhone_code: "",
    otherCon: "",
    otherCon_code: "",
    whatsapp_number: "",
    wa_num_code: "",
    budget: "",
    currency_code: "",
    documents: [],
    isGroupProject: false,
    groupProjectId: null,
    current_stage:"",
  });

  const [helperData, setHelperData] = useState({
    oPhone_city: null,
    otherCon_city: null,
  });

  const [fadeAnim, setFadeAnim] = useState({
    leftContainer: "fade-in-left",
    rightContainer: "fade-in-right",
  });

  useEffect(() => {
    dispatch(listCollectionsAction("collection"));
  }, []);

  useEffect(() => {
    if (collections.length && category == null) {
      setTimeout(() => {
        setCategory(collections[0]);
        collectionList?.current?.show();
      }, 300);
    }
  }, [collections]);

  function handleAnim(key, value) {
    setFadeAnim((pre) => ({ ...pre, [key]: value }));
  }

  function handleAnimControl() {
    handleAnim("leftContainer", "fade-out-left");
    handleAnim("rightContainer", "fade-out-right");
  }

  function removeDocument(key) {
    setData((pre) => ({
      ...pre,
      documents: pre.documents.filter((_, index) => index !== key),
    }));
    setErrors((pre) => ({ ...pre, [key]: "" }));
  }

  function handleHelperData(key, value) {
    setHelperData((pre) => ({ ...pre, [key]: value }));
  }

  function handleChange(key, value) {
    if (key === "documents") {
      const uploadedFilesCount = value && value.length ? value.length : 0;
      const totalFilesCount = data.documents.length + uploadedFilesCount;
      if (data.documents.length === 5 || totalFilesCount > 5) {
        setErrors((pre) => ({ ...pre, [key]: "Max 5 documents are allowed" }));
      } else {
        setData((pre) => ({ ...pre, [key]: [...pre[key], ...value] }));
        setErrors((pre) => ({ ...pre, [key]: "" }));
      }
    } else {
      setData((pre) => ({ ...pre, [key]: value }));
      setErrors((pre) => ({ ...pre, [key]: "" }));
    }
  }

  function handleChangeType(key, value) {
    if (key === "type") {
      let types = data[key] ? data[key].split(",") : [];
      if (types.includes(value)) {
        types.splice(
          types.findIndex((t) => t === value),
          1
        );
        setData((pre) => ({
          ...pre,
          [key]: types.join(","),
        }));
      } else {
        types.push(value);
        setData((pre) => ({
          ...pre,
          [key]: types.join(","),
        }));
      }
      setErrors((pre) => ({ ...pre, [key]: "" }));
    } else {
      setData((pre) => ({ ...pre, [key]: value }));
      setErrors((pre) => ({ ...pre, [key]: "" }));
    }
  }

  function onSubmit() {
    setShowLoader(true);
    try {
      dispatch(loadingToggleAction(true));
      collectionDonationValidator
        .validate(data, {
          abortEarly: false,
        })
        .then(async (valid) => {
          const formdata = new FormData();
          for (const key in data) {
            if (key == "image" || key == "banner") {
              formdata.append(key, data[key].file);
            } else if (key === "documents") {
              if (data[key] && data[key].length) {
                for (let i = 0; i < data[key].length; i++) {
                  formdata.append("documents", data[key][i].file);
                }
              }
            }
            // else if (key === "oPhone" || key === "otherCon") {
            //   formdata.append(key, String(user.city.phone_code + data[key]));
            // }
            else {
              formdata.append(key, String(data[key]));
            }
          }
          const res = await createCollectionDonation(formdata);
          setShowLoader(false);
          if (res.data.status == 200) {
            toast.success("Project successfully created!");
            dispatch(addCollectionDonation(res.data.newDonation));
            history.push("/my-projects");
          }
        })
        .catch((err) => {
          setShowLoader(false);
          toast.warning("Oops! something went wrong");

          if (err.inner) {
            let errs = {};
            err.inner.forEach((e) => {
              errs = { ...errs, [e.path]: e.message };
            });
            setErrors(errs);
          }
          // console.log("err", err);
        });
    } catch (error) {
      dispatch(loadingToggleAction(false));
    } finally {
      dispatch(loadingToggleAction(false));
    }
  }
  return (
    <Fragment>
      {showLoading ? <Loading /> : null}
      <PageTitle
        pageTitle={"Create Projects"}
        pageContent={" "}
        activeMenu="Add Donation"
        motherMenu="Dashboard"
      />
      <Button
        style={{ marginBottom: "20px" }}
        onClick={() => {
          handleAnimControl();
          setTimeout(() => {
            history.goBack();
          }, 300);
        }}
      >
        <AiOutlineArrowLeft className="mr-1" />
        Back
      </Button>
      <div className={fadeAnim.rightContainer}>
        <CollectionForm
          {...{
            showLoader,
            data,
            removeDocument,
            errors,
            onSubmit,
            handleChange,
            handleChangeType,
            handleHelperData,
            helperData,
          }}
        />
      </div>
    </Fragment>
  );
};

const formWizard = reduxForm({
  form: "donationForm",
})(CollectionDonationForm);

const submitFormWizard = connect((state) => {
  return { state };
})(formWizard);

const mapStateToProps = (state) => {
  return {
    errorMessage: state.auth.errorMessage,
    successMessage: state.auth.successMessage,
    showLoading: state.auth.showLoading,
  };
};

export default connect(mapStateToProps)(submitFormWizard);
