import api from "./AxiosInstance";

export function createFeedback(data) {
    return api.post("feedbacks", data);
}

export function listAllFeedback() {
    return api.get("feedbacks");
}

export function getFeedbackById(feedbackId) {
    return api.get("feedbacks/" + feedbackId);
}