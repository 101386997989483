import React, { Fragment, useState } from "react";
import { Button } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import alert from "./alert.json";
import Lottie from "lottie-react";
import { useEffect } from "react";
import { AiOutlineArrowLeft } from "react-icons/ai";


const ContestParticipate = (props) => {
    const { } = props;
    const history = useHistory();
    const [showAnimation, setShowAnimation] = useState(false);

    useEffect(() => {
        setShowAnimation(true);
    }, []);

    return (
        <Fragment>
            <Button onClick={() => history.goBack()}>
                <AiOutlineArrowLeft className="mr-1" />
                Back
            </Button>
            <div className="row" style={{ justifyContent: "center" }}>
                <div className="col-xl-5 col-sm-6 m-t35">
                    <div className="card card-coin" style={{ borderRadius: "20px" }}>
                        <div className="card-body text-center">
                            <div
                                className="col-lg-12"
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                }}
                            >
                                {showAnimation ? (
                                    <Lottie
                                        style={{ width: "100px", height: "100px" }}
                                        animationData={alert}
                                        loop={false}
                                    />
                                ) : null}
                            </div>
                            <h4 className="fs-20 mt-3">Alert</h4>
                            <hr />
                            <div className="">
                                <p>To participate in this contest, please Download Mobile App TerraNove</p>
                                <div className="mt-1 d-flex justify-content-center">
                                    <Button
                                        style={{ marginBottom: "20px" }}
                                        onClick={() => {
                                            window.open("https://www.linkedin.com/company/terranova-technologies/");
                                        }}
                                    >
                                        Download
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default ContestParticipate;
