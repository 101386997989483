export const URL =
  window.location.hostname.includes("test") ||
  window.location.hostname === "localhost"
    ? "https://terranova-api-test.herokuapp.com/"
    : "https://terra-nova.herokuapp.com/";

// export const URL = "http://localhost:3001/";
// export const URL = "https://terra-nova.herokuapp.com/";
// export const URL = " https://terranova-api-test.herokuapp.com/";

export const BASE_URL = "https://portal.terra-nova.io";
export const STRIPE_PUBLIC_KEY =
  "pk_live_51NYYMDA2Rz5TmUjbykgqh6gvfovR3SNOqql3es9WoIOfBpwpBMgIiCpH1oJ7ZkY0dDjKYLW9GYLQSQFnzW6K9QbG00dYBfsWFa";
// export const URL = "https://bf79-2400-adc7-901-1200-b5b0-838a-222d-7403.in.ngrok.io/";
