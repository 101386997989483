import React, { Fragment, useEffect, useState } from "react";
import { useSelector, useDispatch, connect } from "react-redux";
import FilteringTable from "../table/FilteringTable/FilteringTable";
import { ColumnFilter } from "../table/FilteringTable/ColumnFilter";
import { Badge, Dropdown } from "react-bootstrap";
import Dialog from "../partials/Dialog";
import {
  getTokenRewardsAction,
  getTokenRewardRequestsAction,
  removeTokenRewardsAction,
  transferRewardByIdAction,
  updateTokenRewardsAction,
  transferAllRewardAction,
} from "../../../store/actions/TokenRewardActions";
import {
  getTokenRewards,
  getTokenRewardRequests,
} from "../../../store/selectors/TokenRewardSelector";
import { useHistory } from "react-router-dom";
// import moment from "moment";
import { getRoleId } from "../../../store/selectors/AuthSelectors";
import { toast } from "react-toastify";

const ThreeDots = (
  <svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <rect x="0" y="0" width="24" height="24"></rect>
      <circle fill="#000000" cx="5" cy="12" r="2"></circle>
      <circle fill="#000000" cx="12" cy="12" r="2"></circle>
      <circle fill="#000000" cx="19" cy="12" r="2"></circle>
    </g>
  </svg>
);

const TokenRewardList = (props) => {
  const dispatch = useDispatch();
  const roleId = useSelector(getRoleId);
  const history = useHistory();
  const dataList = useSelector(getTokenRewards);
  const dataRequests = useSelector(getTokenRewardRequests);

  let data = [];
  if (roleId === 1) {
    data = dataRequests;
  } else {
    data = dataList;
  }

  const defaultDialog = {
    open: false,
    title: "",
    text: "",
    onClose: () => {},
  };

  let headerBtns = [
    {
      title: "+ Add",
      onClick: () => {
        history.push("add-token-reward");
      },
    },
  ];

  if (roleId === 1) {
    headerBtns = [
      ...headerBtns,
      {
        title: "Approve All Requests",
        onClick: () => {
          approveAll();
        },
      },
    ];
  }
  const [dialogProps, setDialogProps] = useState(defaultDialog);

  const columns = [
    {
      Header: "#",
      Footer: "#",
      accessor: "id",
      Filter: ColumnFilter,
    },
    {
      Header: "Name",
      Footer: "Name",
      Filter: ColumnFilter,
      Cell: (cell) => {
        return `${cell.row.original.first_name} ${cell.row.original.last_name}`;
      },
    },
    {
      Header: "Contact",
      Footer: "Contact",
      Filter: ColumnFilter,
      Cell: (cell) => {
        return (
          <div>
            <strong>{cell.row.original.email}</strong>
            <br />
            <small>{cell.row.original.phone}</small>
          </div>
        );
      },
    },
    {
      Header: "Status",
      Footer: "Status",
      Filter: ColumnFilter,
      Cell: (cell) => {
        let status = <Badge variant="light">{cell.row.original.status}</Badge>;
        if (cell.row.original.status === "Pending") {
          status = <Badge variant="info light">Pending Review</Badge>;
        } else if (cell.row.original.status === "Rejected") {
          status = <Badge variant="danger light">Rejected</Badge>;
        } else if (cell.row.original.status === "Approved") {
          status = <Badge variant="success light">Approved</Badge>;
        }
        return status;
      },
    },
    {
      Header: "Actions",
      Footer: "Actions",
      Cell: (cell) => {
        let variant = "success";
        return (
          <Dropdown>
            <Dropdown.Toggle variant={variant} className="light sharp i-false">
              {ThreeDots}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {roleId === 1 ? (
                <Dropdown.Header>Change Status</Dropdown.Header>
              ) : null}
              {/* <Dropdown.Item
                onSelect={() => {
                  history.push(
                    "update-token-allocation/" + cell.row.original.id
                  );
                }}
                variant="danger"
                className="danger light sharp i-false"
              >
                Edit
              </Dropdown.Item> */}
              {roleId === 1 ? (
                <Dropdown.Item
                  onSelect={() => {
                    setDialogProps({
                      open: true,
                      title: "Confirmation",
                      text: "This action will transfer the listed LogiE Token(s) to the recipients wallet. Are you sure to confirm?",
                      yesBtn: {
                        title: "Yes",
                        onTap: () => {
                          approve(cell.row.original.id);
                          setDialogProps(defaultDialog);
                        },
                      },
                      noBtn: {
                        title: "No",
                        onTap: () => setDialogProps(defaultDialog),
                      },
                      onClose: () => setDialogProps(defaultDialog),
                    });
                  }}
                  variant="danger"
                  className="danger light sharp i-false"
                >
                  Approve
                </Dropdown.Item>
              ) : null}
              {roleId === 1 ? (
                <Dropdown.Item
                  onSelect={() => {
                    setDialogProps({
                      open: true,
                      title: "Confirmation",
                      text: "This action will reject the request to transfer the LogiE Token(s). Are you sure to confirm?",
                      yesBtn: {
                        title: "Yes",
                        onTap: (note) => {
                          reject(cell.row.original.id, note);
                          setDialogProps(defaultDialog);
                        },
                      },
                      noBtn: {
                        title: "No",
                        onTap: () => setDialogProps(defaultDialog),
                      },
                      input: {
                        required: true,
                        placeholder: "Reason for rejection",
                      },
                      onClose: () => setDialogProps(defaultDialog),
                    });
                  }}
                  variant="danger"
                  className="danger light sharp i-false"
                >
                  Reject
                </Dropdown.Item>
              ) : null}
              <Dropdown.Header>Actions</Dropdown.Header>
              <Dropdown.Item
                onSelect={() => {
                  setDialogProps({
                    open: true,
                    title: "Confirmation",
                    text: "This action is irreversible. Are you sure to proceed?",
                    yesBtn: {
                      title: "Yes",
                      onTap: () => {
                        remove(cell.row.original.id);
                        setDialogProps(defaultDialog);
                      },
                    },
                    noBtn: {
                      title: "No",
                      onTap: () => setDialogProps(defaultDialog),
                    },
                    onClose: () => setDialogProps(defaultDialog),
                  });
                }}
                variant="danger"
                className="danger light sharp i-false"
              >
                Delete
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        );
      },
    },
  ];

  useEffect(() => {
    if (roleId === 1) {
      dispatch(getTokenRewardRequestsAction());
    } else {
      dispatch(getTokenRewardsAction());
    }
  }, []);

  function approve(tokenRewardId) {
    dispatch(transferRewardByIdAction(tokenRewardId, { toast }));
  }

  function reject(tokenRewardId, note) {
    dispatch(
      updateTokenRewardsAction(
        tokenRewardId,
        { status: "Rejected", note },
        { toast }
      )
    );
  }

  function approveAll() {
    dispatch(transferAllRewardAction({ toast }));
  }

  function remove(tokenRewardId) {
    dispatch(removeTokenRewardsAction(tokenRewardId));
  }

  return (
    <Fragment>
      <div className="col-xl-12 col-xxl-12">
        <FilteringTable
          data={data}
          columns={columns}
          title="Token Rewards"
          headerBtns={headerBtns}
        />
      </div>
      <Dialog {...dialogProps} />
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    successMessage: state.auth.successMessage,
    showLoading: state.auth.showLoading,
  };
};

export default connect(mapStateToProps)(TokenRewardList);
